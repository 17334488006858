<template>
  <div id="app">
    <component :is="this.$route.meta.layout || 'div'" @show-loading="showLoading" >
      <router-view @show-loading="showLoading" />
    </component>
    <Loading :type="loading_type" :isVisible="loading_isVisible" :message="loading_text" :transition="loading_transition" />
  </div>
</template>

<script>
import Loading from "@/components/Loading";

export default {
  name: 'app',
  components: {
    Loading,
  },
  data() {
    return {
      loading_isVisible: true,
      loading_type: "SESSION",
      loading_text: "",
      loading_transition: "",
    };
  },
  created() {
    try {
    } catch (error) {
      throw Error("[App.vue] created()::ERROR = "+error);
    }
  },
  async mounted() {
    try {
      var routeAccessType = this.$router.currentRoute.meta.routeAccessType;
      this.loading_isVisible = true;
      this.loading_type = routeAccessType;
      this.loading_text = "";
      this.loading_transition = "";
      await this.$nextTick();

    } catch (error) {
      throw Error("[App.vue] mounted()::ERROR = "+error);
    }
  },
  methods: {
    showLoading(value,text='',transition='LEFT'){
      var routeAccessType = this.$router.currentRoute.meta.routeAccessType;
      this.loading_isVisible = value;
      this.loading_type = routeAccessType;
      this.loading_text = text;
      this.loading_transition = transition;
    },
  },
  metaInfo: {
    title: 'Biotransfer | Soluciones Biotecnológicas',
    meta: [
      { name:"description", content:"Biotransfer | Soluciones Biotecnológicas" },
      { name:"author", content:"Pixellar" },
      { name:"og:title", content:"Biotransfer | Soluciones Biotecnológicas" },
      { name:"og:site_name", content:"Biotransfer | Soluciones Biotecnológicas" },
      { name:"og:locale", content:"es_ES" },
      { name:"og:type", content:"website" },
      { name:"og:url", content:"https://www.biotransfer.pe" },
      //{ name:"og:image", content:"{{ asset('images/fbthumb200.png') }}" },
      { name:"og:description", content:"Biotransfer | Soluciones Biotecnológicas" },
    ]
  },
};
</script>

<style>
/* Custom Fonts */
@import './assets/fonts/site-fonts.css';
@import './views/css/page/page-style.css';
@import './views/css/page/page-header-style.css';
@import './views/css/page/page-list-style.css';
@import './views/css/page/page-form-style.css';


</style>
