import bgImage from "@/assets/images/bg.png";
import SessionLayout from "@/components/layout/session/SessionLayout";
import ContenidoEditar from '@/views/pages/admin/configuracion/ContenidoEditar';

const adminConfiguracionRoutes = [
    {
        path: "/admin/configuracion/editar",
        name: "session_contenido_editar",
        component: ContenidoEditar,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
]

export default adminConfiguracionRoutes