<template>
  <div class="sidebarMenu">
    <div class="sidebarMenu-backdrop" :style="computedBackgroundStyle" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
    <transition :name="computedTransition">
      <div v-if="isPanelOpen"
           class="sidebarMenu-panel" :style="computedStyle">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
  export default {
    name: "SidebarMenu",
    props: {
      backgroundColor: {
        type: String,
        default: "#FFFFFF",
      },
      orientation: {
        type: String,
        default: "right",
      },
      width: {
        type: String,
        default: "200px",
      },
    },
    async mounted() {
      try {
        await this.$nextTick();

      } catch (error) {
        console.log("[SidebarMenu.vue] mounted()::ERROR = "+error);
      }
    },
    computed: {
      computedBackgroundStyle(){
        var backgroundColor = this.$props.backgroundColor;
        return {
          'background-color': backgroundColor,
        }

      },
      computedTransition() {
        if(this.$props.orientation=="left"){
          return 'slide-left';
        }

        return 'slide-right';
      },
      computedStyle(){
        if(this.$props.orientation=="left"){
          return {
            'left': 0,
            'width': this.$props.width,
            //'background-color': this.$props.backgroundColor,
          };
        }

        return {
          'right': 0,
          'width': this.$props.width,
          //'background-color': this.$props.backgroundColor
        };

      },
      isPanelOpen() {
        return this.$store.getters['sidebarMenuStore/getIsOpen'];
      }
    },
    methods: {
      closeSidebarPanel() {
        var isOpen = this.$store.getters['sidebarMenuStore/getIsOpen'];
        this.$store.dispatch('sidebarMenuStore/setIsOpen',!isOpen);
        this.isPanelOpen;
      }
    }
  }
</script>
<style scoped>
  .sidebarMenu-backdrop {
    width: 100vw;
    height: 100vh;
    z-index: 11;
    position: fixed;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .sidebarMenu-panel {
    overflow-y: auto;
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 100;
  }

  /* ================================= */

  .slide-right-enter-active,
  .slide-right-leave-active
  {
    transition: transform 0.2s ease;
  }

  .slide-right-enter,
  .slide-right-leave-to {
    transform: translateX(100%);
    transition: all 150ms ease-in 0s
  }

  .slide-left-enter-active,
  .slide-left-leave-active
  {
    transition: transform 0.2s ease;
  }

  .slide-left-enter,
  .slide-left-leave-to {
    transform: translateX(-100%);
    transition: all 150ms ease-in 0s
  }

</style>

