<template>
    <div class="dropDownList__border" :style="computedBorderColor">
      <select class="dropDownList__select" :style="computedFontColor"
              v-model="selected"
              @input="updateValue($event.target.value)">
        <option v-for="(option, index) in options" :value="option.id" :selected="option.id==selected">
          {{ option.nombre }}
        </option>
      </select>
    </div>
</template>

<script>
    export default {
      name: "DropDownList",
      props: {
        value: {
          type: [Number,String],
          default: "",
        },
        options: {
            type: Array,
        },
        bgColor: {
          type: String,
          default: "#FFFFFF",
        },
        bdColorError: {
          type: String,
          default: '#BD0000'
        },
        fontColor: {
          type: String,
          default: "#5C5C5C",
        },
        isError:{
            type: [String,Boolean],
            default: false,
        }
      },
      data() {
        return {
        }
      },
      created(){
        if(this.options.length>0){
          const option = this.options[0].id;
          this.$emit("input", option);
        }
      },
      async mounted() {
        try {
          await this.$nextTick();

        } catch (error) {
          console.log("[DropDownList.vue] mounted()::ERROR = "+error);
        }
      },
      computed: {
        selected: {
          get(){
            this.$emit("input", this.value);
            return this.value
          },
          set(v){
            this.$emit('input', v)
          }
        },
        computedBorderColor() {
          var isError = this.$props.isError;
          var bdColorError = this.$props.bdColorError;
          var bgColor = this.$props.bgColor;

          var objStyle = {};
          objStyle['background-color'] = bgColor;

          if(isError){
            objStyle['border'] = '2px solid '+bdColorError;
          } else {
            objStyle['border'] = '2px solid '+bgColor;
          }

          return objStyle;
        },
        computedFontColor() {
          var fontColor = this.$props.fontColor;
          return {
            'color':fontColor,
          };
        },
      },
      methods: {
        updateValue(value) {
          console.log("updateValue",value);
          this.$emit('input', value);
        }
      }
    };
</script>

<style scoped>
.dropDownList__border{
  border-radius:8px;
  padding:7px 10px 6px 10px;
}

.dropDownList__border select{
  /*color: #abb2ba;*/
}
.dropDownList__border option{
  font-family: 'roboto-regular';
}
.dropDownList__border option:first-child{
  font-family: 'roboto-regular';
}

.dropDownList__border-error select{
  /*color: #dc7676;*/
}
.dropDownList__border-error option{
  font-family: 'roboto-regular';
  /*color: #BD0000;*/
}
.dropDownList__border-error option:first-child{
  font-family: 'roboto-regular';
  /*color: #dc7676;*/
}

.dropDownList__select{
  font-family: 'roboto-regular';
  background-color:transparent;
  border:none;
  width:100%;
  -webkit-appearance: none;
  -moz-appearance : none;
  background-repeat: no-repeat;
  background-position:right center;
  padding:0;
  margin:0;
}

.dropDownList__select:focus {
  outline: none;
  border:0;
  background-color:transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}


/* Small only */
@media screen and (max-width: 39.9375em) {
  .dropDownList__select{
    height:19px;
    font-size:0.75em;
    line-height:1em;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .dropDownList__select{
    height:19px;
    font-size:0.8em;
    line-height:1em;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  .dropDownList__select{
    height:19px;
    font-size:0.85em;
    line-height:1em;
  }
}


</style>
