<template>
  <div class="textArea__border" :style="computedBorderColor">
    <span v-if="maxlength>0">
      <span v-if="type=='number'">
        <textarea  type="number"
                :rows="rowCount"
                :class="computedInputClass"
                :style="computedFontColor"
                v-bind:value="value"
                v-on:input="updateValue($event.target.value)"
                :placeholder="computedPlaceholder"
                :maxlength="maxlength"
                @keypress="typeNumber"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        ></textarea>
      </span>
      <span v-else>
        <span v-if="type=='password'">
          <textarea  type="password"
                  :rows="rowCount"
                  :class="computedInputClass"
                  :style="computedFontColor"
                  v-bind:value="value"
                  v-on:input="updateValue($event.target.value)"
                  :placeholder="computedPlaceholder"
                  :maxlength="maxlength"
                  @keypress="typeText"
          ></textarea>
        </span>
        <span v-else>
          <textarea :rows="rowCount"
                  :class="computedInputClass"
                  :style="computedFontColor"
                  v-bind:value="value"
                  v-on:input="updateValue($event.target.value)"
                  :placeholder="computedPlaceholder"
                  :maxlength="maxlength"
                  @keypress="typeText"
          ></textarea>
        </span>
      </span>
    </span>
    <span v-else>
      <span v-if="type=='number'">
        <textarea  type="number"
                :rows="rowCount"
                :class="computedInputClass"
                :style="computedFontColor"
                v-bind:value="value"
                v-on:input="updateValue($event.target.value)"
                :placeholder="computedPlaceholder"
                @keypress="typeNumber"
        ></textarea>
      </span>
      <span v-else>
        <span v-if="type=='password'">
          <textarea  type="password"
                  :rows="rowCount"
                  :class="computedInputClass"
                  :style="computedFontColor"
                  v-bind:value="value"
                  v-on:input="updateValue($event.target.value)"
                  :placeholder="computedPlaceholder"
                  @keypress="typeText"
          ></textarea>
        </span>
        <span v-else>
          <textarea :rows="rowCount"
                  :class="computedInputClass"
                  :style="computedFontColor"
                  v-bind:value="value"
                  v-on:input="updateValue($event.target.value)"
                  :placeholder="computedPlaceholder"
                  @keypress="typeText"
          ></textarea>
        </span>
      </span>
    </span>
  </div>
</template>

<script>
  export default {
    name: "TextBox",
    props: {
      value: {
        type: [Number,String],
        default: "",
      },
      rowCount: {
        type: [Number,String],
        default: 5,
      },
      type: {
        type: String,
        default: "text",
      },
      placeholder: {
        type: String,
        default: "",
      },
      maxlength: {
        type: [String,Number],
        default: "",
      },
      bgColor: {
        type: String,
        default: "#FFFFFF",
      },
      bdColorError: {
        type: String,
        default: '#BD0000'
      },
      fontColor: {
        type: String,
        default: "#5C5C5C",
      },
      isError:{
          type: Boolean,
          default: false,
      }
    },
    async mounted() {
      try {
        await this.$nextTick();

      } catch (error) {
        console.log("[TextArea.vue] mounted()::ERROR = "+error);
      }
    },
    computed: {
      computedType(){
        return this.$props.type;
      },
      computedInputClass() {
        var isError = this.$props.isError;

        if(isError){
          return 'textArea__input-error';
        }

        return 'textArea__input';
      },
      computedBorderColor() {
        var isError = this.$props.isError;
        var bgColor = this.$props.bgColor;
        var bdColorError = this.$props.bdColorError;

        var objStyle = {};
        objStyle['background-color'] = bgColor;

        if(isError){
          objStyle['border'] = '2px solid '+bdColorError;
        } else {
          objStyle['border'] = '2px solid '+bgColor;
        }

        return objStyle;
      },
      computedFontColor() {
        var fontColor = this.$props.fontColor;
        console.log("textarea - fontcolor",fontColor);

        var objStyle = {};
        objStyle['color'] = fontColor;

        return objStyle;
      },
      computedPlaceholder() {
        var placeholder = this.$props.placeholder;

        return placeholder;
      }
    },
    methods: {
      typeText: function ($event) {
      },
      typeNumber: function ($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          $event.preventDefault();
        }
      },
      updateValue: function (value) {
          this.$emit('input', value)
      }
    }
  };
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.textArea__input{
  font-family: 'roboto-regular';
  width:100%;
  border:0;
  background-color: transparent;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;

  padding:0;
  margin:0;
}

.textArea__input::placeholder {
  color: #626f7f;
}

.textArea__input:focus {
  outline: none;
  border:0;
  background-color:transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.textArea__input:disabled {
  background: transparent;
}

.textArea__input-error{
  font-family: 'roboto-regular';
  width:100%;
  border:0;
  background-color: transparent;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;

  padding:0;
  margin:0;
}

.textArea__input-error::placeholder {
  color: #626f7f;
}

.textArea__input-error:focus {
  outline: none;
  border:0;
  background-color:transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.textArea__input-error:disabled {
  background: transparent;
}


/* Small only */
@media screen and (max-width: 39.9375em) {
  .textArea__border{
    border-radius:8px;
    padding:12px 10px;
  }

  .textArea__input{
    font-size:0.8em;
    line-height:1em;
  }

  .textArea__input-error{
    font-size:0.8em;
    line-height:1em;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .textArea__border{
    border-radius:8px;
    padding:12px 10px;
  }

  .textArea__input{
    font-size:0.85em;
    line-height:1em;
  }

  .textArea__input-error{
    font-size:0.85em;
    line-height:1em;
  }

}

/* Large and up */
@media screen and (min-width: 64em) {
  .textArea__border{
    border-radius:8px;
    padding:12px 12px;
  }

  .textArea__input{
    font-size:0.85em;
    line-height:1em;
  }

  .textArea__input-error{
    font-size:0.85em;
    line-height:1em;
  }

}
</style>
