<template>
  <div class="sessionMenuIcon__container" :style="computedStyle" ref="refBackgroundImage">
    <div v-if="isLoading==true" class="sessionMenuIcon__message-container" :style="computedDimentionsStyle">
      <svg class="loading__pulse-animation sessionMenuIcon__message-loading-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm32-48h224V288l-23.5-23.5c-4.7-4.7-12.3-4.7-17 0L176 352l-39.5-39.5c-4.7-4.7-12.3-4.7-17 0L80 352v64zm48-240c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z"></path></svg>
    </div>
    <div v-else>
      <div v-if="img_image==''" class="sessionMenuIcon__message-container" :style="computedDimentionsStyle">
        <svg class="sessionMenuIcon__message-no-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 766 638">
          <path d="M637,122v288c0,26.5-21.5,48-48,48H173c-26.5,0-48-21.5-48-48V122c0-26.5,21.5-48,48-48h88l12.3-32.9
          c7-18.7,24.9-31.1,44.9-31.1h125.5c20,0,37.9,12.4,44.9,31.1L501,74h88C615.5,74,637,95.5,637,122z M501,266
          c0-66.2-53.8-120-120-120s-120,53.8-120,120s53.8,120,120,120S501,332.2,501,266z M469,266c0,48.5-39.5,88-88,88s-88-39.5-88-88
          s39.5-88,88-88S469,217.5,469,266z"/>
          <g>
            <path d="M77.9,596.3c0,9.6-4.2,16.8-12.7,21.7c-6.6,3.9-14.6,5.8-23.9,5.8c-7,0-14-2-21.2-5.9c-8.6-4.7-12.9-10.6-12.9-17.6
            c0-2.1,0.6-3.8,1.9-5.3c0.7-0.9,2.2-2.1,4.5-3.8c2.4-1.8,4-2.6,4.8-2.6c0.2,0,0.4,0.1,0.4,0.4c0,2,0.2,3.5,0.5,4.3
            c0.3,0.9,1.1,2,2.4,3.5c3.9,4.5,6.1,6.9,6.7,7.4c3.4,2.7,7.5,4,12.4,4c4.5,0,8.9-1.2,13.2-3.6c5.2-2.9,7.9-6.6,7.9-11.1
            c0-6.1-4.3-9.8-12.9-11.1c-2.8-0.4-10.7-1.4-23.8-3.2c-8.6-2.4-12.9-7.7-12.9-16.1s3.8-15.6,11.4-21.7c7-5.7,14.3-8.5,22.1-8.5
            c5,0,9.4,0.8,13.4,2.5c6.1,2.6,9.4,6.9,9.8,12.7c0.1,1.4-0.4,3-1.4,4.8c-0.5,1-1.3,2.5-2.4,4.5c-1.2,2.1-2.7,3.2-4.6,3.5
            c0,0-0.1,0-0.1-0.1c0-0.2,0.3-1.2,0.9-2.9c0.5-1.6,0.7-2.4,0.6-2.5c-5.9-4.3-11-6.4-15.2-6.4c-3.8,0-7.6,1-11.6,3
            c-4.9,2.4-7.3,5.4-7.3,8.9c0,3.2,2.7,5.1,8.2,5.6c11.1,1.1,17.4,1.8,19.1,2.1c7.9,1.5,13.6,4.4,17.2,8.8
            C76.1,582,77.9,588.3,77.9,596.3z"/>
            <path d="M122.2,540.9c0,3.1-0.4,5.2-1.3,6.2c-0.3,0.3-1.4,0.8-3.3,1.4s-3.4,1.5-4.2,2.5c-0.7,8.6-1.1,17.8-1.1,27.5l0.1,27.5
            c3.9,0.5,6.2,1,7,1.6c1.3,1.1,2,3.5,2,7.3c0,6.1-6.5,9.1-19.4,9.1h-3.4c0.1,0-0.5,0-1.9-0.1h-1.2c-2.4,0-4.4-0.7-6.2-2.1
            c-1.9-1.5-2.8-3.5-2.8-5.8c0-4.2,0.2-6.5,0.6-7.1c0.6-0.9,3-1.4,7-1.8L94,577.8c0-8.5,0.4-17.6,1.3-27.4c-0.6,0-0.9,0.1-1,0.2
            c0,0,0.1,0.1,0.2,0.1c-0.2,0-0.5,0.1-1,0.1c-2.3,0-4.2-0.8-5.9-2.3c-1.7-1.5-2.5-3.4-2.5-5.7c0-3.2,0.7-5.4,2.1-6.7
            c1.4-1.3,3.7-1.9,6.9-1.9c0.7,0,1.7,0,3.1,0.1s2.4,0.1,3.1,0.1c1.1,0,2.6-0.2,4.8-0.6c2.1-0.4,3.7-0.6,4.8-0.6
            C118.1,533.3,122.2,535.8,122.2,540.9z"/>
            <path d="M208.5,589.7c0,10.9-0.7,19.9-2.1,26.9c-1,5-4.2,7.6-9.4,7.6c-9.7,0-21.9-13.3-36.4-40c-1.7-3.2-5.5-10.3-11.4-21.4
            c0-1.7,0.1,13.5,0.1,45.4c0,11.5-3.1,17.3-9.3,17.3c-2.7,0-5-0.7-6.9-2.1c-2.1-1.6-3.2-3.6-3.2-6.2l0.1-33.8c0-5-0.1-12.2-0.3-21.6
            c-0.2-9.4-0.3-16.6-0.3-21.6c0-2.6,1.2-4.8,3.6-6.6c2.2-1.6,4.6-2.5,7.3-2.5c5.2,0,14.2,10.2,27.1,30.6
            c3.9,6.2,11.6,19.3,22.9,39.1l0.1-8.9c0-6.2-0.1-15-0.4-26.5c-0.3-12.1-0.5-21-0.5-26.4c0-5.4,3.1-8.1,9.4-8.1
            c6.2,0,9.3,8.2,9.3,24.6v3.5v0.5c0,0.4-0.1,0.8-0.1,1.1v0.9c0,3.1,0.1,7.8,0.2,14C208.4,581.9,208.5,586.6,208.5,589.7z"/>
            <path d="M301.6,540.9c0,3.1-0.4,5.2-1.3,6.2c-0.3,0.3-1.4,0.8-3.3,1.4s-3.4,1.5-4.2,2.5c-0.7,8.6-1.1,17.8-1.1,27.5l0.1,27.5
            c3.9,0.5,6.2,1,7,1.6c1.3,1.1,2,3.5,2,7.3c0,6.1-6.5,9.1-19.4,9.1h-3.4c0.1,0-0.5,0-1.9-0.1h-1.2c-2.4,0-4.4-0.7-6.2-2.1
            c-1.9-1.5-2.8-3.5-2.8-5.8c0-4.2,0.2-6.5,0.6-7.1c0.6-0.9,3-1.4,7-1.8l-0.1-29.5c0-8.5,0.4-17.6,1.3-27.4c-0.6,0-0.9,0.1-1,0.2
            c0,0,0.1,0.1,0.2,0.1c-0.2,0-0.5,0.1-1,0.1c-2.3,0-4.2-0.8-5.9-2.3c-1.7-1.5-2.5-3.4-2.5-5.7c0-3.2,0.7-5.4,2.1-6.7
            c1.4-1.3,3.7-1.9,6.9-1.9c0.7,0,1.7,0,3.1,0.1s2.4,0.1,3.1,0.1c1.1,0,2.6-0.2,4.8-0.6c2.1-0.4,3.7-0.6,4.8-0.6
            C297.5,533.3,301.6,535.8,301.6,540.9z"/>
            <path d="M406,540.1c0,2.3-0.3,5.8-0.8,10.5c-0.5,4.6-0.8,8.1-0.8,10.5c0,2.5-0.1,6.2-0.2,11.1c-0.1,5-0.2,8.7-0.2,11.1
            c0,2.3,0.1,5.8,0.3,10.4c0.2,4.6,0.3,8.1,0.3,10.4c0,1.6,0.1,3.9,0.4,7.1s0.4,5.5,0.4,7.1c0,2.7-1,4.7-3.1,6.3
            c-1.9,1.4-4.2,2.1-6.9,2.1c-6.2,0-9.3-5.8-9.3-17.3c0-3.1,0.1-7.7,0.2-13.9s0.2-10.9,0.2-13.9c0-2.3-0.1-8.5-0.4-18.7
            c-11,19.4-17.3,30.2-18.8,32.3c-4.4,6.3-8.8,9.4-13.4,9.3c-2.4,0.3-5.3-3-8.8-9.8l-16.6-31.9l-0.4,18.7c-0.1,3.9,0,8.6,0.2,13.9
            c0.2,6.8,0.3,11.5,0.3,13.9c0,11.5-3.1,17.3-9.3,17.3c-2.8,0-5.1-0.7-6.9-2.1c-2.1-1.5-3.2-3.6-3.2-6.3c0-1.6,0.1-3.9,0.4-7.1
            s0.4-5.5,0.4-7.1c0-2.3,0.1-5.8,0.3-10.4c0.2-4.6,0.3-8.1,0.3-10.4c0-2.5-0.1-6.2-0.2-11.1c-0.1-5-0.2-8.7-0.2-11.1
            c0-2.3-0.3-5.8-0.8-10.5c-0.5-4.6-0.8-8.1-0.8-10.5c0-2.5,1.2-4.7,3.6-6.5c2.2-1.7,4.6-2.5,7.3-2.5c3.2,0,6.7,2.5,10.5,7.5
            c8.6,11.2,13.4,17.6,14.4,19.2c1.3,2.4,4.7,8.6,10.1,18.6c0.7,3.4,1.4,5.1,2.3,5.1c0.3-0.1,0.8-0.3,1.6-0.8l0.6-1.5l10.7-20
            c1.6-2.9,6.5-9.8,14.9-20.6c3.9-5,7.4-7.5,10.5-7.5c2.8,0,5.2,0.8,7.4,2.4C404.9,535.2,406,537.4,406,540.1z"/>
            <path d="M504,616.8c0,2.2-0.9,3.9-2.6,5.3c-1.6,1.2-3.5,1.8-5.7,1.8c-3.7,0-8-3.7-13.1-11c-3.2-4.6-6.2-9.9-9-15.9
            c-4.5,0.6-10.2,0.8-17.3,0.7c-8.9-0.2-14.7-0.1-17.3,0.1c-2.1,8.6-3.5,13.8-4.2,15.6c-2.6,6.7-5.8,10.1-9.7,10.1
            c-2.7,0-5.1-0.8-7.3-2.3c-2.4-1.6-3.9-3.7-4.4-6.2c0-0.2-0.1-0.4-0.1-0.7c0-1.9,1-5.4,3.1-10.4c1.9-4.6,3.4-8.3,4.5-11
            c18.3-43.5,29.6-65.3,33.8-65.3c5.4,0,9.4,2.8,12,8.5c6.8,14.8,16.5,35.7,29,62.6c0,0.1,1.7,3.1,5,9
            C502.8,611.6,504,614.7,504,616.8z M466.5,582.3c-2.4-6.8-5.9-15.9-10.3-27.4c-2.9,7-7,16.6-12.1,28.8c2.2-0.5,5.9-0.8,11.1-0.9
            C460.9,582.6,464.6,582.5,466.5,582.3z"/>
            <path d="M588.9,604.9c0,0.6-0.1,1.2-0.3,1.7v10.7c0,2.4-0.9,4.3-2.7,5.8s-3.9,2.2-6.3,2.2c-4.6,0-7.5-2-8.7-5.9
            c-7.3,2.7-13.3,4-18,4c-11.2,0-20.9-3.7-29.1-11c-8.5-7.6-12.7-16.9-12.7-28c0-13.2,2.6-24.2,7.9-33c7.3-12.3,19.1-18.5,35.3-18.6
            c6.5,0,13.5,2.1,20.9,6.3c8.2,4.7,12.3,9.7,12.3,15.1c0,1.1-0.9,2-2.6,2.8c-1.4,0.6-2.7,0.9-4,0.9c-2.3,0-6.2-1.3-11.7-4
            s-9.4-4-12-4c-10.3-0.1-17.9,4.6-22.8,14.2c-3.3,6.6-5,14.3-5,22.9c0,5.7,2,10.5,6,14.5s8.8,6,14.5,6c3.9,0,7.9-0.9,11.7-2.8
            c2-1,4.8-2.8,8.6-5.4l0.5-11.5c-7.6,0.1-13.8,0.2-18.8,0.2c-2.4,0-4.5-0.8-6.2-2.3s-2.6-3.5-2.6-5.8c0-5.7,5-8.6,14.9-8.6
            c1.7,0,4.2,0.1,7.5,0.3c3.3,0.2,5.8,0.3,7.4,0.3c10.6,0,15.9,4.2,15.8,12.6l-0.2,18.8C588.8,603.8,588.9,604.4,588.9,604.9z"/>
            <path d="M668.4,538.8c0,2.3-0.9,4.2-2.6,5.8c-1.7,1.6-3.8,2.3-6.1,2.3c-2.6,0-6.5,0.1-11.6,0.3c-5.3,0.2-9.1,0.3-11.5,0.2
            c-0.2,0-3.5-0.1-9.8-0.3c-3.9-0.1-7.2,0-9.8,0.3c-0.1,7.1-0.3,14.5-0.6,22.3c1.8-0.3,3.5-0.5,5.3-0.6c1.1,0,2.9,0,5.3,0.1l5.4,0.2
            c1.2,0,2.9,0,5.2-0.2c2.5-0.2,4.2-0.4,5.3-0.4c6,0,9,2.6,9,7.8c0,6.1-3.5,9.2-10.4,9.2c-1,0-2.5-0.1-4.6-0.2
            c-2-0.1-3.5-0.2-4.5-0.2c-8.2,0-13.6,0.3-16.3,0.9v25.1l41.8-0.1c2.4,0,4.5,0.8,6.2,2.3s2.6,3.5,2.6,5.8c0,5.7-10.6,8.6-31.8,8.6
            c-2.6,0-6.4-0.1-11.6-0.3c-5.2-0.2-9-0.3-11.6-0.3h-1.3c-0.3,0.1-1.7,0.2-4.1,0.2c-4.3,0-6.9-1.6-8-4.8c-1.6-2-2.5-4.7-2.5-8
            c0-5.3,0.3-13.3,1-23.8c0.7-10.6,1-18.5,1-23.8V553c0-1.2-0.1-2.9-0.2-5.3c-0.1-2.4-0.2-4.2-0.2-5.3c0-4.7,1.5-7.7,4.6-8.9
            c1.1-0.8,2.9-1.2,5.6-1.2c2.3,0,3.3,0,3-0.1h0.5c2.8,0,6.9-0.1,12.5-0.3s9.7-0.3,12.5-0.3C657.8,531.6,668.4,534,668.4,538.8z"/>
            <path d="M754.7,589.7c0,10.9-0.7,19.9-2.1,26.9c-1,5-4.2,7.6-9.4,7.6c-9.7,0-21.9-13.3-36.4-40c-1.7-3.2-5.5-10.3-11.4-21.4
            c0-1.7,0.1,13.5,0.1,45.4c0,11.5-3.1,17.3-9.3,17.3c-2.7,0-5-0.7-6.9-2.1c-2.1-1.6-3.2-3.6-3.2-6.2l0.1-33.8c0-5-0.1-12.2-0.3-21.6
            c-0.2-9.4-0.3-16.6-0.3-21.6c0-2.6,1.2-4.8,3.6-6.6c2.2-1.6,4.6-2.5,7.3-2.5c5.2,0,14.2,10.2,27.1,30.6
            c3.9,6.2,11.6,19.3,22.9,39.1l0.1-8.9c0-6.2-0.1-15-0.4-26.5c-0.3-12.1-0.5-21-0.5-26.4c0-5.4,3.1-8.1,9.4-8.1
            c6.2,0,9.3,8.2,9.3,24.6v3.5v0.5c0,0.4-0.1,0.8-0.1,1.1v0.9c0,3.1,0.1,7.8,0.2,14C754.7,581.9,754.7,586.6,754.7,589.7z"/>
          </g>
        </svg>
      </div>
      <div v-else>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
      name: "SessionMenuIcon",
      props: {
        positionWidth: {
          type: String,
          default: "center",
        },
        positionHeight: {
          type: String,
          default: "center",
        },
        backgroundColor: {
          type: String,
          default: "transparent",
        },
        backgroundURL: {
          type: String,
          default: "",
        },
        backgroundSize: {
          type: String,
          default: "cover",
        },
        width: {
          type: String,
          default: "100%",
        },
        height: {
          type: String,
          default: "0",
        },
        isLocal: {
          type: String,
          default: "N",
        },
      },
      data() {
        return {
          componentWidth: 0,
          componentHeight: 0,

          // Image
          isLoading: true,
          img_image: '',
        };
      },
      created() {
        try {
          this.img_image="";
        } catch (error) {
          console.log("[SessionMenuIcon.vue] created()::ERROR = "+error);
        }
      },
      async mounted() {
        try {
          window.addEventListener('resize', this.onResize);
          this.onResize();

          await this.page__preloadImages();
          await this.$nextTick();

          // AVISAMOS QUE SE CARGARON TODAS LAS IMAGENES DE ESTE COMPONENTE
          this.$emit('load', true);

        } catch (error) {
          console.log("[SessionMenuIcon.vue] mounted()::ERROR = "+error);
        }
      },
      computed: {
        computedDimentionsStyle() {
          var objStyle = {};
          objStyle['width'] = this.componentWidth+'px';
          objStyle['height'] = this.componentHeight+'px';
          return objStyle;
        },
        computedStyle() {
          var objStyle = {};
          objStyle['background-repeat'] = 'no-repeat';
          objStyle['background-position'] = this.$props.positionWidth+' '+this.$props.positionHeight;
          objStyle['background-color'] = this.$props.backgroundColor;
          objStyle['background-size'] = this.componentWidth+'px '+this.componentHeight+'px';
          objStyle['width'] = this.$props.width;
          objStyle['height'] = this.$props.height;

          if(this.$props.backgroundURL!=="") {
            objStyle['background-image'] = 'url(' + this.img_image + ')';

            if(this.$props.backgroundSize!=""){
              switch(this.$props.backgroundSize) {
                case "cover":
                  objStyle['background-size'] = 'cover';
                  break;
                case "contain":
                  objStyle['background-size'] = 'contain';
                  break;
                default:
                  objStyle['background-size'] = this.componentWidth + 'px auto';
              }
            } else {
              objStyle['background-size'] = this.componentWidth + 'px auto';
            }
          }

          return objStyle;
        },
      },
      watch: {
        backgroundURL: function(newVal){
          this.img_image="";
          this.backgroundURL = newVal;
          this.page__preloadImages();
        },
      },
      methods: {
        onResize(event) {
          this.componentWidth = this.$refs.refBackgroundImage.clientWidth;
          this.componentHeight = this.$refs.refBackgroundImage.clientHeight;
        },

        // =================
        // PRIVATE FUNCTIONS
        // =================

        async page__preloadImages() {
          var {default: ImageHelper} = await import('@/helpers/image.helper');

          var img_image_url = this.$props.backgroundURL;

          if(img_image_url==null){
            img_image_url = "";
          }

          if(img_image_url!=""){
            if(this.$props.isLocal=="S"){
              img_image_url = require('../../../assets/images/' + this.$props.backgroundURL);
            }

            const prm_image = ImageHelper.loadImage(img_image_url);

            const arrImagePromises = [prm_image];
            const [res_image] = await Promise.all(arrImagePromises);
            this.img_image = res_image;
          }

          this.isLoading = false;
        },
      },
      beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
      }
    };
</script>

<style scoped>

.sessionMenuIcon__message-container{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  background-color:#FFFFFF;
}

@keyframes pulseAnimation {
  from { transform: scale(1); }
  50% { transform: scale(0.85); }
  to { transform: scale(1); }
}

.loading__pulse-animation {
  animation-name: pulseAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}


/* Small only */
@media screen and (max-width: 39.9375em) {
  .sessionMenuIcon__container{
  }

  .sessionMenuIcon__message-container{
  }

  .sessionMenuIcon__message-loading-icon{
    width:25%;
    color:#000000;
  }

  .sessionMenuIcon__message-no-image{
    width:50%;
    color:#000000;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .sessionMenuIcon__container{
  }

  .sessionMenuIcon__message-container{
  }

  .sessionMenuIcon__message-loading-icon{
    width:30%;
    color:#000000;
  }

  .sessionMenuIcon__message-no-image{
    width:60%;
    color:#000000;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  .sessionMenuIcon__container{
  }

  .sessionMenuIcon__message-container{
  }

  .sessionMenuIcon__message-loading-icon{
    width:30%;
    color:#000000;
  }

  .sessionMenuIcon__message-no-image{
    width:60%;
    color:#000000;
  }
}

</style>
