import Redirect from '@/views/pages/admin/Redirect';
import Index from '@/views/pages/admin/Index';
import bgImage from "@/assets/images/bg.png";

// WEBSITE
import adminServicioRoute from '@/router/route/admin/servicio.route';
import adminNoticiaRoute from "@/router/route/admin/noticia.route";
import adminClienteRoute from "@/router/route/admin/cliente.route";
import adminSlideRoute from "@/router/route/admin/slide.route";
import adminUsuarioRoute from "@/router/route/admin/usuario.route";
import adminConfiguracionRoute from "@/router/route/admin/configuracion.route";

// BACKEND
import adminBackendConfiguracionRoute from "@/router/route/admin/_backend/configuracion.route";
import adminBackendServerLogRoute from "@/router/route/admin/_backend/serverlog.route";
import adminBackendTokenRoute from "@/router/route/admin/_backend/token.route";

const adminRoutes = [
    {   path: "/admin",
        name: "session_index",
        component: Index,
        meta: {
            apiAccessType: "PUBLIC",
            routeAccessType: "SESSION",
            requiresAuth: false,
            bgImage: bgImage
        },
    },
    {   path: "/admin/redirect",
        name: "session_redirect",
        component: Redirect,
        meta: {
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
        },
    },
    ...adminServicioRoute,
    ...adminNoticiaRoute,
    ...adminClienteRoute,
    ...adminSlideRoute,
    ...adminUsuarioRoute,
    ...adminConfiguracionRoute,
    ...adminBackendConfiguracionRoute,
    ...adminBackendServerLogRoute,
    ...adminBackendTokenRoute,
]

export default adminRoutes