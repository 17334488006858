import bgImage from "@/assets/images/bg.png";
import SessionLayout from "@/components/layout/session/SessionLayout";
import ConfiguracionEditar from '@/views/pages/admin/_backend/configuracion/ConfiguracionEditar';

const adminBackendConfiguracionRoutes = [
    {
        path: "/admin/backend/configuracion/editar",
        name: "session_backend_configuracion_editar",
        component: ConfiguracionEditar,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
]

export default adminBackendConfiguracionRoutes;