<template>
  <transition :name="transitionName"
              v-on:before-enter="beforeEnter"
              v-on:enter="enter"
              v-on:after-enter="afterEnter"
              v-on:before-leave="beforeLeave"
              v-on:after-leave="afterLeave"
              v-on:leave="leave"
  >
    <div class="modal__wrapper" v-if="data_isVisible" :style="computedModalVisibility">
      <div v-if="data_isLoading==false" class="modal__container">
        <LoadingLogoPublic v-if="accessType=='PUBLIC'" />
        <LoadingLogoSession v-if="accessType=='SESSION'" />
        <div v-if="message != '' ">
          <div :class="computedModalMessage">
            {{ message }}
          </div>
          <div v-if='type === "ALERT"'>
            <div v-if="accessType=='PUBLIC'" class="modal__content-buttons">
              <div class="modal__button-public" v-on:click="clickAceptar()">
                ACEPTAR
              </div>
            </div>
            <div v-else class="modal__content-buttons">
              <div class="modal__button-session" v-on:click="clickAceptar()">
                ACEPTAR
              </div>
            </div>
          </div>
          <div v-if='type === "CONFIRM"'>
            <div v-if="accessType=='PUBLIC'" class="modal__content-buttons">
              <div class="modal__button-public modal__button-margin-right" v-on:click="clickAceptar()">
                ACEPTAR
              </div>
              <div class="modal__button-border-public" v-on:click="clickCancelar()">
                CANCELAR
              </div>
            </div>
            <div v-else class="modal__content-buttons">
              <div class="modal__button-session modal__button-margin-right" v-on:click="clickAceptar()">
                ACEPTAR
              </div>
              <div class="modal__button-border-session" v-on:click="clickCancelar()">
                CANCELAR
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="modal__container">
        <LoadingLogoPublic v-if="accessType=='PUBLIC'" class="modal__pulse-animation" />
        <LoadingLogoSession v-if="accessType=='SESSION'" class="modal__pulse-animation" />
        <div v-if="messageLoading != '' ">
          <div :class="computedModalMessage">
            {{ messageLoading }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import LoadingLogoPublic from "@/components/LoadingLogoPublic";
import LoadingLogoSession from "@/components/LoadingLogoSession";

export default {
  name: "Modal",
  props: {
    accessType:{
      type: String,
      default: "SESSION",
    },
    type:{
      type: String,
      default: "ALERT",
    },
    message:{
      type: String,
      default: "",
    },
    messageLoading:{
      type: String,
      default: "",
    },
    isVisible:{
      type: Boolean,
      default: false,
    },
    isLoading:{
      type: Boolean,
      default: false,
    },
    forceClose:{
      type: Boolean,
      default: false,
    },
    transition:{
      type: String,
      default: "LEFT",
    },
  },
  components: {
    LoadingLogoPublic,
    LoadingLogoSession,
  },
  data() {
    return {
      data_isLoading: false,
      data_isVisible: false,
    };
  },
  async mounted() {
    try {
      await this.$nextTick();

    } catch (error) {
      console.log("[Modal.vue] mounted()::ERROR = "+error);
    }
  },
  computed: {
    computedModalMessage(){
      var accessType = this.$props.accessType;

      if(accessType=="PUBLIC"){
        return "modal__message-public";
      }

      return "modal__message-session";
    },
    computedModalButton(){
      var accessType = this.$props.accessType;

      if(accessType=="PUBLIC"){
        return "modal__message-public";
      }

      return "modal__message-session";
    },
    computedModalVisibility() {
      var forceClose = this.$props.forceClose;

      var objStyle = {};
      objStyle["visibility"] = "visible";

      if(forceClose){
        objStyle["visibility"] = "hidden";
      }

      return objStyle;
    },
    transitionName() {
      var transition = this.$props.transition;

      switch(transition) {
        case "RIGHT":
          return "modal-right";
          break;
        case "LEFT":
          return "modal-left";
          break;
        case "RIGHT-FULL":
          return "modal-right-full";
          break;
        case "LEFT-FULL":
          return "modal-left-full";
          break;
        case "BOTTOM":
          return "modal-bottom";
          break;
        case "TOP":
          return "modal-top";
          break;
        case "BOTTOM-FULL":
          return "modal-bottom-full";
          break;
        case "TOP-FULL":
          return "modal-top-full";
          break;
        default:
          return "modal-left";
      }
    },
  },
  watch: {
    isLoading(newValue) {
      console.log("isLoading",newValue);
      this.data_isLoading = newValue;
    },
    isVisible(newValue) {
      console.log("isVisible",newValue);
      this.data_isVisible = newValue;
    }
  },
  methods: {
    clickAceptar() {
      this.$emit('aceptar');
    },
    clickCancelar() {
      this.$emit('cancelar');
    },

    beforeEnter() {
      this.$emit('before-enter');
    },
    enter() {
      this.$emit('enter');
    },
    afterEnter() {
      this.$emit('after-enter');
    },

    beforeLeave() {
      this.$emit('before-leave');
    },
    leave() {
      this.$emit('leave');
    },
    afterLeave() {
      this.$emit('after-leave');
    },

    // =================
    // PRIVATE FUNCTIONS
    // =================

    async page__preloadImages() {
    },
  }
};
</script>

<style scoped>
@keyframes pulseAnimation {
  from { transform: scale(1); }
  50% { transform: scale(0.85); }
  to { transform: scale(1); }
}

.modal__pulse-animation {
  animation-name: pulseAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.modal__wrapper {
  position: fixed;
  top:0;
  left:0;
  height:100vh;
  width:100vw;
  background:#DDDDDD;
  z-index:1000;
}

.modal__container{
  display:flex;
  flex-direction:column;
  width:100%;
  height:100%;
  justify-content:center;
  align-items:center;
  color: #FFFFFF;
}

.modal__message-session{
  font-family: 'kronika';
  color:#7C7C7C;
  text-align:center;
}

.modal__message-public{
  font-family:'futura-round-medium';
  color:#7C7C7C;
  text-align:center;
}

.modal__content-buttons{
  display:flex;
  flex-direction:row;
  justify-content:center;
  width:100%;
}

.modal__button-public{
  font-family:'futura-round-medium';
  border:2px solid #7C7C7C;
  border-radius:8px;
  background-color:#7C7C7C;
  color:#DDDDDD;
  text-align:center;
  cursor:pointer;
}

.modal__button-session{
  font-family: 'kronika';
  border:2px solid #7C7C7C;
  background-color:#7C7C7C;
  color:#DDDDDD;
  text-align:center;
  cursor:pointer;
}

.modal__button-border-public{
  font-family:'futura-round-medium';
  border:2px solid #7C7C7C;
  border-radius:8px;
  background-color:#DDDDDD;
  color:#7C7C7C;
  text-align:center;
  cursor:pointer;
}

.modal__button-border-session{
  font-family: 'kronika';
  border:2px solid #7C7C7C;
  background-color:#DDDDDD;
  color:#7C7C7C;
  text-align:center;
  cursor:pointer;
}

/* =============================== */

.close-enter-active{
  opacity: 0;
}

.close-leave-active {
  opacity: 0;
}

.close-enter,
.close-leave-to {
  opacity: 0;
}

/* =============================== */

.modal-right-enter-active{
  transition: transform 0.6s;
}

.modal-right-leave-active {
  transition: transform 0.8s;
}

.modal-right-enter,
.modal-right-leave-to {
  transform: translateX(-100vw);
}

/* =============================== */

.modal-left-enter-active{
  transition: transform 0.6s;
}

.modal-left-leave-active {
  transition: transform 0.8s;
}

.modal-left-enter,
.modal-left-leave-to {
  transform: translateX(100vw);
}

/* =============================== */

.modal-right-full-enter-active{
  transition: transform 0.6s;
}

.modal-right-full-leave-active {
  transition: transform 0.8s;
}

.modal-right-full-enter {
  transform: translateX(-100vw);
}

.modal-right-full-leave-to {
  transform: translateX(100vw);
}

/* =============================== */

.modal-left-full-enter-active{
  transition: transform 0.6s;
}

.modal-left-full-leave-active {
  transition: transform 0.8s;
}

.modal-left-full-enter {
  transform: translateX(100vw);
}

.modal-left-full-leave-to {
  transform: translateX(-100vw);
}

/* =============================== */

.modal-bottom-enter-active{
  transition: transform 0.5s;
}

.modal-bottom-leave-active {
  transition: transform 1s;
}

.modal-bottom-enter,
.modal-bottom-leave-to {
  transform: translateY(-100vw);
}

/* =============================== */

.modal-bottom-full-enter-active{
  transition: transform 0.5s;
}

.modal-bottom-full-leave-active {
  transition: transform 1s;
}

.modal-bottom-full-enter {
  transform: translateY(-100vw);
}

.modal-bottom-full-leave-to {
  transform: translateY(100vw);
}

/* =============================== */

.modal-top-enter-active{
  transition: transform 0.5s;
}

.modal-top-leave-active {
  transition: transform 1s;
}

.modal-top-enter,
.modal-top-leave-to {
  transform: translateY(100vw);
}

/* =============================== */

.modal-top-full-enter-active{
  transition: transform 0.5s;
}

.modal-top-full-leave-active {
  transition: transform 1s;
}

.modal-top-full-enter {
  transform: translateY(100vw);
}

.modal-top-full-leave-to {
  transform: translateY(-100vw);
}

/* =============================== */

/* Small only */
@media screen and (max-width: 39.9375em) {
  .modal__message-public{
    width:450px;
    padding-top:25px;
    padding-bottom:20px;
    font-size:0.9em;
    line-height:1.4em;
  }

  .modal__message-session{
    width:230px;
    padding-top:15px;
    padding-bottom:20px;
    font-size:0.8em;
    line-height:1.4em;
  }

  .modal__button-public{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.75em;
    line-height:0.75em;
  }

  .modal__button-session{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.7em;
    line-height:0.7em;
  }

  .modal__button-border-public{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.75em;
    line-height:0.75em;
  }

  .modal__button-border-session{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.65em;
    line-height:0.65em;
  }

  .modal__button-margin-right{
    margin-right:15px;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .modal__message-public{
    width:450px;
    padding-top:25px;
    padding-bottom:20px;
    font-size:1em;
    line-height:1.4em;
  }

  .modal__message-session{
    width:320px;
    padding-top:20px;
    padding-bottom:20px;
    font-size:0.9em;
    line-height:1.4em;
  }

  .modal__button-public{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.8em;
    line-height:0.8em;
  }

  .modal__button-session{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.7em;
    line-height:0.7em;
  }

  .modal__button-border-public{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.8em;
    line-height:0.8em;
  }

  .modal__button-border-session{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.7em;
    line-height:0.7em;
  }

  .modal__button-margin-right{
    margin-right:15px;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  .modal__message-public{
    width:450px;
    padding-top:25px;
    padding-bottom:20px;
    font-size:1em;
    line-height:1.4em;
  }

  .modal__message-session{
    width:450px;
    padding-top:25px;
    padding-bottom:20px;
    font-size:0.9em;
    line-height:1.4em;
  }

  .modal__button-public{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.85em;
    line-height:0.85em;
  }

  .modal__button-session{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.75em;
    line-height:0.75em;
  }

  .modal__button-border-public{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.85em;
    line-height:0.85em;
  }

  .modal__button-border-session{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.75em;
    line-height:0.75em;
  }

  .modal__button-margin-right{
    margin-right:15px;
  }
}
</style>

