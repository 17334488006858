import { render, staticRenderFns } from "./SidebarMenuBurger.vue?vue&type=template&id=72ff8854&scoped=true&"
import script from "./SidebarMenuBurger.vue?vue&type=script&lang=js&"
export * from "./SidebarMenuBurger.vue?vue&type=script&lang=js&"
import style0 from "./SidebarMenuBurger.vue?vue&type=style&index=0&id=72ff8854&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72ff8854",
  null
  
)

export default component.exports