<template>
</template>

<script>
  export default {
    name: "Redirect",
    mounted() {
      try {
        var redirect = this.$store.getters['authSessionStore/getHomeRoute'];

        if(redirect!=""){
          // Verificamos si la ruta existe
          var objNamedRoute = this.$router.resolve({name: redirect});

          // Si la busqueda de ruta da mas que 0 entonces is existe
          if( objNamedRoute.resolved.matched.length > 0){
            nextRoute = redirect;
            this.$router.push({ name: nextRoute }, () => {
            });
          }else{
            throw Error("La ruta definida como HOME no existe");
          }
        } else {
          throw Error("No se ha definido una ruta como HOME");
        }

      } catch (error) {
        // Si hay algún ERROR cerramos la sesion y redireccionamos al 'session_index'
        this.$store.dispatch("authSessionStore/logOut");
        var strError = error.message.replace("Error: ", "");
        var nextRoute = 'session_index';
        this.$router.push({ name: nextRoute, params: { mensaje: strError } }, () => {});
      }
    },
  };
</script>
