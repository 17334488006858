import StorageHelper from '@/helpers/storage.helper';

const state = {
    isOpen: StorageHelper.getItem('sidebarMenu_isOpen') || false,
    section: StorageHelper.getItem('sidebarMenu_section') || false,
}

const getters = {
    getIsOpen(state) {
        return state.isOpen;
    },
    getSection(state) {
        return state.section;
    },
}

const mutations = {
    mut_setIsOpen(state,data) {
        state.isOpen = data;
    },
    mut_deleteIsOpen(state) {
        state.data= false;
    },

    mut_setSection(state,data) {
        state.section = data;
    },
    mut_deleteSection(state) {
        state.data= "";
    },
}

const actions = {
    setIsOpen(context, data) {
        StorageHelper.setItem('sidebarMenu_isOpen', data);
        context.commit('mut_setIsOpen', data);
    },
    deleteIsOpen(context) {
        StorageHelper.removeItem('sidebarMenu_isOpen');
        context.commit('mut_deleteIsOpen');
    },

    setSection(context, data) {
        StorageHelper.setItem('sidebarMenu_section', data);
        context.commit('mut_setSection', data);
    },
    deleteSection(context) {
        StorageHelper.removeItem('sidebarMenu_section');
        context.commit('mut_deleteSection');
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}