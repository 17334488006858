<template>
  <transition name="modalError" appear
              v-on:before-leave="beforeLeave"
              v-on:after-leave="afterLeave"
              v-on:leave="leave"
  >
    <div class="modalError__wrapper" v-if="isVisible">
      <div class="modalError__center">
        <div class="modalError__content">
          <div class="modalError__title-container">
            <div class="modalError__title-icon" @click="isShow = !isShow">
              <img :src='img_ico_alert_white' />
            </div>
            <div class="modalError__title-text" v-html="title">
              {{ title }}
            </div>
          </div>
          <div class="modalError__message" v-html="message">
            {{ message }}
          </div>
          <div class="modalError__title-excepcion-container">
            <div class="modalError__title-excepcion-icon" @click="isShow = !isShow">
              <div v-if="isShow==true">
                <img :src='img_ico_chevron_up_white' />
              </div>
              <div v-else>
                <img :src='img_ico_chevron_down_white' />
              </div>
            </div>
            <div class="modalError__title-excepcion-text" @click="isShow = !isShow">
              Ver detalle de la excepción
            </div>
          </div>
          <transition name="slide">
            <div class="modalError__full-width" v-if="isShow">
              <div class="modalError__excepcion-container">
                <div class="modalError__excepcion-text">
                  {{ exception }}
                </div>
              </div>
            </div>
          </transition>
          <div class="modalError__content-buttons">
            <div class="modalError__button" v-on:click="clickAceptar()">
              ACEPTAR
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: "ModalError",
    props: {
      title:{
        type: String,
        default: "",
      },
      message:{
        type: String,
        default: "",
      },
      exception:{
        type: String,
        default: "",
      },
      isVisible:{
        type: Boolean,
        default: false,
      },
    },
    model: {
        prop: 'isVisible',
    },
    data() {
      return {
        isShow:false,

        // Images
        img_ico_alert_white: '',
        img_ico_chevron_up_white: '',
        img_ico_chevron_down_white: '',
      };
    },
    async mounted() {
      try {
        await this.page__preloadImages();
        await this.$nextTick();

      } catch (error) {
        console.log("[ModalError.vue] mounted()::ERROR = "+error);
      }
    },
    methods: {
      clickAceptar() {
        this.$emit('aceptar');
      },
      clickCancelar() {
        this.$emit('cancelar');
      },
      beforeLeave: function () {
        this.$emit('before-leave');
      },
      leave: function () {
        this.$emit('leave');
      },
      afterLeave: function () {
        this.$emit('after-leave');
      },

      // =================
      // PRIVATE FUNCTIONS
      // =================

      async page__preloadImages() {
        var {default: ImageHelper} = await import('@/helpers/image.helper');

        var img_ico_alert_white_url = require('../assets/images/ico_alert_white.png');
        const prm_ico_alert_white = ImageHelper.loadImage(img_ico_alert_white_url);

        var img_ico_chevron_up_white_url = require('../assets/images/ico_chevron_up_white.png');
        const prm_ico_chevron_up_white = ImageHelper.loadImage(img_ico_chevron_up_white_url);

        var img_ico_chevron_down_white_url = require('../assets/images/ico_chevron_down_white.png');
        const prm_ico_chevron_down_white = ImageHelper.loadImage(img_ico_chevron_down_white_url);

        const arrImagePromises = [prm_ico_alert_white,prm_ico_chevron_up_white,prm_ico_chevron_down_white];
        const [res_ico_alert_white,res_ico_chevron_up_white,res_ico_chevron_down_white] = await Promise.all(arrImagePromises);
        this.img_ico_alert_white = res_ico_alert_white;
        this.img_ico_chevron_up_white = res_ico_chevron_up_white;
        this.img_ico_chevron_down_white = res_ico_chevron_down_white;
      },
    }
  };
</script>

<style scoped>
  .modalError__wrapper {
    position: fixed;
    top:0;
    left:0;
    height:100vh;
    width:100vw;
    background:#CC6686;
    z-index:999;
  }

  .modalError__center{
    display:flex;
    width:100%;
    height:100%;
    justify-content:center;
    align-items:center;
  }

  .modalError__container-image img{
    width:auto;
  }

  .modalError__content{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    background-color:#AE2A3B;
  }

  .modalError__title-text{
    font-family: 'roboto-bold';
    color: #FFFFFF;
    text-align:left;
    flex-shrink:1;
  }

  .modalError__message{
    font-family: 'roboto-regular';
    width:100%;
    color: #FFFFFF;
    text-align:left;
  }

  .modalError__content-buttons{
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:100%;
  }

  .modalError__button{
    font-family: 'roboto-bold';
    background-color:#6B1D2E;
    color:#FFFFFF;
    text-align:center;
    cursor:pointer;
  }

  .modalError__title-container{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
  }

  .modalError__title-icon{
    cursor:pointer;
  }

  .modalError__title-icon img{
    width:100%;
  }

  .modalError__title-excepcion-container{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
  }

  .modalError__title-excepcion-icon{
    cursor:pointer;
  }

  .modalError__title-excepcion-icon img{
    width:100%;
  }

  .modalError__title-excepcion-text{
    flex-shrink:1;
    cursor:pointer;
    font-family: 'roboto-medium';
    color:#FFFFFF;
  }

  .modalError__excepcion-container{
    width:100%;
    background-color:#FFFFFF;
  }

  .modalError__excepcion-text{
    font-family: 'roboto-regular';
    color:#5B5B5B;
    overflow-y:auto;
    height:100%;
  }

  .modalError__full-width{
    width:100%;
  }


  /* Small only */
  @media screen and (max-width: 39.9375em) {
    .modalError__container-image img{
      min-height:210px;
    }

    .modalError__content{
      width:90%;
      padding:25px 20px;
      border-radius:15px;
      margin:0 auto;
    }

    .modalError__title-container{
      margin-bottom:13px;
    }

    .modalError__title-icon{
      width:25px;
      margin-right:8px;
    }

    .modalError__title-text{
      font-size:1.2em;
      line-height:1.2em;
    }

    .modalError__message{
      padding-bottom:10px;
      font-size:0.8em;
      line-height:1.2em;
    }
    
    .modalError__title-excepcion-container{
      margin-bottom:5px;
    }

    .modalError__title-excepcion-icon{
      width:15px;
      margin-right:8px;
    }

    .modalError__title-excepcion-text{
      font-size:0.8em;
      line-height:1em;
    }

    .modalError__excepcion-container{
      padding:15px 15px;
      height:150px;
    }

    .modalError__excepcion-text{
      font-size:0.9em;
      line-height:1em;
    }

    .modalError__content-buttons{
      margin-top:12px;
    }

    .modalError__button{
      width:100px;
      padding:8px 0;
      font-size:0.75em;
      line-height:1em;
    }
  }

  /* Medium only */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .modalError__container-image img{
      min-height:280px;
    }

    .modalError__content {
      width:70%;
      padding:30px 30px;
      border-radius:15px;
      margin:0 auto;
    }

    .modalError__title-container{
      margin-bottom:15px;
    }

    .modalError__title-icon{
      width:28px;
      margin-right:8px;
    }

    .modalError__title-text{
      font-size:1.4em;
      line-height:1.2em;
    }

    .modalError__message{
      padding-bottom:15px;
      font-size:0.9em;
      line-height:1.2em;
    }

    .modalError__title-excepcion-container{
      margin-bottom:5px;
    }

    .modalError__title-excepcion-icon{
      width:18px;
      margin-right:8px;
    }

    .modalError__title-excepcion-text{
      font-size:0.9em;
      line-height:1em;
    }

    .modalError__excepcion-container{
      padding:15px 15px;
      height:150px;
    }

    .modalError__excepcion-text{
      font-size:0.9em;
      line-height:1em;
    }

    .modalError__content-buttons{
      margin-top:15px;
    }

    .modalError__button{
      width:100px;
      padding:10px 0;
      font-size:0.8em;
      line-height:1em;
    }
  }

  /* Large and up */
  @media screen and (min-width: 64em) {
    .modalError__container-image img{
      min-height:280px;
    }

    .modalError__content {
      width:500px;
      padding:30px 30px;
      border-radius:15px;
      margin:0 auto;
    }

    .modalError__title-container{
      margin-bottom:15px;
    }

    .modalError__title-icon{
      width:30px;
      margin-right:8px;
    }

    .modalError__title-text{
      font-size:1.5em;
      line-height:1.3em;
    }

    .modalError__message{
      padding-bottom:10px;
      font-size:0.9em;
      line-height:1.3em;
    }

    .modalError__title-excepcion-container{
      margin-bottom:5px;
    }

    .modalError__title-excepcion-icon{
      width:18px;
      margin-right:8px;
    }

    .modalError__title-excepcion-text{
      font-size:0.9em;
      line-height:1em;
    }

    .modalError__excepcion-container{
      padding:15px 15px;
      height:150px;
    }

    .modalError__excepcion-text{
      font-size:0.9em;
      line-height:1em;
    }

    .modalError__content-buttons{
      margin-top:15px;
    }

    .modalError__button{
      width:100px;
      padding:10px 0;
      font-size:0.8em;
      line-height:1em;
    }
  }

  /* ============================= */

  .modalError-leave-active,
  .modalError-enter-active {
    transition: 0.4s;
  }
  .modalError-enter {
    transform: translate(100%, 0);
  }
  .modalError-leave-to {
    transform: translate(-100%, 0);
  }

  /* ============================= */

  .slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
  }

  .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }
</style>

