<template>
  <div class="page__contacto index__fullpage-container">
    <div v-if="olvideClaveEstado=='VALID'">
      <div class="index__invitacion--fullpage-centered" :style="computedDetectHeight">
        <div v-if="modal_isUpdated==true">
          <div class="index__invitacion--container">
            <div class="index__invitacion-logo-image">
              <img class="menu__logo" src='@/assets/images/logo_menu.png' />
            </div>
            <div class="index__invitacion-line">
              <div class="index__invitacion-mensaje">
                Muchas gracias, sus credenciales han sido actualizadas con éxito. Ahora ya puede autentificarse en nuestro sitio web de administración.
              </div>
            </div>
            <div class="index__invitacion-line">
              <Button labelText="IR A ADMIN"
                      bgColor="#00B0A5"
                      borderRadius="8px"
                      fontColor="#FFFFFF"
                      :width="buttonWidth"
                      style="margin:0 auto;"
                      v-on:action-button-clicked="irAdminWeb()"/>
            </div>
          </div>
        </div>
        <div v-else class="index__invitacion--container">
          <div class="index__invitacion-logo-image">
            <img class="menu__logo" src='@/assets/images/logo_menu.png' />
          </div>
          <div class="index__invitacion-line-flexcolumn">
            <div class="index__invitacion-label">
              <LabelForm labelText="Clave" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorClave" />
            </div>
            <div class="index__invitacion-control">
              <TextBox placeholder="" type="password" maxlength="250" fontColor="#7C7C7C" bgColor="#E4EAED" v-model="clave" v-bind:isError="errorClave" />
            </div>
          </div>
          <div class="index__invitacion-line-flexcolumn">
            <div class="index__invitacion-label">
              <LabelForm labelText="Confirmar Clave" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorConfirmarClave" />
            </div>
            <div class="index__invitacion-control">
              <TextBox placeholder="" type="password" maxlength="250" fontColor="#7C7C7C" bgColor="#E4EAED" v-model="confirmarClave" v-bind:isError="errorConfirmarClave" />
            </div>
          </div>
          <div class="index__invitacion-button">
            <Button labelText="ACTUALIZAR CLAVE"
                    bgColor="#00B0A5"
                    borderRadius="8px"
                    fontColor="#FFFFFF"
                    :width="buttonWidth"
                    style="margin:0 auto;"
                    v-on:action-button-clicked="actualizarClave()"/>
          </div>
          <div class="index__invitacion-line">
            <div class="index__invitacion-mensaje-error">
              {{ mensajeError }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="olvideClaveEstado=='INVALID'">
      <div class="index__invitacion--fullpage-centered" :style="computedDetectHeight">
        <div class="index__invitacion--container">
          <div class="index__invitacion-logo-image">
            <img class="menu__logo" src='@/assets/images/logo_menu.png' />
          </div>
          <div class="index__invitacion-line">
            <div class="index__invitacion-mensaje">
              Lo sentimos, el enlace ingresado no es válido.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="olvideClaveEstado=='EXPIRED'">
      <div class="index__invitacion--fullpage-centered" :style="computedDetectHeight">
        <div class="index__invitacion--container">
          <div class="index__invitacion-logo-image">
            <img class="menu__logo" src='@/assets/images/logo_menu.png' />
          </div>
          <div class="index__invitacion-line">
            <div class="index__invitacion-mensaje">
              Lo sentimos, el enlace ingresado ha caducado.
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal type="CONFIRM"
           accessType="PUBLIC"
           :message="modal_message"
           :messageLoading="modal_messageLoading"
           :isVisible="modal_isVisible"
           :isLoading="modal_isLoading"
           :transition="modal_transition"
           :style="computedModalStyle"
           @aceptar="modalAceptar"
           @cancelar="modalCancelar" />
    <ModalError :title="modalErrorTitle"
                :message="modalErrorMessage"
                :exception="modalErrorException"
                v-model="isModalErrorMessage"
                v-on:aceptar="modal__ErrorAceptar()" />
  </div>
</template>

<script>

import Button from "@/components/Button";
import ButtonForm from "@/components/ButtonForm";
import ButtonUpload from "@/components/ButtonUpload";
import Label from "@/components/Label";
import LabelForm from "@/components/LabelForm";
import TextBox from "@/components/TextBox";
import TextArea from "@/components/TextArea";
import TextEditor from "@/components/custom/texteditor/TextEditor";
import CheckBoxForm from "@/components/CheckBoxForm";
import DropDownList from "@/components/DropDownList";
import BackgroundImage from "@/components/BackgroundImage";
import Modal from "@/components/Modal";
import ModalError from "@/components/ModalError";


export default {
  name: "IndexOlvideClave",
  components: {
    Button,
    ButtonForm,
    ButtonUpload,
    Label,
    LabelForm,
    TextBox,
    TextArea,
    TextEditor,
    CheckBoxForm,
    DropDownList,
    BackgroundImage,
    Modal,
    ModalError,
  },
  data() {
    return {
      hash: "",
      isContentOverflow: false,
      olvideClaveEstado: "",
      modal_isUpdated: false,

      // MEDIA QUERIES
      buttonWidth:"0",

      // FORM
      clave: "",
      confirmarClave: "",
      mensajeError: "",

      // FORM ERROR
      errorClave: false,
      errorConfirmarClave: false,

      // IMAGES
      isPageImagesLoad: true,
      isExternalImagesLoad: true,
      isMountFinished: false,

      // MODAL
      modal_message:'',
      modal_messageLoading:'',
      modal_isVisible:false,
      modal_isLoading:false,
      modal_isClosed:false,
      modal_transition:"",

      // MODAL ERROR
      isModalErrorMessage:false,
      modalErrorTitle:'',
      modalErrorMessage:'',
      modalErrorException:'',
    };
  },
  created() {
    try {
      window.addEventListener('resize', this.handleWindowResize);
      this.mensajeError = "";
    } catch (error) {
      console.log("[IndexOlvideClave.vue] created()::ERROR = "+error);
    }
  },
  async mounted() {
    try {
      this.hash = this.$route.params.hash;
      this.olvideClaveEstado = await this.verificarOlvideClave(this.hash);
      this.modal_isUpdated = false;

      await this.page__preloadImages();
      await this.$nextTick();

      this.handleWindowResize();
      this.isMountFinished = true;

    } catch (error) {
      this.$emit('show-loading', false);
      var tmpExcepcion = "[IndexOlvideClave.vue] mounted()::ERROR >>> "+error;
      var strExcepcion = tmpExcepcion.replace('Error:', '');
      this.modal__ErrorRegistrarExcepcion(strExcepcion);
    }
  },
  computed: {
    computedDetectHeight(){
      if(this.isContentOverflow){
        return {
          "padding-top": "40px",
          "padding-bottom": "40px"
        }
      }

      return {
        "padding-top": "0",
        "padding-bottom": "0"
      }
    },
    computedModalStyle() {
      var objStyle = {};
      objStyle["visibility"] = "visible";

      if(this.modal_isClosed){
        objStyle["visibility"] = "hidden";
      }

      return objStyle;
    },
    page__isAllContentLoaded(){
      return this.isPageImagesLoad && this.isExternalImagesLoad && this.isMountFinished;
    },
  },
  watch: {
    page__isAllContentLoaded(newValue) {
      if(newValue){
        var that;
        that = this;
        setTimeout(function () {
          that.$emit('show-loading', false);
        }, 1500);
      }
    },
  },
  methods: {
    async actualizarClave(){
      try {
        var contVacio = 0;
        var strClave = this.clave;
        var strConfirmarClave = this.confirmarClave;

        if (strClave === "") {
          this.errorClave = true;
          contVacio = contVacio + 1;
        } else {
          this.errorClave = false;
        }
        if (strConfirmarClave === "") {
          this.errorConfirmarClave = true;
          contVacio = contVacio + 1;
        } else {
          this.errorConfirmarClave = false;
        }

        if (contVacio === 0) {
          var isError = true;
          var strMensajeError = "";

          if ((strClave == "") && (strConfirmarClave == "")) {
            isError = false;
          } else {
            if (strClave == strConfirmarClave) {
              var PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
              var passwordValidator = PASSWORD_REGEXP.test(strClave);

              if(!passwordValidator){
                isError = true;
                strMensajeError = "La clave debe tener al menos: 8 caracteres / 1 letra mayúscula / 1 letra minúscula / 1 número";
              } else {
                isError = false;
              }
            } else {
              isError = true;
              strMensajeError = "Las claves ingresadas no coinciden. Por favor revise los campos ingresados";
            }
          }

          if (isError) {
            this.mensajeError = strMensajeError;
          } else {
            this.mensajeError = "";
            this.modal_message = "¿Está seguro que desea registrar esta información?";
            this.modal_transition = "RIGHT";
            this.modal_isClosed = false;
            this.modal_isVisible = true;
          }
        } else {
          this.mensajeError = "Por favor ingrese los datos faltantes";
        }

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[IndexOlvideClave.vue] actualizarClave()::ERROR >>> " + error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    async verificarOlvideClave(hash){
      try {
        var olvideClaveObj = {}
        olvideClaveObj["hash"] = hash;

        var {default: UsuarioLogic} = await import('@/logic/usuario.logic');
        var httpUsuario = await UsuarioLogic.olvideClaveVerificar(olvideClaveObj);
        var httpUsuarioData = httpUsuario.data;
        var responseUsuario = httpUsuario.data.response;

        var resultado = "";

        switch(httpUsuarioData.status) {
          case "OK":
            resultado = responseUsuario;
            break;

          default:
            var error = httpUsuarioData.message;
            throw Error("[IndexOlvideClave.vue] verificarOlvideClave()::ERROR = "+error);
        }

        return resultado;

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[IndexOlvideClave.vue] verificarOlvideClave()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    irAdminWeb(){
      this.$store.dispatch("authSessionStore/logOut");
      var nextRoute = "session_index";

      if(nextRoute!=''){
        this.$emit('show-loading', true);
        this.$router.push({name: nextRoute}, () => {
        });
      }
    },

    getUploadObject(objData){
      this.fileImagen = objData.data;
      this.rutaImagen = objData.url;
    },

    // LISTENERS
    handleWindowResize(){
      this.mediaQueries();
    },

    // MODAL
    async modalAceptar(){
      try {
        this.modal_messageLoading = "Registrando usuario. Un momento por favor"
        this.modal_isLoading=true;

        var usuarioObj = {};
        usuarioObj['hash'] = this.hash;
        usuarioObj['clave'] = this.clave;

        var {default: UsuarioLogic} = await import('@/logic/usuario.logic');
        var httpUsuario = await UsuarioLogic.olvideClaveActualizar(usuarioObj);
        var httpUsuarioData = httpUsuario.data;
        var responseUsuario = httpUsuario.data.response;

        switch(httpUsuarioData.status) {
          case "OK":
            this.modal_isUpdated = true;
            this.modal_isVisible=false;
            this.modal_isLoading=false;
            break;

          default:
            var error = httpUsuarioData.message;
            throw Error("[IndexOlvideClave.vue] modalAceptar()::ERROR = "+error);
        }

      } catch (error) {
        this.modal_isVisible=false;
        this.modal_isLoading=false;

        var tmpExcepcion = "[IndexOlvideClave.vue] modalAceptar()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },
    modalCancelar(){
      this.modal_isClosed = false;
      this.modal_isVisible = false;
    },

    // MODAL ERROR
    modal__ErrorRegistrarExcepcion(excepcion){
      this.modalErrorTitle = "LO SENTIMOS";
      this.modalErrorMessage = "Se produjo un error al realizar la operación. Por favor intente más tarde.";
      this.modalErrorException = excepcion;
      this.isModalErrorMessage = true;
    },
    modal__ErrorAceptar(){
      this.isModalErrorMessage=false;
    },

    // =================
    // PRIVATE FUNCTIONS
    // =================

    mediaQueries() {
      var windowHeight = window.innerHeight;
      var contentHeight= parseFloat(window.getComputedStyle(document.querySelector('.index__invitacion--fullpage-centered')).height.replace("px",""));

      if(contentHeight>windowHeight){
        this.isContentOverflow=true;
      } else {
        this.isContentOverflow=false;
      }

      var _buttonWidth = 0;
      const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
      const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );
      const matchMediaLarge = window.matchMedia( "(min-width: 64em)" );

      if (matchMediaSmall.matches) {
        _buttonWidth = 120;
      }

      if (matchMediaMedium.matches) {
        _buttonWidth = 120;
      }

      if (matchMediaLarge.matches) {
        _buttonWidth = 120;
      }

      this.buttonWidth = _buttonWidth+"px";
    },

    async page__preloadImages() {
      var {default: ImageHelper} = await import('@/helpers/image.helper');

      this.isPageImagesLoad = true;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  }
};
</script>

<style scoped>
.index__invitacion--fullpage-centered{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  width:100%;
  min-height:100vh;
}

.index__invitacion--container{
  margin:0 auto;
}

.index__invitacion-line{
  width:100%;
}

.index__invitacion-line-flexcolumn{
  display:flex;
  width:100%;
  flex-direction:column;
}

.index__invitacion-label{
  width:100%;
  color:#303843;
  font-family: 'futura-round-cond-medium';
}

.index__invitacion-control{
  width:100%;
}

.index__invitacion-mensaje{
  width:100%;
  text-align:center;
  color:#000000;
  font-family:"futura-round-cond-medium"
}

.index__invitacion-mensaje-error{
  width:100%;
  text-align:center;
  color:#BD0000;
  font-family:"futura-round-cond-medium"
}

.index__invitacion-logo-image{
  display:flex;
  flex-direction:row;
  justify-content:center;
  width:100%;
}

.index__invitacion-button{
  display:flex;
  flex-direction:row;
  justify-content:center;
  width:100%;
}


/* Small only */
@media screen and (max-width: 39.9375em) {
  .index__invitacion--container{
    width:90%;
  }

  .index__invitacion-line{
    padding-bottom:10px;
  }

  .index__invitacion-line-flexcolumn{
    padding:0 30px 10px 30px;
  }

  .index__invitacion-label{
    padding-bottom:5px;
  }

  .index__invitacion-mensaje{
    font-size:1.3em;
    line-height:1.1em;
    margin:20px 0;
  }

  .index__invitacion-mensaje-error{
    height:2.5em;
    font-size:1.2em;
    line-height:1.2em;
    padding-top:20px;
  }

  .index__invitacion-logo-image{
    padding-bottom:20px;
  }

  .index__invitacion-button{
    padding-top:25px;
  }

  .menu__logo{
    width:200px;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .index__invitacion--container{
    width:350px;
  }

  .index__invitacion-line{
    padding-bottom:10px;
  }

  .index__invitacion-line-flexcolumn{
    padding:0 30px 10px 30px;
  }

  .index__invitacion-label{
    padding-bottom:5px;
  }

  .index__invitacion-mensaje{
    font-size:1.3em;
    line-height:1.1em;
    margin:20px 0;
  }

  .index__invitacion-mensaje-error{
    height:2.5em;
    font-size:1.2em;
    line-height:1.2em;
    padding-top:20px;
  }

  .index__invitacion-logo-image{
    padding-bottom:20px;
  }

  .index__invitacion-button{
    padding-top:25px;
  }

  .menu__logo{
    width:220px;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  .index__invitacion--container{
    width:350px;
  }

  .index__invitacion-line{
    padding-bottom:10px;
  }

  .index__invitacion-line-flexcolumn{
    padding:0 30px 10px 30px;
  }

  .index__invitacion-label{
    padding-bottom:5px;
  }

  .index__invitacion-mensaje{
    font-size:1.3em;
    line-height:1.1em;
    margin:20px 0;
  }

  .index__invitacion-mensaje-error{
    height:2.5em;
    font-size:1.3em;
    line-height:1.1em;
    padding-top:20px;
  }

  .index__invitacion-logo-image{
    padding-bottom:30px;
  }

  .index__invitacion-button{
    padding-top:25px;
  }

  .menu__logo{
    width:250px;
  }
}

</style>