import bgImage from "@/assets/images/bg.png";
import SessionLayout from "@/components/layout/session/SessionLayout";
import UsuarioListar from '@/views/pages/admin/usuario/UsuarioListar';
import UsuarioCrear from '@/views/pages/admin/usuario/UsuarioCrear';
import UsuarioEditar from '@/views/pages/admin/usuario/UsuarioEditar';

const adminUsuarioRoutes = [
    {
        path: "/admin/usuario/listar",
        name: "session_usuario_listar",
        component: UsuarioListar,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
    {
        path: "/admin/usuario/crear",
        name: "session_usuario_crear",
        component: UsuarioCrear,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
    {
        path: "/admin/usuario/editar/:idUsuario",
        name: "session_usuario_editar",
        component: UsuarioEditar,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
]

export default adminUsuarioRoutes