<template lang="html">
  <div id="spritediv" class="vue-sprite">
    <canvas ref="vue-sprite-canvas"></canvas>
  </div>
</template>

<script>
export default {
  name: 'SpriteAnimator2',
  props: {
    spritesheet: {
      required: true,
      type: String,
      default: ''
    },
    json: {
      required: true,
      type: Object,
      default: {}
    },
    fps: {
      type: Number,
      default: 30
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    yoyo: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'vue-sprite'
    },
    width: {
      type: Number,
      default: 0
    },
    isLoop: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      frames: [],
      visible: true,
      length: 0,
      frameIndex: 0,
      currentIndex: 0,
      animationFrameID: null,
      yoyodirection: 0,
      sprite: null,
      canvas: null,
      ctx: null,
      heightSprite: 0,
      widthSprite: 0,
      now: 0,
      then: 0,
      stopAnimation: false,
    }
  },
  created () {
  },
  mounted () {
    this.json.frames.forEach((frame) => {
      var widthFrame = frame.frame.w;
      var heightFrame = frame.frame.h;

      if(this.$props.width>0){
        var newWidth = this.$props.width;
        var newHeight = (heightFrame/widthFrame)*newWidth;
        widthFrame = newWidth;
        heightFrame = newHeight;
      }

      this.frames.push({
        name: frame.filename,
        x: frame.frame.x,
        y: frame.frame.y,
        w: widthFrame,
        h: heightFrame
      })
    })

    this.frames.sort((a, b) => a.filename < b.filename)
    this.widthSprite = this.frames[0].w
    this.heightSprite = this.frames[0].h
    this.length = (this.frames.length - 1)

    this.sprite = new Image();
    this.sprite.src = this.spritesheet;

    this.sprite.onload = ({ target }) => {
      this.init(target)
    }
  },
  computed: {
    sprite__stopAnimation(){
      return this.stopAnimation;
    },
  },
  watch: {
    sprite__stopAnimation(newValue) {
      if(newValue){
        window.cancelAnimationFrame(this.animationFrameID)
        this.currentIndex = 0
      }
    },
    width: function(newVal){
      this.width = newVal;
    },
  },
  methods: {
    init(img) {
      var imgwidth = img.width;
      var imgheight = img.height;

      // =======

      var tempCanvasResized = document.createElement("canvas");
      tempCanvasResized.width = imgwidth;
      tempCanvasResized.height = imgheight;

      var tctxResized = tempCanvasResized.getContext("2d");
      tctxResized.drawImage(img,0,0);

      // =======

      var tempCanvasOriginal = document.createElement("canvas");
      tempCanvasOriginal.width = imgwidth;
      tempCanvasOriginal.height = imgheight;

      var tctxOriginal = tempCanvasOriginal.getContext("2d");
      tctxOriginal.drawImage(img,0,0);

      // =======

      var newImageWidth = imgwidth;
      var newImageHeight = imgheight;

      if(this.$props.width>0) {
        newImageWidth = this.$props.width;
        newImageHeight = (img.height / img.width) * newImageWidth;
      }

      tempCanvasResized.width = newImageWidth;
      tempCanvasResized.height = newImageHeight;
      var ctx=tempCanvasResized.getContext('2d');
      ctx.drawImage(tempCanvasOriginal,0,0,imgwidth,imgheight,0,0,newImageWidth,newImageHeight);

      // =======

      this.canvas = this.$refs['vue-sprite-canvas'];
      this.canvas.width = this.widthSprite;
      this.canvas.height = this.heightSprite;
      this.ctx = this.canvas.getContext('2d')
      this.sprite = tempCanvasResized;

      this.autoplay && this.loop()
    },
    render () {
      this.ctx && this.ctx.clearRect(0, 0, this.widthSprite, this.heightSprite)
      if (this.yoyo && this.currentIndex % this.length === 0 && this.currentIndex) {
        this.yoyodirection = Number(!this.yoyodirection)
      }
      //const index = Math.abs((this.currentIndex % this.length) - (this.length * this.yoyodirection))
      const index = Math.abs((this.currentIndex))
      const x = this.frames[index].x

      var y = this.frames[index].y
      if(this.$props.width>0) {
        y = this.heightSprite * this.currentIndex;
      }
      this.ctx && this.ctx.drawImage(this.sprite, x, y, this.widthSprite, this.heightSprite, 0, 0, this.widthSprite, this.heightSprite)
    },
    loop () {
      if(!this.stopAnimation){
        this.now = Date.now()
        const delta = (this.now - this.then)
        if (delta > (1000 / this.fps)) {
          this.then = this.now - (delta % (1000 / this.fps))
          this.render();
          this.currentIndex++

          if(this.currentIndex>this.length){
            var isLoop = this.$props.isLoop;
            if(!isLoop){
              this.stopAnimation=true;
              return;
            }

            this.currentIndex = 0;
          }
        }
        this.animationFrameID = window.requestAnimationFrame(this.loop)
      }
    },
    stop () {
      window.cancelAnimationFrame(this.animationFrameID)
      this.currentIndex = 0
    },
    play (from) {
      this.currentIndex = Number.isNaN(Number(from)) ? this.currentIndex : from
      this.loop()
    }
  }
}
</script>

<style></style>
