<template>
  <transition :name="transitionName"
              v-on:before-enter="beforeEnter"
              v-on:enter="enter"
              v-on:after-enter="afterEnter"
              v-on:before-leave="beforeLeave"
              v-on:after-leave="afterLeave"
              v-on:leave="leave"
  >
    <div class="modal__wrapper" v-if="data_isVisible">
      <div class="modal__close">
        <span class="cursor-pointer" v-on:click="clickClose()">X</span>
      </div>
      <div class="modal__container">
        <div v-if="data_isLoading==false">
          <div class="modal__box">
            <div class="modal__border-dotted">
              <div class="modal__line">
                <div class="modal__label">
                  <Label labelText="Nombre" fontFamily="kronika" fontColor="#7C7C7C" />
                </div>
                <div class="full-width">
                  <TextBox placeholder="" maxlength="250" v-model="nombre" v-bind:isError="errorNombre" bgColor="#C1C1C1" bdColorError="#7C7C7C" fontColor="#7C7C7C" />
                </div>
              </div>
              <div class="modal__line">
                <div class="modal__label">
                  <Label labelText="Correo Electrónico" fontFamily="kronika" fontColor="#7C7C7C" />
                </div>
                <div class="full-width">
                  <TextBox placeholder="" maxlength="250" v-model="email" v-bind:isError="errorEmail" bgColor="#C1C1C1" bdColorError="#7C7C7C" fontColor="#7C7C7C" />
                </div>
              </div>
              <div class="modal__line">
                <div class="modal__label">
                  <Label labelText="Duracion URL" fontFamily="kronika" fontColor="#7C7C7C" />
                </div>
                <div class="full-width">
                  <DropDownList v-model="duracionURL" v-bind:options="duracionURLOpciones" bgColor="#C1C1C1" fontColor="#7C7C7C" />
                </div>
              </div>
              <div v-if="objUsuario" class="modal__line">
                <div class="modal__label">
                  <Label labelText="Perfil" fontFamily="kronika" fontColor="#7C7C7C" />
                </div>
                <div class="full-width">
                  <DropDownList v-model="idPerfil" v-bind:options="idPerfilOpciones" bgColor="#C1C1C1" fontColor="#7C7C7C" />
                </div>
              </div>
              <div class="modal__content-buttons">
                <div class="modal__button-border" v-on:click="clickInvitar()">
                  INVITAR
                </div>
              </div>
            </div>
            <div class="page__form-error-message-modal text-align-center">
              {{ mensajeError }}
            </div>
          </div>
        </div>
        <div v-else>
          <div style="width:100%;text-align: center;">
            <LoadingLogoSession class="modal__pulse-animation" />
          </div>
          <div class="modal__message">
            {{ loadingMessage }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Label from "@/components/Label";
import TextBox from "@/components/TextBox";
import DropDownList from "@/components/DropDownList";
import LoadingLogoSession from "@/components/LoadingLogoSession";

export default {
  name: "UsuarioInvitarModal",
  props: {
    isVisible:{
      type: Boolean,
      default: false,
    },
    isLoading:{
      type: Boolean,
      default: false,
    },
    loadingMessage:{
      type: String,
      default: "",
    },
    transition:{
      type: String,
      default: "LEFT",
    },
  },
  components: {
    LoadingLogoSession,
    Label,
    TextBox,
    DropDownList,
  },
  data() {
    return {
      objUsuario: {},
      duracionURLOpciones: [],
      idPerfilOpciones: [],

      // FORM
      nombre: "",
      email: '',
      duracionURL: '',
      idPerfil: '',
      mensajeError: "",

      // FORM ERROR
      errorNombre: false,
      errorEmail: false,

      // VISIBILITY
      data_isLoading: false,
      data_isVisible: false,
    };
  },
  async mounted() {
    try {
      this.obtenerDuracionURLOpciones();
      await this.obtenerIdPerfilOpciones();
      await this.$nextTick();

    } catch (error) {
      console.log("[UsuarioInvitarModal.vue] mounted()::ERROR = "+error);
    }
  },
  computed: {
    transitionName() {
      var transition = this.$props.transition;

      switch(transition) {
        case "CLOSE":
          return "modal-top";
          break;
        case "RIGHT":
          return "modal-right";
          break;
        case "LEFT":
          return "modal-left";
          break;
        case "RIGHT-FULL":
          return "modal-right-full";
          break;
        case "LEFT-FULL":
          return "modal-left-full";
          break;
        case "BOTTOM":
          return "modal-bottom";
          break;
        case "TOP":
          return "modal-top";
          break;
        case "BOTTOM-FULL":
          return "modal-bottom-full";
          break;
        case "TOP-FULL":
          return "modal-top-full";
          break;
        default:
          return "modal-left";
      }
    },
  },
  watch: {
    isLoading(newValue) {
      this.data_isLoading = newValue;
    },
    isVisible(newValue) {
      this.data_isVisible = newValue;
    }
  },
  methods: {
    clickClose() {
      this.$emit('close');
      this.nombre = "";
      this.email = "";
      this.duracionURL = "";
      this.idPerfil = "";
      this.errorEmail = false;
      this.errorNombre = false;
      this.mensajeError = "";
    },
    clickInvitar() {
      var contVacio = 0;
      var strNombre = this.nombre;
      var strEmail = this.email;

      if(strNombre===""){
        this.errorNombre=true;
        contVacio = contVacio + 1;
      } else {
        this.errorNombre=false;
      }

      if(strEmail===""){
        this.errorEmail=true;
        contVacio = contVacio + 1;
      } else {
        this.errorEmail=false;
      }

      if (contVacio === 0) {
        var EMAIL_REGEXP = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        var emailValidator = EMAIL_REGEXP.test(strEmail);

        if (!emailValidator) {
          this.mensajeError = "/* Por favor ingrese un formato válido de Correo Electrónico */";
          this.errorEmail = true;
        } else {
          var objInvitacion = {};
          objInvitacion['nombre'] = this.nombre;
          objInvitacion['email'] = this.email;
          objInvitacion['duracionURL'] = this.duracionURL;
          objInvitacion['idPerfil'] = this.idPerfil;

          this.$emit('invitar',objInvitacion);
          this.nombre = "";
          this.email = "";
          this.duracionURL = "";
          this.errorEmail = false;
          this.errorNombre = false;
          this.mensajeError = "";
        }
      } else {
        this.mensajeError = "/* Por favor ingrese los datos faltantes */";
      }
    },

    // --

    beforeEnter() {
      this.$emit('before-enter');
    },
    enter() {
      this.$emit('enter');
    },
    afterEnter() {
      this.$emit('after-enter');
    },

    beforeLeave() {
      this.$emit('before-leave');
    },
    leave() {
      this.$emit('leave');
    },
    afterLeave() {
      this.$emit('after-leave');
    },

    // =================
    // PRIVATE FUNCTIONS
    // =================

    obtenerDuracionURLOpciones(){
      try {
        this.duracionURLOpciones = [];

        var optionObj = {};
        optionObj['id'] = "1";
        optionObj['nombre'] = "UN DÍA";
        this.duracionURLOpciones.push(optionObj);

        var optionObj = {};
        optionObj['id'] = "3";
        optionObj['nombre'] = "TRES DÍAS";
        this.duracionURLOpciones.push(optionObj);

        var optionObj = {};
        optionObj['id'] = "6";
        optionObj['nombre'] = "SEIS DÍAS";
        this.duracionURLOpciones.push(optionObj);

      } catch (error) {
        throw Error("[UsuarioInvitarModal.vue] obtenerDuracionURLOpciones()::ERROR = "+error);
      }
    },

    async obtenerIdPerfilOpciones(){
      try {
        this.idPerfilOpciones = [];
        this.objUsuario = this.$store.getters['authSessionStore/getUser'];

        var {default: PerfilLogic} = await import('@/logic/perfil.logic');
        var httpPerfil = await PerfilLogic.dropdownlist();
        var httpPerfilData = httpPerfil.data;
        var responsePerfil = httpPerfil.data.response;

        switch(httpPerfilData.status) {
          case "OK":
            responsePerfil.forEach((objPerfil, indexPerfil) => {
              if(objPerfil.id=="PIX"){
                if(this.objUsuario.perfil.id=="PIX"){
                  var optionObj = {};
                  optionObj['id'] = objPerfil.id;
                  optionObj['nombre'] = objPerfil.nombre;
                  this.idPerfilOpciones.push(optionObj);
                }
              } else {
                var optionObj = {};
                optionObj['id'] = objPerfil.id;
                optionObj['nombre'] = objPerfil.nombre;
                this.idPerfilOpciones.push(optionObj);
              }
            });

            break;

          default:
            var error = httpPerfilData.message;
            throw Error(error);
        }

      } catch (error) {
        throw Error("[UsuarioInvitarModal.vue] obtenerIdPerfilOpciones()::ERROR = "+error);
      }
    },

    async page__preloadImages() {
    },
  }
};
</script>

<style scoped>
@keyframes pulseAnimation {
  from { transform: scale(1); }
  50% { transform: scale(0.85); }
  to { transform: scale(1); }
}

.modal__pulse-animation {
  animation-name: pulseAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.modal__wrapper {
  position: fixed;
  top:0;
  left:0;
  height:100vh;
  width:100vw;
  background:#DDDDDD;
  z-index:1000;
}

.modal__container{
  display:flex;
  flex-direction:column;
  width:100%;
  height:100%;
  justify-content:center;
  align-items:center;
  color: #FFFFFF;
}

.modal__border-dotted{
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%237C7C7C' stroke-width='8' stroke-dasharray='10%2c20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.modal__line{
  width:100%;
}
.modal__label{
  width:100%;
}

.modal__message{
  font-family: 'kronika';
  color:#7C7C7C;
  text-align:center;
}

.modal__content-buttons{
  display:flex;
  flex-direction:row;
  justify-content:center;
  width:100%;
}

.modal__button{
  font-family: 'kronika';
  border:2px solid #7C7C7C;
  background-color:#7C7C7C;
  color:#DDDDDD;
  text-align:center;
  cursor:pointer;
}

.modal__button-border{
  font-family: 'kronika';
  border:2px solid #7C7C7C;
  background-color:#DDDDDD;
  color:#7C7C7C;
  text-align:center;
  cursor:pointer;
}

.modal__close{
  position:fixed;
  width:100%;
  text-align:right;
  color:#7C7C7C;
  font-family:'kronika';
}

.full-width{
  width:100%;
}

.cursor-pointer{
  cursor:pointer;
}

.text-align-center{
  text-align: center;
}

/* =============================== */

.close-enter-active{
  opacity: 0;
}

.close-leave-active {
  opacity: 0;
}

.close-enter,
.close-leave-to {
  opacity: 0;
}

/* =============================== */

.modal-right-enter-active{
  transition: transform 0.6s;
}

.modal-right-leave-active {
  transition: transform 0.8s;
}

.modal-right-enter,
.modal-right-leave-to {
  transform: translateX(-100vw);
}

/* =============================== */

.modal-left-enter-active{
  transition: transform 0.6s;
}

.modal-left-leave-active {
  transition: transform 0.8s;
}

.modal-left-enter,
.modal-left-leave-to {
  transform: translateX(100vw);
}

/* =============================== */

.modal-right-full-enter-active{
  transition: transform 0.6s;
}

.modal-right-full-leave-active {
  transition: transform 0.8s;
}

.modal-right-full-enter {
  transform: translateX(-100vw);
}

.modal-right-full-leave-to {
  transform: translateX(100vw);
}

/* =============================== */

.modal-left-full-enter-active{
  transition: transform 0.6s;
}

.modal-left-full-leave-active {
  transition: transform 0.8s;
}

.modal-left-full-enter {
  transform: translateX(100vw);
}

.modal-left-full-leave-to {
  transform: translateX(-100vw);
}

/* =============================== */

.modal-bottom-enter-active{
  transition: transform 0.5s;
}

.modal-bottom-leave-active {
  transition: transform 1s;
}

.modal-bottom-enter,
.modal-bottom-leave-to {
  transform: translateY(-100vw);
}

/* =============================== */

.modal-bottom-full-enter-active{
  transition: transform 0.5s;
}

.modal-bottom-full-leave-active {
  transition: transform 1s;
}

.modal-bottom-full-enter {
  transform: translateY(-100vw);
}

.modal-bottom-full-leave-to {
  transform: translateY(100vw);
}

/* =============================== */

.modal-top-enter-active{
  transition: transform 0.5s;
}

.modal-top-leave-active {
  transition: transform 1s;
}

.modal-top-enter,
.modal-top-leave-to {
  transform: translateY(100vw);
}

/* =============================== */

.modal-top-full-enter-active{
  transition: transform 0.5s;
}

.modal-top-full-leave-active {
  transition: transform 1s;
}

.modal-top-full-enter {
  transform: translateY(100vw);
}

.modal-top-full-leave-to {
  transform: translateY(-100vw);
}

/* =============================== */

/* Small only */
@media screen and (max-width: 39.9375em) {
  .modal__box{
    width:90%;
    padding:20px 20px;
  }

  .modal__line{
    padding-bottom:15px;
  }
  .modal__label{
    padding-bottom:3px;
  }
  .modal__message{
    width:230px;
    padding-top:15px;
    padding-bottom:20px;
    font-size:0.8em;
    line-height:1.4em;
  }

  .modal__content-buttons{
    padding-bottom:0;
  }

  .modal__border-dotted{
    border-radius: 15px;
    padding:30px 30px 30px 30px;
  }

  .modal__button{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.65em;
    line-height:0.65em;
  }

  .modal__button-border{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.65em;
    line-height:0.65em;
  }

  .modal__close{
    font-size:1.9em;
    line-height:1em;
    padding:10px;
  }

  .modal__button-margin-right{
    margin-right:15px;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .modal__box{
    width:350px;
    padding:20px 20px;
  }

  .modal__line{
    padding-bottom:10px;
  }
  .modal__label{
    padding-bottom:3px;
  }
  .modal__message{
    width:320px;
    padding-top:20px;
    padding-bottom:20px;
    font-size:0.9em;
    line-height:1.4em;
  }
  .modal__content-buttons{
    padding-bottom:0;
  }

  .modal__border-dotted{
    border-radius: 15px;
    padding:30px 30px 30px 30px;
  }

  .modal__button{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.7em;
    line-height:0.7em;
  }

  .modal__button-border{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.7em;
    line-height:0.7em;
  }

  .modal__close{
    font-size:2.5em;
    line-height:1em;
    padding:10px;
  }

  .modal__button-margin-right{
    margin-right:15px;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  .modal__box{
    width:350px;
    padding:30px 30px;
  }

  .modal__line{
    padding-bottom:15px;
  }
  .modal__label{
    padding-bottom:3px;
  }
  .modal__message{
    width:450px;
    padding-top:25px;
    padding-bottom:20px;
    font-size:0.9em;
    line-height:1.4em;
  }
  .modal__content-buttons{
    padding-bottom:0;
  }

  .modal__border-dotted{
    border-radius: 15px;
    padding:30px 30px 30px 30px;
  }

  .modal__button{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.75em;
    line-height:0.75em;
  }

  .modal__button-border{
    width:100px;
    padding:11px 0 10px 0;
    font-size:0.75em;
    line-height:0.75em;
  }

  .modal__close{
    font-size:2.5em;
    line-height:1em;
    padding:10px;
  }

  .modal__button-margin-right{
    margin-right:15px;
  }
}
</style>