<template>
  <div>
    <svg version="1.1"
         id="loadingLogo__layer"
         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px"
         y="0px"
         viewBox="0 0 1024 256"
         class="loadingLogo__container"
         style="enable-background:new 0 0 1024 256;"
         xml:space="preserve">
        <g>
          <g>
            <path class="st0" d="M235.2,190.4c0-0.4,0.2-0.8,0.5-1.1s0.7-0.5,1.1-0.5c0.4,0,0.8,0.2,1.1,0.5s0.4,0.7,0.4,1.1v10.1h14V189h3.2
              V214c0,0.5-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.4-1.1,0.4c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.1v-10.8h-14v12.1l-3.1,0
              V190.4z"/>
            <path class="st0" d="M265.3,209.1c0.3,0.7,0.7,1.4,1.2,1.9c0.5,0.5,1.1,1,1.8,1.3c0.7,0.3,1.5,0.5,2.3,0.5c0.7,0,1.4-0.1,2-0.2
              c0.7-0.2,1.3-0.3,2-0.5l0.7,2.4c-0.8,0.3-1.6,0.6-2.4,0.8c-0.7,0.2-1.6,0.3-2.5,0.3c-1.5,0-2.7-0.3-3.9-0.8
              c-1.1-0.5-2.1-1.3-2.8-2.2c-0.8-0.9-1.4-2-1.8-3.2c-0.4-1.2-0.6-2.5-0.6-3.9c0-1.3,0.2-2.5,0.6-3.7s1-2.3,1.8-3.2
              c0.8-0.9,1.7-1.7,2.8-2.2c1.1-0.6,2.3-0.8,3.7-0.8c1.3,0,2.4,0.3,3.5,0.8c1,0.5,1.9,1.3,2.6,2.1c0.7,0.9,1.3,1.9,1.7,3
              c0.4,1.1,0.6,2.3,0.6,3.5c0,0.7-0.2,1.2-0.5,1.4s-0.8,0.3-1.5,0.3h-11.8C264.9,207.6,265,208.3,265.3,209.1z M275.4,204.4
              c0-0.7-0.2-1.5-0.4-2.2c-0.2-0.7-0.6-1.4-1-2c-0.4-0.6-1-1.1-1.6-1.5c-0.6-0.4-1.3-0.6-2.2-0.6c-0.9,0-1.7,0.2-2.3,0.5
              c-0.6,0.4-1.2,0.8-1.6,1.4c-0.4,0.6-0.7,1.2-1,2c-0.2,0.8-0.4,1.5-0.5,2.3H275.4z"/>
            <path class="st0" d="M284.6,197.1c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.4,1.1-0.4c0.4,0,0.7,0.1,1.1,0.4c0.3,0.2,0.5,0.6,0.5,1
              c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6-0.1,0.9c0.1-0.4,0.3-0.7,0.5-1.1c0.2-0.4,0.5-0.7,0.8-1.1c0.3-0.3,0.7-0.6,1.1-0.8
              s0.8-0.3,1.2-0.3c0.5,0,0.9,0.2,1.3,0.5c0.4,0.3,0.5,0.7,0.5,1.2c0,0.4-0.1,0.7-0.4,0.9c-0.3,0.2-0.6,0.4-1,0.6
              c-0.4,0.2-0.8,0.4-1.3,0.6c-0.5,0.2-0.9,0.6-1.3,1.1c-0.4,0.5-0.7,1.1-1,1.8c-0.3,0.8-0.4,1.8-0.4,3v10l-3.1,0V197.1z"/>
            <path class="st0" d="M299.1,197.1c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.4,1.1-0.4c0.4,0,0.7,0.1,1.1,0.4c0.3,0.2,0.5,0.6,0.5,1
              c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6-0.1,0.9c0.1-0.4,0.3-0.7,0.5-1.1c0.2-0.4,0.5-0.7,0.8-1.1c0.3-0.3,0.7-0.6,1.1-0.8
              c0.4-0.2,0.8-0.3,1.2-0.3c0.5,0,0.9,0.2,1.3,0.5c0.4,0.3,0.5,0.7,0.5,1.2c0,0.4-0.1,0.7-0.4,0.9c-0.3,0.2-0.6,0.4-1,0.6
              c-0.4,0.2-0.8,0.4-1.3,0.6c-0.5,0.2-0.9,0.6-1.3,1.1c-0.4,0.5-0.7,1.1-1,1.8c-0.3,0.8-0.4,1.8-0.4,3v10l-3.1,0V197.1z"/>
            <path class="st0" d="M324.6,197.2c0-0.3,0.1-0.6,0.1-0.8c0.1-0.2,0.2-0.4,0.5-0.6c0.2-0.2,0.5-0.2,1-0.2c0.4,0,0.8,0.2,1,0.5
              c0.3,0.3,0.4,0.7,0.4,1.1v18.3h-3.1v-2.8c-0.6,1-1.4,1.7-2.3,2.2c-1,0.5-2,0.7-3.2,0.7c-1.4,0-2.6-0.3-3.7-0.9
              c-1.1-0.6-1.9-1.3-2.6-2.3c-0.7-0.9-1.2-2-1.6-3.2c-0.4-1.2-0.5-2.4-0.5-3.7c0-1.2,0.2-2.4,0.6-3.6c0.4-1.2,0.9-2.2,1.6-3.2
              c0.7-0.9,1.6-1.7,2.6-2.3c1-0.6,2.2-0.9,3.6-0.9c1.1,0,2.1,0.2,3,0.7c1,0.4,1.8,1.1,2.5,1.9C324.6,197.8,324.6,197.5,324.6,197.2z
               M314.2,208.1c0.2,0.9,0.5,1.7,0.9,2.4c0.4,0.7,1,1.3,1.7,1.8c0.7,0.5,1.5,0.7,2.5,0.7c1.1,0,2-0.2,2.7-0.7
              c0.7-0.5,1.3-1.1,1.7-1.8c0.4-0.7,0.7-1.5,0.8-2.5c0.1-0.9,0.2-1.8,0.2-2.7c0-0.8-0.1-1.7-0.2-2.5c-0.2-0.8-0.4-1.6-0.8-2.3
              s-0.9-1.2-1.6-1.6c-0.7-0.4-1.5-0.6-2.5-0.6c-1,0-1.8,0.2-2.6,0.7c-0.7,0.4-1.3,1-1.7,1.7c-0.4,0.7-0.8,1.5-1,2.3
              c-0.2,0.9-0.3,1.7-0.3,2.6C313.9,206.3,314,207.2,314.2,208.1z"/>
            <path class="st0" d="M333.7,197.1c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.5,1.1-0.5c0.4,0,0.6,0.1,0.8,0.2c0.2,0.1,0.3,0.3,0.4,0.6
              c0.1,0.2,0.2,0.5,0.2,0.8c0,0.3,0,0.6,0,0.8c0.5-0.8,1.2-1.4,2.1-1.8c0.9-0.4,1.8-0.6,2.7-0.6c1.2,0,2.3,0.3,3.3,0.9
              c1,0.6,1.8,1.4,2.2,2.5c0.6-1.1,1.4-1.9,2.5-2.5c1.1-0.6,2.2-0.8,3.4-0.8c2.1,0,3.8,0.6,5,1.8c1.3,1.2,1.9,2.8,1.9,5V214
              c0,0.4-0.1,0.8-0.4,1.1s-0.7,0.5-1.1,0.5c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.1v-11.5c0-0.6-0.1-1.1-0.3-1.7
              c-0.2-0.5-0.5-1-0.9-1.3c-0.4-0.4-0.8-0.7-1.3-0.9c-0.5-0.2-1.1-0.3-1.6-0.3c-0.6,0-1.1,0.1-1.7,0.3c-0.5,0.2-1,0.5-1.3,0.9
              c-0.4,0.4-0.7,0.8-0.9,1.3c-0.2,0.5-0.3,1.1-0.3,1.7v12.8h-3v-12.8c0-0.6-0.1-1.1-0.3-1.7c-0.2-0.5-0.5-1-0.9-1.4
              c-0.4-0.4-0.8-0.7-1.4-0.9c-0.5-0.2-1.1-0.3-1.7-0.3c-0.6,0-1.1,0.1-1.6,0.3c-0.5,0.2-1,0.5-1.3,0.9c-0.4,0.4-0.7,0.8-0.9,1.3
              c-0.2,0.5-0.3,1.1-0.3,1.6v12.8h-3.1V197.1z"/>
            <path class="st0" d="M366,197.1c0-0.5,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.4,1.1-0.4c0.4,0,0.8,0.2,1,0.5s0.4,0.7,0.4,1.1v18.2H366
              V197.1z M366.5,190.3c0.2-0.3,0.6-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4c0.3,0.2,0.4,0.5,0.4,0.9c0,0.4-0.1,0.7-0.4,0.9
              c-0.3,0.3-0.6,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4c-0.3-0.3-0.4-0.6-0.4-0.9C366.2,190.9,366.3,190.6,366.5,190.3z"/>
            <path class="st0" d="M378.9,209.1c0.3,0.7,0.7,1.4,1.2,1.9c0.5,0.5,1.1,1,1.8,1.3c0.7,0.3,1.5,0.5,2.3,0.5c0.7,0,1.4-0.1,2-0.2
              c0.7-0.2,1.3-0.3,2-0.5l0.7,2.4c-0.8,0.3-1.6,0.6-2.4,0.8c-0.7,0.2-1.6,0.3-2.5,0.3c-1.5,0-2.7-0.3-3.9-0.8
              c-1.1-0.5-2.1-1.3-2.8-2.2c-0.8-0.9-1.4-2-1.8-3.2c-0.4-1.2-0.6-2.5-0.6-3.9c0-1.3,0.2-2.5,0.6-3.7s1-2.3,1.8-3.2
              c0.8-0.9,1.7-1.7,2.8-2.2c1.1-0.6,2.3-0.8,3.7-0.8c1.3,0,2.4,0.3,3.5,0.8c1,0.5,1.9,1.3,2.6,2.1c0.7,0.9,1.3,1.9,1.7,3
              c0.4,1.1,0.6,2.3,0.6,3.5c0,0.7-0.2,1.2-0.5,1.4s-0.8,0.3-1.5,0.3h-11.8C378.5,207.6,378.6,208.3,378.9,209.1z M389,204.4
              c0-0.7-0.2-1.5-0.4-2.2c-0.2-0.7-0.6-1.4-1-2c-0.4-0.6-1-1.1-1.6-1.5c-0.6-0.4-1.3-0.6-2.2-0.6c-0.9,0-1.7,0.2-2.3,0.5
              c-0.6,0.4-1.2,0.8-1.6,1.4c-0.4,0.6-0.7,1.2-1,2c-0.2,0.8-0.4,1.5-0.5,2.3H389z"/>
            <path class="st0" d="M398.2,197.1c0-0.4,0.2-0.8,0.5-1.1s0.7-0.5,1.1-0.5c0.4,0,0.7,0.1,0.9,0.3c0.2,0.2,0.4,0.4,0.4,0.6
              c0.1,0.3,0.1,0.5,0.1,0.9c0,0.3,0,0.6,0,0.9c0.6-0.8,1.3-1.5,2.2-1.9c0.9-0.4,1.9-0.7,2.9-0.7c1.1,0,2.1,0.2,3,0.5
              c0.9,0.4,1.6,0.9,2.2,1.5c0.6,0.7,1.1,1.4,1.4,2.3c0.3,0.9,0.5,1.9,0.5,2.9v11.1c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5
              c-0.4,0-0.8-0.2-1.1-0.5s-0.4-0.7-0.4-1.1V203c0-0.6-0.1-1.2-0.4-1.8s-0.6-1.1-1-1.5c-0.4-0.4-0.9-0.8-1.5-1
              c-0.6-0.3-1.2-0.4-1.8-0.4c-0.6,0-1.2,0.1-1.8,0.4c-0.6,0.2-1,0.6-1.5,1c-0.4,0.4-0.7,0.9-1,1.5c-0.2,0.6-0.3,1.1-0.3,1.8v12.4
              h-3.1V197.1z"/>
            <path class="st0" d="M420.9,191.5c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.5,1.1-0.5c0.4,0,0.8,0.2,1.1,0.5c0.3,0.4,0.5,0.7,0.5,1.1
              l0,4.4c0.2,0,0.5,0,0.9,0c0.4,0,0.7,0,1,0.1c0.3,0.1,0.6,0.2,0.8,0.4c0.2,0.2,0.3,0.5,0.3,0.9c0,0.4-0.1,0.7-0.3,0.9
              c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.7,0-0.9,0v16.7h-3.1v-16.7c-0.3,0-0.6,0-0.9,0c-0.3,0-0.7,0-1-0.1
              c-0.3-0.1-0.6-0.2-0.8-0.4c-0.2-0.2-0.3-0.5-0.3-0.9s0.1-0.7,0.3-0.9c0.2-0.2,0.5-0.3,0.8-0.4c0.3-0.1,0.6-0.1,1-0.1
              c0.3,0,0.6,0,0.9,0V191.5z"/>
            <path class="st0" d="M444.3,197.2c0-0.3,0.1-0.6,0.1-0.8c0.1-0.2,0.2-0.4,0.5-0.6c0.2-0.2,0.5-0.2,1-0.2c0.4,0,0.8,0.2,1,0.5
              c0.3,0.3,0.4,0.7,0.4,1.1v18.3h-3.1v-2.8c-0.6,1-1.4,1.7-2.3,2.2c-1,0.5-2,0.7-3.2,0.7c-1.4,0-2.6-0.3-3.7-0.9
              c-1.1-0.6-1.9-1.3-2.6-2.3c-0.7-0.9-1.2-2-1.6-3.2c-0.4-1.2-0.5-2.4-0.5-3.7c0-1.2,0.2-2.4,0.6-3.6c0.4-1.2,0.9-2.2,1.6-3.2
              s1.6-1.7,2.6-2.3c1-0.6,2.2-0.9,3.6-0.9c1.1,0,2.1,0.2,3,0.7c1,0.4,1.8,1.1,2.5,1.9C444.3,197.8,444.3,197.5,444.3,197.2z
               M433.9,208.1c0.2,0.9,0.5,1.7,0.9,2.4c0.4,0.7,1,1.3,1.7,1.8c0.7,0.5,1.5,0.7,2.5,0.7c1.1,0,2-0.2,2.7-0.7
              c0.7-0.5,1.3-1.1,1.7-1.8c0.4-0.7,0.7-1.5,0.8-2.5c0.1-0.9,0.2-1.8,0.2-2.7c0-0.8-0.1-1.7-0.2-2.5c-0.2-0.8-0.4-1.6-0.8-2.3
              s-0.9-1.2-1.6-1.6c-0.7-0.4-1.5-0.6-2.5-0.6c-1,0-1.8,0.2-2.6,0.7c-0.7,0.4-1.3,1-1.7,1.7c-0.4,0.7-0.8,1.5-1,2.3
              c-0.2,0.9-0.3,1.7-0.3,2.6C433.6,206.3,433.7,207.2,433.9,208.1z"/>
            <path class="st0" d="M455.2,210.4c0.5,0.3,0.9,0.7,1.3,0.9c0.4,0.3,0.8,0.5,1.2,0.8c0.4,0.2,0.8,0.4,1.3,0.5
              c0.5,0.1,1,0.2,1.6,0.2c0.4,0,0.8-0.1,1.3-0.2c0.4-0.1,0.9-0.3,1.2-0.6c0.4-0.3,0.7-0.6,0.9-0.9s0.4-0.8,0.4-1.3
              c0-0.7-0.3-1.2-0.8-1.7c-0.5-0.4-1.2-0.8-1.9-1.2c-0.8-0.3-1.6-0.7-2.5-1c-0.9-0.3-1.8-0.7-2.5-1.1c-0.8-0.4-1.4-1-1.9-1.6
              c-0.5-0.6-0.8-1.4-0.8-2.4c0-0.9,0.2-1.6,0.6-2.3c0.4-0.7,0.9-1.2,1.6-1.7c0.6-0.4,1.3-0.8,2.1-1c0.8-0.2,1.6-0.3,2.3-0.3
              c0.4,0,1,0.1,1.7,0.2c0.7,0.1,1.5,0.3,2.2,0.6s1.3,0.6,1.9,1c0.5,0.4,0.8,0.9,0.8,1.4c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4
              c-0.3,0-0.6-0.1-0.9-0.3c-0.3-0.2-0.7-0.4-1.2-0.7c-0.4-0.2-0.9-0.5-1.5-0.7c-0.5-0.2-1.1-0.3-1.7-0.3c-0.4,0-0.8,0-1.2,0.1
              c-0.4,0.1-0.8,0.3-1.1,0.5c-0.3,0.2-0.6,0.5-0.8,0.8c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.5,0.3,1,0.8,1.3c0.5,0.4,1.2,0.7,1.9,1
              c0.8,0.3,1.6,0.7,2.5,1c0.9,0.3,1.8,0.8,2.5,1.3c0.8,0.5,1.4,1.1,1.9,1.8c0.5,0.7,0.8,1.6,0.8,2.6c0,0.9-0.2,1.8-0.7,2.5
              c-0.4,0.7-1,1.3-1.7,1.8c-0.7,0.5-1.5,0.8-2.3,1.1c-0.9,0.2-1.7,0.3-2.5,0.3c-0.6,0-1.3-0.1-2-0.2c-0.7-0.1-1.3-0.3-2-0.6
              c-0.6-0.3-1.3-0.6-1.8-1c-0.6-0.4-1.1-0.8-1.4-1.3L455.2,210.4z"/>
            <path class="st0" d="M487.6,190.6c0-0.5,0.2-0.9,0.5-1.1c0.3-0.3,0.7-0.4,1.2-0.4h5.6c1,0,1.9,0.2,2.8,0.5
              c0.9,0.3,1.6,0.8,2.3,1.4c0.6,0.6,1.2,1.3,1.5,2.1c0.4,0.8,0.6,1.8,0.6,2.8c0,1.2-0.3,2.3-0.9,3.2c-0.6,0.9-1.5,1.6-2.7,2.1
              c0.8,0.1,1.6,0.3,2.3,0.7c0.7,0.4,1.3,0.8,1.8,1.4c0.5,0.6,0.9,1.2,1.2,2c0.3,0.8,0.4,1.5,0.4,2.4c0,1.1-0.2,2.2-0.6,3.1
              c-0.4,0.9-1,1.8-1.7,2.4c-0.7,0.7-1.5,1.2-2.5,1.6c-1,0.4-2,0.6-3.1,0.6h-8.6V190.6z M495.7,212.4c0.7,0,1.3-0.1,2-0.4
              c0.6-0.2,1.2-0.6,1.6-1c0.5-0.4,0.8-1,1.1-1.5c0.3-0.6,0.4-1.2,0.4-1.9c0-0.7-0.1-1.3-0.4-1.9c-0.3-0.6-0.7-1.1-1.1-1.5
              c-0.5-0.4-1-0.8-1.6-1c-0.6-0.2-1.3-0.4-2-0.4h-5v9.7H495.7z M494,200.2c0.6,0,1.2-0.1,1.7-0.3s1.1-0.5,1.6-0.9
              c0.4-0.4,0.8-0.8,1.1-1.3c0.3-0.5,0.4-1.1,0.4-1.7c0-0.6-0.1-1.1-0.4-1.6c-0.2-0.5-0.6-0.9-1-1.3c-0.4-0.4-0.9-0.6-1.4-0.9
              s-1.1-0.3-1.7-0.3h-3.6v8.3H494z"/>
            <path class="st0" d="M510.1,197.1c0-0.5,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.4,1.1-0.4c0.4,0,0.8,0.2,1,0.5c0.3,0.3,0.4,0.7,0.4,1.1
              v18.2h-3.1V197.1z M510.7,190.3c0.2-0.3,0.6-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4c0.3,0.2,0.4,0.5,0.4,0.9c0,0.4-0.1,0.7-0.4,0.9
              c-0.3,0.3-0.6,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4c-0.3-0.3-0.4-0.6-0.4-0.9C510.3,190.9,510.4,190.6,510.7,190.3z"/>
            <path class="st0" d="M519.8,201.8c0.4-1.2,0.9-2.3,1.7-3.2c0.7-0.9,1.6-1.7,2.7-2.3s2.3-0.9,3.6-0.9c1.4,0,2.6,0.3,3.6,0.9
              c1.1,0.6,2,1.3,2.7,2.3c0.7,0.9,1.3,2,1.7,3.2c0.4,1.2,0.6,2.4,0.6,3.7c0,1.2-0.2,2.5-0.6,3.7c-0.4,1.2-0.9,2.3-1.7,3.2
              c-0.7,1-1.6,1.7-2.7,2.3c-1.1,0.6-2.3,0.9-3.6,0.9c-1.4,0-2.6-0.3-3.7-0.9c-1.1-0.6-2-1.3-2.7-2.3c-0.7-1-1.3-2-1.7-3.2
              c-0.4-1.2-0.6-2.4-0.6-3.7C519.2,204.3,519.4,203,519.8,201.8z M522.8,208c0.2,0.9,0.5,1.7,0.9,2.4s1,1.3,1.6,1.8
              c0.7,0.5,1.5,0.7,2.4,0.7c1,0,1.8-0.2,2.5-0.7c0.7-0.4,1.3-1,1.7-1.7c0.4-0.7,0.7-1.5,0.9-2.4c0.2-0.9,0.3-1.7,0.3-2.6
              c0-0.8-0.1-1.7-0.3-2.5c-0.2-0.9-0.5-1.6-0.9-2.3c-0.4-0.7-1-1.3-1.7-1.7c-0.7-0.4-1.5-0.7-2.5-0.7c-0.9,0-1.8,0.2-2.4,0.7
              c-0.7,0.5-1.2,1-1.6,1.8c-0.4,0.7-0.7,1.5-0.9,2.3c-0.2,0.8-0.3,1.7-0.3,2.5C522.5,206.3,522.6,207.2,522.8,208z"/>
            <path class="st0" d="M542.6,191.5c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.5,1.1-0.5c0.4,0,0.8,0.2,1.1,0.5c0.3,0.4,0.5,0.7,0.5,1.1
              l0,4.4c0.2,0,0.5,0,0.9,0c0.4,0,0.7,0,1,0.1c0.3,0.1,0.6,0.2,0.8,0.4c0.2,0.2,0.3,0.5,0.3,0.9c0,0.4-0.1,0.7-0.3,0.9
              c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.7,0-0.9,0v16.7h-3.1v-16.7c-0.3,0-0.6,0-0.9,0c-0.3,0-0.7,0-1-0.1
              c-0.3-0.1-0.6-0.2-0.8-0.4c-0.2-0.2-0.3-0.5-0.3-0.9s0.1-0.7,0.3-0.9c0.2-0.2,0.5-0.3,0.8-0.4c0.3-0.1,0.6-0.1,1-0.1
              c0.3,0,0.6,0,0.9,0V191.5z"/>
            <path class="st0" d="M555.7,209.1c0.3,0.7,0.7,1.4,1.2,1.9c0.5,0.5,1.1,1,1.8,1.3c0.7,0.3,1.5,0.5,2.3,0.5c0.7,0,1.4-0.1,2-0.2
              s1.3-0.3,2-0.5l0.7,2.4c-0.8,0.3-1.6,0.6-2.4,0.8s-1.6,0.3-2.5,0.3c-1.5,0-2.7-0.3-3.9-0.8c-1.1-0.5-2.1-1.3-2.8-2.2
              c-0.8-0.9-1.4-2-1.8-3.2c-0.4-1.2-0.6-2.5-0.6-3.9c0-1.3,0.2-2.5,0.6-3.7c0.4-1.2,1-2.3,1.8-3.2c0.8-0.9,1.7-1.7,2.8-2.2
              c1.1-0.6,2.3-0.8,3.7-0.8c1.3,0,2.4,0.3,3.5,0.8c1,0.5,1.9,1.3,2.6,2.1c0.7,0.9,1.3,1.9,1.7,3c0.4,1.1,0.6,2.3,0.6,3.5
              c0,0.7-0.2,1.2-0.5,1.4s-0.8,0.3-1.5,0.3h-11.8C555.3,207.6,555.4,208.3,555.7,209.1z M565.8,204.4c0-0.7-0.2-1.5-0.4-2.2
              c-0.2-0.7-0.6-1.4-1-2c-0.4-0.6-1-1.1-1.6-1.5c-0.6-0.4-1.3-0.6-2.2-0.6c-0.9,0-1.7,0.2-2.3,0.5c-0.6,0.4-1.2,0.8-1.6,1.4
              c-0.4,0.6-0.7,1.2-1,2c-0.2,0.8-0.4,1.5-0.5,2.3H565.8z"/>
            <path class="st0" d="M575.7,201.8c0.4-1.2,1-2.3,1.7-3.2c0.8-0.9,1.7-1.7,2.8-2.2c1.1-0.5,2.4-0.8,3.8-0.8c0.6,0,1.1,0,1.6,0.1
              c0.5,0.1,0.9,0.2,1.4,0.3c0.4,0.1,0.9,0.3,1.3,0.5c0.4,0.2,0.9,0.5,1.4,0.7l-1.5,2.5c-0.6-0.3-1.3-0.7-2-1
              c-0.7-0.3-1.4-0.5-2.2-0.5c-1,0-1.8,0.2-2.6,0.6s-1.3,1-1.7,1.6c-0.5,0.7-0.8,1.4-1,2.3s-0.3,1.7-0.3,2.5c0,0.8,0.1,1.6,0.3,2.4
              c0.2,0.9,0.6,1.6,1,2.3c0.4,0.7,1,1.3,1.7,1.7c0.7,0.5,1.5,0.7,2.4,0.7c0.4,0,0.7-0.1,1.1-0.2c0.4-0.1,0.7-0.2,1.1-0.4
              c0.3-0.1,0.7-0.3,0.9-0.4c0.3-0.1,0.5-0.2,0.8-0.2c0.4,0,0.7,0.1,1,0.4s0.4,0.6,0.4,1c0,0.5-0.2,1-0.6,1.3
              c-0.4,0.3-0.9,0.6-1.4,0.8c-0.6,0.2-1.1,0.3-1.7,0.4c-0.6,0.1-1.1,0.1-1.5,0.1c-1.4,0-2.7-0.3-3.8-0.8c-1.1-0.5-2-1.3-2.8-2.2
              c-0.7-0.9-1.3-2-1.7-3.2c-0.4-1.2-0.6-2.4-0.6-3.8C575.1,204.3,575.3,203,575.7,201.8z"/>
            <path class="st0" d="M596.1,197.1c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.5,1.1-0.5c0.4,0,0.7,0.1,0.9,0.3c0.2,0.2,0.4,0.4,0.4,0.6
              c0.1,0.3,0.1,0.5,0.1,0.9c0,0.3,0,0.6,0,0.9c0.6-0.8,1.3-1.5,2.2-1.9c0.9-0.4,1.9-0.7,2.9-0.7c1.1,0,2.1,0.2,3,0.5
              c0.9,0.4,1.6,0.9,2.2,1.5c0.6,0.7,1.1,1.4,1.4,2.3c0.3,0.9,0.5,1.9,0.5,2.9v11.1c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5
              c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.7-0.4-1.1V203c0-0.6-0.1-1.2-0.4-1.8c-0.2-0.6-0.6-1.1-1-1.5c-0.4-0.4-0.9-0.8-1.5-1
              c-0.6-0.3-1.2-0.4-1.8-0.4c-0.6,0-1.2,0.1-1.8,0.4c-0.6,0.2-1,0.6-1.5,1c-0.4,0.4-0.7,0.9-1,1.5c-0.2,0.6-0.3,1.1-0.3,1.8v12.4
              h-3.1V197.1z"/>
            <path class="st0" d="M618.1,201.8c0.4-1.2,0.9-2.3,1.7-3.2c0.7-0.9,1.6-1.7,2.7-2.3s2.3-0.9,3.6-0.9c1.4,0,2.6,0.3,3.6,0.9
              c1.1,0.6,2,1.3,2.7,2.3c0.7,0.9,1.3,2,1.7,3.2c0.4,1.2,0.6,2.4,0.6,3.7c0,1.2-0.2,2.5-0.6,3.7s-0.9,2.3-1.7,3.2
              c-0.7,1-1.6,1.7-2.7,2.3c-1.1,0.6-2.3,0.9-3.6,0.9c-1.4,0-2.6-0.3-3.7-0.9c-1.1-0.6-2-1.3-2.7-2.3c-0.7-1-1.3-2-1.7-3.2
              c-0.4-1.2-0.6-2.4-0.6-3.7C617.5,204.3,617.7,203,618.1,201.8z M621,208c0.2,0.9,0.5,1.7,0.9,2.4s1,1.3,1.6,1.8
              c0.7,0.5,1.5,0.7,2.4,0.7c1,0,1.8-0.2,2.5-0.7c0.7-0.4,1.3-1,1.7-1.7c0.4-0.7,0.7-1.5,0.9-2.4c0.2-0.9,0.3-1.7,0.3-2.6
              c0-0.8-0.1-1.7-0.3-2.5c-0.2-0.9-0.5-1.6-0.9-2.3c-0.4-0.7-1-1.3-1.7-1.7c-0.7-0.4-1.5-0.7-2.5-0.7c-0.9,0-1.8,0.2-2.4,0.7
              c-0.7,0.5-1.2,1-1.6,1.8c-0.4,0.7-0.7,1.5-0.9,2.3c-0.2,0.8-0.3,1.7-0.3,2.5C620.8,206.3,620.9,207.2,621,208z"/>
            <path class="st0" d="M640.7,188.5c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.7-0.5,1.1-0.5c0.4,0,0.8,0.2,1.1,0.5c0.3,0.3,0.4,0.7,0.4,1.1
              v26.8l-3.1,0V188.5z"/>
            <path class="st0" d="M650.4,201.8c0.4-1.2,0.9-2.3,1.7-3.2c0.7-0.9,1.6-1.7,2.7-2.3s2.3-0.9,3.6-0.9c1.4,0,2.6,0.3,3.6,0.9
              c1.1,0.6,2,1.3,2.7,2.3c0.7,0.9,1.3,2,1.7,3.2c0.4,1.2,0.6,2.4,0.6,3.7c0,1.2-0.2,2.5-0.6,3.7s-0.9,2.3-1.7,3.2
              c-0.7,1-1.6,1.7-2.7,2.3c-1.1,0.6-2.3,0.9-3.6,0.9c-1.4,0-2.6-0.3-3.7-0.9c-1.1-0.6-2-1.3-2.7-2.3c-0.7-1-1.3-2-1.7-3.2
              c-0.4-1.2-0.6-2.4-0.6-3.7C649.8,204.3,650,203,650.4,201.8z M653.4,208c0.2,0.9,0.5,1.7,0.9,2.4c0.4,0.7,1,1.3,1.6,1.8
              c0.7,0.5,1.5,0.7,2.4,0.7c1,0,1.8-0.2,2.5-0.7c0.7-0.4,1.3-1,1.7-1.7c0.4-0.7,0.7-1.5,0.9-2.4c0.2-0.9,0.3-1.7,0.3-2.6
              c0-0.8-0.1-1.7-0.3-2.5c-0.2-0.9-0.5-1.6-0.9-2.3c-0.4-0.7-1-1.3-1.7-1.7c-0.7-0.4-1.5-0.7-2.5-0.7c-0.9,0-1.8,0.2-2.4,0.7
              c-0.7,0.5-1.2,1-1.6,1.8c-0.4,0.7-0.7,1.5-0.9,2.3c-0.2,0.8-0.3,1.7-0.3,2.5C653.1,206.3,653.2,207.2,653.4,208z M662.1,189.8
              c0.3,0.3,0.4,0.6,0.4,1c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.4,0.4-0.7,0.6c-0.2,0.1-0.6,0.3-1.2,0.5c-0.5,0.2-1.1,0.5-1.7,0.7
              c-0.6,0.2-1.2,0.5-1.7,0.6c-0.5,0.2-0.9,0.3-1.1,0.3c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.6-0.4-1c0-0.3,0.1-0.6,0.3-0.9
              c0.2-0.3,0.4-0.4,0.7-0.6c0.2-0.1,0.6-0.3,1.2-0.5c0.5-0.2,1.1-0.5,1.7-0.7c0.6-0.2,1.2-0.5,1.7-0.6c0.5-0.2,0.9-0.3,1.1-0.3
              C661.5,189.4,661.8,189.5,662.1,189.8z"/>
            <path class="st0" d="M687.3,197.4c0-0.3,0-0.6,0.1-0.9c0.1-0.3,0.2-0.5,0.4-0.7c0.2-0.2,0.5-0.3,1-0.3c0.4,0,0.8,0.2,1,0.5
              c0.3,0.3,0.4,0.7,0.4,1.1v17.3c0,1.3-0.2,2.5-0.7,3.6c-0.5,1.1-1.1,2.1-2,2.9c-0.8,0.8-1.8,1.4-2.9,1.9c-1.1,0.5-2.3,0.7-3.6,0.7
              c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8-0.1-1.1-0.2c0,0,0,0,0-0.1V223c0-0.5,0-0.9,0.1-1.4c0-0.4,0.1-0.9,0.1-1.3
              c0.8,0.2,1.5,0.3,2.3,0.3c1.1,0,2-0.2,2.8-0.6c0.8-0.4,1.4-0.9,1.9-1.5c0.5-0.6,0.8-1.4,1.1-2.3c0.2-0.9,0.3-1.8,0.3-2.7
              c0-0.1,0-0.2,0-0.4c0-0.1,0-0.3,0-0.4c-0.6,1-1.4,1.7-2.4,2.2c-1,0.5-2,0.7-3.2,0.7c-1.4,0-2.6-0.3-3.6-0.8
              c-1-0.6-1.9-1.3-2.6-2.2c-0.7-0.9-1.2-2-1.6-3.1c-0.4-1.2-0.5-2.4-0.5-3.6c0-1.2,0.2-2.5,0.5-3.7c0.4-1.2,0.9-2.3,1.6-3.3
              c0.7-1,1.6-1.8,2.6-2.4c1-0.6,2.3-0.9,3.7-0.9c1.1,0,2.1,0.2,3.1,0.7c1,0.5,1.8,1.2,2.4,2.1C687.3,198.1,687.3,197.8,687.3,197.4z
               M684.6,212.2c0.6-0.4,1.2-1,1.6-1.7c0.4-0.7,0.7-1.4,0.9-2.3c0.2-0.8,0.3-1.6,0.3-2.4c0-0.7,0-1.3-0.1-2c0-0.7-0.1-1.3-0.3-1.9
              c-0.2-0.6-0.5-1.1-0.8-1.7c-0.4-0.5-0.9-0.9-1.5-1.3c-0.8-0.4-1.6-0.7-2.4-0.7c-1,0-1.8,0.2-2.6,0.6c-0.7,0.4-1.3,1-1.7,1.7
              c-0.4,0.7-0.8,1.4-1,2.3c-0.2,0.9-0.3,1.7-0.3,2.6c0,0.9,0.1,1.7,0.3,2.6c0.2,0.9,0.5,1.7,0.9,2.4c0.4,0.7,1,1.3,1.7,1.7
              c0.7,0.4,1.6,0.7,2.6,0.7C683.2,212.9,683.9,212.7,684.6,212.2z"/>
            <path class="st0" d="M696.3,197.1c0-0.5,0.2-0.8,0.5-1.1s0.7-0.4,1.1-0.4c0.4,0,0.8,0.2,1,0.5c0.3,0.3,0.4,0.7,0.4,1.1v18.2h-3.1
              V197.1z M696.9,190.3c0.2-0.3,0.6-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4c0.3,0.2,0.4,0.5,0.4,0.9c0,0.4-0.1,0.7-0.4,0.9
              c-0.3,0.3-0.6,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4c-0.3-0.3-0.4-0.6-0.4-0.9C696.5,190.9,696.6,190.6,696.9,190.3z"/>
            <path class="st0" d="M706,201.8c0.4-1.2,1-2.3,1.7-3.2c0.8-0.9,1.7-1.7,2.8-2.2c1.1-0.5,2.4-0.8,3.8-0.8c0.6,0,1.1,0,1.6,0.1
              c0.5,0.1,0.9,0.2,1.4,0.3c0.4,0.1,0.9,0.3,1.3,0.5c0.4,0.2,0.9,0.5,1.4,0.7l-1.5,2.5c-0.6-0.3-1.3-0.7-2-1
              c-0.7-0.3-1.4-0.5-2.2-0.5c-1,0-1.8,0.2-2.6,0.6s-1.3,1-1.7,1.6c-0.5,0.7-0.8,1.4-1,2.3c-0.2,0.8-0.3,1.7-0.3,2.5
              c0,0.8,0.1,1.6,0.3,2.4c0.2,0.9,0.6,1.6,1,2.3c0.4,0.7,1,1.3,1.7,1.7c0.7,0.5,1.5,0.7,2.4,0.7c0.4,0,0.7-0.1,1.1-0.2
              c0.4-0.1,0.7-0.2,1.1-0.4c0.3-0.1,0.7-0.3,0.9-0.4c0.3-0.1,0.5-0.2,0.8-0.2c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1
              c0,0.5-0.2,1-0.6,1.3c-0.4,0.3-0.9,0.6-1.4,0.8c-0.6,0.2-1.1,0.3-1.7,0.4c-0.6,0.1-1.1,0.1-1.5,0.1c-1.4,0-2.7-0.3-3.8-0.8
              c-1.1-0.5-2-1.3-2.8-2.2c-0.7-0.9-1.3-2-1.7-3.2c-0.4-1.2-0.6-2.4-0.6-3.8C705.4,204.3,705.6,203,706,201.8z"/>
            <path class="st0" d="M737.3,197.2c0-0.3,0.1-0.6,0.1-0.8c0.1-0.2,0.2-0.4,0.5-0.6c0.2-0.2,0.5-0.2,1-0.2c0.4,0,0.8,0.2,1,0.5
              s0.4,0.7,0.4,1.1v18.3h-3.1v-2.8c-0.6,1-1.4,1.7-2.3,2.2s-2,0.7-3.2,0.7c-1.4,0-2.6-0.3-3.7-0.9s-1.9-1.3-2.6-2.3
              c-0.7-0.9-1.2-2-1.6-3.2c-0.4-1.2-0.5-2.4-0.5-3.7c0-1.2,0.2-2.4,0.6-3.6c0.4-1.2,0.9-2.2,1.6-3.2s1.6-1.7,2.6-2.3
              c1-0.6,2.2-0.9,3.6-0.9c1.1,0,2.1,0.2,3,0.7c1,0.4,1.8,1.1,2.5,1.9C737.3,197.8,737.3,197.5,737.3,197.2z M726.9,208.1
              c0.2,0.9,0.5,1.7,0.9,2.4c0.4,0.7,1,1.3,1.7,1.8c0.7,0.5,1.5,0.7,2.5,0.7c1.1,0,2-0.2,2.7-0.7c0.7-0.5,1.3-1.1,1.7-1.8
              c0.4-0.7,0.7-1.5,0.8-2.5c0.1-0.9,0.2-1.8,0.2-2.7c0-0.8-0.1-1.7-0.2-2.5c-0.2-0.8-0.4-1.6-0.8-2.3c-0.4-0.7-0.9-1.2-1.6-1.6
              s-1.5-0.6-2.5-0.6c-1,0-1.8,0.2-2.6,0.7c-0.7,0.4-1.3,1-1.7,1.7c-0.4,0.7-0.8,1.5-1,2.3c-0.2,0.9-0.3,1.7-0.3,2.6
              C726.6,206.3,726.7,207.2,726.9,208.1z"/>
            <path class="st0" d="M748.2,210.4c0.5,0.3,0.9,0.7,1.3,0.9c0.4,0.3,0.8,0.5,1.2,0.8c0.4,0.2,0.8,0.4,1.3,0.5
              c0.5,0.1,1,0.2,1.6,0.2c0.4,0,0.8-0.1,1.3-0.2c0.4-0.1,0.9-0.3,1.2-0.6c0.4-0.3,0.7-0.6,0.9-0.9c0.2-0.3,0.4-0.8,0.4-1.3
              c0-0.7-0.3-1.2-0.8-1.7c-0.5-0.4-1.2-0.8-1.9-1.2c-0.8-0.3-1.6-0.7-2.5-1c-0.9-0.3-1.8-0.7-2.5-1.1c-0.8-0.4-1.4-1-1.9-1.6
              c-0.5-0.6-0.8-1.4-0.8-2.4c0-0.9,0.2-1.6,0.6-2.3c0.4-0.7,0.9-1.2,1.5-1.7c0.6-0.4,1.3-0.8,2.1-1c0.8-0.2,1.6-0.3,2.3-0.3
              c0.4,0,1,0.1,1.7,0.2c0.7,0.1,1.5,0.3,2.2,0.6c0.7,0.3,1.3,0.6,1.9,1c0.5,0.4,0.8,0.9,0.8,1.4c0,0.4-0.1,0.7-0.4,1
              c-0.3,0.3-0.6,0.4-1,0.4c-0.3,0-0.6-0.1-0.9-0.3c-0.3-0.2-0.7-0.4-1.2-0.7c-0.4-0.2-0.9-0.5-1.5-0.7c-0.5-0.2-1.1-0.3-1.7-0.3
              c-0.4,0-0.8,0-1.2,0.1c-0.4,0.1-0.8,0.3-1.1,0.5c-0.3,0.2-0.6,0.5-0.8,0.8c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.5,0.3,1,0.8,1.3
              c0.5,0.4,1.2,0.7,1.9,1c0.8,0.3,1.6,0.7,2.5,1c0.9,0.3,1.8,0.8,2.5,1.3c0.8,0.5,1.4,1.1,1.9,1.8c0.5,0.7,0.8,1.6,0.8,2.6
              c0,0.9-0.2,1.8-0.7,2.5c-0.4,0.7-1,1.3-1.7,1.8c-0.7,0.5-1.5,0.8-2.3,1.1c-0.9,0.2-1.7,0.3-2.5,0.3c-0.6,0-1.3-0.1-1.9-0.2
              c-0.7-0.1-1.3-0.3-2-0.6c-0.6-0.3-1.3-0.6-1.8-1c-0.6-0.4-1.1-0.8-1.4-1.3L748.2,210.4z"/>
            <path class="st0" d="M783.5,209.1c0.3,0.7,0.7,1.4,1.2,1.9c0.5,0.5,1.1,1,1.8,1.3c0.7,0.3,1.5,0.5,2.3,0.5c0.7,0,1.4-0.1,2-0.2
              c0.7-0.2,1.3-0.3,2-0.5l0.7,2.4c-0.8,0.3-1.6,0.6-2.4,0.8s-1.6,0.3-2.5,0.3c-1.5,0-2.7-0.3-3.9-0.8c-1.1-0.5-2.1-1.3-2.8-2.2
              c-0.8-0.9-1.4-2-1.8-3.2c-0.4-1.2-0.6-2.5-0.6-3.9c0-1.3,0.2-2.5,0.6-3.7c0.4-1.2,1-2.3,1.8-3.2c0.8-0.9,1.7-1.7,2.8-2.2
              c1.1-0.6,2.3-0.8,3.7-0.8c1.3,0,2.4,0.3,3.5,0.8c1,0.5,1.9,1.3,2.6,2.1c0.7,0.9,1.3,1.9,1.7,3c0.4,1.1,0.6,2.3,0.6,3.5
              c0,0.7-0.2,1.2-0.5,1.4c-0.3,0.2-0.8,0.3-1.5,0.3H783C783,207.6,783.2,208.3,783.5,209.1z M793.5,204.4c0-0.7-0.2-1.5-0.4-2.2
              c-0.2-0.7-0.6-1.4-1-2c-0.4-0.6-1-1.1-1.6-1.5c-0.6-0.4-1.3-0.6-2.2-0.6c-0.9,0-1.7,0.2-2.3,0.5c-0.6,0.4-1.2,0.8-1.6,1.4
              c-0.4,0.6-0.7,1.2-1,2c-0.2,0.8-0.4,1.5-0.5,2.3H793.5z"/>
            <path class="st0" d="M816.8,190.4c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.5,1.1-0.5c0.4,0,0.8,0.2,1.1,0.5c0.3,0.3,0.4,0.7,0.4,1.1
              v24.8h-3.1V190.4z"/>
            <path class="st0" d="M826,197.1c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.5,1.1-0.5c0.4,0,0.7,0.1,0.9,0.3c0.2,0.2,0.4,0.4,0.4,0.6
              c0.1,0.3,0.1,0.5,0.1,0.9c0,0.3,0,0.6,0,0.9c0.6-0.8,1.3-1.5,2.2-1.9c0.9-0.4,1.9-0.7,2.9-0.7c1.1,0,2.1,0.2,3,0.5
              c0.9,0.4,1.6,0.9,2.2,1.5c0.6,0.7,1.1,1.4,1.4,2.3c0.3,0.9,0.5,1.9,0.5,2.9v11.1c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5
              c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.7-0.4-1.1V203c0-0.6-0.1-1.2-0.4-1.8c-0.2-0.6-0.6-1.1-1-1.5c-0.4-0.4-0.9-0.8-1.5-1
              c-0.6-0.3-1.2-0.4-1.8-0.4s-1.2,0.1-1.8,0.4c-0.6,0.2-1,0.6-1.5,1s-0.7,0.9-1,1.5c-0.2,0.6-0.3,1.1-0.3,1.8v12.4H826V197.1z"/>
            <path class="st0" d="M861.3,187.4h3.1V214c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5c-0.5,0-0.8-0.1-1-0.3
              c-0.2-0.2-0.4-0.5-0.5-0.8c-0.1-0.3-0.1-0.6-0.1-1s0-0.7,0-1c-0.6,1-1.4,1.7-2.4,2.3c-1,0.5-2.1,0.8-3.2,0.8
              c-1.3,0-2.5-0.3-3.5-0.9c-1-0.6-1.9-1.4-2.6-2.3c-0.7-0.9-1.2-2-1.6-3.2c-0.4-1.2-0.5-2.4-0.5-3.5c0-1.3,0.2-2.5,0.6-3.7
              c0.4-1.2,0.9-2.3,1.6-3.2c0.7-0.9,1.6-1.7,2.7-2.3c1.1-0.6,2.3-0.9,3.7-0.9c1.1,0,2.2,0.2,3.1,0.7c0.9,0.5,1.7,1.2,2.3,2.2V187.4z
               M858.3,212.1c0.7-0.4,1.2-0.9,1.7-1.5c0.4-0.6,0.8-1.3,1-2.1c0.2-0.8,0.3-1.6,0.3-2.5c0-0.8-0.1-1.7-0.2-2.6
              c-0.1-0.9-0.4-1.7-0.8-2.4c-0.4-0.7-0.9-1.3-1.6-1.8c-0.7-0.5-1.6-0.7-2.6-0.7c-0.9,0-1.8,0.2-2.5,0.6c-0.7,0.4-1.3,1-1.7,1.7
              c-0.5,0.7-0.8,1.4-1,2.3c-0.2,0.8-0.3,1.7-0.3,2.5c0,0.8,0.1,1.6,0.3,2.5c0.2,0.9,0.5,1.6,0.9,2.3c0.4,0.7,1,1.3,1.6,1.7
              c0.7,0.4,1.5,0.7,2.5,0.7C856.8,212.6,857.6,212.5,858.3,212.1z"/>
            <path class="st0" d="M870.4,196.3l3.1,0V208c0,1.5,0.4,2.7,1.1,3.6c0.7,0.9,1.9,1.3,3.5,1.3c1.6,0,2.8-0.4,3.5-1.3
              c0.7-0.9,1.1-2,1.1-3.6v-10.9c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.5,1.1-0.5c0.4,0,0.8,0.2,1.1,0.5c0.3,0.3,0.4,0.7,0.4,1.1V208
              c0,2.5-0.7,4.3-2,5.6c-1.3,1.3-3.2,1.9-5.7,1.9c-2.5,0-4.4-0.6-5.7-1.9c-1.3-1.3-2-3.1-2-5.6V196.3z"/>
            <path class="st0" d="M893.1,210.4c0.5,0.3,0.9,0.7,1.3,0.9c0.4,0.3,0.8,0.5,1.2,0.8c0.4,0.2,0.8,0.4,1.3,0.5
              c0.5,0.1,1,0.2,1.6,0.2c0.4,0,0.8-0.1,1.3-0.2c0.4-0.1,0.9-0.3,1.2-0.6c0.4-0.3,0.7-0.6,0.9-0.9c0.2-0.3,0.4-0.8,0.4-1.3
              c0-0.7-0.3-1.2-0.8-1.7c-0.5-0.4-1.2-0.8-1.9-1.2c-0.8-0.3-1.6-0.7-2.5-1c-0.9-0.3-1.8-0.7-2.5-1.1c-0.8-0.4-1.4-1-1.9-1.6
              c-0.5-0.6-0.8-1.4-0.8-2.4c0-0.9,0.2-1.6,0.6-2.3c0.4-0.7,0.9-1.2,1.5-1.7c0.6-0.4,1.3-0.8,2.1-1c0.8-0.2,1.6-0.3,2.3-0.3
              c0.4,0,1,0.1,1.7,0.2c0.7,0.1,1.5,0.3,2.2,0.6s1.3,0.6,1.9,1c0.5,0.4,0.8,0.9,0.8,1.4c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4
              c-0.3,0-0.6-0.1-0.9-0.3c-0.3-0.2-0.7-0.4-1.2-0.7c-0.4-0.2-0.9-0.5-1.5-0.7c-0.5-0.2-1.1-0.3-1.7-0.3c-0.4,0-0.8,0-1.2,0.1
              c-0.4,0.1-0.8,0.3-1.1,0.5c-0.3,0.2-0.6,0.5-0.8,0.8c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.5,0.3,1,0.8,1.3c0.5,0.4,1.2,0.7,1.9,1
              c0.8,0.3,1.6,0.7,2.5,1c0.9,0.3,1.8,0.8,2.5,1.3c0.8,0.5,1.4,1.1,1.9,1.8c0.5,0.7,0.8,1.6,0.8,2.6c0,0.9-0.2,1.8-0.7,2.5
              c-0.4,0.7-1,1.3-1.7,1.8c-0.7,0.5-1.5,0.8-2.3,1.1c-0.9,0.2-1.7,0.3-2.5,0.3c-0.6,0-1.3-0.1-1.9-0.2c-0.7-0.1-1.3-0.3-2-0.6
              c-0.6-0.3-1.3-0.6-1.8-1c-0.6-0.4-1.1-0.8-1.4-1.3L893.1,210.4z"/>
            <path class="st0" d="M913.2,191.5c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.5,1.1-0.5c0.4,0,0.8,0.2,1.1,0.5c0.3,0.4,0.5,0.7,0.5,1.1
              l0,4.4c0.2,0,0.5,0,0.9,0c0.4,0,0.7,0,1,0.1c0.3,0.1,0.6,0.2,0.8,0.4c0.2,0.2,0.3,0.5,0.3,0.9c0,0.4-0.1,0.7-0.3,0.9
              c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.7,0-0.9,0v16.7h-3.1v-16.7c-0.3,0-0.6,0-0.9,0c-0.3,0-0.7,0-1-0.1
              c-0.3-0.1-0.6-0.2-0.8-0.4c-0.2-0.2-0.3-0.5-0.3-0.9s0.1-0.7,0.3-0.9c0.2-0.2,0.5-0.3,0.8-0.4c0.3-0.1,0.6-0.1,1-0.1
              c0.3,0,0.6,0,0.9,0V191.5z"/>
            <path class="st0" d="M925.2,197.1c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.4,1.1-0.4c0.4,0,0.7,0.1,1.1,0.4c0.3,0.2,0.5,0.6,0.5,1
              c0,0.3,0,0.6,0,0.9c0,0.3-0.1,0.6-0.1,0.9c0.1-0.4,0.3-0.7,0.5-1.1c0.2-0.4,0.5-0.7,0.8-1.1c0.3-0.3,0.7-0.6,1.1-0.8
              c0.4-0.2,0.8-0.3,1.2-0.3c0.5,0,0.9,0.2,1.3,0.5c0.4,0.3,0.5,0.7,0.5,1.2c0,0.4-0.1,0.7-0.4,0.9c-0.3,0.2-0.6,0.4-1,0.6
              c-0.4,0.2-0.8,0.4-1.3,0.6c-0.5,0.2-0.9,0.6-1.3,1.1c-0.4,0.5-0.7,1.1-1,1.8c-0.3,0.8-0.4,1.8-0.4,3v10l-3.1,0V197.1z"/>
            <path class="st0" d="M939.6,197.1c0-0.5,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.4,1.1-0.4c0.4,0,0.8,0.2,1,0.5c0.3,0.3,0.4,0.7,0.4,1.1
              v18.2h-3.1V197.1z M940.2,190.3c0.2-0.3,0.6-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4c0.3,0.2,0.4,0.5,0.4,0.9c0,0.4-0.1,0.7-0.4,0.9
              c-0.3,0.3-0.6,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4c-0.3-0.3-0.4-0.6-0.4-0.9C939.8,190.9,939.9,190.6,940.2,190.3z"/>
            <path class="st0" d="M962.7,197.2c0-0.3,0.1-0.6,0.1-0.8c0.1-0.2,0.2-0.4,0.5-0.6c0.2-0.2,0.5-0.2,1-0.2c0.4,0,0.8,0.2,1,0.5
              c0.3,0.3,0.4,0.7,0.4,1.1v18.3h-3.1v-2.8c-0.6,1-1.4,1.7-2.3,2.2c-1,0.5-2,0.7-3.2,0.7c-1.4,0-2.6-0.3-3.7-0.9
              c-1.1-0.6-1.9-1.3-2.6-2.3c-0.7-0.9-1.2-2-1.6-3.2c-0.4-1.2-0.5-2.4-0.5-3.7c0-1.2,0.2-2.4,0.6-3.6c0.4-1.2,0.9-2.2,1.6-3.2
              c0.7-0.9,1.6-1.7,2.6-2.3c1-0.6,2.2-0.9,3.6-0.9c1.1,0,2.1,0.2,3,0.7c1,0.4,1.8,1.1,2.5,1.9C962.7,197.8,962.7,197.5,962.7,197.2z
               M952.3,208.1c0.2,0.9,0.5,1.7,0.9,2.4c0.4,0.7,1,1.3,1.7,1.8c0.7,0.5,1.5,0.7,2.5,0.7c1.1,0,2-0.2,2.7-0.7
              c0.7-0.5,1.3-1.1,1.7-1.8c0.4-0.7,0.7-1.5,0.8-2.5c0.1-0.9,0.2-1.8,0.2-2.7c0-0.8-0.1-1.7-0.2-2.5c-0.2-0.8-0.4-1.6-0.8-2.3
              c-0.4-0.7-0.9-1.2-1.6-1.6c-0.7-0.4-1.5-0.6-2.5-0.6c-1,0-1.8,0.2-2.6,0.7c-0.7,0.4-1.3,1-1.7,1.7c-0.4,0.7-0.8,1.5-1,2.3
              c-0.2,0.9-0.3,1.7-0.3,2.6C952,206.3,952.1,207.2,952.3,208.1z"/>
          </g>
          <path class="st0" d="M301.3,88.5C306,83,308.7,76,308.7,69c0-18.7-14.5-34.2-33.7-36.2c-0.6-0.1-1.3-0.2-2-0.2h-28.8
                c-5,0-9,3.9-9,8.6v76.3c0,20.1,17,36.4,37.8,36.4h7.2c20.9,0,37.8-16.3,37.8-36.4C318.1,105.4,311.9,94.8,301.3,88.5z M276.6,82.3
                h-23.5V49.9h17.7c11,0,19.9,8.6,19.9,19.1C290.8,74.6,285.8,82.3,276.6,82.3z M300.1,117.5c0,10.5-8.9,19.1-19.9,19.1H273
                c-11,0-19.9-8.6-19.9-19.1V99.6h23.5C290.9,99.6,300.1,106.6,300.1,117.5z"/>
          <path class="st0" d="M334.8,34.7c-5,0-9,3.2-9,7.2v4.2c0,4,4,7.2,9,7.2s9-3.2,9-7.2v-4.2C343.8,38,339.8,34.7,334.8,34.7z"/>
          <path class="st0" d="M334.8,58c-5,0-9,3.9-9,8.6v80.8c0,4.8,4,8.6,9,8.6s9-3.9,9-8.6V66.7C343.8,61.9,339.8,58,334.8,58z"/>
          <path class="st0" d="M392.9,58h-5.6c-17.3,0-31.4,13.5-31.4,30.2v37.7c0,16.6,14.1,30.2,31.4,30.2h5.6c17.3,0,31.4-13.5,31.4-30.2
                V88.2C424.3,71.6,410.2,58,392.9,58z M406.3,88.2v37.7c0,7.1-6,12.9-13.4,12.9h-5.6c-7.4,0-13.4-5.8-13.4-12.9V88.2
                c0-7.1,6-12.9,13.4-12.9h5.6C400.3,75.3,406.3,81.1,406.3,88.2z"/>
          <path class="st0" d="M467.8,138.8c-7.4,0-13.4-5.8-13.4-12.9V75.3h3.6c5,0,9-3.9,9-8.6c0-4.8-4-8.6-9-8.6h-3.6V43.4
		            c0-4.8-4-8.6-9-8.6s-9,3.9-9,8.6v82.5c0,16.6,14.1,30.2,31.4,30.2c5,0,9-3.9,9-8.6S472.8,138.8,467.8,138.8z"/>
          <path class="st0" d="M526,58h-5.6C503.1,58,489,71.6,489,88.2v59.3c0,4.8,4,8.6,9,8.6c5,0,9-3.9,9-8.6V88.2c0-7.1,6-12.9,13.4-12.9
		            h5.6c7.4,0,13.4,5.8,13.4,12.9c0,4.8,4,8.6,9,8.6c5,0,9-3.9,9-8.6C557.4,71.6,543.3,58,526,58z"/>
          <path class="st0" d="M606.5,58h-5.6c-17.3,0-31.4,13.5-31.4,30.2c0,4.8,4,8.6,9,8.6c5,0,9-3.9,9-8.6c0-7.1,6-12.9,13.4-12.9h5.6
                c7.4,0,13.4,5.8,13.4,12.9v10.2h-16.2c-28.6,0-34.2,2.2-34.2,27.5c0,16.6,14.1,30.2,31.4,30.2h5.6c17.3,0,31.4-13.5,31.4-30.2V88.2
                C637.9,71.6,623.8,58,606.5,58z M620,115.7v10.2c0,7.1-6,12.9-13.4,12.9h-5.6c-7.4,0-13.4-5.8-13.4-12.9c0-3,0-7.6,0.3-9.7
                c2.8-0.5,10.3-0.5,15.9-0.5H620z"/>
          <path class="st0" d="M687.1,58h-5.6c-17.3,0-31.4,13.5-31.4,30.2v59.3c0,4.8,4,8.6,9,8.6c5,0,9-3.9,9-8.6V88.2
		            c0-7.1,6-12.9,13.4-12.9h5.6c7.4,0,13.4,5.8,13.4,12.9v59.3c0,4.8,4,8.6,9,8.6c5,0,9-3.9,9-8.6V88.2C718.5,71.6,704.4,58,687.1,58z"/>
          <path class="st0" d="M762,75.3h5.6c7.4,0,13.4,5.8,13.4,12.9c0,4.8,4,8.6,9,8.6c5,0,9-3.9,9-8.6c0-16.6-14.1-30.2-31.4-30.2H762
                c-17.3,0-31.4,13.5-31.4,30.2c0,21.5,19.6,25.1,32.6,27.6c15.7,2.9,17.8,4.7,17.8,10.1c0,7.1-6,12.9-13.4,12.9H762
                c-7.4,0-13.4-5.8-13.4-12.9c0-4.8-4-8.6-9-8.6c-5,0-9,3.9-9,8.6c0,16.6,14.1,30.2,31.4,30.2h5.6c17.3,0,31.4-13.5,31.4-30.2
                c0-21.1-19.4-24.7-32.3-27.1c-16-3-18.1-4.8-18.1-10.6C748.6,81.1,754.6,75.3,762,75.3z"/>
          <path class="st0" d="M842.6,52h3.7c5,0,9-3.9,9-8.6s-4-8.6-9-8.6h-3.7c-17.3,0-31.4,13.5-31.4,30.2v82.5c0,4.8,4,8.6,9,8.6
		            c5,0,9-3.9,9-8.6v-55h17.1c5,0,9-3.9,9-8.6s-4-8.6-9-8.6h-17.1V64.9C829.2,57.8,835.2,52,842.6,52z"/>
          <path class="st0" d="M901.6,115.7c28.6,0,34.2-2.2,34.2-27.5c0-16.6-14.1-30.2-31.4-30.2h-5.6c-17.3,0-31.4,13.5-31.4,30.2v37.7
                c0,16.6,14.1,30.2,31.4,30.2h5.6c17.3,0,31.4-13.5,31.4-30.2c0-4.8-4-8.6-9-8.6c-5,0-9,3.9-9,8.6c0,7.1-6,12.9-13.4,12.9h-5.6
                c-7.4,0-13.4-5.8-13.4-12.9v-10.2H901.6z M885.4,98.4V88.2c0-7.1,6-12.9,13.4-12.9h5.6c7.4,0,13.4,5.8,13.4,12.9c0,3,0,7.6-0.3,9.7
                c-2.8,0.5-10.3,0.5-15.9,0.5H885.4z"/>
          <path class="st0" d="M985,58h-5.6C962.1,58,948,71.6,948,88.2v59.3c0,4.8,4,8.6,9,8.6c5,0,9-3.9,9-8.6V88.2c0-7.1,6-12.9,13.4-12.9
		            h5.6c7.4,0,13.4,5.8,13.4,12.9c0,4.8,4,8.6,9,8.6s9-3.9,9-8.6C1016.3,71.6,1002.3,58,985,58z"/>
          <path class="st0" d="M139.5,118.6c9.4-11,14.9-25.1,14.9-39c0-37.2-28.9-68.2-67.3-72.2c-1.3-0.3-2.6-0.4-4-0.4H25.6
                C15.7,7,7.7,14.7,7.7,24.2v77C23.1,83.3,44.8,66.4,75.3,57c-5.5-11.6-5.5-11.6-5.5-11.6h41.6L85.9,82.2l-6.3-13.1
                c0,0-36.1,17.5-50.7,58C14.3,167.4,23.1,249,100,249c40.5-1.3,73-33.3,73-72.5C173,152.2,160.7,131.2,139.5,118.6z M72.6,92.2
                c2.8-2.5,7.1-2.2,9.6,0.6c1.2,1.4,1.8,3.1,1.7,4.9c-0.1,1.8-0.9,3.5-2.3,4.7c-1.3,1.1-2.9,1.7-4.5,1.7c-1.9,0-3.8-0.8-5.1-2.3
                c-1.2-1.4-1.8-3.1-1.7-4.9C70.4,95.1,71.2,93.4,72.6,92.2z M56.3,108.4c3.3-2.9,8.4-2.6,11.3,0.7c0.8,0.9,1.4,2,1.7,3.2l16.4,1.1
                c0.7,0,1.3,0.4,1.8,0.9c0.5,0.5,0.7,1.2,0.6,1.9c0,0.7-0.4,1.3-0.9,1.8c-0.5,0.5-1.2,0.7-1.9,0.6L69,117.4c-0.5,1.2-1.2,2.2-2.1,3
                c-1.5,1.3-3.3,2-5.3,2c-0.2,0-0.3,0-0.5,0c-2.1-0.1-4.1-1.1-5.5-2.7s-2.1-3.7-2-5.8C53.8,111.8,54.7,109.8,56.3,108.4z M44.1,127.4
                c3.2-2.8,8.1-2.5,10.9,0.7c0.8,0.9,1.4,2,1.7,3.3l36,2.3c0.3-0.5,0.6-0.8,0.9-1.1c2.2-1.9,5.5-1.7,7.5,0.5c0.9,1.1,1.4,2.4,1.3,3.8
                c-0.1,1.4-0.7,2.7-1.8,3.6c-1,0.9-2.2,1.3-3.5,1.3c-0.1,0-0.2,0-0.3,0c-1.4-0.1-2.7-0.7-3.6-1.8c-0.3-0.3-0.6-0.7-0.8-1.2
                l-36.2-2.3c-0.5,1-1.1,1.8-1.8,2.5c-3.2,2.8-8.1,2.5-10.9-0.7C40.6,135,40.9,130.2,44.1,127.4z M69.1,221.3
                c-1.2,1.1-2.8,1.7-4.5,1.7c-0.1,0-0.3,0-0.4,0c-1.8-0.1-3.5-0.9-4.7-2.3c-2.5-2.8-2.2-7.1,0.6-9.6c1.4-1.2,3.1-1.8,4.9-1.7
                c1.8,0.1,3.5,0.9,4.7,2.3c1.2,1.4,1.8,3.1,1.7,4.9C71.2,218.4,70.4,220.1,69.1,221.3z M85.7,205.3c-1.5,1.3-3.3,2-5.3,2
                c-0.2,0-0.4,0-0.5,0c-2.1-0.1-4.1-1.1-5.5-2.7c-0.8-0.9-1.4-2-1.7-3.2l-20.9-1.3c-1.2-0.1-2.1-1.1-2-2.3l0.1-0.8
                c0.1-1.2,1.1-2.1,2.3-2l20.9,1.3c0.5-1.2,1.2-2.2,2.1-3c1.6-1.4,3.7-2.1,5.8-2c2.1,0.1,4.1,1.1,5.5,2.7
                C89.3,197.3,89,202.4,85.7,205.3z M98,186.5c-1.5,1.3-3.4,2-5.3,2c-2.2,0-4.4-0.9-6-2.7c-0.7-0.8-1.3-1.8-1.6-2.9l-36.4-2.3
                c-0.3,0.4-0.5,0.7-0.8,1c-1,0.9-2.2,1.3-3.6,1.2c-1.3-0.1-2.5-0.7-3.4-1.7c-0.9-1-1.3-2.2-1.2-3.6c0.1-1.3,0.7-2.5,1.7-3.4
                c1-0.9,2.2-1.3,3.6-1.2c1.3,0.1,2.5,0.7,3.4,1.7c0.2,0.3,0.4,0.5,0.5,0.8l36.4,2.3c0.5-1.3,1.2-2.4,2.2-3.2
                c3.3-2.9,8.4-2.6,11.3,0.7c1.4,1.6,2.1,3.7,2,5.8C100.6,183.1,99.6,185.1,98,186.5z M102.1,163.3c-1.2,1-2.6,1.6-4.2,1.6
                c-0.1,0-0.3,0-0.4,0c-1.7-0.1-3.2-0.9-4.4-2.1c-0.4-0.5-0.7-1-1-1.6l-42.3-2.7c-0.4,0.7-0.9,1.4-1.5,1.9c-1.4,1.2-3.2,1.8-5.1,1.7
                c-1.9-0.1-3.6-1-4.8-2.4c-1.2-1.4-1.8-3.2-1.7-5.1c0.1-1.9,1-3.6,2.4-4.8c2.9-2.5,7.3-2.3,9.8,0.6c0.7,0.8,1.2,1.8,1.5,2.9
                l41.7,2.7c0.4-0.9,0.9-1.6,1.5-2.2c2.6-2.3,6.7-2.1,9,0.6c1.1,1.3,1.7,2.9,1.6,4.6C104.1,160.7,103.4,162.2,102.1,163.3z"/>
        </g>
    </svg>
  </div>
</template>

<script>
    export default {
      name: "LoadingLogoPublic",
    };
</script>

<style scoped>
  .st0{fill:#7C7C7C;}

  /* Small only */
  @media screen and (max-width: 39.9375em) {
    .loadingLogo__container{
      width:230px;
    }
  }

  /* Medium only */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .loadingLogo__container{
      width:250px;
    }
  }

  /* Large and up */
  @media screen and (min-width: 64em) {
    .loadingLogo__container{
      width:270px;
    }
  }

</style>

