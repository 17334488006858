<template>
  <div class="page__container" :style="computedFullPageStyle">
    <div class="page__fullpage-centered-container">
      <div class="page__fullpage-top-container" :style="computedTopDivStyle">
        <div class="page__index-login-form-box">
          <div class="page__index-logo-container">
            <img class="page__index-logo" src='@/assets/images/logo_web.png' />
          </div>
          <div class="page__index-name-container">
            /* BIOTRANSFER */
          </div>
          <div class="page__form-container page__border-dotted">
            <div class="page__form-search-line">
              <div class="page__form-label">
                <Label labelText="Correo Electrónico" fontFamily="kronika" fontColor="#FFFFFF" />
              </div>
              <div class="full-width">
                <TextBox v-model="correoElectronico" v-bind:isError="errorCorreoElectronico" />
              </div>
            </div>
            <div class="page__form-search-line">
              <div class="page__form-label">
                <Label labelText="Clave" fontFamily="kronika" fontColor="#FFFFFF" />
              </div>
              <div class="full-width">
                <TextBox type="password" v-model="clave" v-bind:isError="errorClave" />
              </div>
            </div>
          </div>
          <div class="page__index-button-container">
            <Button labelText="INGRESAR"
                    bgColor="#000000"
                    fontColor="#FFFFFF"
                    :width="buttonWidth"
                    class="page__index-button-margin-botton"
                    v-on:action-button-clicked="ingresar()"/>
            <Button labelText="OLVIDÉ MI CLAVE"
                    bgColor="#C00606"
                    fontColor="#FFFFFF"
                    :width="buttonWidth"
                    v-on:action-button-clicked="olvideMiClaveModal()"/>
          </div>
          <div class="page__index-message-error-container">
            <span class="page__index-message-error">{{ mensajeError }}</span>
          </div>
        </div>
      </div>
      <div class="page__fullpage-bottom-container" :style="computedFooterDivStyle">
        <Footer />
      </div>
      <IndexOlvideClaveModal :isVisible="modal_isIndexOlvideClaveVisible"
                             :isLoading="modal_isIndexOlvideClaveLoading"
                             :transition="modal_indexOlvideClaveTransition"
                             :loadingMessage="modal_indexOlvideClaveLoadingMessage"
                             :style="computedIndexOlvideClaveModalStyle"
                             @close="modalIndexOlvideClaveCerrar"
                             @enviar="modalIndexOlvideClave" />
      <ModalError :title="modalErrorTitle"
                  :message="modalErrorMessage"
                  :exception="modalErrorException"
                  v-model="isModalErrorMessage"
                  v-on:aceptar="modal__ErrorAceptar()" />
    </div>
  </div>
</template>

<script>
  import Label from "@/components/Label";
  import Footer from "@/components/Footer";
  import TextBox from "@/components/TextBox";
  import Button from "@/components/Button";
  import ModalError from "@/components/ModalError";
  import IndexOlvideClaveModal from "@/views/pages/admin/IndexOlvideClaveModal";

  export default {
    name: "admin_Index",
    components: {
      Label,
      Button,
      TextBox,
      Footer,
      ModalError,
      IndexOlvideClaveModal,
    },
    data() {
      return {
        windowHeight:window.innerHeight,
        topDivHeight:0,
        footerDivHeight:0,
        buttonWidth:"0",
        mensajeError: '',

        // FORM
        correoElectronico: '',
        clave: '',

        // FORM ERROR
        errorCorreoElectronico: false,
        errorClave: false,

        // IMAGES
        isPageImagesLoad: true, // No hay imagenes locales
        isExternalImagesLoad: true, // No hay imagenes externas
        isMountFinished: false,

        // MODAL
        modal_isIndexOlvideClaveVisible:false,
        modal_isIndexOlvideClaveLoading:false,
        modal_isUsuarioInvitarClosed:false, // Aqui indicamos si cerramos de inmediato el modal
        modal_indexOlvideClaveTransition:"",
        modal_indexOlvideClaveLoadingMessage:"",

        // MODAL ERROR
        isModalErrorMessage:false,
        modalErrorTitle:'',
        modalErrorMessage:'',
        modalErrorException:'',
      };
    },
    created() {
      try {
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();

      } catch (error) {
        console.log("[Index.vue] created()::ERROR = "+error);
      }
    },
    async mounted() {
      try {
        // BROWSER BACK/FORWARD BUTTON
        window.popStateDetected = false
        window.addEventListener('popstate', () => { window.popStateDetected = true });

        await this.page__preloadImages();
        await this.$nextTick();
        this.isMountFinished = true;

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[Index.vue] mounted()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },
    computed: {
      computedFullPageStyle(){
        var objStyle = {};
        objStyle["height"] = this.windowHeight+"px";

        console.log("computedFullPageStyle - this.windowHeight :: ",this.windowHeight);

        return objStyle;
      },

      computedTopDivStyle(){
        var objStyle = {};
        objStyle["height"] = this.topDivHeight+"px";

        console.log("computedTopDivStyle - this.topDivHeight :: ",this.topDivHeight);

        return objStyle;
      },
      computedFooterDivStyle(){
        var objStyle = {};
        objStyle["height"] = this.footerDivHeight+"px";

        console.log("computedFooterDivStyle - this.footerDivHeight :: ",this.footerDivHeight);

        return objStyle;
      },

      computedIndexOlvideClaveModalStyle() {
        var objStyle = {};
        objStyle["visibility"] = "visible";

        if(this.modal_isUsuarioInvitarClosed){
          objStyle["visibility"] = "hidden";
        }

        return objStyle;
      },
      page__isAllContentLoaded(){
        return this.isPageImagesLoad && this.isExternalImagesLoad && this.isMountFinished;
      },
    },
    watch: {
      page__isAllContentLoaded(newValue) {
        if(newValue){
          var that;
          that = this;
          setTimeout(function () {
            that.$emit('show-loading', false);
          }, 1500);
        }
      }
    },
    methods: {
      async ingresar() {
        try {
          this.mensajeError = "";

          var contVacio = 0;
          var contFormatoCorreo = 0;
          var strCorreoElectronico = this.correoElectronico;
          var strClave = this.clave;

          if(strCorreoElectronico==""){
            this.errorCorreoElectronico=true;
            contVacio = contVacio + 1;
          } else {
            this.errorCorreoElectronico=false;
          }

          if(strClave==""){
            this.errorClave=true;
            contVacio = contVacio + 1;
          } else {
            this.errorClave=false;
          }

          if(contVacio==0){
            var EMAIL_REGEXP = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            var emailValidator = EMAIL_REGEXP.test(strCorreoElectronico);

            if(!emailValidator){
              contFormatoCorreo = contFormatoCorreo + 1;
              this.errorCorreoElectronico=true;
            } else {
              this.errorCorreoElectronico=false;
            }

            if(contFormatoCorreo>0){
              this.errorCorreoElectronico=true;
              this.mensajeError = "/* Por favor ingrese un formato válido */";
            } else {
              this.errorCorreoElectronico=false;
              this.$emit('show-loading',true,"Validando información. Un momento por favor");

              var objUsuario = {
                email: strCorreoElectronico,
                clave: strClave,
              }

              var {default: UsuarioLogic} = await import('@/logic/usuario.logic');
              var httpUsuario = await UsuarioLogic.validar(objUsuario);
              var httpUsuarioData = httpUsuario.data;
              var responseUsuario = httpUsuario.data.response;

              switch(httpUsuarioData.status) {
                case "OK":
                  this.$store.dispatch('authSessionStore/logIn');
                  this.$store.dispatch('authSessionStore/setUser',responseUsuario);

                  // ===========================================
                  // Obtenemos los TOKENS de SESSION del usuario
                  // ===========================================
                  var {default: TokenLogic} = await import('@/logic/_backend/token.logic');
                  await TokenLogic.request(objUsuario);

                  // Guardamos en sesión la pantalla HOME asignado al perfil del usuario
                  this.$store.dispatch('authSessionStore/setHomeRoute',responseUsuario.routeHome);

                  var nextRoute = "session_redirect";
                  this.$router.push({ name: nextRoute }, () => {
                  });

                  break;

                case "NX":
                  this.mensajeError = "/* Usuario o clave incorrectas */";
                  this.$emit('show-loading', false);
                  break;

                default:
                  var error = httpUsuarioData.message;
                  throw Error(error);
              }
            }

          } else {
            this.mensajeError = "/* Por favor ingrese los datos faltantes */";
          }

        } catch (error) {
          this.$emit('show-loading', false);
          var tmpExcepcion = "[admin/Index.vue] ingresar()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },

      async enviarOlvideClave(objEnviar){
        try {
          var {default: UsuarioLogic} = await import('@/logic/usuario.logic');
          var httpUsuario = await UsuarioLogic.olvideClaveEnviar(objEnviar);
          var httpUsuarioData = httpUsuario.data;
          var responseUsuario = httpUsuario.data.response;

          switch(httpUsuarioData.status) {
            case "OK":
              break;

            default:
              var error = httpUsuarioData.message;
              throw Error(error);
          }

        } catch (error) {
          this.$emit('show-loading', false);
          var tmpExcepcion = "[UsuarioListar.vue] enviarOlvideClave()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },


      // LISTENERS
      handleWindowResize(){
        this.mediaQueries();
      },


      // =======
      //  MODAL
      // =======

      // --------------
      //  Olvide Clave
      // --------------

      olvideMiClaveModal(){
        try {
          this.modal_isIndexOlvideClaveVisible=true;
          this.mensajeError = "";

        } catch (error) {
          this.$emit('show-loading', false);
          var tmpExcepcion = "[UsuarioListar.vue] invitarUsuarioModal()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },

      async modalIndexOlvideClave(objEnviar){
        this.modal_indexOlvideClaveLoadingMessage = "Enviando información. Un momento por favor";
        this.modal_isIndexOlvideClaveLoading = true;
        await this.enviarOlvideClave(objEnviar);
        this.modal_isIndexOlvideClaveVisible = false;
        this.modal_isIndexOlvideClaveLoading = false;
      },

      modalIndexOlvideClaveCerrar(){
        this.modal_isIndexOlvideClaveVisible = false;
      },


      // -------------
      //  Modal Error
      // -------------

      modal__ErrorRegistrarExcepcion(excepcion){
        this.modalErrorTitle = "LO SENTIMOS";
        this.modalErrorMessage = "Se produjo un error al realizar la operación. Por favor intente más tarde.";
        this.modalErrorException = excepcion;
        this.isModalErrorMessage = true;
      },
      modal__ErrorAceptar(){
        this.isModalErrorMessage=false;
      },

      // =================
      // PRIVATE FUNCTIONS
      // =================

      mediaQueries() {
        var _buttonWidth = 0;
        var _footerDivHeight = 0;
        var _topDivHeight = 0;
        var _windowHeight = window.innerHeight;

        const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
        const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );
        const matchMediaLarge = window.matchMedia( "(min-width: 64em)" );

        if (matchMediaSmall.matches) {
          _buttonWidth = 120;
          _footerDivHeight = 80;

          if(_windowHeight<750){
            _windowHeight = 750;
          }
        }

        if (matchMediaMedium.matches) {
          _buttonWidth = 120;
          _footerDivHeight = 90;

          if(_windowHeight<800){
            _windowHeight = 800;
          }
        }

        if (matchMediaLarge.matches) {
          _buttonWidth = 120;
          _footerDivHeight = 100;

          if(_windowHeight<800){
            _windowHeight = 800;
          }
        }

        _topDivHeight = parseInt(_windowHeight)-parseInt(_footerDivHeight);

        this.buttonWidth = _buttonWidth+"px";
        this.windowHeight = _windowHeight;
        this.topDivHeight = _topDivHeight;
        this.footerDivHeight = _footerDivHeight;
      },

      async page__preloadImages() {
        var {default: ImageHelper} = await import('@/helpers/image.helper');

        this.isPageImagesLoad = true;
      },
    },
    beforeRouteLeave (to, from, next) {
      if(window.popStateDetected){
        // HACK: Se mostrará el LOADING siembre que se abandone la página. Esto nos
        // permitirá abordar los casos en que se haga BACK/FORWARD en el browser
        this.$emit('show-loading', true);
      }
      next();
    },
    beforeDestroy() {
      window.removeEventListener('popstate', () => { window.popStateDetected = true });
    }
  };
</script>


<style scoped>
  /* Small only */
  @media screen and (max-width: 39.9375em) {

  }

  /* Medium only */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {

  }

  /* Large and up */
  @media screen and (min-width: 64em) {

  }
</style>
