import Vue from "vue";
import Vuex from "vuex";

import createMultiTabState from 'vuex-multi-tab-state';

// COMMON
import sidebarMenuStore from "./modules/sidebarMenu.store";

// PUBLIC
import authPublicStore from "./modules/public/authPublic.store";
import configuracionStore from "./modules/public/configuracion.store";

// SESSION
import authSessionStore from "./modules/session/authSession.store";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        // COMMON
        sidebarMenuStore,

        // PUBLIC
        authPublicStore,
        configuracionStore,

        // SESSION
        authSessionStore,
    },
    plugins: [
        createMultiTabState(),
    ],
});