import bgImage from "@/assets/images/bg.png";
import SessionLayout from "@/components/layout/session/SessionLayout";
import NoticiaListar from '@/views/pages/admin/noticia/NoticiaListar';
import NoticiaCrear from '@/views/pages/admin/noticia/NoticiaCrear';
import NoticiaEditar from '@/views/pages/admin/noticia/NoticiaEditar';

const adminNoticiaRoutes = [
    {
        path: "/admin/noticia/listar",
        name: "session_noticia_listar",
        component: NoticiaListar,
        meta: {
            layout: SessionLayout,
            apiAccessType: "PUBLIC",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
    {
        path: "/admin/noticia/crear",
        name: "session_noticia_crear",
        component: NoticiaCrear,
        meta: {
            layout: SessionLayout,
            apiAccessType: "PUBLIC",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
    {
        path: "/admin/noticia/editar/:idNoticia",
        name: "session_noticia_editar",
        component: NoticiaEditar,
        meta: {
            layout: SessionLayout,
            apiAccessType: "PUBLIC",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
]

export default adminNoticiaRoutes