<template>
  <div class="page__wrapper">
    <div class="page__container">
      <div class="page__header-container">
        <div class="page__header-container-left">
          <SpriteAnimator
              :id="'sprite'"
              :spritesheet="require('../../../../assets/sprites/appear/spritesheet.png')"
              :json="require('../../../../assets/sprites/appear/spritesheet.json')"
              :yoyo="false"
              :fps="15"
              :width=headerSpriteWidth
              :isLoop="false"
              ref="sprite"
          ></SpriteAnimator>
          <div class="page__header-title">
            MI CONTENIDO
          </div>
        </div>
        <div class="page__header-container-right">
          <Button v-on:action-button-clicked="editarContenido()"
                  labelText="EDITAR DATOS"
                  :width=buttonWidth />
        </div>
      </div>
      <div class="page__header-container-mobile">
        <div class="page__header-container-mobile-top">
          <div class="page__header-sprite">
            <SpriteAnimator
                :id="'sprite'"
                :spritesheet="require('../../../../assets/sprites/appear/spritesheet.png')"
                :json="require('../../../../assets/sprites/appear/spritesheet.json')"
                :yoyo="false"
                :fps="15"
                :width=headerSpriteWidth
                :isLoop="false"
                ref="sprite"
            ></SpriteAnimator>
          </div>
          <div class="page__header-title">
            MI CONTENIDO
          </div>
        </div>
        <div class="page__header-container-mobile-botom">
          <Button v-on:action-button-clicked="editarContenido()"
                  labelText="EDITAR DATOS"
                  :width=buttonWidth />
        </div>
      </div>
      <div class="page__content-container">
        <div class="page__main-container">
          <div class="page__main-container-left">
            <div class="page__form-section-label">
              EMPRESA
            </div>
            <div class="page__form-border-container">
              <div class="page__form-line-flexrow-wrap">
                <div class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(0)">
                  <div class="page__form-label">
                    <Label labelText="Email Contacto" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorEmailContacto" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" maxlength="250" v-model="emailContacto" v-bind:isError="errorEmailContacto" />
                    </div>
                  </div>
                </div>
                <div class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(1)">
                  <div class="page__form-label">
                    <Label labelText="Teléfono Fijo" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorTelefonoFijo" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" maxlength="250" v-model="telefonoFijo" v-bind:isError="errorTelefonoFijo" />
                    </div>
                  </div>
                </div>
                <div class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(0)">
                  <div class="page__form-label">
                    <Label labelText="Teléfono Celular" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorTelefonoCelular" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" maxlength="250" v-model="telefonoCelular" v-bind:isError="errorTelefonoCelular" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="page__form-line-flexrow-wrap">
                <div class="flex1 padding-bottom20" :style="getPaddingRight(1)">
                  <div class="page__form-label">
                    <Label labelText="Dirección" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorDireccion" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextArea placeholder="" v-model="direccion" v-bind:isError="errorDireccion" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="page__form-line-flexrow-wrap">
                <div class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(0)">
                  <div class="page__form-label">
                    <Label labelText="URL Facebook" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorUrlFacebook" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" maxlength="250" v-model="urlFacebook" v-bind:isError="errorUrlFacebook" />
                    </div>
                  </div>
                </div>
                <div class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(1)">
                  <div class="page__form-label">
                    <Label labelText="URL Youtube" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorUrlYoutube" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" maxlength="250" v-model="urlYoutube" v-bind:isError="errorUrlYoutube" />
                    </div>
                  </div>
                </div>
                <div class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(0)">
                  <div class="page__form-label">
                    <Label labelText="URL Instagram" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorUrlInstagram" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" maxlength="250" v-model="urlInstagram" v-bind:isError="errorUrlInstagram" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="page__form-error-message" v-if="mensajeErrorEmpresa!=''">
                {{ mensajeErrorEmpresa }}
              </div>
            </div>
            <br>
            <div class="page__form-section-label">
              SITIO WEB
            </div>
            <div class="page__form-border-container">
              <div class="page__form-line-flexrow-wrap">
                <div class="flex1 padding-bottom20" :style="getPaddingRight(1)">
                  <div class="page__form-label">
                    <Label labelText="Mensaje Inicio" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorMensajeInicio" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextArea placeholder="" v-model="mensajeInicio" v-bind:isError="errorMensajeInicio" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="page__form-line-flexrow-wrap">
                <div class="flex1 padding-bottom20" :style="getPaddingRight(1)">
                  <div class="page__form-label">
                    <Label labelText="Quienes Somos" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorQuienesSomos" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextArea placeholder="" v-model="quienesSomos" v-bind:isError="errorQuienesSomos" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="page__form-line-flexrow-wrap">
                <div class="flex1 padding-bottom20" :style="getPaddingRight(1)">
                  <div class="page__form-label">
                    <Label labelText="Misión" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorMision" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextArea placeholder="" v-model="mision" v-bind:isError="errorMision" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="page__form-line-flexrow-wrap">
                <div class="flex1 padding-bottom20" :style="getPaddingRight(0)">
                  <div class="page__form-label">
                    <Label labelText="Visión" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorVision" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextArea placeholder="" v-model="vision" v-bind:isError="errorVision" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="page__form-line-flexrow-wrap">
                <div class="flex1 padding-bottom20" :style="getPaddingRight(0)">
                  <div class="page__form-label">
                    <Label labelText="Contacto Imagen" fontFamily="kronika" fontColor="#FF5229" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <div class="page__form-image-upload-cotainer">
                        <div class="page__form-image-upload-cotainer-left">
                          <BackgroundImage isBordered="S" height="150px" width="150px" positionHeight="center" :backgroundURL="contactoRutaImagen"/>
                        </div>
                        <div class="page__form-image-upload-cotainer-right">
                          <ButtonUpload @upload-object="getUploadObject" />
                        </div>
                      </div>
                      <div class="page__form-image-upload-cotainer-mobile">
                        <div class="page__form-image-upload-cotainer-mobile-top">
                          <BackgroundImage isBordered="S" height="150px" width="150px" positionHeight="center" :backgroundURL="contactoRutaImagen"/>
                        </div>
                        <div class="page__form-image-upload-cotainer-mobile-bottom">
                          <ButtonUpload @upload-object="getUploadObject" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="page__form-error-message" v-if="mensajeErrorSitioWeb!=''">
                {{ mensajeErrorSitioWeb }}
              </div>
            </div>
          </div>
          <div class="page__main-container-right">
          </div>
        </div>
      </div>
    </div>
    <Modal type="CONFIRM"
           accessType="SESSION"
           :message="modal_message"
           :messageLoading="modal_messageLoading"
           :isVisible="modal_isVisible"
           :isLoading="modal_isLoading"
           :transition="modal_transition"
           :forceClose="modal_forceClose"
           @aceptar="modalAceptar"
           @cancelar="modalCancelar" />
    <ModalError :title="modalErrorTitle"
                :message="modalErrorMessage"
                :exception="modalErrorException"
                v-model="isModalErrorMessage"
                v-on:aceptar="modal__ErrorAceptar()" />
  </div>
</template>

<script>
  import SpriteAnimator from "@/components/SpriteAnimator";
  import Button from "@/components/Button";
  import ButtonForm from "@/components/ButtonForm";
  import ButtonUpload from "@/components/ButtonUpload";
  import Label from "@/components/Label";
  import TextBox from "@/components/TextBox";
  import TextArea from "@/components/TextArea";
  import TextEditor from "@/components/custom/texteditor/TextEditor";
  import CheckBoxForm from "@/components/CheckBoxForm";
  import BackgroundImage from "@/components/BackgroundImage";
  import Modal from "@/components/Modal";
  import ModalError from "@/components/ModalError";

  export default {
    name: "ContenidoEditar",
    components: {
      SpriteAnimator,
      Button,
      ButtonForm,
      ButtonUpload,
      Label,
      TextBox,
      TextArea,
      TextEditor,
      CheckBoxForm,
      BackgroundImage,
      Modal,
      ModalError,
    },
    data() {
      return {
        // MEDIA QUERIES
        headerSpriteWidth:0,
        buttonWidth:"0",
        formPaddingRight:"0",

        // FORM
        mensajeInicio: "",
        quienesSomos: "",
        mision: "",
        vision: "",
        emailContacto: "",
        telefonoFijo: "",
        telefonoCelular: "",
        direccion: "",
        urlFacebook: "",
        urlYoutube: "",
        urlInstagram: "",
        contactoRutaImagen: "",
        contactoFileImagen: null,

        mensajeErrorEmpresa: "",
        mensajeErrorSitioWeb: "",

        // FORM ERROR
        errorMensajeInicio: false,
        errorQuienesSomos: false,
        errorMision: false,
        errorVision: false,
        errorEmailContacto: false,
        errorTelefonoFijo: false,
        errorTelefonoCelular: false,
        errorDireccion: false,
        errorUrlFacebook: false,
        errorUrlYoutube: false,
        errorUrlInstagram: false,

        // IMAGES
        isPageImagesLoad: true,
        isExternalImagesLoad: true,
        isMountFinished: false,

        // MODAL
        modal_message:'',
        modal_messageLoading:'',
        modal_isVisible:false,
        modal_isLoading:false,
        modal_forceClose:false,
        modal_transition:"",

        // MODAL ERROR
        isModalErrorMessage:false,
        modalErrorTitle:'',
        modalErrorMessage:'',
        modalErrorException:'',
      };
    },
    created() {
      try {
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();

        this.mensajeError = "";
      } catch (error) {
        console.log("[ContenidoEditar.vue] created()::ERROR = "+error);
      }
    },
    async mounted() {
      try {
        // BROWSER BACK/FORWARD BUTTON
        window.popStateDetected = false
        window.addEventListener('popstate', () => { window.popStateDetected = true });

        await this.detalleContenido();
        await this.page__preloadImages();
        await this.$nextTick();
        this.isMountFinished = true;

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[ContenidoEditar.vue] mounted()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },
    computed: {
      page__isAllContentLoaded(){
        return this.isPageImagesLoad && this.isExternalImagesLoad && this.isMountFinished;
      },
    },
    watch: {
      page__isAllContentLoaded(newValue) {
        if(newValue){
          var that;
          that = this;
          setTimeout(function () {
            that.$emit('show-loading', false);
          }, 1500);
        }
      },
    },
    methods: {
      async detalleContenido(){
        try {
          var {default: ContenidoLogic} = await import('@/logic/contenido.logic');
          var httpContenido = await ContenidoLogic.detalle();
          var httpContenidoData = httpContenido.data;
          var responseContenido = httpContenido.data.response;

          switch(httpContenidoData.status) {
            case "OK":
              this.mensajeInicio = responseContenido.mensajeInicio;
              this.quienesSomos = responseContenido.quienesSomos;
              this.mision = responseContenido.mision;
              this.vision = responseContenido.vision;
              this.emailContacto = responseContenido.emailContacto;
              this.telefonoFijo = responseContenido.telefonoFijo;
              this.telefonoCelular = responseContenido.telefonoCelular;
              this.direccion = responseContenido.direccion;
              this.contactoRutaImagen = responseContenido.contactoRutaImagen;

              this.urlFacebook = responseContenido.redesSociales.facebook;
              this.urlYoutube = responseContenido.redesSociales.youtube;
              this.urlInstagram = responseContenido.redesSociales.instagram;
              break;

            default:
              var error = httpContenidoData.message;
              throw Error("[ContenidoEditar.vue] detalleContenido()::ERROR = "+error);
          }

        } catch (error) {
          throw Error("[ContenidoEditar.vue] detalleContenido()::ERROR = "+error);
        }
      },

      async editarContenido(){
        try {
          var contFormatoCorreo = 0;
          var contVacioEmpresa = 0;
          var contVacioSitioWeb = 0;
          var contTotal = 0;

          // EMPRESA
          var strEmailContacto = this.emailContacto;
          var strTelefonoFijo = this.telefonoFijo;
          var strTelefonoCelular = this.telefonoCelular;
          var strDireccion = this.direccion;

          if(strEmailContacto===""){
            this.errorEmailContacto=true;
            contVacioEmpresa = contVacioEmpresa + 1;
          } else {
            this.errorEmailContacto=false;
          }
          if(strTelefonoFijo===""){
            this.errorTelefonoFijo=true;
            contVacioEmpresa = contVacioEmpresa + 1;
          } else {
            this.errorTelefonoFijo=false;
          }
          if(strTelefonoCelular===""){
            this.errorTelefonoCelular=true;
            contVacioEmpresa = contVacioEmpresa + 1;
          } else {
            this.errorTelefonoCelular=false;
          }
          if(strDireccion===""){
            this.errorDireccion=true;
            contVacioEmpresa = contVacioEmpresa + 1;
          } else {
            this.errorDireccion=false;
          }

          // WEB
          var strMensajeInicio = this.mensajeInicio;
          var strQuienesSomos = this.quienesSomos;
          var strMision = this.mision;
          var strVision = this.vision;

          if(strMensajeInicio===""){
            this.errorMensajeInicio=true;
            contVacioSitioWeb = contVacioSitioWeb + 1;
          } else {
            this.errorMensajeInicio=false;
          }
          if(strQuienesSomos===""){
            this.errorQuienesSomos=true;
            contVacioSitioWeb = contVacioSitioWeb + 1;
          } else {
            this.errorQuienesSomos=false;
          }
          if(strMision===""){
            this.errorMision=true;
            contVacioSitioWeb = contVacioSitioWeb + 1;
          } else {
            this.errorMision=false;
          }
          if(strVision===""){
            this.errorVision=true;
            contVacioSitioWeb = contVacioSitioWeb + 1;
          } else {
            this.errorVision=false;
          }

          contTotal = parseInt(contVacioEmpresa)+parseInt(contVacioSitioWeb);

          if(contTotal==0){
            var EMAIL_REGEXP = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            var emailValidator = EMAIL_REGEXP.test(strEmailContacto);

            if(!emailValidator){
              contFormatoCorreo = contFormatoCorreo + 1;
              this.errorEmailContacto=true;
            } else {
              this.errorEmailContacto=false;
            }

            if(contFormatoCorreo>0){
              this.errorEmailContacto=true;
              this.mensajeErrorEmpresa = "Por favor ingrese un formato válido";
            } else {
              this.mensajeErrorEmpresa = "";
              this.modal_message = "¿Está seguro que desea editar esta información?";
              this.modal_transition = "LEFT";
              this.modal_forceClose = false;
              this.modal_isVisible = true;
            }
          } else {
            if(contVacioEmpresa>0){
              this.mensajeErrorEmpresa = "/* Por favor ingrese los datos faltantes */";
            } else {
              this.mensajeErrorEmpresa = "";
            }

            if(contVacioSitioWeb>0){
              this.mensajeErrorSitioWeb = "/* Por favor ingrese los datos faltantes */";
            } else {
              this.mensajeErrorSitioWeb = "";
            }
          }

        } catch (error) {
          this.$emit('show-loading', false);
          var tmpExcepcion = "[ContenidoEditar.vue] editarServicio()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },

      getUploadObject(objData){
        this.contactoFileImagen = objData.data;
        this.contactoRutaImagen = objData.url;
      },

      // LISTENERS
      handleWindowResize(){
        this.mediaQueries();
      },

      // MODAL
      async modalAceptar(){
        try {
          this.modal_messageLoading = "Actualizando información. Un momento por favor."
          this.modal_forceClose = false;
          this.modal_isLoading = true;
          this.modal_isVisible = true;

          var contenidoObj = {};
          contenidoObj['mensajeInicio'] = this.mensajeInicio;
          contenidoObj['quienesSomos'] = this.quienesSomos;
          contenidoObj['mision'] = this.mision;
          contenidoObj['vision'] = this.vision;
          contenidoObj['emailContacto'] = this.emailContacto;
          contenidoObj['telefonoFijo'] = this.telefonoFijo;
          contenidoObj['telefonoCelular'] = this.telefonoCelular;
          contenidoObj['direccion'] = this.direccion;
          contenidoObj['contactoFileImagen'] = this.contactoFileImagen;

          contenidoObj['urlFacebook'] = this.urlFacebook;
          contenidoObj['urlYoutube'] = this.urlYoutube;
          contenidoObj['urlInstagram'] = this.urlInstagram;

          var {default: ContenidoLogic} = await import('@/logic/contenido.logic');
          var httpContenido = await ContenidoLogic.editar(contenidoObj);
          var httpContenidoData = httpContenido.data;
          var responseContenido = httpContenido.data.response;

          switch(httpContenidoData.status) {
            case "OK":
              var {default: CommonHelper} = await import('@/helpers/common.helper');
              await CommonHelper.delay(2000);
              this.modal_forceClose = false;
              this.modal_isLoading = false;
              this.modal_isVisible = false;
              break;

            default:
              var error = httpContenidoData.message;
              throw Error("[ContenidoEditar.vue] modalAceptar()::ERROR = "+error);
          }

        } catch (error) {
          this.modal_forceClose = false;
          this.modal_isLoading = false;
          this.modal_isVisible = false;

          var tmpExcepcion = "[ContenidoEditar.vue] modalAceptar()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },
      modalCancelar(){
        this.modal_forceClose = false;
        this.modal_isLoading = false;
        this.modal_isVisible = false;
      },


      // MODAL ERROR
      modal__ErrorRegistrarExcepcion(excepcion){
        this.modalErrorTitle = "LO SENTIMOS";
        this.modalErrorMessage = "Se produjo un error al realizar la operación. Por favor intente más tarde.";
        this.modalErrorException = excepcion;
        this.isModalErrorMessage = true;
      },
      modal__ErrorAceptar(){
        this.isModalErrorMessage=false;
      },

      // =================
      // PRIVATE FUNCTIONS
      // =================

      mediaQueries() {
        var _headerSpriteWidth = 0;
        var _buttonWidth = 0;
        var _formPaddingRight = 0;

        const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
        const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );
        const matchMediaLarge = window.matchMedia( "(min-width: 64em)" );

        if (matchMediaSmall.matches) {
          _headerSpriteWidth = 120;
          _buttonWidth = 120;
          _formPaddingRight = 0;
        }

        if (matchMediaMedium.matches) {
          _headerSpriteWidth = 120;
          _buttonWidth = 120;
          _formPaddingRight = 0;
        }

        if (matchMediaLarge.matches) {
          _headerSpriteWidth = 120;
          _buttonWidth = 120;
          _formPaddingRight = 20;
        }

        this.headerSpriteWidth = _headerSpriteWidth;
        this.buttonWidth = _buttonWidth+"px";
        this.formPaddingRight = _formPaddingRight+"px";
      },

      getPaddingRight(index){
        var objStyle = {};

        if(index % 2 !== 0) {
          objStyle["padding-right"] = 0;
        } else {
          objStyle["padding-right"] = this.formPaddingRight;
        }

        return objStyle;
      },

      async page__preloadImages() {
        var {default: ImageHelper} = await import('@/helpers/image.helper');

        this.isPageImagesLoad = true;
      },
    },
    beforeRouteLeave (to, from, next) {
      if(window.popStateDetected){
        // HACK: Se mostrará el LOADING siembre que se abandone la página. Esto nos
        // permitirá abordar los casos en que se haga BACK/FORWARD en el browser
        this.$emit('show-loading', true);
      }
      next();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleWindowResize);
      window.removeEventListener('popstate', () => { window.popStateDetected = true });
    }
  };
</script>

<style scoped>

/* Small only */
@media screen and (max-width: 39.9375em) {

}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

}

/* Large and up */
@media screen and (min-width: 64em) {

}

</style>