import bgImage from "@/assets/images/bg.png";
import SessionLayout from "@/components/layout/session/SessionLayout";
import TokenAccessListar from '@/views/pages/admin/_backend/token/TokenAccessListar';
import TokenRefreshListar from '@/views/pages/admin/_backend/token/TokenRefreshListar';

const adminBackendTokenRoutes = [
    {
        path: "/admin/backend/token/access/listar",
        name: "session_backend_token_access_listar",
        component: TokenAccessListar,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
    {
        path: "/admin/backend/token/refresh/listar",
        name: "session_backend_token_refresh_listar",
        component: TokenRefreshListar,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
]

export default adminBackendTokenRoutes;