<template>
  <div class="sessionLayout__container">
    <SidebarMenu backgroundColor="rgba(0,176,165,.5)" orientation="left">
      <PublicMobileMenu />
    </SidebarMenu>
    <div>
      <div class="sessionLayout__full-page-container">
        <div id="session-content-div"  class="sessionLayout__right-container">

          <div id="session-header-mobile-div" class="sessionLayout__session-container-mobile">
            <div class="sessionLayout__session-container-mobile-left">
              <img class="menu__logo" src='@/assets/images/logo_menu.png' />
            </div>
            <div class="sessionLayout__session-container-mobile-right">
            </div>
          </div>
          <div class="sessionLayout__session-burger-container-mobile">
            <div class="sessionLayout__session-burger-container-mobile-left">
            </div>
            <div class="sessionLayout__session-burger-container-mobile-right">
              <SidebarMenuBurger color="#00B0A5" />
            </div>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SidebarMenu from "@/components/mobile/SidebarMenu";
import SidebarMenuBurger from "@/components/mobile/SidebarMenuBurger";
import PublicMobileMenu from "@/components/mobile/PublicMobileMenu";

export default {
  name: "PublicLayout",
  components: {
    SidebarMenu,
    SidebarMenuBurger,
    PublicMobileMenu,
  },
  data() {
    return {
    };
  },
  created(){
  },
  async mounted() {
    try {
      await this.$nextTick();

    } catch (error) {
      throw Error("[PublicLayout.vue] mounted()::ERROR = "+error);
    }
  },
  computed: {
  },
  methods: {

  }
};
</script>

<style scoped>

.sessionLayout__container{
  overflow-x: hidden;
}

.sessionLayout__full-page-container{
  display:flex;
  flex-direction:row;
  width:100%;
  height:100vh;
}

.sessionLayout__right-container{
  flex:1;
}

/* MOBILE */
.sessionLayout__session-container-mobile{
  position:absolute;
  z-index:3;
  display:flex;
  flex-direction:row;
  align-items:center;
  width:100%;
  background-color:#FFFFFF;
}
.sessionLayout__session-container-mobile-left{
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  flex:1;
}
.sessionLayout__session-container-mobile-right{
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
  flex:1;
}

.sessionLayout__session-burger-container-mobile{
  position:absolute;
  z-index:15;
  display:flex;
  flex-direction:row;
  align-items:center;
  width:100%;
}
.sessionLayout__session-burger-container-mobile-left{
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  flex:1;
}
.sessionLayout__session-burger-container-mobile-right{
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
  flex:1;
}


/* Small only */
@media screen and (max-width: 39.9375em) {
  #session-header-mobile-div{
    display:flex;
  }

  #session-content-div{
    display:block;
  }

  .sessionLayout__menu-icon img{
    width:20px;
  }

  .sessionLayout__session-container-mobile{
    height:65px;
    padding:0 15px;
  }

  .sessionLayout__session-burger-container-mobile{
    height:65px;
    padding:0 15px;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  #session-header-mobile-div{
    display:flex;
  }

  #session-content-div{
    display:block;
  }
  .sessionLayout__menu-icon img{
    width:20px;
  }

  .sessionLayout__session-container-mobile{
    height:65px;
    padding:0 15px;
  }

  .sessionLayout__session-burger-container-mobile{
    height:65px;
    padding:0 15px;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  #session-content-div{
    display:block;
  }

  #session-header-mobile-div{
    display:none;
  }

  .sessionLayout__menu-icon img{
    width:20px;
  }
}
</style>