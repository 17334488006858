<template>
  <div class="page__wrapper">
    <div class="page__container">
      <div class="page__header-container">
        <div class="page__header-container-left">
          <SpriteAnimator
              :id="'sprite'"
              :spritesheet="require('../../../../assets/sprites/appear/spritesheet.png')"
              :json="require('../../../../assets/sprites/appear/spritesheet.json')"
              :yoyo="false"
              :fps="15"
              :width=headerSpriteWidth
              :isLoop="false"
              ref="sprite"
          ></SpriteAnimator>
          <div class="page__header-title">
            EDITAR USUARIO
          </div>
          <Button v-on:action-button-clicked="regresar()"
                  labelText="REGRESAR"
                  bgColor="#C00606"
                  :width=buttonWidth />
        </div>
        <div class="page__header-container-right">
          <Button v-on:action-button-clicked="editarUsuario()"
                  labelText="EDITAR DATOS"
                  :width=buttonWidth />
        </div>
      </div>
      <div class="page__header-container-mobile">
        <div class="page__header-container-mobile-top">
          <div class="page__header-sprite">
            <SpriteAnimator
                :id="'sprite'"
                :spritesheet="require('../../../../assets/sprites/appear/spritesheet.png')"
                :json="require('../../../../assets/sprites/appear/spritesheet.json')"
                :yoyo="false"
                :fps="15"
                :width=headerSpriteWidth
                :isLoop="false"
                ref="sprite"
            ></SpriteAnimator>
          </div>
          <div class="page__header-title">
            EDITAR USUARIO
          </div>
        </div>
        <div class="page__header-container-mobile-botom">
          <Button v-on:action-button-clicked="regresar()"
                  labelText="REGRESAR"
                  bgColor="#C00606"
                  :width=buttonWidth
                  class="button__margin-right"/>
          <Button v-on:action-button-clicked="editarUsuario()"
                  labelText="EDITAR DATOS"
                  :width=buttonWidth />
        </div>
      </div>
      <div class="page__content-container">
        <div class="page__main-container">
          <div class="page__main-container-left">
            <div class="page__form-border-container">
              <div class="page__form-image-upload-cotainer">
                <div class="page__form-image-upload-cotainer-left">
                  <BackgroundImage isBordered="S" height="150px" width="150px" positionHeight="center" :backgroundURL="rutaImagen"/>
                </div>
                <div class="page__form-image-upload-cotainer-right">
                  <ButtonUpload @upload-object="getUploadObject" />
                </div>
              </div>
              <div class="page__form-image-upload-cotainer-mobile">
                <div class="page__form-image-upload-cotainer-mobile-top">
                  <BackgroundImage isBordered="S" height="150px" width="150px" positionHeight="center" :backgroundURL="rutaImagen"/>
                </div>
                <div class="page__form-image-upload-cotainer-mobile-bottom">
                  <ButtonUpload @upload-object="getUploadObject" />
                </div>
              </div>
              <div class="page__form-line-flexrow-wrap">
                <div class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(0)">
                  <div class="page__form-label">
                    <Label labelText="Nombre" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorNombre" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" maxlength="250" v-model="nombre" v-bind:isError="errorNombre" />
                    </div>
                  </div>
                </div>
                <div class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(1)">
                  <div class="page__form-label">
                    <Label labelText="Primer Apellido" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorPrimerApellido" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" maxlength="250" v-model="primerApellido" v-bind:isError="errorPrimerApellido" />
                    </div>
                  </div>
                </div>
                <div class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(0)">
                  <div class="page__form-label">
                    <Label labelText="Segundo Apellido" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorSegundoApellido" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" maxlength="250" v-model="segundoApellido" v-bind:isError="errorSegundoApellido" />
                    </div>
                  </div>
                </div>
                <div class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(1)">
                  <div class="page__form-label">
                    <Label labelText="Correo Electrónico" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorEmail" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" maxlength="250" v-model="email" v-bind:isError="errorEmail" />
                    </div>
                  </div>
                </div>
                <div class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(0)">
                  <div class="page__form-label">
                    <Label labelText="Clave" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorClave" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" type="password" maxlength="250" v-model="clave" v-bind:isError="errorClave" />
                    </div>
                  </div>
                </div>
                <div class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(1)">
                  <div class="page__form-label">
                    <Label labelText="Confirmar Clave" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorConfirmarClave" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" type="password" maxlength="250" v-model="confirmarClave" v-bind:isError="errorConfirmarClave" />
                    </div>
                  </div>
                </div>
                <div v-if="objUsuario.perfil.id=='PIX'" class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(0)">
                  <div class="page__form-label">
                    <Label labelText="Perfil" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorClave" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <DropDownList v-model="idPerfil" v-bind:isError="errorIdPerfil" v-bind:options="idPerfilOpciones" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="page__form-error-message" v-if="mensajeError!=''">
                {{ mensajeError }}
              </div>
            </div>
          </div>
          <div class="page__main-container-right">
          </div>
        </div>
      </div>
    </div>
    <Modal type="CONFIRM"
           :message="modal_message"
           :messageLoading="modal_messageLoading"
           :isVisible="modal_isVisible"
           :isLoading="modal_isLoading"
           :transition="modal_transition"
           :forceClose="modal_forceClose"
           @aceptar="modalAceptar"
           @cancelar="modalCancelar" />
    <ModalError :title="modalErrorTitle"
                :message="modalErrorMessage"
                :exception="modalErrorException"
                v-model="isModalErrorMessage"
                v-on:aceptar="modal__ErrorAceptar()" />
  </div>
</template>

<script>
  import SpriteAnimator from "@/components/SpriteAnimator";
  import Button from "@/components/Button";
  import ButtonForm from "@/components/ButtonForm";
  import ButtonUpload from "@/components/ButtonUpload";
  import Label from "@/components/Label";
  import TextBox from "@/components/TextBox";
  import TextArea from "@/components/TextArea";
  import TextEditor from "@/components/custom/texteditor/TextEditor";
  import CheckBoxForm from "@/components/CheckBoxForm";
  import BackgroundImage from "@/components/BackgroundImage";
  import DropDownList from "@/components/DropDownList";
  import Modal from "@/components/Modal";
  import ModalError from "@/components/ModalError";

  export default {
    name: "UsuarioEditar",
    components: {
      SpriteAnimator,
      Button,
      ButtonForm,
      ButtonUpload,
      Label,
      TextBox,
      TextArea,
      TextEditor,
      CheckBoxForm,
      BackgroundImage,
      DropDownList,
      Modal,
      ModalError,
    },
    data() {
      return {
        objUsuario: {},
        idPerfilOpciones: [],

        // MEDIA QUERIES
        headerSpriteWidth:0,
        buttonWidth:"0",
        formPaddingRight:"0",

        // FORM
        idUsuario: 0,
        idUser: 0,
        nombre: "",
        primerApellido: "",
        segundoApellido: "",
        email: "",
        clave: "",
        confirmarClave: "",
        idPerfil: "",
        rutaImagen: "",
        fileImagen: null,
        mensajeError: "",

        // FORM ERROR
        errorNombre: false,
        errorPrimerApellido: false,
        errorSegundoApellido: false,
        errorEmail: false,
        errorClave: false,
        errorConfirmarClave: false,
        errorIdPerfil: false,

        // IMAGES
        isPageImagesLoad: true,
        isExternalImagesLoad: true,
        isMountFinished: false,

        // MODAL
        modal_message:'',
        modal_messageLoading:'',
        modal_isVisible:false,
        modal_isLoading:false,
        //modal_isClosed:false,
        modal_forceClose:false,
        modal_transition:"",

        // MODAL ERROR
        isModalErrorMessage:false,
        modalErrorTitle:'',
        modalErrorMessage:'',
        modalErrorException:'',
      };
    },
    created() {
      try {
        this.objUsuario = this.$store.getters['authSessionStore/getUser'];
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();

        this.mensajeError = "";
      } catch (error) {
        console.log("[UsuarioEditar.vue] created()::ERROR = "+error);
      }
    },
    async mounted() {
      try {
        // BROWSER BACK/FORWARD BUTTON
        window.popStateDetected = false
        window.addEventListener('popstate', () => { window.popStateDetected = true });

        var idUsuario = this.$route.params.idUsuario;
        this.idUsuario = idUsuario;
        this.idPerfil = this.objUsuario.perfil.id;

        await this.detalleUsuario(idUsuario);
        await this.obtenerIdPerfilOpciones();
        await this.page__preloadImages();
        await this.$nextTick();
        this.isMountFinished = true;

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[UsuarioEditar.vue] mounted()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },
    computed: {
      page__isAllContentLoaded(){
        return this.isPageImagesLoad && this.isExternalImagesLoad && this.isMountFinished;
      },
    },
    watch: {
      page__isAllContentLoaded(newValue) {
        if(newValue){
          var that;
          that = this;
          setTimeout(function () {
            that.$emit('show-loading', false);
          }, 1500);
        }
      },
    },
    methods: {
      async detalleUsuario(idUsuario){
        try {
          var usuarioObj = {};
          usuarioObj['id'] = idUsuario;

          var {default: UsuarioLogic} = await import('@/logic/usuario.logic');
          var httpUsuario = await UsuarioLogic.detalle(usuarioObj);
          var httpUsuarioData = httpUsuario.data;
          var responseUsuario = httpUsuario.data.response;

          switch(httpUsuarioData.status) {
            case "OK":
              this.idUsuario = responseUsuario.id;
              this.idUser = responseUsuario.idUser;
              this.idPerfil = responseUsuario.perfil.id;
              this.nombre = responseUsuario.nombre;
              this.primerApellido = responseUsuario.primerApellido;
              this.segundoApellido = responseUsuario.segundoApellido;
              this.email = responseUsuario.email;
              this.clave = "";
              this.confirmarClave = "";
              this.rutaImagen = "";

              if(responseUsuario.rutaImagen!=null){
                this.rutaImagen = responseUsuario.rutaImagen;
              }

              break;

            default:
              var error = httpUsuarioData.message;
              throw Error("[UsuarioEditar.vue] detalleUsuario()::ERROR = "+error);
          }

        } catch (error) {
          throw Error("[UsuarioEditar.vue] detalleUsuario()::ERROR = "+error);
        }
      },

      async editarUsuario(){
        try {
          var contVacio = 0;
          var strNombre = this.nombre;
          var strPrimerApellido = this.primerApellido;
          var strSegundoApellido = this.segundoApellido;
          var strEmail = this.email;
          var strClave = this.clave;
          var strConfirmarClave = this.confirmarClave;
          var strIdPerfil = this.idPerfil;

          if(strNombre===""){
            this.errorNombre=true;
            contVacio = contVacio + 1;
          } else {
            this.errorNombre=false;
          }
          if(strPrimerApellido===""){
            this.errorPrimerApellido=true;
            contVacio = contVacio + 1;
          } else {
            this.errorPrimerApellido=false;
          }
          if(strSegundoApellido===""){
            this.errorSegundoApellido=true;
            contVacio = contVacio + 1;
          } else {
            this.errorSegundoApellido=false;
          }
          if(strEmail===""){
            this.errorEmail=true;
            contVacio = contVacio + 1;
          } else {
            this.errorEmail=false;
          }
          if(this.objUsuario.perfil.id=="PIX"){
            if (strIdPerfil === "") {
              this.errorIdPerfil = true;
              contVacio = contVacio + 1;
            } else {
              this.errorIdPerfil = false;
            }
          }

          if (contVacio === 0) {
            var strMensajeError = "";
            var isError = false;
            var EMAIL_REGEXP = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            var emailValidator = EMAIL_REGEXP.test(strEmail);

            if (!emailValidator) {
              this.errorEmail = true;
              this.mensajeError =  "/* Por favor ingrese un formato válido de Correo Electrónico */";
            } else {
              this.errorEmail = false;
              this.errorClave = false;
              this.errorConfirmarClave = false;

              if ((strClave == "") && (strConfirmarClave == "")) {
                isError = false;
              } else {
                if (strClave == strConfirmarClave) {
                  var PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
                  var passwordValidator = PASSWORD_REGEXP.test(strClave);
                  if(!passwordValidator){
                    this.errorClave = true;
                    this.errorConfirmarClave = true;
                    isError = true;
                    strMensajeError = "La clave debe tener al menos: 8 caracteres / 1 letra mayúscula / 1 letra minúscula / 1 número";
                  } else {
                    this.errorClave = false;
                    this.errorConfirmarClave = false;
                    isError = false;
                  }
                } else {
                  this.errorClave = true;
                  this.errorConfirmarClave = true;
                  isError = true;
                  strMensajeError = "/* Las claves ingresadas no coinciden. Por favor revise los campos ingresados */";
                }
              }

              if (isError) {
                this.mensajeError = strMensajeError;
              } else {
                this.modal_transition = "LEFT";
                this.modal_messageLoading = "Validando Contenidos. Un momento por favor."
                this.modal_forceClose = false;
                this.modal_isLoading = true;
                this.modal_isVisible = true;

                this.errorEmail = false;
                this.errorClave = false;
                this.errorConfirmarClave = false;

                var usuarioExiste = await this.existeUsuario(this.idUser,this.email);
                var {default: CommonHelper} = await import('@/helpers/common.helper');
                await CommonHelper.delay(1500);

                if(!usuarioExiste){
                  this.mensajeError = "";
                  this.errorEmail = false;

                  this.modal_forceClose = false;
                  this.modal_isLoading = false;
                  this.modal_isVisible = true;
                  this.modal_message = "¿Está seguro que desea editar esta información?";
                } else {
                  this.mensajeError = "/* El Correo Electrónico ingresado ya se encuentra registrado */";
                  this.errorEmail = true;

                  this.modal_forceClose = false;
                  this.modal_isLoading = false;
                  this.modal_isVisible = false;
                }
              }
            }
          } else {
            this.mensajeError = "/* Por favor ingrese los datos faltantes */";
          }

        } catch (error) {
          this.$emit('show-loading', false);
          var tmpExcepcion = "[UsuarioEditar.vue] editarUsuario()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },

      async existeUsuario(idUser,email){
        try {
          var usuarioObj = {}
          usuarioObj["email"] = email;
          usuarioObj["idUser"] = idUser;

          var {default: UsuarioLogic} = await import('@/logic/usuario.logic');
          var httpUsuario = await UsuarioLogic.verificar(usuarioObj);
          var httpUsuarioData = httpUsuario.data;
          var responseUsuario = httpUsuario.data.response;

          var resultado = true;

          switch(httpUsuarioData.status) {
            case "OK":
              resultado=responseUsuario;
              break;

            default:
              var error = httpUsuarioData.message;
              throw Error("[UsuarioEditar.vue] existeUsuario()::ERROR = "+error);
          }

          return resultado;

        } catch (error) {
          this.$emit('show-loading', false);
          var tmpExcepcion = "[UsuarioEditar.vue] existeUsuario()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },

      regresar(){
        try {
          this.$emit('show-loading', true);
          var nextRoute = 'session_usuario_listar';
          this.$router.push({ name: nextRoute }, () => {
          });

        } catch (error) {
          this.$emit('show-loading', false);
          var tmpExcepcion = "[UsuarioEditar.vue] regresar()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },

      getUploadObject(objData){
        this.fileImagen = objData.data;
        this.rutaImagen = objData.url;
      },


      // ===========
      //  LISTENERS
      // ===========

      handleWindowResize(){
        this.mediaQueries();
      },


      // =======
      //  MODAL
      // =======

      async modalAceptar(){
        try {
          this.$emit('show-loading', true, "Actualizando información. Un momento por favor","RIGHT");
          this.modal_forceClose = true;
          this.modal_isVisible = false;
          this.modal_forceClose = false;

          var usuarioObj = {};
          usuarioObj['id'] = this.idUsuario;
          usuarioObj['idPerfil'] = this.idPerfil;
          usuarioObj['nombre'] = this.nombre;
          usuarioObj['primerApellido'] = this.primerApellido;
          usuarioObj['segundoApellido'] = this.segundoApellido;
          usuarioObj['email'] = this.email;
          usuarioObj['clave'] = this.clave;
          usuarioObj['confirmarClave'] = this.confirmarClave;
          usuarioObj['fileImagen'] = this.fileImagen;

          var {default: UsuarioLogic} = await import('@/logic/usuario.logic');
          var httpUsuario = await UsuarioLogic.editar(usuarioObj);
          var httpUsuarioData = httpUsuario.data;
          var responseUsuario = httpUsuario.data.response;

          switch(httpUsuarioData.status) {
            case "OK":
              // Verificamos si se está editando al usuario que actualmente esta logueado al sistema
              var objUsuario = this.$store.getters['authSessionStore/getUser'];
              if(objUsuario.idUsuario==this.idUsuario){
                objUsuario.nombre = this.nombre;
                objUsuario.primerApellido = this.primerApellido;
                objUsuario.segundoApellido = this.segundoApellido;
                objUsuario.correoElectronico = this.email;
                objUsuario.rutaImagen = responseUsuario.rutaImagen
                this.$store.dispatch('authSessionStore/setUser',objUsuario);
              }

              var nextRoute = 'session_usuario_listar';
              this.$router.push({ name: nextRoute }, () => {
              });
              break;

            default:
              var error = httpUsuarioData.message;
              throw Error("[UsuarioEditar.vue] modalAceptar()::ERROR = "+error);
          }

        } catch (error) {
          this.modal_forceClose = false;
          this.modal_isLoading = false;
          this.modal_isVisible = false;

          var tmpExcepcion = "[UsuarioEditar.vue] modalAceptar()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },
      modalCancelar(){
        this.modal_forceClose = false;
        this.modal_isLoading = false;
        this.modal_isVisible = false;
      },


      // MODAL ERROR
      modal__ErrorRegistrarExcepcion(excepcion){
        this.modalErrorTitle = "LO SENTIMOS";
        this.modalErrorMessage = "Se produjo un error al realizar la operación. Por favor intente más tarde.";
        this.modalErrorException = excepcion;
        this.isModalErrorMessage = true;
      },
      modal__ErrorAceptar(){
        this.isModalErrorMessage=false;
      },

      // =================
      // PRIVATE FUNCTIONS
      // =================

      async obtenerIdPerfilOpciones(){
        try {
          this.idPerfilOpciones = [];

          var optionObj = {};
          optionObj['id'] = "";
          optionObj['nombre'] = "Seleccione opción";
          this.idPerfilOpciones.push(optionObj);

          var {default: PerfilLogic} = await import('@/logic/perfil.logic');
          var httpPerfil = await PerfilLogic.dropdownlist();
          var httpPerfilData = httpPerfil.data;
          var responsePerfil = httpPerfil.data.response;

          switch(httpPerfilData.status) {
            case "OK":
              responsePerfil.forEach((objPerfil, indexPerfil) => {
                if(objPerfil.id=="PIX"){
                  if(this.objUsuario.perfil.id=="PIX"){
                    var optionObj = {};
                    optionObj['id'] = objPerfil.id;
                    optionObj['nombre'] = objPerfil.nombre;
                    this.idPerfilOpciones.push(optionObj);
                  }
                  if(this.objUsuario.perfil.id=="SYS"){
                    var optionObj = {};
                    optionObj['id'] = objPerfil.id;
                    optionObj['nombre'] = objPerfil.nombre;
                    this.idPerfilOpciones.push(optionObj);
                  }
                } else {
                  var optionObj = {};
                  optionObj['id'] = objPerfil.id;
                  optionObj['nombre'] = objPerfil.nombre;
                  this.idPerfilOpciones.push(optionObj);
                }
              });

              break;

            default:
              var error = httpPerfilData.message;
              throw Error(error);
          }

        } catch (error) {
          throw Error("[UsuarioCrear.vue] obtenerIdPerfilOpciones()::ERROR = "+error);
        }
      },

      mediaQueries() {
        var _headerSpriteWidth = 0;
        var _buttonWidth = 0;
        var _formPaddingRight = 0;

        const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
        const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );
        const matchMediaLarge = window.matchMedia( "(min-width: 64em)" );

        if (matchMediaSmall.matches) {
          _headerSpriteWidth = 120;
          _buttonWidth = 120;
          _formPaddingRight = 0;
        }

        if (matchMediaMedium.matches) {
          _headerSpriteWidth = 120;
          _buttonWidth = 120;
          _formPaddingRight = 0;
        }

        if (matchMediaLarge.matches) {
          _headerSpriteWidth = 120;
          _buttonWidth = 120;
          _formPaddingRight = 20;
        }

        this.headerSpriteWidth = _headerSpriteWidth;
        this.buttonWidth = _buttonWidth+"px";
        this.formPaddingRight = _formPaddingRight+"px";
      },

      getPaddingRight(index){
        var objStyle = {};

        if(index % 2 !== 0) {
          objStyle["padding-right"] = 0;
        } else {
          objStyle["padding-right"] = this.formPaddingRight;
        }

        return objStyle;
      },

      async page__preloadImages() {
        var {default: ImageHelper} = await import('@/helpers/image.helper');

        this.isPageImagesLoad = true;
      },
    },
    beforeRouteLeave (to, from, next) {
      if(window.popStateDetected){
        // HACK: Se mostrará el LOADING siembre que se abandone la página. Esto nos
        // permitirá abordar los casos en que se haga BACK/FORWARD en el browser
        this.$emit('show-loading', true);
      }
      next();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleWindowResize);
      window.removeEventListener('popstate', () => { window.popStateDetected = true });
    }
  };
</script>

<style scoped>

/* Small only */
@media screen and (max-width: 39.9375em) {

}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

}

/* Large and up */
@media screen and (min-width: 64em) {

}

</style>