<template>
  <div>
    <input type="file" accept="image/*" name="fileImage" id="fileImage" class="inputfile" @change="onFileInput($event)">
    <label ref="refInputUploaderLabel" id="labelId" for="fileImage" :style="computedStyle">
      <div class="inputfile-label-text">
        <span ref="refInputUploaderSpan" id="file-name">{{ placeholder }}</span>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "ButtonUpload",
  props: {
    placeholder: {
      type: String,
      default: "Seleccionar Archivo",
    },
    bdColor: {
      type: String,
      default: "#FF5229",
    },
    bdRadius: {
      type: [Number,String],
      default: "0",
    },
    bgColor: {
      type: String,
      default: "#FFFFFF",
    },
    fontColor: {
      type: String,
      default: "#FF5229",
    },
    isError:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      textMaxLength:35,
    };
  },
  async mounted() {
    try {

    } catch (error) {
      console.log("[ButtonUpload.vue] mounted()::ERROR = "+error);
    }
  },
  computed: {
    computedStyle() {
      var isError = this.$props.isError;
      var bdColor = this.$props.bdColor;
      var bgColor = this.$props.bgColor;
      var bdRadius = this.$props.bdRadius;
      var fontColor = this.$props.fontColor;

      var objStyle = {};
      objStyle["border-radius"] = bdRadius;

      if(isError){
        objStyle["border"] = "2px solid #BD0000";
        objStyle["color"] = "#FFFFFF";
        objStyle["background-color"] = "#BD0000";
      } else {
        objStyle["border"] = "2px solid "+bdColor;
        objStyle["color"] = fontColor;
        objStyle["background-color"] = bgColor;
      }

      return objStyle;
    },
  },
  methods: {
    onFileInput(event) {
      var label = this.$refs.refInputUploaderLabel;
      var labelVal = this.$refs.refInputUploaderLabel.innerHTML;

      var fileName = '';
      if( event.target.files && event.target.files.length > 1 )
        fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', event.target.files.length );
      else
        fileName = event.target.value.split( '\\' ).pop();

      if(fileName)
        label.querySelector( 'span' ).innerHTML = this.formatFilename(fileName);
      else
        label.innerHTML = labelVal;

      const data = URL.createObjectURL(event.target.files[0]);

      var objData = {};
      objData['data'] = event.target.files[0];
      objData['url'] = data;

      this.$emit('upload-object', objData)
    },
    formatFilename(str) {
      if (str.length > this.textMaxLength) {
        return str.substr(0, this.textMaxLength-15) + '...' + str.substr(str.length-10, str.length);
      }
      return str;
    }
  }
};
</script>

<style scoped>

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: relative;
}

.inputfile + label {
  font-family: 'kronika';
  background-color:#FFFFFF;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inputfile-label-text{
  display:flex;
  flex-direction:row;
  flex-shrink:1;
  align-items:center;
  justify-content: center;
}

/* Small only */
@media screen and (max-width: 39.9375em) {
  .inputfile + label {
    padding:8px 15px;
  }

  .inputfile-label-text{
    height:13px;
    font-size:0.8em;
    line-height:0.8em;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .inputfile + label {
    padding:8px 15px;
  }

  .inputfile-label-text{
    height:13px;
    font-size:0.85em;
    line-height:0.85em;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  .inputfile + label {
    padding:8px 15px;
  }

  .inputfile-label-text{
    height:13px;
    font-size:0.85em;
    line-height:0.85em;
  }
}

</style>