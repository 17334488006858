<template>
  <div class="textEditor__border" :style="computedBorderColor">
    <vue-editor v-model="editorValue"
                :editor-toolbar="customToolbar"></vue-editor>
  </div>
</template>

<script>
    import {VueEditor} from "vue2-editor/dist/vue2-editor.core";

    export default {
      name: "TextEditor",
      props: {
        value: {
          type: [Number,String],
          default: "",
        },
        placeholder: {
          type: String,
          default: "",
        },
        isError:{
          type: Boolean,
          default: false,
        }
      },
      components: {
        VueEditor
      },
      data() {
        return {
          // WYSIWYG EDITOR
          customToolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }]
          ],
        };
      },
      async mounted() {
        try {
          //await this.$nextTick();

        } catch (error) {
          console.log("[TextEditor.vue] mounted()::ERROR = "+error);
        }
      },
      computed: {
        editorValue: {
          get(){ return this.value },
          set(v){
            this.$emit('input', v)
          }
        },
        computedBorderColor() {
          var isError = this.$props.isError;

          if(isError){
            return {
              'border': '2px solid #BD0000',
            };
          }

          return {
            'border': '1px solid #FFFFFF',
          };
        },
      },
      methods: {
      }
    };
</script>

<style scoped>

@import './css/vue2-editor.css';
@import './css/vue2-editor-core.css';
@import './css/vue2-editor-style.css';

.textEditor__border{
  background-color:#FFFFFF;
  width:100%;
}

/* Small only */
@media screen and (max-width: 39.9375em) {
  .textEditor__border{
    border-radius:8px;
    padding:10px 10px;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .textEditor__border{
    border-radius:8px;
    padding:10px 10px;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  .textEditor__border{
    border-radius:8px;
    padding:15px 15px;
  }
}

</style>
