<template>
  <div class="page__wrapper">
    <div class="page__container">
      <div class="page__header-container">
        <div class="page__header-container-left">
          <SpriteAnimator
              :id="'sprite'"
              :spritesheet="require('../../../../../assets/sprites/appear/spritesheet.png')"
              :json="require('../../../../../assets/sprites/appear/spritesheet.json')"
              :yoyo="false"
              :fps="15"
              :width=headerSpriteWidth
              :isLoop="false"
              ref="sprite"
          ></SpriteAnimator>
          <div class="page__header-title">
            CONFIGURACIÓN
          </div>
        </div>
        <div class="page__header-container-right">
          <Button v-on:action-button-clicked="editarConfiguracion()"
                  labelText="EDITAR DATOS"
                  :width=buttonWidth />
        </div>
      </div>
      <div class="page__header-container-mobile">
        <div class="page__header-container-mobile-top">
          <div class="page__header-sprite">
            <SpriteAnimator
                :id="'sprite'"
                :spritesheet="require('../../../../../assets/sprites/appear/spritesheet.png')"
                :json="require('../../../../../assets/sprites/appear/spritesheet.json')"
                :yoyo="false"
                :fps="15"
                :width=headerSpriteWidth
                :isLoop="false"
                ref="sprite"
            ></SpriteAnimator>
          </div>
          <div class="page__header-title">
            CONFIGURACIÓN
          </div>
        </div>
        <div class="page__header-container-mobile-botom">
          <Button v-on:action-button-clicked="editarConfiguracion()"
                  labelText="EDITAR DATOS"
                  :width=buttonWidth />
        </div>
      </div>
      <div class="page__content-container">
        <div class="page__main-container">
          <div class="page__main-container-left">
            <div class="page__form-line-flexrow">
              <div class="page__form-line-flexrow-col2 page__form-section-label">
                <div class="page__form-line-flexrow full-height justify-flex-start align-flex-center">
                  BACKEND
                </div>
              </div>
              <div class="page__form-line-flexrow-col2 page__form-section-label" style="padding-right:0;">
                <div class="page__form-line-flexrow page__form-line-flexrow-col2-justify">
                  <ButtonForm v-on:action-button-clicked="limpiarCache()"
                              labelText="LIMPIAR CACHÉ"
                              bdColor="#C00606"
                              bgColor="#C00606"
                              fontColor="#FFFFFF"
                              class="margin-right10"
                              :width="buttonFormWidth" />
                  <ButtonForm v-on:action-button-clicked="reiniciarQueue()"
                              labelText="REINICIAR QUEUE"
                              bdColor="#C00606"
                              bgColor="#C00606"
                              fontColor="#FFFFFF"
                              :width="buttonFormWidth" />
                </div>
              </div>
            </div>
            <div class="page__form-border-container">
              <div class="page__form-line-flexrow-wrap">
                <div class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(0)">
                  <div class="page__form-label">
                    <Label labelText="Items Per Page" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorPaginationItemsPerPage" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" maxlength="250" v-model="paginationItemsPerPage" v-bind:isError="errorPaginationItemsPerPage" />
                    </div>
                  </div>
                </div>
                <div class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(1)">
                  <div class="page__form-label">
                    <Label labelText="URL Production" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorUrlProduction" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" maxlength="250" v-model="urlProduction" v-bind:isError="errorUrlProduction" />
                    </div>
                  </div>
                </div>
                <div class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(0)">
                  <div class="page__form-label">
                    <Label labelText="URL Development" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorUrlDevelopment" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" maxlength="250" v-model="urlDevelopment" v-bind:isError="errorUrlDevelopment" />
                    </div>
                  </div>
                </div>
                <div class="page__form-line-flexbasis50 padding-bottom20"  :style="getPaddingRight(1)">
                  <div class="page__form-label">
                    <Label labelText="URL Localhost" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorUrlLocalhost" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1">
                      <TextBox placeholder="" maxlength="250" v-model="urlLocalhost" v-bind:isError="errorUrlLocalhost" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="page__form-error-message" v-if="mensajeErrorBackend!=''">
                {{ mensajeErrorBackend }}
              </div>
            </div>
            <br>
            <div class="page__form-section-label">
              TOKENS
            </div>
            <div class="page__form-border-container">
              <div class="page__form-line-flexrow-wrap">
                <div v-for="(token, index) in arrTokens" class="page__form-line-flexbasis50 padding-bottom20" :style="getPaddingRight(index)">
                  <div class="page__form-label">
                    <Label :labelText="token.tipoLabel" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorLabelTokens[token.id]" />
                  </div>
                  <div class="page__form-line-flexrow">
                    <div class="flex1 padding-right10">
                      <TextBox type="number" placeholder="" maxlength="250" v-model="tiempoTokens[token.id]" v-bind:isError="errorTiempoTokens[token.id]" />
                    </div>
                    <div class="flex1">
                      <DropDownList v-model="tiempoMedidaTokens[token.id]" v-bind:options="idTiempoMedidaOpciones" v-bind:isError="errorTiempoMedidaTokens[token.id]" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="page__form-error-message" v-if="mensajeErrorTokens!=''">
                {{ mensajeErrorTokens }}
              </div>
            </div>
          </div>
          <div class="page__main-container-right">
          </div>
        </div>
      </div>
    </div>
    <Modal type="CONFIRM"
           accessType="SESSION"
           :message="modal_message"
           :messageLoading="modal_messageLoading"
           :isVisible="modal_isVisible"
           :isLoading="modal_isLoading"
           :transition="modal_transition"
           :forceClose="modal_forceClose"
           @aceptar="modalAceptar"
           @cancelar="modalCancelar" />
    <ModalError :title="modalErrorTitle"
                :message="modalErrorMessage"
                :exception="modalErrorException"
                v-model="isModalErrorMessage"
                v-on:aceptar="modal__ErrorAceptar()" />
  </div>
</template>

<script>
  import SpriteAnimator from "@/components/SpriteAnimator";
  import Button from "@/components/Button";
  import ButtonForm from "@/components/ButtonForm";
  import ButtonUpload from "@/components/ButtonUpload";
  import Label from "@/components/Label";
  import TextBox from "@/components/TextBox";
  import TextArea from "@/components/TextArea";
  import TextEditor from "@/components/custom/texteditor/TextEditor";
  import DropDownList from "@/components/DropDownList";
  import CheckBoxForm from "@/components/CheckBoxForm";
  import BackgroundImage from "@/components/BackgroundImage";
  import Modal from "@/components/Modal";
  import ModalError from "@/components/ModalError";

  export default {
    name: "ConfiguracionEditar",
    components: {
      SpriteAnimator,
      Button,
      ButtonForm,
      ButtonUpload,
      Label,
      TextBox,
      TextArea,
      TextEditor,
      DropDownList,
      CheckBoxForm,
      BackgroundImage,
      Modal,
      ModalError,
    },
    data() {
      return {
        idTiempoMedidaOpciones: [],
        arrTokens: [],

        // MEDIA QUERIES
        headerSpriteWidth:0,
        buttonWidth:"0",
        buttonFormWidth:"0",
        formPaddingRight:"0",

        // FORM
        paginationItemsPerPage: "",
        urlProduction: "",
        urlDevelopment: "",
        urlLocalhost: "",
        tiempoMedidaTokens:{},
        tiempoTokens:{},
        mensajeErrorBackend: "",
        mensajeErrorTokens: "",

        // FORM ERROR
        errorPaginationItemsPerPage: false,
        errorUrlProduction: false,
        errorUrlDevelopment: false,
        errorUrlLocalhost: false,
        errorLabelTokens:{}, // Dynamic Information
        errorTiempoTokens:{}, // Dynamic Information
        errorTiempoMedidaTokens:{}, // Dynamic Information

        // IMAGES
        isPageImagesLoad: true,
        isExternalImagesLoad: true,
        isMountFinished: false,

        // MODAL
        modal_message:'',
        modal_messageLoading:'',
        modal_isVisible:false,
        modal_isLoading:false,
        modal_forceClose:false,
        modal_transition:"",

        // MODAL ERROR
        isModalErrorMessage:false,
        modalErrorTitle:'',
        modalErrorMessage:'',
        modalErrorException:'',
      };
    },
    created() {
      try {
        this.modalTransition = "";
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();

        this.mensajeError = "";
      } catch (error) {
        console.log("[ConfiguracionEditar.vue] created()::ERROR = "+error);
      }
    },
    async mounted() {
      try {
        // BROWSER BACK/FORWARD BUTTON
        window.popStateDetected = false
        window.addEventListener('popstate', () => { window.popStateDetected = true });

        this.obtenerIdTiempoMedidaOpciones();
        await this.detalleConfiguracion();
        await this.page__preloadImages();
        await this.$nextTick();
        this.isMountFinished = true;

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[ConfiguracionEditar.vue] mounted()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },
    computed: {
      page__isAllContentLoaded(){
        return this.isPageImagesLoad && this.isExternalImagesLoad && this.isMountFinished;
      },
    },
    watch: {
      page__isAllContentLoaded(newValue) {
        if(newValue){
          var that;
          that = this;
          setTimeout(function () {
            that.$emit('show-loading', false);
          }, 1500);
        }
      },
    },
    methods: {
      async detalleConfiguracion(){
        try {
          var {default: ConfiguracionLogic} = await import('@/logic/_backend/configuracion.logic');
          var httpConfiguracion = await ConfiguracionLogic.detalle();
          var httpConfiguracionData = httpConfiguracion.data;
          var responseConfiguracion = httpConfiguracion.data.response;

          switch(httpConfiguracionData.status) {
            case "OK":
              this.paginationItemsPerPage = responseConfiguracion.paginationItemsPerPage;
              this.urlProduction = responseConfiguracion.urlProduction;
              this.urlDevelopment = responseConfiguracion.urlDevelopment;
              this.urlLocalhost = responseConfiguracion.urlLocalhost;

              var arrTokens = responseConfiguracion.tokens;
              arrTokens.forEach((objToken, indexToken) => {
                var tokenObj = {};
                tokenObj["id"] = objToken.id;
                tokenObj["tipo"] = objToken.tipo;
                tokenObj["tipoLabel"] = objToken.tipoLabel;
                tokenObj["tiempoMedida"] = objToken.tiempoMedida;
                tokenObj["tiempo"] = objToken.tiempo;
                tokenObj["estado"] = objToken.estado;
                this.arrTokens.push(tokenObj);

                this.tiempoMedidaTokens[objToken.id] = objToken.tiempoMedida;
                this.tiempoTokens[objToken.id] = objToken.tiempo;

                this.errorLabelTokens[objToken.id] = false;
                this.errorTiempoTokens[objToken.id] = false;
                this.errorTiempoMedidaTokens[objToken.id] = false;
              });

              break;

            default:
              var error = httpConfiguracionData.message;
              throw Error("[ConfiguracionEditar.vue] detalleConfiguracion()::ERROR = "+error);
          }

        } catch (error) {
          throw Error("[ConfiguracionEditar.vue] detalleConfiguracion()::ERROR = "+error);
        }
      },

      editarConfiguracion(){
        try {
          var contVacioBackend = 0;
          var contVacioTokens = 0;
          var contTotal = 0;

          // BACKEND
          var strPaginationItemsPerPage = this.paginationItemsPerPage;
          var strUrlProduction = this.urlProduction;
          var strUrlDevelopment = this.urlDevelopment;
          var strUrlLocalhost = this.urlLocalhost;

          if(strPaginationItemsPerPage===""){
            this.errorPaginationItemsPerPage=true;
            contVacioBackend = contVacioBackend + 1;
          } else {
            this.errorPaginationItemsPerPage=false;
          }
          if(strUrlProduction===""){
            this.errorUrlProduction=true;
            contVacioBackend = contVacioBackend + 1;
          } else {
            this.errorUrlProduction=false;
          }
          if(strUrlDevelopment===""){
            this.errorUrlDevelopment=true;
            contVacioBackend = contVacioBackend + 1;
          } else {
            this.errorUrlDevelopment=false;
          }
          if(strUrlLocalhost===""){
            this.errorUrlLocalhost=true;
            contVacioBackend = contVacioBackend + 1;
          } else {
            this.errorUrlLocalhost=false;
          }

          // WEB
          this.arrTokens.forEach((objToken, indexToken) => {
            var contErrorToken = 0;
            var strTiempo = this.tiempoTokens[objToken.id];
            var strTiempoMedida = this.tiempoMedidaTokens[objToken.id];

            if(strTiempo===""){
              this.errorTiempoTokens[objToken.id]=true;
              contVacioTokens = contVacioTokens + 1;
              contErrorToken = contErrorToken + 1;
            } else {
              this.errorTiempoTokens[objToken.id]=false;
            }

            if(strTiempoMedida===""){
              this.errorTiempoMedidaTokens[objToken.id]=true;
              contVacioTokens = contVacioTokens + 1;
              contErrorToken = contErrorToken + 1;
            } else {
              this.errorTiempoMedidaTokens[objToken.id]=false;
            }

            if(contErrorToken==0){
              this.errorLabelTokens[objToken.id]=false;
            } else {
              this.errorLabelTokens[objToken.id]=true;
            }
          });

          // HACK VUE2 REACTIVITY:
          // Como estamos modificando los valores de los keys de los objetos:
          // "this.errorTiempoTokens", "this.errorTiempoMedidaTokens" y "this.errorLabelTokens"
          // debemos hacer un FORCE UPDATE para que se refleje el cambio en pantalla.
          this.$forceUpdate();

          contTotal = parseInt(contVacioBackend)+parseInt(contVacioTokens);

          if(contTotal==0){
            this.mensajeErrorBackend = "";
            this.mensajeErrorTokens = "";
            this.modal_message = "¿Está seguro que desea editar esta información?";
            this.modal_messageLoading = ""
            this.modal_transition = "LEFT";
            this.modal_forceClose = false;
            this.modal_isVisible = true;

          } else {
            if(contVacioBackend>0){
              this.mensajeErrorBackend = "/* Por favor ingrese los datos faltantes */";
            } else {
              this.mensajeErrorBackend = "";
            }

            if(contVacioTokens>0){
              this.mensajeErrorTokens = "/* Por favor ingrese los datos faltantes */";
            } else {
              this.mensajeErrorTokens = "";
            }
          }

        } catch (error) {
          this.$emit('show-loading', false);
          var tmpExcepcion = "[ConfiguracionEditar.vue] editarServicio()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },

      async limpiarCache(){
        try {
          this.modal_messageLoading = "Limpiando Caché del Backend. Un momento por favor."
          this.modal_forceClose = false;
          this.modal_isLoading = true;
          this.modal_isVisible = true;

          var {default: ConfiguracionLogic} = await import('@/logic/_backend/configuracion.logic');
          var httpConfiguracion = await ConfiguracionLogic.limpiarCache();
          var httpConfiguracionData = httpConfiguracion.data;
          var responseConfiguracion = httpConfiguracion.data.response;

          switch(httpConfiguracionData.status) {
            case "OK":
              var {default: CommonHelper} = await import('@/helpers/common.helper');
              await CommonHelper.delay(2000);
              this.modal_isVisible = false;
              this.modal_isLoading = false;
              break;

            default:
              var error = httpConfiguracionData.message;
              throw Error("[ConfiguracionEditar.vue] limpiarCache()::ERROR = "+error);
          }

        } catch (error) {
          this.modal_forceClose = false;
          this.modal_isLoading = false;
          this.modal_isVisible = false;

          var tmpExcepcion = "[ConfiguracionEditar.vue] limpiarCache()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },

      async reiniciarQueue(){
        try {
          this.modal_messageLoading = "Reiniciando Queue. Un momento por favor."
          this.modal_forceClose = false;
          this.modal_isLoading = true;
          this.modal_isVisible = true;

          var {default: ConfiguracionLogic} = await import('@/logic/_backend/configuracion.logic');
          var httpConfiguracion = await ConfiguracionLogic.reiniciarQueue();
          var httpConfiguracionData = httpConfiguracion.data;
          var responseConfiguracion = httpConfiguracion.data.response;

          switch(httpConfiguracionData.status) {
            case "OK":
              var {default: CommonHelper} = await import('@/helpers/common.helper');
              await CommonHelper.delay(2000);
              this.modal_isVisible = false;
              this.modal_isLoading = false;
              break;

            default:
              var error = httpConfiguracionData.message;
              throw Error("[ConfiguracionEditar.vue] reiniciarQueue()::ERROR = "+error);
          }

        } catch (error) {
          this.modal_forceClose = false;
          this.modal_isLoading = false;
          this.modal_isVisible = false;

          var tmpExcepcion = "[ConfiguracionEditar.vue] reiniciarQueue()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },

      obtenerIdTiempoMedidaOpciones(){
        try {
          this.idTiempoMedidaOpciones = [];

          var optionObj = {};
          optionObj['id'] = "SEG";
          optionObj['nombre'] = "Segundos";
          this.idTiempoMedidaOpciones.push(optionObj);

          var optionObj = {};
          optionObj['id'] = "MIN";
          optionObj['nombre'] = "Minutos";
          this.idTiempoMedidaOpciones.push(optionObj);

          var optionObj = {};
          optionObj['id'] = "HOR";
          optionObj['nombre'] = "Horas";
          this.idTiempoMedidaOpciones.push(optionObj);

          var optionObj = {};
          optionObj['id'] = "DIA";
          optionObj['nombre'] = "Días";
          this.idTiempoMedidaOpciones.push(optionObj);

          var optionObj = {};
          optionObj['id'] = "MES";
          optionObj['nombre'] = "Meses";
          this.idTiempoMedidaOpciones.push(optionObj);

          var optionObj = {};
          optionObj['id'] = "ANH";
          optionObj['nombre'] = "Años";
          this.idTiempoMedidaOpciones.push(optionObj);

        } catch (error) {
          throw Error("[UsuarioCrear.vue] obtenerIdTiempoMedidaOpciones()::ERROR = "+error);
        }
      },



      // LISTENERS
      handleWindowResize(){
        this.mediaQueries();
      },

      // MODAL
      async modalAceptar(){
        try {
          this.modal_messageLoading = "Actualizando información. Un momento por favor."
          this.modal_forceClose = false;
          this.modal_isLoading = true;
          this.modal_isVisible = true;

          var configuracionObj = {};
          configuracionObj['paginationItemsPerPage'] = this.paginationItemsPerPage;
          configuracionObj['urlProduction'] = this.urlProduction;
          configuracionObj['urlDevelopment'] = this.urlDevelopment;
          configuracionObj['urlLocalhost'] = this.urlLocalhost;
          configuracionObj['tiempoMedidaTokens'] = this.tiempoMedidaTokens;
          configuracionObj['tiempoTokens'] = this.tiempoTokens;

          var {default: ConfiguracionLogic} = await import('@/logic/_backend/configuracion.logic');
          var httpConfiguracion = await ConfiguracionLogic.editar(configuracionObj);
          var httpConfiguracionData = httpConfiguracion.data;
          var responseConfiguracion = httpConfiguracion.data.response;

          switch(httpConfiguracionData.status) {
            case "OK":
              var {default: CommonHelper} = await import('@/helpers/common.helper');
              await CommonHelper.delay(2000);
              this.modal_isVisible = false;
              this.modal_isLoading = false;
              break;

            default:
              var error = httpConfiguracionData.message;
              throw Error("[ConfiguracionEditar.vue] modalAceptar()::ERROR = "+error);
          }

        } catch (error) {
          this.modal_forceClose = false;
          this.modal_isLoading = false;
          this.modal_isVisible = false;

          var tmpExcepcion = "[ConfiguracionEditar.vue] modalAceptar()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },
      modalCancelar(){
        this.modal_forceClose = false;
        this.modal_isLoading = false;
        this.modal_isVisible = false;
      },


      // MODAL ERROR
      modal__ErrorRegistrarExcepcion(excepcion){
        this.modalErrorTitle = "LO SENTIMOS";
        this.modalErrorMessage = "Se produjo un error al realizar la operación. Por favor intente más tarde.";
        this.modalErrorException = excepcion;
        this.isModalErrorMessage = true;
      },
      modal__ErrorAceptar(){
        this.isModalErrorMessage=false;
      },

      // =================
      // PRIVATE FUNCTIONS
      // =================

      mediaQueries() {
        var _headerSpriteWidth = 0;
        var _buttonWidth = 0;
        var _buttonFormWidth = 0;
        var _formPaddingRight = 0;

        const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
        const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );
        const matchMediaLarge = window.matchMedia( "(min-width: 64em)" );

        if (matchMediaSmall.matches) {
          _headerSpriteWidth = 120;
          _buttonWidth = 120;
          _buttonFormWidth = 120;
          _formPaddingRight = 0;
        }

        if (matchMediaMedium.matches) {
          _headerSpriteWidth = 120;
          _buttonWidth = 120;
          _buttonFormWidth = 140;
          _formPaddingRight = 0;
        }

        if (matchMediaLarge.matches) {
          _headerSpriteWidth = 120;
          _buttonWidth = 120;
          _buttonFormWidth = 140;
          _formPaddingRight = 20;
        }

        this.headerSpriteWidth = _headerSpriteWidth;
        this.buttonWidth = _buttonWidth+"px";
        this.buttonFormWidth = _buttonFormWidth+"px";
        this.formPaddingRight = _formPaddingRight+"px";
      },

      getPaddingRight(index){
        var objStyle = {};

        if(index % 2 !== 0) {
          objStyle["padding-right"] = 0;
        } else {
          objStyle["padding-right"] = this.formPaddingRight;
        }

        return objStyle;
      },

      async page__preloadImages() {
        var {default: ImageHelper} = await import('@/helpers/image.helper');

        this.isPageImagesLoad = true;
      },
    },
    beforeRouteLeave (to, from, next) {
      if(window.popStateDetected){
        // HACK: Se mostrará el LOADING siembre que se abandone la página. Esto nos
        // permitirá abordar los casos en que se haga BACK/FORWARD en el browser
        this.$emit('show-loading', true);
      }
      next();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleWindowResize);
      window.removeEventListener('popstate', () => { window.popStateDetected = true });
    }
  };
</script>

<style scoped>

/* Small only */
@media screen and (max-width: 39.9375em) {

}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

}

/* Large and up */
@media screen and (min-width: 64em) {

}

</style>