<template>
  <div class="page__wrapper">
    <div class="page__container">
      <div class="page__header-container">
        <div class="page__header-container-left">
          <SpriteAnimator
              :id="'sprite'"
              :spritesheet="require('../../../../../assets/sprites/appear/spritesheet.png')"
              :json="require('../../../../../assets/sprites/appear/spritesheet.json')"
              :yoyo="false"
              :fps="15"
              :width=headerSpriteWidth
              :isLoop="false"
              ref="sprite"
          ></SpriteAnimator>
          <div class="page__header-title">
            REFRESH TOKENS
          </div>
        </div>
        <div class="page__header-container-right">
        </div>
      </div>
      <div class="page__header-container-mobile">
        <div class="page__header-container-mobile-top">
          <div class="page__header-sprite">
            <SpriteAnimator
                :id="'sprite'"
                :spritesheet="require('../../../../../assets/sprites/appear/spritesheet.png')"
                :json="require('../../../../../assets/sprites/appear/spritesheet.json')"
                :yoyo="false"
                :fps="15"
                :width=headerSpriteWidth
                :isLoop="false"
                ref="sprite"
            ></SpriteAnimator>
          </div>
          <div class="page__header-title">
            REFRESH TOKENS
          </div>
        </div>
        <div class="page__header-container-mobile-botom">
          <img class="page__header-icon" src='@/assets/images/ico_camera_white.png' @click="buscarTokenRefreshModal()" />
        </div>
      </div>
      <div class="page__content-container">
        <div class="page__main-list-container">
          <div id="page__search-div" class="page__main-list-container-left">
            <div class="page__form-container page__border-dotted">
              <div class="page__form-search-line">
                <div class="page__form-label">
                  <Label labelText="Fecha Inicio" fontFamily="kronika" fontColor="#FFFFFF" />
                </div>
                <div class="full-width">
                  <TextBox type="date" v-model="fechaInicio" />
                </div>
              </div>
              <div class="page__form-search-line">
                <div class="page__form-label">
                  <Label labelText="Fecha Fin" fontFamily="kronika" fontColor="#FFFFFF" />
                </div>
                <div class="full-width">
                  <TextBox type="date" v-model="fechaFin" />
                </div>
              </div>
              <div class="page__form-search-line">
                <div class="page__form-label">
                  <Label labelText="Correo Electrónico" fontFamily="kronika" fontColor="#FFFFFF" />
                </div>
                <div class="full-width">
                  <TextBox placeholder="" maxlength="250" v-model="email" />
                </div>
              </div>
              <div class="page__form-search-line">
                <div class="page__form-label">
                  <Label labelText="Nombre" fontFamily="kronika" fontColor="#FFFFFF" />
                </div>
                <div class="full-width">
                  <TextBox placeholder="" maxlength="250" v-model="nombre" />
                </div>
              </div>
              <div class="page__form-search-line">
                <div class="page__form-label">
                  <Label labelText="Primer Apellido" fontFamily="kronika" fontColor="#FFFFFF" />
                </div>
                <div class="full-width">
                  <TextBox placeholder="" maxlength="250" v-model="primerApellido" />
                </div>
              </div>
              <div class="page__form-search-line">
                <div class="page__form-label">
                  <Label labelText="Segundo Apellido" fontFamily="kronika" fontColor="#FFFFFF" />
                </div>
                <div class="full-width">
                  <TextBox placeholder="" maxlength="250" v-model="segundoApellido" />
                </div>
              </div>
              <div class="page__form-search-line">
                <div class="page__form-label">
                  <Label labelText="Estado" fontFamily="kronika" fontColor="#FFFFFF" />
                </div>
                <div class="full-width">
                  <DropDownList v-model="estado" v-bind:options="estadoOpciones" />
                </div>
              </div>
              <div class="full-width">
                <ButtonForm v-on:action-button-clicked="buscarTokenRefresh()"
                            labelText="BUSCAR"
                            :width=buttonFormWidth />
              </div>
            </div>
          </div>
          <div class="page__main-list-container-right">
            <div class="page__list-header-container">
              <div class="page__list-header-container-top">
                <div class="page__list-header-container-top-left">
                  <div class="page__list-flex-shrink">
                    <CheckBoxForm backgroundColor="#FFFFFF" v-model="chkSeleccionarTodo" v-on:action-checkbox-clicked="seleccionarTodo()" />
                  </div>
                  <div class="page__list-header-checkbox-label-container">
                    <span class="page__list-header-checkbox-label">Seleccionar todo</span>
                  </div>
                  <div class="page__list-flex-shrink page__list-header-margin-right">
                    <ButtonForm v-on:action-button-clicked="borrarTokenRefresh()"
                                labelText="BORRAR"
                                bdColor="#C00606"
                                bgColor="#FFFFFF"
                                fontColor="#C00606"
                                :width=buttonFormWidth />
                  </div>
                  <div class="page__list-flex-shrink">
                    <ButtonForm v-on:action-button-clicked="revocarTokenRefresh()"
                                labelText="REVOCAR"
                                bdColor="#C00606"
                                bgColor="#C00606"
                                fontColor="#FFFFFF"
                                :width=buttonFormWidth />
                  </div>
                </div>
                <div class="page__list-header-container-top-right">
                  <div class="page__list-paginator-container">
                    <span v-if="isVisiblePagination" v-for="page in paginationLinks">
                      <span v-if="page==currentPage">
                        <span class="page__list-paginator-link-selected">
                          {{ page }}
                        </span>
                      </span>
                      <span v-else class="page__list-paginator-link">
                        <span v-if="page!='...'" style="margin-left:20px;" @click="buscarTokenRefreshPaginated(page)">
                          {{ page }}
                        </span>
                        <span v-else style="margin-left:10px;" >
                          {{ page }}
                        </span>
                      </span>
                    </span>
                    <span v-else class="page__list-paginator-link">
                      &nbsp
                    </span>
                  </div>
                </div>
              </div>
              <div class="page__list-header-container-bottom">
                <canvas ref="canvasHeaderListBorder" class="page__list-canvas-border"></canvas>
              </div>
            </div>
            <div class="page__list-container">
              <div v-if="lstTokenRefresh.length>0">
                <div v-for="(objTokenRefresh, indexTokenRefresh) in lstTokenRefresh" :key="indexTokenRefresh">
                  <div :class="listStyle(lstTokenRefresh.length,indexTokenRefresh)">
                    <div class="page__list-item-container-left">
                      <div class="page__list-item-column-checkbox">
                        <CheckBoxForm backgroundColor="#FFFFFF" v-model="objTokenRefresh.selected" />
                      </div>
                      <div class="page__list-item-column-image">
                        <BackgroundImage v-if="objTokenRefresh.user.email==protectedPublicEmail" isBordered="S" :height=backgroundImageListWidth :width=backgroundImageListHeight positionHeight="center" isLocal="S" backgroundURL="img_public_user.jpg" style="border-radius:8px;" />
                        <BackgroundImage v-else isBordered="S" :height=backgroundImageListWidth :width=backgroundImageListHeight positionHeight="center" :backgroundURL="objTokenRefresh.user.rutaImagen" style="border-radius:8px;" />
                      </div>
                      <div class="page__list-item-column-label">
                        <div class="page__list-item-label-main color-FF5229">
                          {{ objTokenRefresh.clientIp }} <span v-if="objTokenRefresh.revoked=='1'" class="page__list-item-label-revoked color-FFFFFF background-color-C00606">REVOKED</span> <span v-if="objTokenRefresh.isExpired==true" class="page__list-item-label-revoked color-FFFFFF background-color-FF5229">EXPIRED</span>
                        </div>
                        <div class="page__list-item-label-main page__list-item-padding-bottom">
                          <div class="page__list-item-truncated-text-oneline" style="word-break: break-all;">
                            {{ objTokenRefresh.id }}
                          </div>
                        </div>
                        <div v-if="objTokenRefresh.user.email==protectedPublicEmail" class="page__list-item-label-mainsub page__list-item-padding-bottom color-C00606">
                          PROTECTED PUBLIC USER
                        </div>
                        <div v-else class="page__list-item-label-mainsub page__list-item-padding-bottom color-C00606">
                          {{ objTokenRefresh.user.completo }}
                        </div>
                        <div class="page__list-item-label-mainsub page__list-item-padding-bottom color-C00606">
                          {{ objTokenRefresh.user.email }}
                        </div>
                        <div class="page__list-item-label-mainsub color-6B6B6B">
                          Expira: {{ objTokenRefresh.expiresAt.fecha }} ({{ objTokenRefresh.expiresAt.hora }})
                        </div>
                      </div>
                    </div>
                    <div class="page__list-item-container-right">
                      <ButtonForm v-on:action-button-clicked="detalleTokenRefresh(objTokenRefresh.id)"
                                  labelText="VER DETALLE"
                                  bdColor="#FF5229"
                                  bgColor="#FFFFFF"
                                  fontColor="#FF5229"
                                  :width=buttonListWidth />
                    </div>
                  </div>
                  <div :class="listStyleMobile(lstTokenRefresh.length,indexTokenRefresh)">
                    <div class="page__list-item-container-mobile-left">
                      <CheckBoxForm backgroundColor="#FFFFFF" v-model="objTokenRefresh.selected" />
                    </div>
                    <div class="page__list-item-container-mobile-right">
                      <div class="page__list-item-column-image-mobile">
                        <BackgroundImage v-if="objTokenRefresh.user.email==protectedPublicEmail" isBordered="S" :height=backgroundImageListWidth :width=backgroundImageListHeight positionHeight="center" isLocal="S" backgroundURL="img_public_user.jpg" style="border-radius:8px;" />
                        <BackgroundImage v-else isBordered="S" :height=backgroundImageListWidth :width=backgroundImageListHeight positionHeight="center" :backgroundURL="objTokenRefresh.user.rutaImagen" style="border-radius:8px;" />
                      </div>
                      <div class="page__list-item-column-label-mobile">
                        <div class="page__list-item-label-main color-FF5229">
                          {{ objTokenRefresh.clientIp }} <span v-if="objTokenRefresh.revoked=='1'" class="page__list-item-label-revoked color-FFFFFF background-color-C00606">REVOKED</span> <span v-if="objTokenRefresh.isExpired==true" class="page__list-item-label-revoked color-FFFFFF background-color-FF5229">EXPIRED</span>
                        </div>
                        <div class="page__list-item-label-main page__list-item-padding-bottom">
                          <div class="page__list-item-truncated-text-oneline" style="word-break: break-all;">
                            {{ objTokenRefresh.id }}
                          </div>
                        </div>
                        <div v-if="objTokenRefresh.user.email==protectedPublicEmail" class="page__list-item-label-mainsub page__list-item-padding-bottom color-C00606">
                          PROTECTED PUBLIC USER
                        </div>
                        <div v-else class="page__list-item-label-mainsub page__list-item-padding-bottom color-C00606">
                          {{ objTokenRefresh.user.completo }}
                        </div>
                        <div class="page__list-item-label-mainsub page__list-item-padding-bottom color-C00606">
                          {{ objTokenRefresh.user.email }}
                        </div>
                        <div class="page__list-item-label-mainsub color-6B6B6B">
                          Expira: {{ objTokenRefresh.expiresAt.fecha }} ({{ objTokenRefresh.expiresAt.hora }})
                        </div>
                      </div>
                      <div class="page__list-item-column-options-mobile">
                        <ButtonForm v-on:action-button-clicked="detalleTokenRefresh(objTokenRefresh.id)"
                                    labelText="VER DETALLE"
                                    bdColor="#FF5229"
                                    bgColor="#FFFFFF"
                                    fontColor="#FF5229"
                                    :width=buttonListWidth />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="page__list-item-noregistros">
                /* NO PRESENTA REGISTROS DISPONIBLES */
              </div>
            </div>
            <div class="page__list-footer-container">
              <div class="page__list-footer-container-top">
                <canvas ref="canvasFooterListBorder" class="page__list-canvas-border"></canvas>
              </div>
              <div class="page__list-footer-container-bottom">
                <div class="page__list-paginator-container">
                    <span v-if="isVisiblePagination" v-for="page in paginationLinks">
                      <span v-if="page==currentPage">
                        <span class="page__list-paginator-link-selected">
                          {{ page }}
                        </span>
                      </span>
                      <span v-else class="page__list-paginator-link">
                        <span v-if="page!='...'" style="margin-left:20px;" @click="buscarTokenRefreshPaginated(page)">
                          {{ page }}
                        </span>
                        <span v-else style="margin-left:10px;" >
                          {{ page }}
                        </span>
                      </span>
                    </span>
                  <span v-else class="page__list-paginator-link">
                      &nbsp
                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal type="CONFIRM"
           :message="modal_borrar_message"
           :messageLoading="modal_borrar_messageLoading"
           :isVisible="modal_borrar_isVisible"
           :isLoading="modal_borrar_isLoading"
           :transition="modal_borrar_transition"
           :style="computedBorrarModalStyle"
           @aceptar="modalBorrarTokenRefresh"
           @cancelar="modalBorrarCancelar" />
    <Modal type="CONFIRM"
           :message="modal_revocar_message"
           :messageLoading="modal_revocar_messageLoading"
           :isVisible="modal_revocar_isVisible"
           :isLoading="modal_revocar_isLoading"
           :transition="modal_revocar_transition"
           :style="computedRevocarModalStyle"
           @aceptar="modalRevocarTokenRefresh"
           @cancelar="modalRevocarCancelar" />
    <ModalError :title="modalErrorTitle"
                :message="modalErrorMessage"
                :exception="modalErrorException"
                v-model="isModalErrorMessage"
                v-on:aceptar="modal__ErrorAceptar()" />
    <TokenRefreshDetalleModal :tokenRefresh="selectedTokenRefresh"
                             :isVisible="modal_isTokenRefreshDetalleVisible"
                             :isLoading="modal_isTokenRefreshDetalleLoading"
                             :transition="modal_tokenRefreshDetalleTransition"
                             :loadingMessage="modal_tokenRefreshDetalleLoadingMessage"
                             @close="modalTokenRefreshDetalleCerrar" />
    <TokenRefreshListarModal  :isVisible="modal_isTokenRefreshListarVisible"
                             :isLoading="modal_isTokenRefreshListarLoading"
                             :transition="modal_tokenRefreshListarTransition"
                             :loadingMessage="modal_tokenRefreshListarLoadingMessage"
                             :style="computedTokenRefreshListarModalStyle"
                             @close="modalTokenRefreshListarCerrar"
                             @buscar="modalTokenRefreshListarBuscar" />
  </div>
</template>

<script>
import moment from 'moment';
import SpriteAnimator from "@/components/SpriteAnimator";
import Button from "@/components/Button";
import ButtonForm from "@/components/ButtonForm";
import TextBox from "@/components/TextBox";
import CheckBoxForm from "@/components/CheckBoxForm";
import BackgroundImage from "@/components/BackgroundImage";
import Label from "@/components/Label";
import DropDownList from "@/components/DropDownList";
import Modal from "@/components/Modal";
import ModalError from "@/components/ModalError";
import TokenRefreshDetalleModal from "@/views/pages/admin/_backend/token/TokenRefreshDetalleModal";
import TokenRefreshListarModal from "@/views/pages/admin/_backend/token/TokenRefreshListarModal";

export default {
  name: "TokenRefreshListar",
  components: {
    SpriteAnimator,
    Button,
    ButtonForm,
    TextBox,
    CheckBoxForm,
    BackgroundImage,
    Label,
    DropDownList,
    Modal,
    ModalError,
    TokenRefreshDetalleModal,
    TokenRefreshListarModal
  },
  data() {
    return {
      estadoOpciones: [],
      selectedTokenRefresh: {},
      protectedPublicEmail: process.env.APITOKEN_USER,

      // MEDIA QUERIES
      headerSpriteWidth:0,
      buttonWidth:"0",
      buttonFormWidth:"0",
      buttonListWidth:"0",
      backgroundImageListWidth:"0",
      backgroundImageListHeight:"0",

      // VALIDATE
      mensajeError: '',
      contadorImagenes: 0,

      // FORM
      fechaInicio: '',
      fechaFin: '',
      email: '',
      nombre: '',
      primerApellido: '',
      segundoApellido: '',
      estado: '',

      // PAGINATION
      itemsPerPage: 1,
      currentPage: 1,
      lstTokenRefresh: [],
      paginationLinks: [],
      chkSeleccionarTodo: false,
      lstSeleccionarTodo: false,
      loadPagination: true,
      isVisiblePagination: false,

      // IMAGES
      isPageImagesLoad: true, // No hay imagenes locales
      isExternalImagesLoad: true, // No hay imagenes externas
      isMountFinished: false,

      // MODAL
      modal_borrar_message:'',
      modal_borrar_messageLoading:'',
      modal_borrar_isVisible:false,
      modal_borrar_isLoading:false,
      modal_borrar_isClosed:false,
      modal_borrar_transition:"",

      modal_revocar_message:'',
      modal_revocar_messageLoading:'',
      modal_revocar_isVisible:false,
      modal_revocar_isLoading:false,
      modal_revocar_isClosed:false,
      modal_revocar_transition:"",

      modal_isTokenRefreshDetalleVisible:false,
      modal_isTokenRefreshDetalleLoading:false,
      modal_tokenRefreshDetalleTransition:"",
      modal_tokenRefreshDetalleLoadingMessage:"",

      modal_isTokenRefreshListarVisible:false,
      modal_isTokenRefreshListarLoading:false,
      modal_isTokenRefreshListarClosed:false, // Aqui indicamos si cerramos de inmediato el modal
      modal_tokenRefreshListarTransition:"",
      modal_tokenRefreshListarLoadingMessage:"",

      // MODAL ERROR
      isModalErrorMessage:false,
      modalErrorTitle:'',
      modalErrorMessage:'',
      modalErrorException:'',
    };
  },
  created() {
    try {
      this.modal_transition = "";
      //this.fechaInicio = moment().startOf('month').format('YYYY-MM-DD');
      //this.fechaFin = moment().format('YYYY-MM-DD');
      window.addEventListener('resize', this.handleWindowResize);
      this.handleWindowResize();

    } catch (error) {
      console.log("[TokenRefreshListar.vue] created()::ERROR = "+error);
    }
  },
  async mounted() {
    try {
      // BROWSER BACK/FORWARD BUTTON
      window.popStateDetected = false
      window.addEventListener('popstate', () => { window.popStateDetected = true });

      window.addEventListener('resize', this.handleCanvasResize);
      this.handleCanvasResize();

      this.obtenerEstadoOpciones();
      await this.page__preloadImages();

      var objBusqueda = {};
      objBusqueda['currentPage'] = this.currentPage;
      objBusqueda['email'] = this.email;
      objBusqueda['nombre'] = this.nombre;
      objBusqueda['primerApellido'] = this.primerApellido;
      objBusqueda['segundoApellido'] = this.segundoApellido;
      objBusqueda['fechaInicio'] = this.fechaInicio;
      objBusqueda['fechaFin'] = this.fechaFin;
      objBusqueda['estado'] = this.estado;
      await this.listarTokenRefresh(objBusqueda,true);

      await this.$nextTick();
      this.isMountFinished = true;

    } catch (error) {
      this.$emit('show-loading', false);
      var tmpExcepcion = "[Index.vue] mounted()::ERROR >>> "+error;
      var strExcepcion = tmpExcepcion.replace('Error:', '');
      this.modal__ErrorRegistrarExcepcion(strExcepcion);
    }
  },
  computed: {
    computedBorrarModalStyle: function () {
      var objStyle = {};
      objStyle["visibility"] = "visible";

      if(this.modal_isClosed){
        objStyle["visibility"] = "hidden";
      }

      return objStyle;
    },
    computedRevocarModalStyle: function () {
      var objStyle = {};
      objStyle["visibility"] = "visible";

      if(this.modal_isClosed){
        objStyle["visibility"] = "hidden";
      }

      return objStyle;
    },
    computedTokenRefreshListarModalStyle: function () {
      var objStyle = {};
      objStyle["visibility"] = "visible";

      if(this.modal_isTokenRefreshListarClosed){
        objStyle["visibility"] = "hidden";
      }

      return objStyle;
    },

    canvasHeaderListBorder: function () {
      return this.$refs.canvasHeaderListBorder;
    },
    ctxHeaderListBorder: function () {
      return this.canvasHeaderListBorder.getContext('2d');
    },
    canvasFooterListBorder: function () {
      return this.$refs.canvasFooterListBorder;
    },
    ctxFooterListBorder: function () {
      return this.canvasFooterListBorder.getContext('2d');
    },
    page__isAllContentLoaded(){
      return this.isPageImagesLoad && this.isExternalImagesLoad && this.isMountFinished;
    },
  },
  watch: {
    page__isAllContentLoaded(newValue) {
      if(newValue){
        var that;
        that = this;
        setTimeout(function () {
          that.$emit('show-loading', false);
        }, 1500);
      }
    }
  },
  methods: {
    detalleTokenRefresh(idTokenRefresh){
      try {
        var indexTokenRefresh = this.lstTokenRefresh.findIndex(x => x.id ===idTokenRefresh);
        this.selectedTokenRefresh = this.lstTokenRefresh[indexTokenRefresh];
        this.modal_isTokenRefreshDetalleVisible = true;

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[TokenRefreshListar.vue] detalleTokenRefresh()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    async buscarTokenRefresh(){
      try {
        var objBusqueda = {};
        objBusqueda['currentPage'] = this.currentPage;
        objBusqueda['email'] = this.email;
        objBusqueda['nombre'] = this.nombre;
        objBusqueda['primerApellido'] = this.primerApellido;
        objBusqueda['segundoApellido'] = this.segundoApellido;
        objBusqueda['fechaInicio'] = this.fechaInicio;
        objBusqueda['fechaFin'] = this.fechaFin;
        objBusqueda['estado'] = this.estado;

        var {default: CommonHelper} = await import('@/helpers/common.helper');

        this.$emit('show-loading',true,"","RIGHT-FULL");
        await CommonHelper.delay(700);
        await this.listarTokenRefresh(objBusqueda,true);
        await CommonHelper.delay(700);
        this.$emit('show-loading',false,"","RIGHT-FULL");

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[TokenRefreshListar.vue] buscarTokenRefresh()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    async buscarTokenRefreshPaginated(page){
      try {
        var objBusqueda = {};
        objBusqueda['currentPage'] = page;
        objBusqueda['email'] = this.email;
        objBusqueda['nombre'] = this.nombre;
        objBusqueda['primerApellido'] = this.primerApellido;
        objBusqueda['segundoApellido'] = this.segundoApellido;
        objBusqueda['fechaInicio'] = this.fechaInicio;
        objBusqueda['fechaFin'] = this.fechaFin;
        objBusqueda['estado'] = this.estado;

        var {default: CommonHelper} = await import('@/helpers/common.helper');

        this.$emit('show-loading',true,"","RIGHT-FULL");
        await CommonHelper.delay(700);
        await this.listarTokenRefresh(objBusqueda,true);
        await CommonHelper.delay(700);
        this.$emit('show-loading',false,"","RIGHT-FULL");

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[TokenRefreshListar.vue] buscarTokenRefreshPaginated()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    buscarTokenRefreshModal(){
      try {
        this.modal_isTokenRefreshListarVisible=true;

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[TokenRefreshListar.vue] buscarTokenRefreshPaginated()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    async listarTokenRefresh(objBuscar,isInitialLoad=false){
      try {
        var objRequest = {}
        objRequest['currentPage']=objBuscar.currentPage;
        objRequest['email']=objBuscar.email;
        objRequest['nombre'] = this.nombre;
        objRequest['primerApellido'] = this.primerApellido;
        objRequest['segundoApellido'] = this.segundoApellido;
        objRequest['fechaInicio'] = objBuscar.fechaInicio;
        objRequest['fechaFin'] = objBuscar.fechaFin;
        objRequest['estado'] = this.estado;

        var {default: TokenLogic} = await import('@/logic/_backend/token.logic');
        var httpToken = await TokenLogic.listarTokenRefresh(objRequest);
        var httpTokenData = httpToken.data;
        var responseToken = httpToken.data.response;

        switch(httpTokenData.status) {
          case "OK":
            if(responseToken.currentPage=="" || responseToken.currentPage==null){
              this.currentPage = 1;
            } else {
              this.currentPage = responseToken.currentPage;
            }

            this.itemsPerPage = responseToken.itemsPerPage;
            this.lstTokenRefresh = responseToken.currentResults;
            this.paginationLinks = responseToken.paginationLinks;

            if(responseToken.paginationLinks.length>1){
              this.isVisiblePagination = true;
            } else {
              this.isVisiblePagination = false;
            }

            break;

          default:
            var error = httpTokenData.message;
            throw Error(error);
        }

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[TokenRefreshListar.vue] listarTokenRefresh()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    borrarTokenRefresh(){
      try {
        var arrTokenRefresh = [];
        this.lstTokenRefresh.forEach((objTokenRefresh, indexTokenRefresh) => {
          if(objTokenRefresh.selected){
            arrTokenRefresh.push(objTokenRefresh.id);
          }
        });

        if(arrTokenRefresh.length>0){
          this.modal_borrar_isLoading = false;
          this.modal_borrar_message = "¿Está seguro que desea borrar estos Refresh Tokens?";
          this.modal_borrar_transition = "RIGHT";
          this.modal_borrar_isClosed = false;
          this.modal_borrar_isVisible = true;
        }

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[TokenRefreshListar.vue] borrarTokenRefresh()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    revocarTokenRefresh(){
      try {
        var arrTokenRefresh = [];
        this.lstTokenRefresh.forEach((objTokenRefresh, indexTokenRefresh) => {
          if(objTokenRefresh.selected){
            arrTokenRefresh.push(objTokenRefresh.id);
          }
        });

        if(arrTokenRefresh.length>0){
          this.modal_revocar_isLoading = false;
          this.modal_revocar_message = "¿Está seguro que desea revocar estos Refresh Tokens?";
          this.modal_revocar_transition = "RIGHT";
          this.modal_revocar_isClosed = false;
          this.modal_revocar_isVisible = true;
        }

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[TokenRefreshListar.vue] revocarTokenRefresh()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    seleccionarTodo(){
      var isSelected = this.chkSeleccionarTodo;

      if(isSelected){
        this.lstSeleccionarTodo=false;
      } else {
        this.lstSeleccionarTodo=true;
      }

      var that;
      that = this;
      this.lstTokenRefresh.forEach((objTokenRefresh, indexTokenRefresh) => {
        if(that.lstSeleccionarTodo){
          objTokenRefresh.selected = true;
        } else {
          objTokenRefresh.selected = false;
        }
      });
    },


    // ===========
    //  LISTENERS
    // ===========

    handleWindowResize(){
      this.mediaQueries();
    },

    handleCanvasResize(){
      this.canvasResize();
    },


    // =======
    //  MODAL
    // =======

    /* -- BORRAR -- */

    async modalBorrarTokenRefresh(){
      this.modal_borrar_isLoading = true;
      this.modal_borrar_isVisible = true;
      this.modal_borrar_message = ""
      this.modal_borrar_messageLoading = "Borrando información. Un momento por favor."

      var arrTokenRefresh = [];
      this.lstTokenRefresh.forEach((objTokenRefresh, indexTokenRefresh) => {
        if(objTokenRefresh.selected){
          arrTokenRefresh.push(objTokenRefresh.id);
        }
      });

      var tokenRefreshObj = {};
      tokenRefreshObj['tokenRefresh'] = arrTokenRefresh;

      var {default: TokenLogic} = await import('@/logic/_backend/token.logic');
      var httpToken = await TokenLogic.borrarTokenRefresh(tokenRefreshObj);
      var httpTokenData = httpToken.data;
      var responseToken = httpToken.data.response;

      switch(httpTokenData.status) {
        case "OK":
          var objBusqueda = {};
          objBusqueda['currentPage'] = this.currentPage;
          objBusqueda['email'] = this.email;
          objBusqueda['nombre'] = this.nombre;
          objBusqueda['primerApellido'] = this.primerApellido;
          objBusqueda['segundoApellido'] = this.segundoApellido;
          objBusqueda['fechaInicio'] = this.fechaInicio;
          objBusqueda['fechaFin'] = this.fechaFin;
          objBusqueda['estado'] = this.estado;

          await this.listarTokenRefresh(objBusqueda,true);
          this.chkSeleccionarTodo = false;
          this.modal_borrar_isLoading = false;
          this.modal_borrar_isClosed = false;
          this.modal_borrar_isVisible = false;
          break;

        default:
          var objBusqueda = {};
          objBusqueda['currentPage'] = this.currentPage;
          objBusqueda['email'] = this.email;
          objBusqueda['nombre'] = this.nombre;
          objBusqueda['primerApellido'] = this.primerApellido;
          objBusqueda['segundoApellido'] = this.segundoApellido;
          objBusqueda['fechaInicio'] = this.fechaInicio;
          objBusqueda['fechaFin'] = this.fechaFin;
          objBusqueda['estado'] = this.estado;

          await this.listarTokenRefresh(objBusqueda,true);
          this.chkSeleccionarTodo = false;
          this.modal_borrar_isLoading = false;
          this.modal_borrar_isClosed = false;
          this.modal_borrar_isVisible = false;

          var error = httpTokenData.message;
          throw Error(error);
      }
    },
    modalBorrarCancelar(){
      this.modal_borrar_isLoading = false;
      this.modal_borrar_isClosed = false;
      this.modal_borrar_isVisible = false;
    },

    /* -- REVOCAR -- */

    async modalRevocarTokenRefresh(){
      this.modal_revocar_isLoading = true;
      this.modal_revocar_isVisible = true;
      this.modal_revocar_message = ""
      this.modal_revocar_messageLoading = "Revocando información. Un momento por favor."

      var arrTokenRefresh = [];
      this.lstTokenRefresh.forEach((objTokenRefresh, indexTokenRefresh) => {
        if(objTokenRefresh.selected){
          arrTokenRefresh.push(objTokenRefresh.id);
        }
      });

      var tokenRefreshObj = {};
      tokenRefreshObj['tokenRefresh'] = arrTokenRefresh;

      var {default: TokenLogic} = await import('@/logic/_backend/token.logic');
      var httpToken = await TokenLogic.revocarTokenRefresh(tokenRefreshObj);
      var httpTokenData = httpToken.data;
      var responseToken = httpToken.data.response;

      switch(httpTokenData.status) {
        case "OK":
          var objBusqueda = {};
          objBusqueda['currentPage'] = this.currentPage;
          objBusqueda['email'] = this.email;
          objBusqueda['nombre'] = this.nombre;
          objBusqueda['primerApellido'] = this.primerApellido;
          objBusqueda['segundoApellido'] = this.segundoApellido;
          objBusqueda['fechaInicio'] = this.fechaInicio;
          objBusqueda['fechaFin'] = this.fechaFin;
          objBusqueda['estado'] = this.estado;

          await this.listarTokenRefresh(objBusqueda,true);
          this.chkSeleccionarTodo = false;
          this.modal_revocar_isLoading = false;
          this.modal_revocar_isClosed = false;
          this.modal_revocar_isVisible = false;
          break;

        default:
          var objBusqueda = {};
          objBusqueda['currentPage'] = this.currentPage;
          objBusqueda['email'] = this.email;
          objBusqueda['nombre'] = this.nombre;
          objBusqueda['primerApellido'] = this.primerApellido;
          objBusqueda['segundoApellido'] = this.segundoApellido;
          objBusqueda['fechaInicio'] = this.fechaInicio;
          objBusqueda['fechaFin'] = this.fechaFin;
          objBusqueda['estado'] = this.estado;

          await this.listarTokenRefresh(objBusqueda,true);
          this.chkSeleccionarTodo = false;
          this.modal_revocar_isLoading = false;
          this.modal_revocar_isClosed = false;
          this.modal_revocar_isVisible = false;
          var error = httpTokenData.message;
          throw Error(error);
      }
    },
    modalRevocarCancelar(){
      this.modal_revocar_isLoading = false;
      this.modal_revocar_isClosed = false;
      this.modal_revocar_isVisible = false;
    },



    /* -- LISTAR -- */

    async modalTokenRefreshListarBuscar(objBusqueda){
      var _objBusqueda = {};
      _objBusqueda["currentPage"] = this.currentPage;
      _objBusqueda["email"] = objBusqueda["email"];
      _objBusqueda['nombre'] = objBusqueda["nombre"];
      _objBusqueda['primerApellido'] = objBusqueda["primerApellido"];
      _objBusqueda['segundoApellido'] = objBusqueda["segundoApellido"];
      _objBusqueda['fechaInicio'] = objBusqueda["fechaInicio"];
      _objBusqueda['fechaFin'] = objBusqueda["fechaFin"];
      _objBusqueda['estado'] = objBusqueda["estado"];

      this.email = objBusqueda["email"];
      this.nombre = objBusqueda["nombre"];
      this.primerApellido = objBusqueda["primerApellido"];
      this.segundoApellido = objBusqueda["segundoApellido"];
      this.fechaInicio = objBusqueda["fechaInicio"];
      this.fechaFin = objBusqueda["fechaFin"];
      this.estado = objBusqueda["estado"];

      this.modal_tokenRefreshListarLoadingMessage = "Buscando información. Un momento por favor",
      this.modal_isTokenRefreshListarLoading = true;
      await this.listarTokenRefresh(_objBusqueda,true);
      this.modal_isTokenRefreshListarVisible = false;
      this.modal_isTokenRefreshListarLoading = false;
    },
    modalTokenRefreshListarCerrar(){
      this.modal_isTokenRefreshListarVisible = false;
    },


    /* -- DETALLE -- */

    modalTokenRefreshDetalleCerrar(){
      this.modal_isTokenRefreshDetalleVisible = false;
    },


    // =============
    //  MODAL ERROR
    // =============

    modal__ErrorRegistrarExcepcion(excepcion){
      this.modalErrorTitle = "LO SENTIMOS";
      this.modalErrorMessage = "Se produjo un error al realizar la operación. Por favor intente más tarde.";
      this.modalErrorException = excepcion;
      this.isModalErrorMessage = true;
    },
    modal__ErrorAceptar(){
      this.isModalErrorMessage=false;
    },


    // =================
    // PRIVATE FUNCTIONS
    // =================

    obtenerEstadoOpciones(){
      try {
        this.duracionURLOpciones = [];

        var optionObj = {};
        optionObj['id'] = "";
        optionObj['nombre'] = "Seleccione opción";
        this.estadoOpciones.push(optionObj);

        var optionObj = {};
        optionObj['id'] = "ACT";
        optionObj['nombre'] = "Activo";
        this.estadoOpciones.push(optionObj);

        var optionObj = {};
        optionObj['id'] = "EXP";
        optionObj['nombre'] = "Expirado";
        this.estadoOpciones.push(optionObj);

        var optionObj = {};
        optionObj['id'] = "REV";
        optionObj['nombre'] = "Revocado";
        this.estadoOpciones.push(optionObj);

      } catch (error) {
        throw Error("[TokenRefreshListar.vue] obtenerEstadoOpciones()::ERROR = "+error);
      }
    },

    listStyle(length,index){
      if(index == length - 1){
        return "page__list-item-container margin-bottom0";
      }

      return "page__list-item-container";
    },
    listStyleMobile(length,index){
      if(index == length - 1){
        return "page__list-item-container-mobile margin-bottom0";
      }

      return "page__list-item-container-mobile"
    },

    mediaQueries() {
      var _headerSpriteWidth = 0;
      var _buttonWidth = 0;
      var _buttonFormWidth = 0;
      var _buttonListWidth = 0;
      var _backgroundImageListWidth = 0;
      var _backgroundImageListHeight = 0;

      const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
      const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );
      const matchMediaLarge = window.matchMedia( "(min-width: 64em)" );

      if (matchMediaSmall.matches) {
        _headerSpriteWidth = 120;
        _buttonWidth = 120;
        _buttonFormWidth = 100;
        _buttonListWidth = 150;
        _backgroundImageListWidth = 120;
        _backgroundImageListHeight = 120;
      }

      if (matchMediaMedium.matches) {
        _headerSpriteWidth = 120;
        _buttonWidth = 120;
        _buttonFormWidth = 100;
        _buttonListWidth = 150;
        _backgroundImageListWidth = 150;
        _backgroundImageListHeight = 150;
      }

      if (matchMediaLarge.matches) {
        _headerSpriteWidth = 120;
        _buttonWidth = 120;
        _buttonFormWidth = 100;
        _buttonListWidth = 150;
        _backgroundImageListWidth = 80;
        _backgroundImageListHeight = 80;
      }

      this.headerSpriteWidth = _headerSpriteWidth;
      this.buttonWidth = _buttonWidth+"px";
      this.buttonFormWidth = _buttonFormWidth+"px";
      this.buttonListWidth = _buttonListWidth+"px";
      this.backgroundImageListWidth = _backgroundImageListWidth+"px";
      this.backgroundImageListHeight = _backgroundImageListHeight+"px";
    },

    canvasResize(){
      var canvasListBorderWidth = this.$refs.canvasHeaderListBorder.width;
      var canvasListBorderHeight = this.$refs.canvasHeaderListBorder.height;

      const border_extraRadius = canvasListBorderHeight*6;
      const border_lineWidth = 35;
      const border_extralineWidth = border_lineWidth*2;
      const border_X = canvasListBorderWidth / 2;
      const border_Y = canvasListBorderHeight+(border_extralineWidth+border_extraRadius);
      const border_radius = (canvasListBorderWidth / 2)+(border_extraRadius);

      this.ctxHeaderListBorder.beginPath();
      this.ctxHeaderListBorder.arc(border_X, border_Y, border_radius, 0, 2 * Math.PI, true);
      this.ctxHeaderListBorder.lineWidth = border_lineWidth;
      this.ctxHeaderListBorder.strokeStyle = "black";
      this.ctxHeaderListBorder.lineCap = 'round';
      this.ctxHeaderListBorder.lineJoin = 'round'
      this.ctxHeaderListBorder.stroke();
      this.ctxHeaderListBorder.closePath();

      this.ctxFooterListBorder.beginPath();
      this.ctxFooterListBorder.arc(border_X, border_Y, border_radius, 0, 2 * Math.PI, true);
      this.ctxFooterListBorder.lineWidth = border_lineWidth;
      this.ctxFooterListBorder.strokeStyle = "black";
      this.ctxFooterListBorder.lineCap = 'round';
      this.ctxFooterListBorder.lineJoin = 'round'
      this.ctxFooterListBorder.stroke();
      this.ctxFooterListBorder.closePath();
    },

    async page__preloadImages() {
      var {default: ImageHelper} = await import('@/helpers/image.helper');

      this.isPageImagesLoad = true;
    },
  },
  beforeRouteLeave (to, from, next) {
    if(window.popStateDetected){
      // HACK: Se mostrará el LOADING siembre que se abandone la página. Esto nos
      // permitirá abordar los casos en que se haga BACK/FORWARD en el browser
      this.$emit('show-loading', true);
    }
    next();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleCanvasResize);
    window.removeEventListener('popstate', () => { window.popStateDetected = true });
  }
};
</script>

<style scoped>

/* Small only */
@media screen and (max-width: 39.9375em) {
  #page__search-div{
    display:none;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  #page__search-div{
    display:none;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  #page__search-div{
    display:block;
  }
}
</style>