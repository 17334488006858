<template>
  <div class="textBox__border" :style="computedBorderColor">
    <span v-if="maxlength>0">
      <span v-if="type=='number'">
        <input  type="number"
                :class="computedInputClass"
                :style="computedFontColor"
                v-bind:value="value"
                v-on:input="updateValue($event.target.value)"
                :placeholder="computedPlaceholder"
                :maxlength="maxlength"
                @keypress="typeNumber"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        />
      </span>
      <span v-else>
        <span v-if="type=='password'">
          <input  type="password"
                  :class="computedInputClass"
                  :style="computedFontColor"
                  v-bind:value="value"
                  v-on:input="updateValue($event.target.value)"
                  :placeholder="computedPlaceholder"
                  :maxlength="maxlength"
                  @keypress="typeText"
          />
        </span>
        <span v-else>
          <span v-if="type=='date'">
            <input  type="date"
                    :class="computedInputClass"
                    :style="computedFontColor"
                    v-bind:value="value"
                    v-on:input="updateValue($event.target.value)"
                    :placeholder="computedPlaceholder"
                    @keypress="typeNumber"
            />
          </span>
          <span v-else>
            <input  :class="computedInputClass"
                    :style="computedFontColor"
                    v-bind:value="value"
                    v-on:input="updateValue($event.target.value)"
                    :placeholder="computedPlaceholder"
                    :maxlength="maxlength"
                    @keypress="typeText"
            />
          </span>
        </span>
      </span>
    </span>
    <span v-else>
      <span v-if="type=='number'">
        <input  type="number"
                :class="computedInputClass"
                :style="computedFontColor"
                v-bind:value="value"
                v-on:input="updateValue($event.target.value)"
                :placeholder="computedPlaceholder"
                @keypress="typeNumber"
        />
      </span>
      <span v-else>
        <span v-if="type=='password'">
          <input  type="password"
                  :class="computedInputClass"
                  :style="computedFontColor"
                  v-bind:value="value"
                  v-on:input="updateValue($event.target.value)"
                  :placeholder="computedPlaceholder"
                  @keypress="typeText"
          />
        </span>
        <span v-else>
          <span v-if="type=='date'">
            <input  type="date"
                    :class="computedInputClass"
                    :style="computedFontColor"
                    v-bind:value="value"
                    v-on:input="updateValue($event.target.value)"
                    :placeholder="computedPlaceholder"
                    @keypress="typeNumber"
            />
          </span>
          <span v-else>
            <input  :class="computedInputClass"
                    :style="computedFontColor"
                    v-bind:value="value"
                    v-on:input="updateValue($event.target.value)"
                    :placeholder="computedPlaceholder"
                    @keypress="typeText"
            />
          </span>
        </span>
      </span>
    </span>
  </div>
</template>

<script>
  export default {
    name: "TextBox",
    props: {
      value: {
        type: [Number,String],
        default: "",
      },
      type: {
        type: String,
        default: "text",
      },
      placeholder: {
        type: String,
        default: "",
      },
      maxlength: {
        type: [String,Number],
        default: "",
      },
      bgColor: {
        type: String,
        default: "#FFFFFF",
      },
      bdColorError: {
        type: String,
        default: '#BD0000'
      },
      fontColor: {
        type: String,
        default: "#5C5C5C",
      },
      isError:{
          type: Boolean,
          default: false,
      }
    },
    async mounted() {
      try {
        await this.$nextTick();

      } catch (error) {
        console.log("[TextBox.vue] mounted()::ERROR = "+error);
      }
    },
    computed: {
      computedType(){
          return this.$props.type;
      },
      computedInputClass() {
        var isError = this.$props.isError;

        if(isError){
          return 'textBox__input-error';
        }

        return 'textBox__input';
      },
      computedBorderColor() {
        var isError = this.$props.isError;
        var bgColor = this.$props.bgColor;
        var bdColorError = this.$props.bdColorError;

        var objStyle = {};
        objStyle['background-color'] = bgColor;

        if(isError){
          objStyle['border'] = '2px solid '+bdColorError;
        } else {
          objStyle['border'] = '2px solid '+bgColor;
        }

        return objStyle;
      },
      computedFontColor() {
        var fontColor = this.$props.fontColor;

        var objStyle = {};
        objStyle['color'] = fontColor;

        return objStyle;
      },
      computedPlaceholder() {
        var placeholder = this.$props.placeholder;

        return placeholder;
      }
    },
    methods: {
      typeText: function ($event) {
        //console.log($event.keyCode);
      },
      typeNumber: function ($event) {
        //console.log($event.keyCode);
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          $event.preventDefault();
        }
      },
      updateValue: function (value) {
        this.$emit('input', value)
      }
    }
  };
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.textBox__input{
  font-family: 'roboto-regular';
  width:100%;
  border:0;
  background-color: transparent;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  padding:0;
  margin:0;
}

.textBox__input::placeholder {
  color: #626f7f;
}

.textBox__input:focus {
  outline: none;
  border:0;
  background-color:transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.textBox__input:disabled {
  background: transparent;
}

.textBox__input-error{
  font-family: 'roboto-regular';
  width:100%;
  border:0;
  background-color: transparent;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  padding:0;
  margin:0;
}

.textBox__input-error::placeholder {
  /*color: #BD0000;*/
}

.textBox__input-error:focus {
  outline: none;
  border:0;
  background-color:transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.textBox__input-error:disabled {
  background: transparent;
}


/* Small only */
@media screen and (max-width: 39.9375em) {
  .textBox__border{
    border-radius:8px;
    padding:5px 10px 5px 10px;
  }

  .textBox__input{
    height:1.6em;
    font-size:0.8em;
    line-height:1em;
  }

  .textBox__input-error{
    height:1.6em;
    font-size:0.8em;
    line-height:1em;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .textBox__border{
    border-radius:8px;
    padding:5px 10px 5px 10px;
  }

  .textBox__input{
    height:1.6em;
    font-size:0.85em;
    line-height:1em;
  }

  .textBox__input-error{
    height:1.6em;
    font-size:0.85em;
    line-height:1em;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  .textBox__border{
    border-radius:8px;
    padding:5px 12px 5px 12px;
  }

  .textBox__input{
    height:1.6em;
    font-size:0.85em;
    line-height:1em;
  }

  .textBox__input-error{
    height:1.6em;
    font-size:0.85em;
    line-height:1em;
  }
}

</style>
