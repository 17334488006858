import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import notFoundRoute from '@/router/route/notFound.route';
import publicRoute from '@/router/route/public.route';
import adminRoute from '@/router/route/admin.route';

const routes = [
    ...publicRoute,
    ...adminRoute,
    ...notFoundRoute,
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.NODE_ENV === 'production'
        ? ''
        : '',
    routes,
})

router.afterEach(to => {
    if (to.meta && to.meta.bgImage) {
        document.body.style.backgroundRepeat = "repeat";
        document.body.style.backgroundPosition = "top right";
        document.body.style.backgroundImage = `url(${to.meta.bgImage})`;
        document.body.style.backgroundColor = "#FF5229";
    } else {
        document.body.style.backgroundRepeat = "";
        document.body.style.backgroundPosition = "";
        document.body.style.backgroundImage = "";
        document.body.style.backgroundColor = "#FFFFFF";
    }
});

export default router