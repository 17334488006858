<template>
    <div class="footer__container">
      <canvas ref="canvasFooter" class="footer__canvas-div"></canvas>
      <div class="footer__content-div">
        <img class="footer__poweredby-logo" src='@/assets/images/poweredby_grey.png' />
      </div>
    </div>
</template>

<style scoped>
  .footer__canvas-div{
    position:absolute;
    z-index:1;
    width:100%;
    margin:0;
    padding:0;
  }

  .footer__content-div{
    position:absolute;
    z-index:2;
    width:100%;
    margin:0;
    padding:0;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:flex-end;
  }

  .footer__container{
    position:relative;
    width:100%;
  }

  /* Small only */
  @media screen and (max-width: 39.9375em) {
    .footer__canvas-div{
      height:80px;
    }

    .footer__content-div{
      height:80px;
    }

    .footer__container{
      height:80px;
    }

    .footer__poweredby-logo{
      width:150px;
      margin-bottom:18px;
    }
  }

  /* Medium only */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .footer__canvas-div{
      height:90px;
    }

    .footer__content-div{
      height:90px;
    }

    .footer__container{
      height:90px;
    }

    .footer__poweredby-logo{
      width:170px;
      margin-bottom:18px;
    }
  }

  /* Large and up */
  @media screen and (min-width: 64em) {
    .footer__canvas-div{
      height:100px;
    }

    .footer__content-div{
      height:100px;
    }

    .footer__container{
      height:100px;
    }

    .footer__poweredby-logo{
      width:180px;
      margin-bottom:18px;
    }
  }
</style>

<script>
  export default {
    name: "Footer",
    props: {},
    async mounted() {
      try {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        await this.$nextTick();

      } catch (error) {
        console.log("[Footer.vue] mounted()::ERROR = "+error);
      }
    },
    computed: {
      canvas: function () {
        return this.$refs.canvasFooter;
      },
      ctx: function () {
       return this.canvas.getContext('2d');
      }
    },
    methods: {
      handleResize: function () {
        var canvasFooterWidth = this.$refs.canvasFooter.width;
        var canvasFooterHeight = this.$refs.canvasFooter.height;

        const circle_extraRadius = canvasFooterHeight*0.7;
        const circle_lineWidth = 5;
        const circle_extralineWidth = circle_lineWidth*1;
        const circle_X = canvasFooterWidth / 2;
        const circle_Y = canvasFooterHeight+(circle_extralineWidth+circle_extraRadius);
        const circle_radius = (canvasFooterWidth / 2)+(circle_extraRadius);

        this.ctx.beginPath();
        this.ctx.arc(circle_X, circle_Y, circle_radius, 0, 2 * Math.PI, true);
        this.ctx.lineWidth = circle_lineWidth;
        this.ctx.fillStyle = '#FFF';
        this.ctx.strokeStyle = "white";
        this.ctx.lineCap = 'round';
        this.ctx.lineJoin = 'round'
        this.ctx.fill();
        this.ctx.stroke();
        this.ctx.closePath();

        // ======================

        const border_extraRadius = canvasFooterHeight*3;
        const border_lineWidth = 5;
        const border_extralineWidth = border_lineWidth*4;
        const border_X = canvasFooterWidth / 2;
        const border_Y = canvasFooterHeight+(border_extralineWidth+border_extraRadius);
        const border_radius = (canvasFooterWidth / 2)+(border_extraRadius);

        this.ctx.beginPath();
        this.ctx.arc(border_X, border_Y, border_radius, 0, 2 * Math.PI, true);
        this.ctx.lineWidth = border_lineWidth;
        this.ctx.strokeStyle = "black";
        this.ctx.lineCap = 'round';
        this.ctx.lineJoin = 'round'
        this.ctx.stroke();
        this.ctx.closePath();
      },
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    }
  };
</script>

