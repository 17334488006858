import bgImage from "@/assets/images/bg.png";
import SessionLayout from "@/components/layout/session/SessionLayout";
import ServerlogListar from '@/views/pages/admin/_backend/serverlog/ServerlogListar';

const adminBackendServerLogRoutes = [
    {
        path: "/admin/backend/serverlog/listar",
        name: "session_backend_serverlog_listar",
        component: ServerlogListar,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
]

export default adminBackendServerLogRoutes;