import bgImage from "@/assets/images/bg.png";
import SessionLayout from "@/components/layout/session/SessionLayout";
import ClienteListar from '@/views/pages/admin/cliente/ClienteListar';
import ClienteCrear from '@/views/pages/admin/cliente/ClienteCrear';
import ClienteEditar from '@/views/pages/admin/cliente/ClienteEditar';

const adminClienteRoutes = [
    {
        path: "/admin/cliente/listar",
        name: "session_cliente_listar",
        component: ClienteListar,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
    {
        path: "/admin/cliente/crear",
        name: "session_cliente_crear",
        component: ClienteCrear,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
    {
        path: "/admin/cliente/editar/:idCliente",
        name: "session_cliente_editar",
        component: ClienteEditar,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
]

export default adminClienteRoutes