<template>
  <div id="sidebarContainer" class="sidebar__container">
    <canvas ref="canvasSidebar" class="sidebar__canvas-div"></canvas>
    <div class="sidebar__content-div">
      <div class="sidebar__menu-container-top">
        <div class="sessionLayout__logo-container">
          <img class="sessionLayout__logo" src='@/assets/images/logo_web_nobg.png' />
        </div>
        <div class="sessionLayout__empresa-container">
          <span class="sessionLayout__empresa">/* BIOTRANSFER */</span>
        </div>
      </div>
      <div class="sidebar__menu-container-middle">
        <accordion>
          <accordion-item v-for='(SessionMenuOption, SessionMenuOptionIndex) in this.sessionMenuOptions'
                          :key="SessionMenuOptionIndex"
                          :selectedPaths="getValidModulePaths(SessionMenuOption.module)"
                          v-if="isValidProfile(SessionMenuOption.profiles)"
                          class="sessionLayout__padding-bottom20">
            <template slot="accordion-trigger">
              <div class="sessionLayout__menu-container">
                <div class="sessionLayout__menu-icon">
                  <SessionMenuIcon isLocal="S" :height=backgroundImageIconWidth :width=backgroundImageIconHeight positionHeight="center" :backgroundURL="SessionMenuOption.menuIcon" />
                </div>
                <div class="sessionLayout__menu-content">
                  <span class="sessionLayout__menu-label">{{ SessionMenuOption.module.toUpperCase() }}</span>
                </div>
              </div>
            </template>
            <template v-for='objMenuOption in SessionMenuOption.menuOptions' slot="accordion-content">
              <div class="sessionLayout__menu-container sessionLayout__menu-padding-top5">
                <div class="sessionLayout__menu-icon"></div>
                <div class="sessionLayout__menu-content">
                  <div v-if="validateActualPath(SessionMenuOption.module,objMenuOption.menuOption)">
                    <span class="sessionLayout__menu-sublabel-selected">{{ objMenuOption.menuOptionName }}</span>
                  </div>
                  <div v-else>
                    <span class="sessionLayout__menu-sublabel" @click="goTo(objMenuOption.menuOption)">{{ objMenuOption.menuOptionName }}</span>
                  </div>
                </div>
              </div>
            </template>
          </accordion-item>
        </accordion>
      </div>
      <div class="sidebar__menu-container-bottom">
        <div class="sidebar__menu-container-bottom-top">
          <div class="sessionLayout__full-width">
            <div class="sessionLayout__menu-container">
              <div class="sessionLayout__menu-icon">
                <img class="sessionLayout__menu-icon" src='@/assets/images/menu/ico_menu_logout.png' />
              </div>
              <div class="sessionLayout__menu-content">
                <span class="sessionLayout__menu-label" style="cursor:pointer;" @click="logout()">CERRAR SESIÓN</span>
              </div>
            </div>
          </div>
        </div>
        <div class="sidebar__menu-container-bottom-bottom">
          <div class="sessionLayout__full-width sessionLayout__text-align-center">
            <img class="sidebar__poweredby-logo" src='@/assets/images/poweredby_grey.png' />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Accordion from "@/components/custom/accordion/Accordion";
  import AccordionItem from "@/components/custom/accordion/AccordionItem";
  import SessionMenuIcon from "@/components/layout/session/SessionMenuIcon";
  import SessionMenuOptions from "../layout/session/SessionMenuOptions.json";

  export default {
    name: "MobileMenu",
    components: {
      Accordion,
      AccordionItem,
      SessionMenuIcon,
    },
    props: {
      backgroundColor: {
        type: String,
        default: "#FFFFFF",
      },
      orientation: {
        type: String,
        default: "right",
      },
      width: {
        type: String,
        default: "300px",
      },
    },
    data() {
      return {
        sessionMenuOptions: SessionMenuOptions,
        objUsuario: {},
        menuPaths: [],

        // MEDIA QUERIES
        backgroundImageIconWidth:"0",
        backgroundImageIconHeight:"0",
      };
    },
    created(){
      this.menuPaths = this.getMenuPaths();
      this.objUsuario = this.$store.getters['authSessionStore/getUser'];
      window.addEventListener('resize', this.handleWindowResize);
      this.handleWindowResize();
    },
    async mounted() {
      try {
        window.addEventListener('resize', this.handleCanvasResize);
        this.handleCanvasResize();
        await this.$nextTick();

      } catch (error) {
        console.log("[MobileMenu.vue] mounted()::ERROR = "+error);
      }
    },
    computed: {
      canvas: function () {
        return this.$refs.canvasSidebar;
      },
      ctx: function () {
        return this.canvas.getContext('2d');
      }
    },
    methods: {
      showLoading(value,text='',transition='LEFT'){
        this.$emit('show-loading', true,text,transition);
      },

      /* ===========
          LISTENERS
         =========== */

      handleWindowResize(){
        this.mediaQueries();
      },

      handleCanvasResize(){
        this.canvasResize();
      },

      /* ==============
          SIDEBAR MENU
         ============== */

      getValidModulePaths(moduleName) {
        var resultPaths = [];
        for(const objMenuPath of this.menuPaths) {
          var strModuleName = objMenuPath.module;
          if(strModuleName===moduleName){
            for(const objMenuOption of objMenuPath.menuOptions) {
              resultPaths.push(objMenuOption.paths);
            }
          }
        }

        return resultPaths;
      },

      getValidMenuPaths(moduleName,menuName) {
        for(const objMenuPath of this.menuPaths) {
          var strModuleName = objMenuPath.module;
          if(strModuleName===moduleName){
            for(const objMenuOption of objMenuPath.menuOptions) {
              var strMenuName = objMenuOption.menuOption;
              if(strMenuName===menuName){
                return objMenuOption.paths;
              }
            }
          }
        }

        return [];
      },

      validateActualPath(moduleName,menuName){
        var result = false;
        var arrPath = this.getValidMenuPaths(moduleName,menuName);

        if(arrPath!=null){
          var cont = 0;

          if(arrPath!=null){
            arrPath.forEach((objPath, indexPath) => {
              var isInPath = this.$route.path.match(objPath);
              if(isInPath!=null){
                cont = cont + 1;
              }
            });
          }

          if(cont>0){
            result = true;
          }
        }

        return result;
      },

      goTo(menuName){
        var nextRoute = this.getNameRouteByMenuName(menuName);

        if(nextRoute!=''){
          this.$emit('show-loading', true);
          this.$router.push({name: nextRoute}, () => {
          });
        }
      },

      logout(){
        this.$emit('show-loading', true, "Cerrando sesión. Un momento por favor");
        this.$store.dispatch("authSessionStore/logOut");

        var that;
        that = this;
        setTimeout(function () {
          var nextRoute = 'session_index';
          that.$router.replace({name: nextRoute}, () => {
          });
        }, 800);
      },


      // =================
      // PRIVATE FUNCTIONS
      // =================

      mediaQueries() {
        var _backgroundImageIconWidth = 0;
        var _backgroundImageIconHeight = 0;

        const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
        const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );
        const matchMediaLarge = window.matchMedia( "(min-width: 64em)" );

        if (matchMediaSmall.matches) {
          _backgroundImageIconWidth = 20;
          _backgroundImageIconHeight = 20;
        }

        if (matchMediaMedium.matches) {
          _backgroundImageIconWidth = 20;
          _backgroundImageIconHeight = 20;
        }

        if (matchMediaLarge.matches) {
          _backgroundImageIconWidth = 20;
          _backgroundImageIconHeight = 20;
        }

        this.backgroundImageIconWidth = _backgroundImageIconWidth+"px";
        this.backgroundImageIconHeight = _backgroundImageIconHeight+"px";
      },

      canvasResize(){
        var sidebarContainerWidth = document.getElementById('sidebarContainer').offsetWidth;
        var sidebarContainerHeight = document.getElementById('sidebarContainer').offsetHeight;

        var canvasSidebar = this.$refs.canvasSidebar;
        canvasSidebar.width = sidebarContainerWidth;
        canvasSidebar.height = sidebarContainerHeight;

        var canvasSidebarWidth = canvasSidebar.width;
        var canvasSidebarHeight = canvasSidebar.height;

        var circle_coefficient = 0;

        if (window.matchMedia("(min-height:300px)").matches) {
          circle_coefficient = 0.010

          if (window.matchMedia("(min-height:600px)").matches) {
            circle_coefficient = 0.013

            if (window.matchMedia("(min-height:900px)").matches) {
              circle_coefficient = 0.016

              if (window.matchMedia("(min-height:1200px)").matches) {
                circle_coefficient = 0.018
              }
            }
          }
        }

        const circle_radiusMultiplier = canvasSidebarHeight*circle_coefficient;
        const circle_lineWidth = 5;
        const circle_extraX = 25;
        const circle_extraRadius = canvasSidebarWidth*(circle_radiusMultiplier-2);
        const circle_extralineWidth = circle_lineWidth*6;

        const circle_X = -(canvasSidebarWidth+(circle_extralineWidth+circle_extraRadius))+circle_extraX;
        const circle_Y = canvasSidebarHeight / 2;
        const circle_radius = canvasSidebarWidth*circle_radiusMultiplier;

        this.ctx.beginPath();
        this.ctx.arc(circle_X, circle_Y, circle_radius, 0, 2 * Math.PI, true);
        this.ctx.lineWidth = circle_lineWidth;
        this.ctx.fillStyle = '#FFF';
        this.ctx.strokeStyle = "white";
        this.ctx.lineCap = 'round';
        this.ctx.lineJoin = 'round'
        this.ctx.fill();
        this.ctx.stroke();
        this.ctx.closePath();

        // ======================

        var border_coefficient = 0;

        if (window.matchMedia("(min-height:10px)").matches) {
          border_coefficient = 0.033

          if (window.matchMedia("(min-height:600px)").matches) {
            border_coefficient = 0.029

            if (window.matchMedia("(min-height:800px)").matches) {
              border_coefficient = 0.033

              if (window.matchMedia("(min-height:1200px)").matches) {
                border_coefficient = 0.04
              }
            }
          }
        }

        const border_radiusMultiplier = canvasSidebarHeight*border_coefficient;
        const border_lineWidth = 4;
        const border_extraX = 10;
        const border_extraRadius = canvasSidebarWidth*(border_radiusMultiplier-2);
        const border_extralineWidth = border_lineWidth*6;

        const border_X = -(canvasSidebarWidth+(border_extralineWidth+border_extraRadius))+border_extraX;
        const border_Y = canvasSidebarHeight / 2;
        const border_radius = canvasSidebarWidth*border_radiusMultiplier;

        this.ctx.beginPath();
        this.ctx.arc(border_X, border_Y, border_radius, 0, 2 * Math.PI);
        this.ctx.lineWidth = border_lineWidth;
        this.ctx.strokeStyle = "black";
        this.ctx.lineCap = 'round';
        this.ctx.lineJoin = 'round'
        this.ctx.stroke();
        this.ctx.closePath();
      },


      // =================
      // PRIVATE FUNCTIONS
      // =================

      getNameRouteByMenuName(menuName) {
        var nameRoute = '';

        var moduleGroup = this.menuPaths.filter(e => e.menuOptions.filter(c => c.menuOption === menuName)[0])[0];
        var menuOption = moduleGroup ? moduleGroup.menuOptions.filter(c => c.menuOption === menuName)[0] : null;

        if(menuOption!=null){
          nameRoute = menuOption.nameRoute;
        }

        return nameRoute;
      },

      getMenuPaths(){
        var arrModules = [];

        SessionMenuOptions.forEach((_menu, _indexMenu) => {
          var arrMenuOption = [];
          _menu.menuOptions.forEach((_menuOption, _indexMenuOption) => {
            var _arrPaths = [];
            _menuOption.paths.forEach((_menuOptionPath, _indexMenuOptionPath) => {
              _arrPaths.push(_menuOptionPath);
            });

            var objMenuOption = {};
            objMenuOption.menuOption = _menuOption.menuOption;
            objMenuOption.nameRoute = _menuOption.nameRoute;
            objMenuOption.paths = _arrPaths;

            arrMenuOption.push(objMenuOption);
          });

          var objModule = {};
          objModule.module = _menu.module;
          objModule.menuOptions = arrMenuOption;

          arrModules.push(objModule);
        });

        return arrModules;

      },

      isValidProfile(profiles){
        var idPerfil = this.objUsuario.perfil.id;

        if(profiles.includes(idPerfil)){
          return true;
        }

        return false;
      }
    }
  }
</script>

<style scoped>
.sessionLayout__full-width{
  width:100%;
}

.sessionLayout__text-align-center{
  text-align:center;
}

.sessionLayout__menu-container{
  display:flex;
  flex-direction:row;
  width:100%;
}
.sessionLayout__menu-icon{
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
}

.sessionLayout__menu-content{
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  flex:1;
}

.sessionLayout__menu-label{
  font-family:"helvetica-rounded";
  color:#000000;
}

.sessionLayout__menu-sublabel{
  font-family:"helvetica-rounded";
  color:#FF5229;
}

.sessionLayout__menu-sublabel-selected{
  font-family:"helvetica-rounded";
  text-decoration:underline;
  color:#A8A8A8;
}

.sessionLayout__menu-padding-top5{
  padding-top:5px;
}
.sessionLayout__padding-bottom20{
  padding-bottom:20px;
}

.sessionLayout__logo-container{
  width:100%;
  text-align:center;
}
.sessionLayout__empresa-container{
  width:100%;
  text-align:center;
}
.sessionLayout__empresa{
  font-family:'helvetica-rounded';
  color:#FFBA5F;
}

/* SIDEBAR */

.sidebar__canvas-div{
  position:absolute;
  z-index:2;
  width:100%;
  height:100%;
  margin:0;
  padding:0;
}
.sidebar__content-div{
  position:absolute;
  z-index:3;
  height:100%;
  margin:0;
  padding:0;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
}
.sidebar__container{
  position:relative;
  width:100%;
  height:100%;
}
.sidebar__menu-container-top{
  width:100%;
}
.sidebar__menu-container-middle{
  width:100%;
  flex:1;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;  /* Remove Scrollbar >> IE and Edge */
  scrollbar-width: none;  /* Remove Scrollbar >> Firefox */
}
.sidebar__menu-container-middle::-webkit-scrollbar {
  display: none; /* Remove Scrollbar >> Chrome */
}
.sidebar__menu-container-bottom{
  display:flex;
  flex-direction:column;
  width:100%;
}
.sidebar__menu-container-bottom-top{
  display:flex;
  flex-direction:column;
  width:100%;
  justify-content:flex-end;
}
.sidebar__menu-container-bottom-bottom{
  display:flex;
  flex-direction:column;
  width:100%;
  flex:1;
  justify-content:flex-end;
}


/* Small only */
@media screen and (max-width: 39.9375em) {
  .sessionLayout__logo-container{
    padding:15px 0 5px 0;
  }
  .sessionLayout__logo{
    width:110px;
  }
  .sessionLayout__empresa{
    font-size:0.8em;
  }

  .sessionLayout__menu-container{
    padding-left:12px;
  }

  .sessionLayout__menu-icon{
    width:28px;
  }

  .sessionLayout__menu-icon img{
    width:20px;
  }

  .sessionLayout__menu-content{
    padding-top:4px;
  }

  .sessionLayout__menu-label{
    font-size:0.9em;
    line-height:1em;
  }

  .sessionLayout__menu-sublabel{
    font-size:0.9em;
    line-height:1em;
  }

  .sessionLayout__menu-sublabel-selected{
    font-size:0.9em;
    line-height:1em;
  }


  /* SIDEBAR */

  .sidebar__content-div{
    width:150px;
  }

  .sidebar__menu-container-top{
    height:110px;
  }

  .sidebar__menu-container-bottom{
    height:150px;
    /*background-color: lightgreen;*/
  }

  .sidebar__menu-container-bottom-top{
    height:40px;
  }

  .sidebar__poweredby-logo{
    width:125px;
    margin-bottom:15px;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .sessionLayout__logo-container{
    padding:15px 0 5px 0;
  }
  .sessionLayout__logo{
    width:110px;
  }
  .sessionLayout__empresa{
    font-size:0.8em;
  }

  .sessionLayout__menu-container{
    padding-left:12px;
  }

  .sessionLayout__menu-icon{
    width:28px;
  }

  .sessionLayout__menu-icon img{
    width:20px;
  }

  .sessionLayout__menu-content{
    padding-top:4px;
  }

  .sessionLayout__menu-label{
    font-size:0.9em;
    line-height:1em;
  }

  .sessionLayout__menu-sublabel{
    font-size:0.9em;
    line-height:1em;
  }

  .sessionLayout__menu-sublabel-selected{
    font-size:0.9em;
    line-height:1em;
  }


  /* SIDEBAR */

  .sidebar__content-div{
    width:150px;
  }

  .sidebar__menu-container-top{
    height:110px;
  }

  .sidebar__menu-container-bottom{
    height:150px;
  }

  .sidebar__menu-container-bottom-top{
    height:40px;
  }

  .sidebar__poweredby-logo{
    width:125px;
    margin-bottom:15px;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  .sessionLayout__logo-container{
    padding:15px 0 5px 0;
  }
  .sessionLayout__logo{
    width:110px;
  }
  .sessionLayout__empresa{
    font-size:0.8em;
  }

  .sessionLayout__menu-container{
    padding-left:12px;
  }

  .sessionLayout__menu-icon{
    width:28px;
  }

  .sessionLayout__menu-icon img{
    width:20px;
  }

  .sessionLayout__menu-content{
    padding-top:4px;
  }

  .sessionLayout__menu-label{
    font-size:0.9em;
    line-height:1em;
  }

  .sessionLayout__menu-sublabel{
    font-size:0.9em;
    line-height:1em;
  }

  .sessionLayout__menu-sublabel-selected{
    font-size:0.9em;
    line-height:1em;
  }


  /* SIDEBAR */

  .sidebar__content-div{
    width:150px;
  }

  .sidebar__menu-container-top{
    height:110px;
  }

  .sidebar__menu-container-bottom{
    height:150px;
  }

  .sidebar__menu-container-bottom-top{
    height:40px;
  }

  .sidebar__poweredby-logo{
    width:125px;
    margin-bottom:15px;
  }
}
</style>
