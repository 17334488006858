<template>
  <div id="burger"
       :class="{ 'active' : isBurgerActive }"
       @click.prevent="toggle">
    <slot>
      <button type="button" class="burger-button" title="Menu">
        <span class="hidden">Toggle menu</span>
        <span class="burger-bar burger-bar--1" :style="computedBurgerBar"></span>
        <span class="burger-bar burger-bar--2" :style="computedBurgerBar"></span>
        <span class="burger-bar burger-bar--3" :style="computedBurgerBar"></span>
      </button>
    </slot>
  </div>
</template>
<script>
  export default {
    props: {
      color: {
        type: String,
        default: "#FFFFFF",
      },
    },
    async mounted() {
      try {
        // await this.$nextTick();

      } catch (error) {
        console.log("[SidebarMenuBurger.vue] mounted()::ERROR = "+error);
      }
    },
    computed: {
      computedBurgerBar(){
        var isOpen = this.$store.getters['sidebarMenuStore/getIsOpen'];
        if(!isOpen){
          return{
            'background-color': this.$props.color
          }
        }

        return{
          'background-color': "#FFFFFF"
        }
      },
      isBurgerActive() {
        return this.$store.getters['sidebarMenuStore/getIsOpen'];
      }
    },
    methods: {
      toggle() {
        var isOpen = this.$store.getters['sidebarMenuStore/getIsOpen'];
        this.$store.dispatch('sidebarMenuStore/setIsOpen',!isOpen);
      }
    }
  }
</script>
<style scoped>
  .hidden {
    visibility: hidden;
  }

  button {
    cursor: pointer;
  }

  /* remove blue outline */
  button:focus {
    outline: 0;
  }

  .burger-button {
    position: relative;
    height: 40px;
    width: 42px;
    display: block;
    z-index: 12;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    pointer-events: all;
    transition: transform .6s cubic-bezier(.165,.84,.44,1);
  }

  .burger-bar {
    background-color: #130f40;
    position: absolute;
    top: 50%;
    right: 6px;
    left: 6px;
    height: 3px;
    width: auto;
    margin-top: -1px;
    transition: transform .6s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .6s cubic-bezier(.165,.84,.44,1);
  }

  .burger-bar--1 {
    -webkit-transform: translateY(-7px);
    transform: translateY(-7px);
  }

  .burger-bar--2 {
    transform-origin: 100% 50%;
    transform: scaleX(.8);
  }

  .burger-button:hover .burger-bar--2 {
    transform: scaleX(1);
  }

  .no-touchevents .burger-bar--2:hover {
    transform: scaleX(1);
  }

  .burger-bar--3 {
    transform: translateY(7px);
  }

  #burger.active .burger-button {
    transform: rotate(-180deg);
  }

  #burger.active .burger-bar {
    background-color: #FFFFFF;
  }

  #burger.active .burger-bar--1 {
    transform: rotate(45deg)
  }

  #burger.active .burger-bar--2 {
    opacity: 0;
  }

  #burger.active .burger-bar--3 {
    transform: rotate(-45deg)
  }
</style>