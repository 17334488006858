import StorageHelper from '@/helpers/storage.helper';

const state = {
    data: StorageHelper.getItem('configuracion_data') || null,
}

const getters = {
    getData(state) {
        return state.data;
    }
}

const mutations = {
    mut_setData(state,data) {
        state.data = data;
    },
    mut_deleteData(state) {
        state.data = null;
    }
}

const actions = {
    setData(context, data) {
        StorageHelper.setItem('configuracion_data', data);
        context.commit('mut_setData', data);
    },
    deleteData(context) {
        StorageHelper.setItem('configuracion_data');
        StorageHelper.removeItem('configuracion_data');
        context.commit('mut_deleteData');
    },
    reset(context) {
        StorageHelper.removeItem('configuracion_data');
        StorageHelper.setItem('configuracion_data', null);
        context.commit('mut_deleteData');
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}