<template>
  <div class="index__inicio__container" :style="computedPageStyle">

    <!--
    ============
     LARGE VIEW
    ============
    -->

    <div id="page__largeView">
      <div class="menu__wrapper">
        <div class="menu__container">
          <div class="menu__left-container">
            <img class="menu__logo" src='@/assets/images/logo_menu.png' />
          </div>
          <div class="menu__right-container">
            <div class="menu__redsocial-container">
            <span v-if="objInformacion.configuracion">
              <img v-if="objInformacion.configuracion.urlWhatsapp!=''" @click="openUrl(objInformacion.configuracion.urlWhatsapp)" class="menu__redsocial menu__redsocial-margin-right" src='@/assets/images/ico_whatsapp.png' />
              <img v-if="objInformacion.configuracion.urlInstagram!=''" @click="openUrl(objInformacion.configuracion.urlInstagram)" class="menu__redsocial menu__redsocial-margin-right" src='@/assets/images/ico_instagram.png' />
              <img v-if="objInformacion.configuracion.urlYoutube!=''" @click="openUrl(objInformacion.configuracion.urlYoutube)" class="menu__redsocial menu__redsocial-margin-right" src='@/assets/images/ico_youtube.png' />
              <img v-if="objInformacion.configuracion.urlFacebook!=''" @click="openUrl(objInformacion.configuracion.urlFacebook)" class="menu__redsocial" src='@/assets/images/ico_facebook.png' />
            </span>
            </div>
            <div class="menu__option-container">
              <div class="menu__option menu__option-margin-right" v-on:click="scrollToElement('page__inicio')">
                INICIO
              </div>
              <div class="menu__option menu__option-margin-right" v-on:click="scrollToElement('page__servicios')">
                SERVICIOS
              </div>
              <div class="menu__option menu__option-margin-right" v-on:click="scrollToElement('page__noticias')">
                NOTICIAS
              </div>
              <div class="menu__option menu__option-margin-right" v-on:click="scrollToElement('page__clientes')">
                CLIENTES
              </div>
              <div class="menu__option" v-on:click="scrollToElement('page__contacto')">
                CONTACTO
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--
      ======================
       LARGE VIEW :: INICIO
      ======================
      -->

      <PageSection :fixedHeight="inicioSectionHeight" class="page__inicio">
        <div class="index__inicio_section-container">
          <div :style="computedMenuSpaceHeight"></div>
          <div class="index__inicio_main-container">
            <div class="index__inicio-info-container">
              <div class="index__inicio-info-container-top">
                <hooper class="index__inicio-carrousel-container"
                        :autoPlay="true"
                        :playSpeed="3000"
                        :style="computedHooperStyle"
                        onmouseover="document.body.style.overflow='hidden';"
                        onmouseout="document.body.style.overflow='auto';">
                  <slide v-for="(objCarrouselSlide, indexCarrouselSlide) in objInformacion.carrouselslides"
                         :key="indexCarrouselSlide">
                    <BackgroundImage class="index__inicio-carrousel-slide-container"
                                     :height="carrouselHeight"
                                     positionHeight="center"
                                     :backgroundURL="objCarrouselSlide.rutaImagen">
                      <div class="index__inicio-carrousel-slide-text">
                        {{ objCarrouselSlide.texto }}
                      </div>
                    </BackgroundImage>
                  </slide>
                  <hooper-navigation slot="hooper-addons">
                    <span slot="hooper-prev">
                      <SessionMenuIcon isLocal="S" :width="carrouselNavigationWidth" :height="carrouselNavigationHeight" positionHeight="center" backgroundURL="ico_arrow_left_white.png" />
                    </span>

                    <span slot="hooper-next">
                      <SessionMenuIcon isLocal="S" :width="carrouselNavigationWidth" :height="carrouselNavigationHeight" positionHeight="center" backgroundURL="ico_arrow_right_white.png" />
                    </span>
                  </hooper-navigation>
                </hooper>
              </div>
              <div class="index__inicio-info-container-bottom">
                <div class="index__inicio-info-container-bottom-line">
                  <div class="index__inicio-info-container-bottom-line-left">
                    <div class="index__inicio-bottom-imagen">
                      <div class="index__image-background" :style="{backgroundImage: 'url('+require('../../../assets/images/_static/index_top.jpg')+')'}"></div>
                    </div>
                    <div class="index__inicio-bottom-text-wrapper" style="background-color:#D7D8DC;">
                      <div class="index__inicio-bottom-text-container">
                        <div class="index__inicio-bottom-text-sublabel" style="color:#303843;">
                          <span v-if="objInformacion.configuracion">
                            <span style="color:#00B0A5">BIOTRANSFER</span> <span>{{ objInformacion.configuracion.quienesSomos }}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="index__inicio-info-container-bottom-line-right">
                  </div>
                </div>
                <div class="index__inicio-info-container-bottom-line">
                  <div class="index__inicio-info-container-bottom-line-left">
                    <div class="index__inicio-bottom-imagen">
                      <div class="index__image-background" :style="{backgroundImage: 'url('+require('../../../assets/images/_static/index_left.jpg')+')'}"></div>
                    </div>
                    <div class="index__inicio-bottom-text-wrapper" style="background-color:#9DCFC3;">
                      <div class="index__inicio-bottom-text-container">
                        <div class="index__inicio-bottom-text-label">MISIÓN</div>
                        <div class="index__inicio-bottom-text-sublabel">
                          <span v-if="objInformacion.configuracion">
                            {{ objInformacion.configuracion.mision }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="index__inicio-info-container-bottom-line-right">
                    <div class="index__inicio-bottom-imagen">
                      <div class="index__image-background" :style="{backgroundImage: 'url('+require('../../../assets/images/_static/index_right.jpg')+')'}"></div>
                    </div>
                    <div class="index__inicio-bottom-text-wrapper" style="background-color:#5A577E;">
                      <div class="index__inicio-bottom-text-container">
                        <div class="index__inicio-bottom-text-label">VISIÓN</div>
                        <div class="index__inicio-bottom-text-sublabel">
                          <span v-if="objInformacion.configuracion">
                            {{ objInformacion.configuracion.vision }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageSection>


      <!--
      =========================
       LARGE VIEW :: SERVICIOS
      =========================
      -->

      <div v-if="objInformacion.servicios">
        <div v-if="objInformacion.servicios.length>0">
          <PageSection :fixedHeight="serviciosSectionHeight" class="page__servicios">
            <div class="index__servicios_section-container">
              <div :style="computedMenuSpaceHeight"></div>
              <div class="index__servicios_main-container">
                <div class="index__servicios-info-container">
                  <div class="index__servicios-info-container-left">
                    <div class="index__servicios-main-label">
                      <div class="index__main-label">
                        CONOCE NUESTROS SERVICIOS A LA MEDIDA DE TUS NECESIDADES
                      </div>
                    </div>
                    <div class="index__servicios-description-container">
                      <div v-if="selectedServicio"
                           style="overflow-y:scroll;"
                           onmouseover="document.body.style.overflow='hidden';"
                           onmouseout="document.body.style.overflow='auto';">
                        <div class="index__servicios-detalle-info-number">
                          {{ selectedServicio.orden }}
                        </div>
                        <div class="index__servicios-detalle-info-title">
                          {{ selectedServicio.nombre }}
                        </div>
                        <div class="index__servicios-detalle-info-paragraph">
                          <span v-html="selectedServicio.descripcionLarga"></span>
                        </div>
                        <div class="index__servicios-detalle-info-control">
                          <div class="index__button" v-on:click="scrollToElement('page__contacto')">
                            Solicita tu cotización
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="index__servicios-info-container-right">
                    <div class="index__servicios-lista-scroll-container">
                      <div class="index__scroll-container">
                        <img class="index__scroll-control-up" v-if="servicioScrollUp==false" src='@/assets/images/ico_arrow_up_disabled.png' />
                        <img class="index__scroll-control-up" v-if="servicioScrollUp==true" src='@/assets/images/ico_arrow_up_enabled.png' v-on:click="swipeServiciosUp()" />
                        <img class="index__scroll-control-down" v-if="servicioScrollDown==false" src='@/assets/images/ico_arrow_down_disabled.png' />
                        <img class="index__scroll-control-down" v-if="servicioScrollDown==true" src='@/assets/images/ico_arrow_down_enabled.png' v-on:click="swipeServiciosDown()" />
                      </div>
                    </div>
                    <div ref="servicioListaDiv"
                         id="servicioListaContainer"
                         class="index__servicios-lista-detalle-container"
                         onmouseover="document.body.style.overflow='hidden';"
                         onmouseout="document.body.style.overflow='auto';">
                      <div id="servicioListaBoxes" class="index__servicios-lista-detalle-boxes">
                        <div class="index__servicios-lista-detalle-box"
                             v-for="(objServicio, indexServicio) in objInformacion.servicios"
                             :key="indexServicio"
                             @click="seleccionarServicio(objServicio.id)">
                          <div class="index__servicio-box">
                            <div class="index__image-background" :style="{backgroundImage: 'url('+objServicio.rutaImagen+')'}">
                              <div v-if="objServicio.id != selectedServicio.id" class="index__image-background-overlay" :style="servicioBackgroundColor(objServicio.backgroundColor)"></div>
                              <div class="index__image-background-texto">
                                <div class="index__servicio-number-box">
                                  <div v-if="objServicio.id != selectedServicio.id" class="index__servicio-number-text">
                                    {{ objServicio.orden }}
                                  </div>
                                  <div v-else class="index__servicio-number-text index__servicio-selected">
                                    {{ objServicio.orden }}
                                  </div>
                                </div>
                                <div v-if="objServicio.id != selectedServicio.id" class="index__servicio-text-box">
                                  <div class="index__servicio-title-label">
                                    {{ objServicio.nombre }}
                                  </div>
                                  <div class="index__servicio-paragraph-label">
                                    {{ objServicio.descripcionCorta }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PageSection>
        </div>
      </div>


      <!--
      ========================
       LARGE VIEW :: NOTICIAS
      ========================
      -->

      <div v-if="objInformacion.noticias">
        <div v-if="objInformacion.noticias.length>0">
          <PageSection :fixedHeight="noticiasSectionHeight" class="page__noticias">
            <div class="index__noticias_section-container">
              <div :style="computedMenuSpaceHeight"></div>
              <div class="index__noticias_main-container">
                <div class="index__noticias-info-container">
                  <div class="index__noticias-info-container-left">
                    <div class="index__noticias-scroll-container">
                      <img class="index__scroll-control-up" v-if="noticiaScrollUp==false" src='@/assets/images/ico_arrow_up_disabled.png' />
                      <img class="index__scroll-control-up" v-if="noticiaScrollUp==true" src='@/assets/images/ico_arrow_up_enabled.png' v-on:click="swipeNoticiasUp()" />
                      <img class="index__scroll-control-down" v-if="noticiaScrollDown==false" src='@/assets/images/ico_arrow_down_disabled.png' />
                      <img class="index__scroll-control-down" v-if="noticiaScrollDown==true" src='@/assets/images/ico_arrow_down_enabled.png' v-on:click="swipeNoticiasDown()" />
                    </div>
                    <div ref="noticiaListaDiv"
                         id="noticiaListaContainer"
                         class="index__noticias-lista-container"
                         onmouseover="document.body.style.overflow='hidden';"
                         onmouseout="document.body.style.overflow='auto';">
                      <div id="noticiaListaBoxes">
                        <div class="index__noticia-lista-box"
                             :class="isNoticiaBorderSelected(objNoticia.id)"
                             v-for="(objNoticia, indexNoticia) in objInformacion.noticias"
                             :key="indexNoticia"
                             @click="seleccionarNoticia(objNoticia.id)">
                          <div class="index__noticia-lista-imagen-container">
                            <BackgroundImage class="index__image-background"
                                             :height="noticiaListaImagenHeight"
                                             positionHeight="center"
                                             :backgroundURL="objNoticia.rutaImagen"/>
                          </div>
                          <div class="index__noticia-lista-detalle-container">
                            <div class="index__noticia-lista-detalle-padding-left">
                              <div class="index__noticia-lista-fecha" :class="isNoticiaFechaSelected(objNoticia.id)">
                                {{ objNoticia.fechaRegistro }}
                              </div>
                              <div class="index__noticia-lista-titulo" :class="isNoticiaTituloSelected(objNoticia.id)">
                                {{ objNoticia.titulo }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="index__noticias-info-container-right">
                    <div class="index__noticias-description-container">
                      <div v-if="selectedNoticia"
                           style="overflow-y:scroll;"
                           onmouseover="document.body.style.overflow='hidden';"
                           onmouseout="document.body.style.overflow='auto';">
                        <div class="index__noticia-detalle-fecha">
                          {{ selectedNoticia.fechaRegistro }}
                        </div>
                        <div class="index__noticia-detalle-titulo">
                          {{ selectedNoticia.titulo }}
                        </div>
                        <div class="index__noticia-detalle-imagen">
                          <BackgroundImage class="index__image-background"
                                           :height="noticiaDetalleImagenHeight"
                                           positionHeight="center"
                                           :backgroundURL="selectedNoticia.rutaImagen"/>
                        </div>
                        <div class="index__noticia-detalle-parrafo">
                          <span v-html="selectedNoticia.descripcionLarga" ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PageSection>
        </div>
      </div>


      <!--
      =========================
       LARGE VIEW :: CLIENTES
      =========================
      -->

      <div v-if="objInformacion.clientes">
        <div v-if="objInformacion.clientes.length>0">
          <PageSection isFullWidth="S" :minHeight="clienteSectionHeight" class="page__clientes">
            <div class="index__clientes_section-container">
              <div :style="computedMenuSpaceHeight"></div>
              <div class="index__clientes_main-container">
                <div class="index__clientes-info-top-container index__section-padding">
                  <span class="index__main-label">
                    CONOCE ALGUNOS DE NUESTROS CLIENTES
                  </span>
                </div>
                <div class="index__clientes-info-bottom-container index__section-padding">
                  <div v-for="(objCliente, indexCliente) in objInformacion.clientes"
                       :key="indexCliente"
                       style="padding:15px;">
                    <BackgroundImage :width="clienteImageWidth"
                                     :height="clienteImageHeight"
                                     positionHeight="center"
                                     :backgroundURL="objCliente.rutaImagen"/>
                  </div>
                </div>
              </div>
            </div>
          </PageSection>
        </div>
      </div>


      <!--
      ========================
       LARGE VIEW :: CONTACTO
      ========================
      -->

      <PageSection :minHeight="contactoSectionHeight" class="page__contacto">
        <div class="index__clientes_section-container">
          <div :style="computedMenuSpaceHeight"></div>
          <div class="index__contacto_main-container">
            <div class="index__contacto-info-container">
              <div class="index__contacto-info-container-left">
                <div class="index__contacto-main-label">
                  <div class="index__main-label">
                    SOLICITA TU COTIZACIÓN Y DALE INICIO A TUS PROYECTOS
                  </div>
                </div>
                <div class="index__contacto-form-line">
                  <div class="index__contacto-form-label">
                    <LabelForm labelText="Nombres" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorContactoNombres" />
                  </div>
                  <div class="index__contacto-form-control">
                    <TextBox placeholder="" fontColor="#7C7C7C" bgColor="#E4EAED" v-model="contactoNombres" v-bind:isError="errorContactoNombres" />
                  </div>
                </div>
                <div class="index__contacto-form-line">
                  <div class="index__contacto-form-label">
                    <LabelForm labelText="Institución / Empresa" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorContactoEmpresa" />
                  </div>
                  <div class="index__contacto-form-control">
                    <TextBox placeholder="" fontColor="#7C7C7C" bgColor="#E4EAED" v-model="contactoEmpresa" v-bind:isError="errorContactoEmpresa" />
                  </div>
                </div>
                <div class="index__contacto-form-line-flexrow">
                  <div class="index__contacto-form-line-flexrow-col2">
                    <div class="index__contacto-form-label">
                      <LabelForm labelText="País" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorContactoPais" />
                    </div>
                    <div class="index__contacto-form-control">
                      <DropDownList bgColor="#E4EAED" fontColor="#7C7C7C" v-model="contactoPais" v-bind:options="contactoPaisOptions" v-bind:isError="errorContactoPais" />
                    </div>
                  </div>
                  <div class="index__contacto-form-line-flexrow-col2">
                    <div class="index__contacto-form-label">
                      <LabelForm labelText="Teléfono" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorContactoTelefono" />
                    </div>
                    <div class="index__contacto-form-control">
                      <TextBox bgColor="#E4EAED" fontColor="#7C7C7C" type="number" placeholder="" v-model="contactoTelefono" v-bind:isError="errorContactoTelefono" />
                    </div>
                  </div>
                </div>
                <div class="index__contacto-form-line">
                  <div class="index__contacto-form-label">
                    <LabelForm labelText="Correo Electrónico" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorContactoCorreoElectronico" />
                  </div>
                  <div class="index__contacto-form-control">
                    <TextBox bgColor="#E4EAED" fontColor="#7C7C7C" placeholder="" v-model="contactoCorreoElectronico" v-bind:isError="errorContactoCorreoElectronico" />
                  </div>
                </div>
                <div class="index__contacto-form-line">
                  <div class="index__contacto-form-label">
                    <LabelForm labelText="Servicio" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorContactoServicio" />
                  </div>
                  <div class="index__contacto-form-control">
                    <DropDownList bgColor="#E4EAED" fontColor="#7C7C7C" v-model="contactoServicio" v-bind:options="contactoServicioOptions" v-bind:isError="errorContactoServicio" />
                  </div>
                </div>
                <div class="index__contacto-form-line">
                  <div class="index__contacto-form-label">
                    <LabelForm labelText="Mensaje" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorContactoMensaje" />
                  </div>
                  <div class="index__contacto-form-control">
                    <TextArea bgColor="#E4EAED" placeholder="" v-model="contactoMensaje" v-bind:isError="errorContactoMensaje" />
                  </div>
                </div>
                <div class="index__contacto-detalle-info-control">
                  <div class="index__button" @click="enviarInformacion();">
                    Enviar Información
                  </div>
                </div>
                <div class="index__contacto-form-line">
                  <div class="index__contacto-mensaje">
                    {{ mensajeError }}
                  </div>
                </div>
              </div>
              <div class="index__contacto-info-container-right">
                <div id="contactoImagenContainer" class="index__contacto-imagen-container">
                  <div v-if="objInformacion.configuracion!=null">
                    <div v-if="objInformacion.configuracion.contactoRutaImagen!=null">
                      <BackgroundImage :width=contactoBackgroundImageWidth
                                       :height=contactoBackgroundImageHeight
                                       positionHeight="center"
                                       :backgroundURL="objInformacion.configuracion.contactoRutaImagen" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageSection>


      <!--
      ======================
       LARGE VIEW :: FOOTER
      ======================
      -->

      <div class="footer__wrapper">
        <div ref="footerDiv" class="footer__main-container">
          <div class="footer__top-container">
            <div class="footer__top-left-container">
              <img class="menu__logo" src='@/assets/images/logo_menu_white.png' />
            </div>
            <div class="footer__top-right-container">
              <div class="index__flex-shrink">
                <div class="footer__icon-line footer__icon-line-padding-bottom">
                  <img class="footer__icon-image" src='@/assets/images/ico_mail_green.png' />
                  <span class="footer__icon-label">
                    <span v-if="objInformacion.configuracion">
                      {{ objInformacion.configuracion.emailContacto }}
                    </span>
                  </span>
                </div>
                <div class="footer__icon-line footer__icon-line-padding-bottom">
                  <img class="footer__icon-image" src='@/assets/images/ico_phone_green.png' />
                  <span class="footer__icon-label">
                    <span v-if="objInformacion.configuracion">
                      {{ objInformacion.configuracion.telefonoFijo }} / {{ objInformacion.configuracion.telefonoCelular }}
                    </span>
                  </span>
                </div>
                <div class="footer__icon-line">
                  <img class="footer__icon-image" src='@/assets/images/ico_marker_green.png' />
                  <span class="footer__icon-label">
                    <span v-if="objInformacion.configuracion">
                      {{ objInformacion.configuracion.direccion }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="footer__bottom-container">
            <div class="footer__bottom-left-container">
              <span class="footer__bottom-label">{{ new Date().getFullYear() }} Biotransfer / Todos los derechos reservados</span>
            </div>
            <div class="footer__bottom-right-container">
              <div class="index__flex-row-shrink">
                <div class="index__flex-row-shrink">
                  <span v-if="objInformacion.configuracion">
                    <img v-if="objInformacion.configuracion.urlWhatsapp!=''" @click="openUrl(objInformacion.configuracion.urlWhatsapp)" class="footer__redsocial footer__redsocial-margin-right" src='@/assets/images/ico_whatsapp_green.png' />
                    <img v-if="objInformacion.configuracion.urlInstagram!=''" @click="openUrl(objInformacion.configuracion.urlInstagram)" class="footer__redsocial footer__redsocial-margin-right" src='@/assets/images/ico_instagram_green.png' />
                    <img v-if="objInformacion.configuracion.urlYoutube!=''" @click="openUrl(objInformacion.configuracion.urlYoutube)" class="footer__redsocial footer__redsocial-margin-right" src='@/assets/images/ico_youtube_green.png' />
                    <img v-if="objInformacion.configuracion.urlFacebook!=''" @click="openUrl(objInformacion.configuracion.urlFacebook)" class="footer__redsocial" src='@/assets/images/ico_facebook_green.png' />
                  </span>
                </div>
                <div class="index__flex-shrink">
                  <img class="footer__poweredby footer__redsocial-margin-left" src='@/assets/images/poweredby_green.png' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <!--
    =============
     MOBILE VIEW
    =============
    -->

    <div id="page__mobileView">

      <!--
      ======================
       MOBILE VIEW :: INICIO
      ======================
      -->

      <div class="page__inicio-mobile index__section-container-mobile">
        <div class="menu__top-container-mobile"></div>
        <div id="carrouselContainer" class="index__carrousel-container-mobile">
          <hooper :autoPlay="true" :playSpeed="3000" :style="computedHooperStyle" class="index__carrousel-hooper-container-mobile">
            <slide v-for="(objCarrouselSlide, indexCarrouselSlide) in objInformacion.carrouselslides"
                   :key="indexCarrouselSlide" style="width:100%;">
              <BackgroundImage class="index__inicio-carrousel-slide-container"
                               :height="carrouselHeight"
                               positionHeight="center"
                               :backgroundURL="objCarrouselSlide.rutaImagen">
                <div class="index__inicio-carrousel-slide-text">
                  {{ objCarrouselSlide.texto }}
                </div>
                <span style="clear:both;"></span>
              </BackgroundImage>
            </slide>
            <hooper-pagination slot="hooper-addons"></hooper-pagination>
          </hooper>
        </div>
        <div class="index__inicio-section-padding-mobile">
          <div class="index__inicio-info-container-bottom-line-left">
            <div class="index__inicio-bottom-imagen">
              <div class="index__image-background" :style="{backgroundImage: 'url('+require('../../../assets/images/_static/index_top.jpg')+')'}"></div>
            </div>
            <div class="index__inicio-bottom-text-wrapper" style="background-color:#D7D8DC;">
              <div class="index__inicio-bottom-text-container">
                <div class="index__inicio-bottom-text-sublabel" style="color:#303843;">
                  <span v-if="objInformacion.configuracion">
                    <span style="color:#00B0A5">BIOTRANSFER</span> <span>{{ objInformacion.configuracion.quienesSomos }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="index__inicio-info-container-bottom-line-left">
            <div class="index__inicio-bottom-imagen">
              <div class="index__image-background" :style="{backgroundImage: 'url('+require('../../../assets/images/_static/index_left.jpg')+')'}"></div>
            </div>
            <div class="index__inicio-bottom-text-wrapper" style="background-color:#9DCFC3;">
              <div class="index__inicio-bottom-text-container">
                <div class="index__inicio-bottom-text-label">MISIÓN</div>
                <div class="index__inicio-bottom-text-sublabel">
                  <span v-if="objInformacion.configuracion">
                    {{ objInformacion.configuracion.mision }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="index__inicio-info-container-bottom-line-left">
            <div class="index__inicio-bottom-imagen">
              <div class="index__image-background" :style="{backgroundImage: 'url('+require('../../../assets/images/_static/index_right.jpg')+')'}"></div>
            </div>
            <div class="index__inicio-bottom-text-wrapper" style="background-color:#5A577E;">
              <div class="index__inicio-bottom-text-container">
                <div class="index__inicio-bottom-text-label">VISIÓN</div>
                <div class="index__inicio-bottom-text-sublabel">
                  <span v-if="objInformacion.configuracion">
                    {{ objInformacion.configuracion.vision }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--
      ==========================
       MOBILE VIEW :: SERVICIOS
      ==========================
      -->

      <div v-if="objInformacion.servicios">
        <div v-if="objInformacion.servicios.length>0" class="page__servicios-mobile index__servicios-container-mobile">
          <div class="menu__top-container-mobile"></div>
          <div class="index__section-text-container">
            <div class="index__section-text-mobile">
              CONOCE NUESTROS SERVICIOS A LA MEDIDA DE TUS NECESIDADES
            </div>
          </div>
          <div class="index__servicios-lista-container-mobile" :style="computedServiciosListaContainerHeightMobileStyle">
            <div class="index__servicios-lista-container-mobile-left">
              <div class="index__scroll-container">
                <img class="index__scroll-control-up" v-if="servicioScrollUpMobile==false" src='@/assets/images/ico_arrow_up_disabled.png' />
                <img class="index__scroll-control-up" v-if="servicioScrollUpMobile==true" src='@/assets/images/ico_arrow_up_enabled.png' v-on:click="swipeServiciosUpMobile()" />

                <img class="index__scroll-control-down" v-if="servicioScrollDownMobile==false" src='@/assets/images/ico_arrow_down_disabled.png' />
                <img class="index__scroll-control-down" v-if="servicioScrollDownMobile==true" src='@/assets/images/ico_arrow_down_enabled.png' v-on:click="swipeServiciosDownMobile()" />
              </div>
            </div>
            <div ref="servicioListaDivMobile"
                 class="index__servicios-lista-container-mobile-right"
                 onmouseover="document.body.style.overflow='hidden';"
                 onmouseout="document.body.style.overflow='auto';">
              <div id="servicioListaBoxesMobile" class="index__servicios-lista-detalle-boxes-mobile">
                <div class="index__servicios-lista-detalle-box-mobile"
                     v-for="(objServicio, indexServicio) in objInformacion.servicios"
                     :key="indexServicio"
                     @click="seleccionarServicioModal(objServicio.id)">
                  <div class="index__servicio-box">
                    <div class="index__image-background" :style="{backgroundImage: 'url('+objServicio.rutaImagen+')'}">
                      <div class="index__image-background-overlay" :style="servicioBackgroundColor(objServicio.backgroundColor)"></div>
                      <div class="index__image-background-texto">
                        <div class="index__servicio-number-box">
                          <div class="index__servicio-number-text">
                            {{ objServicio.orden }}
                          </div>
                        </div>
                        <div class="index__servicio-text-box">
                          <div class="index__servicio-title-label">
                            {{ objServicio.nombre }}
                          </div>
                          <div class="index__servicio-paragraph-label">
                            {{ objServicio.descripcionCorta }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--
      =========================
       MOBILE VIEW :: NOTICIAS
      =========================
      -->

      <div v-if="objInformacion.noticias">
        <div v-if="objInformacion.noticias.length>0" class="page__noticias-mobile index__noticias-container-mobile">
          <div class="menu__top-container-mobile"></div>
          <div class="index__section-text-container">
            <div class="index__section-text-mobile">
              CONOCE NUESTRAS NOTICIAS
            </div>
          </div>
          <div class="index__noticias-lista-container-mobile" :style="computedNoticiasListaContainerHeightMobileStyle">
            <div class="index__noticias-lista-container-left-mobile">
              <div class="index__scroll-container">
                <img class="index__scroll-control-up" v-if="noticiaScrollUpMobile==false" src='@/assets/images/ico_arrow_up_disabled.png' />
                <img class="index__scroll-control-up" v-if="noticiaScrollUpMobile==true" src='@/assets/images/ico_arrow_up_enabled.png' v-on:click="swipeNoticiasUpMobile()" />

                <img class="index__scroll-control-down" v-if="noticiaScrollDownMobile==false" src='@/assets/images/ico_arrow_down_disabled.png' />
                <img class="index__scroll-control-down" v-if="noticiaScrollDownMobile==true" src='@/assets/images/ico_arrow_down_enabled.png' v-on:click="swipeNoticiasDownMobile()" />
              </div>
            </div>
            <div ref="noticiaListaDivMobile"
                 class="index__noticias-lista-container-right-mobile">
              <div id="noticiaListaBoxesMobile" class="index__noticias-lista-container">
                <div class="index__noticia-lista-box"
                     :class="isNoticiaBorderSelected()"
                     v-for="(objNoticia, indexNoticia) in objInformacion.noticias"
                     :key="indexNoticia"
                     @click="seleccionarNoticiaModal(objNoticia.id)">
                  <div class="index__noticia-lista-imagen-container">
                    <BackgroundImage class="index__image-background"
                                     :height="noticiaDetalleImagenHeight"
                                     positionHeight="center"
                                     :backgroundURL="objNoticia.rutaImagen"/>
                  </div>
                  <div class="index__noticia-lista-detalle-container">
                    <div class="index__noticia-lista-detalle-padding-left">
                      <div class="index__noticia-lista-fecha" :class="isNoticiaFechaSelected()">
                        {{ objNoticia.fechaRegistro }}
                      </div>
                      <div class="index__noticia-lista-titulo" :class="isNoticiaTituloSelected()">
                        {{ objNoticia.titulo }}
                      </div>
                      <div class="index__noticia-lista-parrafo" :class="isNoticiaParrafoSelected()">
                        {{ objNoticia.descripcionCorta }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!--
      =========================
       MOBILE VIEW :: CLIENTES
      =========================
      -->

      <div v-if="objInformacion.clientes">
        <div v-if="objInformacion.clientes.length>0" class="page__clientes-mobile index__clientes-container-mobile">
          <div class="menu__top-container-mobile"></div>
          <div class="index__clientes-info-container-mobile">
            <div class="index__section-text-container">
              <div class="index__section-text-mobile">
                CONOCE ALGUNOS DE NUESTROS CLIENTES
              </div>
            </div>
            <div class="index__clientes-lista-container-mobile">
              <div v-for="(objCliente, indexCliente) in objInformacion.clientes"
                   :key="indexCliente"
                   style="padding:15px;">
                <BackgroundImage :width="clienteImageWidth"
                                 :height="clienteImageHeight"
                                 positionHeight="center"
                                 :backgroundURL="objCliente.rutaImagen"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--
      =========================
       MOBILE VIEW :: CONTACTO
      =========================
      -->

      <div class="page__contacto-mobile index__contacto-container-mobile">
        <div class="menu__top-container-mobile"></div>
        <div class="index__section-text-container">
          <div class="index__section-text-mobile">
            SOLICITA TU COTIZACIÓN Y DALE INICIO A TUS PROYECTOS
          </div>
        </div>
        <div class="index__contacto-form-mobile">
          <div class="index__contacto-form-line">
            <div class="index__contacto-form-label">
              <LabelForm labelText="Nombres" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorContactoNombres" />
            </div>
            <div class="index__contacto-form-control">
              <TextBox placeholder="" bgColor="#E4EAED" fontColor="#7C7C7C" v-model="contactoNombres" v-bind:isError="errorContactoNombres" />
            </div>
          </div>
          <div class="index__contacto-form-line">
            <div class="index__contacto-form-label">
              <LabelForm labelText="Institución / Empresa" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorContactoEmpresa" />
            </div>
            <div class="index__contacto-form-control">
              <TextBox placeholder="" bgColor="#E4EAED" fontColor="#7C7C7C" v-model="contactoEmpresa" v-bind:isError="errorContactoEmpresa" />
            </div>
          </div>
          <div class="index__contacto-form-line-flexrow">
            <div style="flex:1;padding-right:5px;">
              <div class="index__contacto-form-label">
                <LabelForm labelText="País" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorContactoPais" />
              </div>
              <div class="index__contacto-form-control">
                <DropDownList bgColor="#E4EAED" fontColor="#7C7C7C" v-model="contactoPais" v-bind:options="contactoPaisOptions" v-bind:isError="errorContactoPais" />
              </div>
            </div>
            <div style="flex:1;padding-left:5px;">
              <div class="index__contacto-form-label">
                <LabelForm labelText="Teléfono" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorContactoTelefono" />
              </div>
              <div class="index__contacto-form-control">
                <TextBox bgColor="#E4EAED" fontColor="#7C7C7C" type="number" placeholder="" v-model="contactoTelefono" v-bind:isError="errorContactoTelefono" />
              </div>
            </div>
          </div>
          <div class="index__contacto-form-line">
            <div class="index__contacto-form-label">
              <LabelForm labelText="Correo Electrónico" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorContactoCorreoElectronico" />
            </div>
            <div class="index__contacto-form-control">
              <TextBox bgColor="#E4EAED" fontColor="#7C7C7C" placeholder="" v-model="contactoCorreoElectronico" v-bind:isError="errorContactoCorreoElectronico" />
            </div>
          </div>
          <div class="index__contacto-form-line">
            <div class="index__contacto-form-label">
              <LabelForm labelText="Servicio" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorContactoServicio" />
            </div>
            <div class="index__contacto-form-control">
              <DropDownList bgColor="#E4EAED" fontColor="#7C7C7C" v-model="contactoServicio" v-bind:options="contactoServicioOptions" v-bind:isError="errorContactoServicio" />
            </div>
          </div>
          <div class="index__contacto-form-line">
            <div class="index__contacto-form-label">
              <LabelForm labelText="Mensaje" fontFamily="futura-round-cond-medium" fontColor="#303843" v-bind:isError="errorContactoMensaje" />
            </div>
            <div class="index__contacto-form-control">
              <TextArea bgColor="#E4EAED" fontColor="#7C7C7C" placeholder="" v-model="contactoMensaje" v-bind:isError="errorContactoMensaje" />
            </div>
          </div>
          <div class="index__contacto-detalle-info-control">
            <div class="index__button" @click="enviarInformacion();">
              Enviar Información
            </div>
          </div>
          <div class="index__contacto-mensaje">
            {{ mensajeError }}
          </div>
        </div>
      </div>

      <!--
      =======================
       MOBILE VIEW :: FOOTER
      =======================
      -->

      <div class="footer__container-mobile">
        <div class="footer__container-mobile-logo">
          <img class="menu__logo" src='@/assets/images/logo_menu_white.png' />
        </div>
        <div class="footer__container-mobile-mail">
          <div class="footer__icon-line footer__icon-line-padding-bottom">
            <img class="footer__icon-image" src='@/assets/images/ico_mail_green.png' />
            <span class="footer__icon-label">
              <span v-if="objInformacion.configuracion">
                {{ objInformacion.configuracion.emailContacto }}
              </span>
            </span>
          </div>
        </div>
        <div class="footer__container-mobile-phone">
          <div class="footer__icon-line footer__icon-line-padding-bottom">
            <img class="footer__icon-image" src='@/assets/images/ico_phone_green.png' />
            <span class="footer__icon-label">
              <span v-if="objInformacion.configuracion">
                {{ objInformacion.configuracion.telefonoFijo }} / {{ objInformacion.configuracion.telefonoCelular }}
              </span>
            </span>
          </div>
        </div>
        <div class="footer__container-mobile-marker">
          <div class="footer__icon-line footer__icon-line-padding-bottom">
            <img class="footer__icon-image" src='@/assets/images/ico_marker_green.png' />
            <span class="footer__icon-label">
              <span v-if="objInformacion.configuracion">
                {{ objInformacion.configuracion.direccion }}
              </span>
            </span>
          </div>
        </div>
        <div class="footer__container-mobile-copyright">
          <span class="footer__bottom-label">{{ new Date().getFullYear() }} Biotransfer / Todos los derechos reservados</span>
        </div>
      </div>
      <IndexServicioModal  :servicio="selectedServicio"
                           :isVisible="modal_isIndexServicioVisible"
                           :isLoading="modal_isIndexServicioLoading"
                           :transition="modal_indexServicioTransition"
                           :loadingMessage="modal_indexServicioLoadingMessage"
                           @close="modalIndexServicioCerrar" />
      <IndexNoticiaModal   :noticia="selectedNoticia"
                           :isVisible="modal_isIndexNoticiaVisible"
                           :isLoading="modal_isIndexNoticiaLoading"
                           :transition="modal_indexNoticiaTransition"
                           :loadingMessage="modal_indexNoticiaLoadingMessage"
                           @close="modalIndexNoticiaCerrar" />
    </div>
    <Modal accessType="PUBLIC"
           type="ALERT"
           :message="modal_message"
           :messageLoading="modal_messageLoading"
           :isVisible="modal_isVisible"
           :isLoading="modal_isLoading"
           :transition="modal_transition"
           :style="computedModalStyle"
           @aceptar="modalAceptar" />
    <ModalError :title="modalErrorTitle"
                :message="modalErrorMessage"
                :exception="modalErrorException"
                v-model="isModalErrorMessage"
                v-on:aceptar="modal__ErrorAceptar()" />
  </div>
</template>

<style scoped>

@import '../../css/site-style.css';
@import '../../css/site-menu.css';
@import '../../css/site-inicio.css';
@import '../../css/site-servicios.css';
@import '../../css/site-noticias.css';
@import '../../css/site-clientes.css';
@import '../../css/site-contacto.css';
@import '../../css/site-footer.css';

</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import SidebarMenu from "@/components/mobile/SidebarMenu";
import SidebarMenuBurger from "@/components/mobile/SidebarMenuBurger";
import PublicMobileMenu from "@/components/mobile/PublicMobileMenu";
import PageSection from "@/components/PageSection";
import Footer from "@/components/Footer";
import BackgroundImage from "@/components/BackgroundImage";
import TextBox from "@/components/TextBox";
import TextArea from "@/components/TextArea";
import LabelForm from "@/components/LabelForm";
import DropDownList from "@/components/DropDownList";
import Button from "@/components/Button";
import SpriteAnimator from "@/components/SpriteAnimator";
import Modal from "@/components/Modal";
import ModalError from "@/components/ModalError";
import IndexServicioModal from "./IndexServicioModal";
import IndexNoticiaModal from "./IndexNoticiaModal";

import SessionMenuIcon from "@/components/layout/session/SessionMenuIcon";

import { Hooper, Slide, Navigation as HooperNavigation, Pagination as HooperPagination } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: "public_Index",
  components: {
    SidebarMenu,
    SidebarMenuBurger,
    PublicMobileMenu,
    PageSection,
    BackgroundImage,
    Button,
    TextBox,
    TextArea,
    LabelForm,
    DropDownList,
    Footer,
    SpriteAnimator,
    Modal,
    ModalError,
    IndexServicioModal,
    IndexNoticiaModal,

    SessionMenuIcon,

    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination
  },
  data() {
    return {
      pageHeight:window.innerHeight,
      windowHeight: window.innerHeight,
      objInformacion: {},
      selectedServicio: {},
      selectedNoticia: {},

      // MEDIA QUERIES
      inicioSectionHeight:"0",
      serviciosSectionHeight:"0",
      noticiasSectionHeight:"0",
      clientesSectionHeight:"0",
      contactoSectionHeight:"0",

      noticiaListaImagenHeight:"0",
      noticiaDetalleImagenHeight:"0",
      clienteSectionHeight:"0",
      clienteImageWidth:"0",
      clienteImageHeight:"0",
      menuSpaceHeight:"0",
      carrouselWidth:"0",
      carrouselHeight:"0",
      carrouselNavigationWidth:"0",
      carrouselNavigationHeight:"0",

      // SERVICIO
      serviciosListaContainerHeight: 0,
      serviciosListaHeight: 0,
      serviciosListaContainerHeightMobile: 0,
      serviciosListaHeightMobile: 0,

      servicioScrollUp:false,
      servicioScrollDown:false,
      serviciosScrollTop:0,
      serviciosScrollPixels:0,

      servicioScrollUpMobile:false,
      servicioScrollDownMobile:false,
      serviciosScrollTopMobile:0,
      serviciosScrollPixelsMobile:0,

      modal_isIndexServicioVisible:false,
      modal_isIndexServicioLoading:false,
      modal_indexServicioTransition:"",
      modal_indexServicioLoadingMessage:"",

      // NOTICIA
      noticiasListaContainerHeight: 0,
      noticiasListaHeight: 0,
      noticiasListaContainerHeightMobile: 0,
      noticiasListaHeightMobile: 0,

      noticiaScrollUp:false,
      noticiaScrollDown:false,
      noticiasScrollTop:0,
      noticiasScrollPixels:0,

      noticiaScrollUpMobile:false,
      noticiaScrollDownMobile:false,
      noticiasScrollTopMobile:0,
      noticiasScrollPixelsMobile:0,

      modal_isIndexNoticiaVisible:false,
      modal_isIndexNoticiaLoading:false,
      modal_indexNoticiaTransition:"",
      modal_indexNoticiaLoadingMessage:"",

      // CONTACTO
      contactoBackgroundImageWidth:"0",
      contactoBackgroundImageHeight:"0",

      contactoHeight: 0,
      contactoDetalleLabelHeight: 0,
      contactoDetalleInfoHeight: 0,
      contactoDetalleInfoScrollHeight: 0,
      contactoPaisOptions:[],
      contactoServicioOptions:[],

      // IMAGES
      isPageImagesLoad: true, // No hay imagenes locales
      isExternalImagesLoad: true, // No hay imagenes externas
      isMountFinished: false,

      // FORM
      contactoNombres: "",
      contactoEmpresa: "",
      contactoPais: "",
      contactoServicio: "",
      contactoTelefono: "",
      contactoCorreoElectronico: "",
      contactoMensaje: "",
      mensajeError: "",

      // FORM ERROR
      errorContactoNombres: false,
      errorContactoEmpresa: false,
      errorContactoPais: false,
      errorContactoServicio: false,
      errorContactoTelefono: false,
      errorContactoCorreoElectronico: false,
      errorContactoMensaje: false,

      // MODAL
      modal_message:'',
      modal_messageLoading:'',
      modal_isVisible:false,
      modal_isLoading:false,
      modal_isClosed:false,
      modal_transition:"",

      // MODAL ERROR
      isModalErrorMessage:false,
      modalErrorTitle:'',
      modalErrorMessage:'',
      modalErrorException:'',
    };
  },
  created() {
    try {
      window.addEventListener('resize', this.handleMediaQueries);
      this.handleMediaQueries();

    } catch (error) {
      console.log("[Index.vue] created()::ERROR = "+error);
    }
  },
  async mounted() {
    try {
      await this.obtenerInformacionWeb();
      await this.page__preloadImages();
      this.obtenerPaisOpciones();
      this.obtenerServiciosOpciones();
      await this.$nextTick();

      // BROWSER BACK/FORWARD BUTTON
      window.popStateDetected = false
      window.addEventListener('popstate', () => { window.popStateDetected = true });

      window.addEventListener('resize', this.handleDivDimensions);
      this.handleDivDimensions();

      if(this.objInformacion.servicios){
        if(this.objInformacion.servicios.length>0){
          // Inicializamos las Flechas de scroll
          if(this.serviciosListaHeight>this.serviciosListaContainerHeight){
            this.servicioScrollDown = true;
          } else {
            this.servicioScrollDown = false;
          }

          if(this.serviciosListaHeightMobile>this.serviciosListaContainerHeightMobile){
            this.servicioScrollDownMobile = true;
          } else {
            this.servicioScrollDownMobile = false;
          }

          // Inicializamos los Listeners
          this.$refs.servicioListaDiv.addEventListener('scroll', this.onServicioListaDivScroll);
          this.$refs.servicioListaDivMobile.addEventListener('scroll', this.onServicioListaDivScrollMobile);
        }
      }

      if(this.objInformacion.noticias){
        if(this.objInformacion.noticias.length>0){
          // Inicializamos las Flechas de scroll
          if(this.noticiasListaHeight>this.noticiasListaContainerHeight){
            this.noticiaScrollDown = true;
          } else {
            this.noticiaScrollDown = false;
          }

          if(this.noticiasListaHeightMobile>this.noticiasListaContainerHeightMobile){
            this.noticiaScrollDownMobile = true;
          } else {
            this.noticiaScrollDownMobile = false;
          }

          // Inicializamos los Listeners
          this.$refs.noticiaListaDiv.addEventListener('scroll', this.onNoticiaListaDivScroll);
          this.$refs.noticiaListaDivMobile.addEventListener('scroll', this.onNoticiaListaDivScrollMobile);
        }
      }

      this.isMountFinished = true;

    } catch (error) {
      this.$emit('show-loading', false);
      var tmpExcepcion = "[Index.vue] mounted()::ERROR >>> "+error;
      var strExcepcion = tmpExcepcion.replace('Error:', '');
      this.modal__ErrorRegistrarExcepcion(strExcepcion);
    }
  },
  computed: {
    computedPageStyle(){
      var objStyle = {};
      objStyle["height"] = this.pageHeight+"px";
      objStyle["overflow-x"] = "hidden";
      objStyle["overflow-y"] = "scroll";

      return objStyle;
    },

    ...mapGetters({
      menuScrollTo: 'sidebarMenuStore/getSection'
    }),

    computedSectionStyle(){
      var objStyle = {};
      var windowheight = this.getWindowHeight();

      objStyle["height"] = windowheight+"px";

      return objStyle;
    },

    computedMenuSpaceHeight(){
      var objStyle = {};
      objStyle["width"] = "100%";
      objStyle["height"] = this.menuSpaceHeight;

      return objStyle;
    },

    computedHooperStyle(){
      var objStyle = {};
      objStyle["height"] = this.carrouselHeight;

      return objStyle;
    },

    computedServiciosListaContainerHeightMobileStyle(){
      var objStyle = {};
      objStyle["height"] = this.serviciosListaContainerHeightMobile+"px";

      return objStyle;
    },

    computedNoticiasListaContainerHeightMobileStyle(){
      var objStyle = {};
      objStyle["height"] = this.noticiasListaContainerHeightMobile+"px";

      return objStyle;
    },

    // ===================================

    /* -- mobile -- */

    computedServicioDetalleListaMobileStyle(){
      return {
        'height': this.servicioDetalleListaHeightMobile+"px",
      };
    },

    // ===================================

    /* -- mobile -- */

    computedNoticiaListaMobileStyle(){
      return {
        'height': this.noticiaListaHeightMobile+"px",
      };
    },

    // ===================================

    computedContactoStyle(){
      return {
        'height': this.contactoHeight+"px",
      };
    },

    computedContactoDetalleInfoStyle(){
      return {
        'height': this.contactoDetalleInfoHeight+"px",
      };
    },

    // ===================================

    computedModalStyle(){
      var objStyle = {};
      objStyle["visibility"] = "visible";

      if(this.modal_isClosed){
        objStyle["visibility"] = "hidden";
      }

      return objStyle;
    },

    page__isAllContentLoaded(){
      return this.isPageImagesLoad && this.isExternalImagesLoad && this.isMountFinished;
    },

  },
  watch: {
    menuScrollTo(newValue) {
      var section = this.$store.getters['sidebarMenuStore/getSection'];
      if(section!=""){
        this.scrollToElement(section);
      }
    },

    page__isAllContentLoaded(newValue) {
      if(newValue){
        var that;
        that = this;
        setTimeout(function () {
          that.$emit('show-loading', false);
        }, 1500);
      }
    },

  },
  methods: {
    async obtenerInformacionWeb(){
      try {
        var {default: PublicLogic} = await import('@/logic/public.logic');
        var httpPublic = await PublicLogic.informacion();
        var httpPublicData = httpPublic.data;
        var responsePublic = httpPublic.data.response;

        switch(httpPublicData.status) {
          case "OK":
            this.objInformacion = responsePublic;

            if(this.objInformacion.servicios){
              if(this.objInformacion.servicios.length>0){
                this.selectedServicio = this.objInformacion.servicios[0];
              }
            }

            if(this.objInformacion.noticias){
              if(this.objInformacion.noticias.length>0){
                this.selectedNoticia = this.objInformacion.noticias[0];
              }
            }

            break;
          default:
            var error = httpPublicData.message;
            throw Error("[Index.vue] obtenerInformacionWeb()::ERROR = "+error);
        }

      } catch (error) {
        throw Error("[Index.vue] obtenerInformacionWeb()::ERROR = "+error);
      }
    },


    // ==============================
    //  SERVICIOS
    // ==============================

    servicioBackgroundColor(color){
      return {
        'background-color': color,
        'opacity': 0.7,
      };
    },

    seleccionarServicio(idServicio){
      var lstServicios = this.objInformacion.servicios;
      var indexServicio = lstServicios.findIndex(x => x.id ===idServicio);
      this.selectedServicio = lstServicios[indexServicio];
    },

    seleccionarServicioModal(idServicio){
      var lstServicios = this.objInformacion.servicios;
      var indexServicio = lstServicios.findIndex(x => x.id ===idServicio);
      this.selectedServicio = lstServicios[indexServicio];
      this.modal_isIndexServicioVisible = true;
    },

    /* ------------ */
    /* -- scroll -- */
    /* ------------ */

    swipeServiciosUp() {
      const content = this.$refs.servicioListaDiv;
      this.serviciosScrollOnClick(content, -this.serviciosScrollPixels, 200);
    },
    swipeServiciosDown() {
      const content = this.$refs.servicioListaDiv;
      this.serviciosScrollOnClick(content, this.serviciosScrollPixels, 200);
    },
    serviciosScrollOnClick(element, scrollPixels, duration) {
      const scrollPos = element.scrollTop;
      // Condition to check if scrolling is required
      if ( !( (scrollPos === 0 || scrollPixels > 0) && (element.clientHeight + scrollPos === element.scrollHeight || scrollPixels < 0)))
      {
        // Get the start timestamp
        const startTime =
            "now" in window.performance
                ? performance.now()
                : new Date().getTime();

        var that;
        that = this;

        function scroll(timestamp) {
          //Calculate the timeelapsed
          const timeElapsed = timestamp - startTime;
          //Calculate progress
          const progress = Math.min(timeElapsed / duration, 1);
          //Set the scrolleft
          element.scrollTop = scrollPos + scrollPixels * progress;

          //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            //Request for animation
            window.requestAnimationFrame(scroll);
          } else {
            that.serviciosScrollTop = element.scrollTop;
            return;
          }
        }
        //Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll);
      }
    },
    onServicioListaDivScroll(event){
      const content = this.$refs.servicioListaDiv;
      this.servicioScrollUp = this.checkScrollUp(content);
      this.servicioScrollDown = this.checkScrollDown(content,200);
    },

    /* -- mobile -- */

    swipeServiciosUpMobile(){
      const content = this.$refs.servicioListaDivMobile;
      this.serviciosScrollOnClickMobile(content, -this.serviciosScrollPixelsMobile, 200);
    },
    swipeServiciosDownMobile() {
      const content = this.$refs.servicioListaDivMobile;
      this.serviciosScrollOnClickMobile(content, this.serviciosScrollPixelsMobile, 200);
    },
    serviciosScrollOnClickMobile(element, scrollPixels, duration) {
      const scrollPos = element.scrollTop;
      // Condition to check if scrolling is required
      if ( !( (scrollPos === 0 || scrollPixels > 0) && (element.clientHeight + scrollPos === element.scrollHeight || scrollPixels < 0)))
      {
        // Get the start timestamp
        const startTime =
            "now" in window.performance
                ? performance.now()
                : new Date().getTime();

        var that;
        that = this;

        function scroll(timestamp) {
          //Calculate the timeelapsed
          const timeElapsed = timestamp - startTime;
          //Calculate progress
          const progress = Math.min(timeElapsed / duration, 1);
          //Set the scrolleft
          element.scrollTop = scrollPos + scrollPixels * progress;

          //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            //Request for animation
            window.requestAnimationFrame(scroll);
          } else {
            that.serviciosScrollTopMobile = element.scrollTop;
            return;
          }
        }
        //Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll);
      }
    },
    onServicioListaDivScrollMobile(event){
      const content = this.$refs.servicioListaDivMobile;
      this.servicioScrollUpMobile = this.checkScrollUp(content);
      this.servicioScrollDownMobile = this.checkScrollDown(content,200);
    },


    // ==============================
    //  NOTICIAS
    // ==============================

    seleccionarNoticia(idNoticia){
      var lstNoticias = this.objInformacion.noticias;
      var indexNoticia = lstNoticias.findIndex(x => x.id ===idNoticia);
      this.selectedNoticia = lstNoticias[indexNoticia];
    },

    seleccionarNoticiaModal(idNoticia){
      var lstNoticias = this.objInformacion.noticias;
      var indexNoticia = lstNoticias.findIndex(x => x.id ===idNoticia);
      this.selectedNoticia = lstNoticias[indexNoticia];
      this.modal_isIndexNoticiaVisible = true;
    },

    isNoticiaBorderSelected(idNoticia){
      if(this.selectedNoticia.id==idNoticia){
        return "index__noticia-lista-selected";
      }
      return "index__noticia-lista-unselected";
    },
    isNoticiaFechaSelected(idNoticia){
      if(this.selectedNoticia.id==idNoticia){
        return "index__noticia-lista-fecha-selected";
      }
      return "index__noticia-lista-fecha-unselected";
    },
    isNoticiaTituloSelected(idNoticia){
      if(this.selectedNoticia.id==idNoticia){
        return "index__noticia-lista-titulo-selected";
      }
      return "index__noticia-lista-titulo-unselected";
    },
    isNoticiaParrafoSelected(idNoticia){
      if(this.selectedNoticia.id==idNoticia){
        return "ndex__noticia-lista-parrafo-selected";
      }
      return "ndex__noticia-lista-parrafo-unselected";
    },

    /* ------------ */
    /* -- scroll -- */
    /* ------------ */

    swipeNoticiasUp() {
      const content = this.$refs.noticiaListaDiv;
      this.noticiasScrollOnClick(content, -this.noticiasScrollPixels, 200);
    },
    swipeNoticiasDown() {
      const content = this.$refs.noticiaListaDiv;
      this.noticiasScrollOnClick(content, this.noticiasScrollPixels, 200);
    },
    noticiasScrollOnClick(element, scrollPixels, duration) {
      const scrollPos = element.scrollTop;
      // Condition to check if scrolling is required
      if ( !( (scrollPos === 0 || scrollPixels > 0) && (element.clientHeight + scrollPos === element.scrollHeight || scrollPixels < 0)))
      {
        // Get the start timestamp
        const startTime =
            "now" in window.performance
                ? performance.now()
                : new Date().getTime();

        var that;
        that = this;

        function scroll(timestamp) {
          //Calculate the timeelapsed
          const timeElapsed = timestamp - startTime;
          //Calculate progress
          const progress = Math.min(timeElapsed / duration, 1);
          //Set the scrolleft
          element.scrollTop = scrollPos + scrollPixels * progress;

          //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            //Request for animation
            window.requestAnimationFrame(scroll);
          } else {
            that.noticiasScrollTop = element.scrollTop;
            return;
          }
        }
        //Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll);
      }
    },
    onNoticiaListaDivScroll(event){
      const content = this.$refs.noticiaListaDiv;
      this.noticiaScrollUp = this.checkScrollUp(content);
      this.noticiaScrollDown = this.checkScrollDown(content,200);
    },

    /* -- mobile -- */

    swipeNoticiasUpMobile(){
      const content = this.$refs.noticiaListaDivMobile;
      this.noticiasScrollOnClickMobile(content, -this.noticiasScrollPixelsMobile, 200);
    },
    swipeNoticiasDownMobile() {
      const content = this.$refs.noticiaListaDivMobile;
      this.noticiasScrollOnClickMobile(content, this.noticiasScrollPixelsMobile, 200);
    },
    noticiasScrollOnClickMobile(element, scrollPixels, duration) {
      const scrollPos = element.scrollTop;
      // Condition to check if scrolling is required
      if ( !( (scrollPos === 0 || scrollPixels > 0) && (element.clientHeight + scrollPos === element.scrollHeight || scrollPixels < 0)))
      {
        // Get the start timestamp
        const startTime =
            "now" in window.performance
                ? performance.now()
                : new Date().getTime();

        var that;
        that = this;

        function scroll(timestamp) {
          //Calculate the timeelapsed
          const timeElapsed = timestamp - startTime;
          //Calculate progress
          const progress = Math.min(timeElapsed / duration, 1);
          //Set the scrolleft
          element.scrollTop = scrollPos + scrollPixels * progress;

          //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            //Request for animation
            window.requestAnimationFrame(scroll);
          } else {
            that.noticiasScrollTopMobile = element.scrollTop;
            return;
          }
        }
        //Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll);
      }
    },
    onNoticiaListaDivScrollMobile(event){
      const content = this.$refs.noticiaListaDivMobile;
      this.noticiaScrollUpMobile = this.checkScrollUp(content);
      this.noticiaScrollDownMobile = this.checkScrollDown(content,200);
    },


    // ==============================
    //  CONTACTO
    // ==============================

    async enviarInformacion(){
      try {
        var contVacio = 0;
        var contFormatoCorreo = 0;
        var strContactoNombres = this.contactoNombres;
        var strContactoEmpresa = this.contactoEmpresa;
        var strContactoPais = this.contactoPais;
        var strContactoTelefono = this.contactoTelefono;
        var strContactoCorreoElectronico = this.contactoCorreoElectronico;
        var strContactoServicio = this.contactoServicio;
        var strContactoMensaje = this.contactoMensaje;

        if(strContactoNombres===""){
          this.errorContactoNombres=true;
          contVacio = contVacio + 1;
        } else {
          this.errorContactoNombres=false;
        }
        if(strContactoEmpresa===""){
          this.errorContactoEmpresa=true;
          contVacio = contVacio + 1;
        } else {
          this.errorContactoEmpresa=false;
        }
        if(strContactoPais===""){
          this.errorContactoPais=true;
          contVacio = contVacio + 1;
        } else {
          this.errorContactoPais=false;
        }
        if(strContactoTelefono===""){
          this.errorContactoTelefono=true;
          contVacio = contVacio + 1;
        } else {
          this.errorContactoTelefono=false;
        }
        if(strContactoCorreoElectronico===""){
          this.errorContactoCorreoElectronico=true;
          contVacio = contVacio + 1;
        } else {
          this.errorContactoCorreoElectronico=false;
        }
        if(strContactoServicio===""){
          this.errorContactoServicio=true;
          contVacio = contVacio + 1;
        } else {
          this.errorContactoServicio=false;
        }
        if(strContactoMensaje===""){
          this.errorContactoMensaje=true;
          contVacio = contVacio + 1;
        } else {
          this.errorContactoMensaje=false;
        }

        if(contVacio==0){
          var EMAIL_REGEXP = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          var emailValidator = EMAIL_REGEXP.test(strContactoCorreoElectronico);

          if(!emailValidator){
            contFormatoCorreo = contFormatoCorreo + 1;
            this.errorContactoCorreoElectronico=true;
          } else {
            this.errorContactoCorreoElectronico=false;
          }

          if(contFormatoCorreo>0){
            this.errorContactoCorreoElectronico=true;
            this.mensajeError = "Por favor ingrese un formato válido de correo electrónico";
          } else {
            this.errorContactoCorreoElectronico=false;

            this.mensajeError = "";
            this.modal_isLoading = true;
            this.modal_isVisible = true;
            this.modal_message = "Muchas gracias. Su correo fue enviado y pronto nos contactaremos con usted."
            this.modal_messageLoading = "Enviando información. Un momento por favor."

            var contactoObj = {};
            contactoObj['nombres'] = strContactoNombres;
            contactoObj['empresa'] = strContactoEmpresa;
            contactoObj['pais'] = strContactoPais;
            contactoObj['telefono'] = strContactoTelefono;
            contactoObj['email'] = strContactoCorreoElectronico;
            contactoObj['servicio'] = strContactoServicio;
            contactoObj['mensaje'] = strContactoMensaje;

            var {default: PublicLogic} = await import('@/logic/public.logic');
            var httpEnviarCorreo = await PublicLogic.enviarCorreo(contactoObj);
            var httpEnviarCorreoData = httpEnviarCorreo.data;
            var responseEnviarCorreo = httpEnviarCorreo.data.response;

            switch(httpEnviarCorreoData.status) {
              case "OK":
                this.modal_isLoading = false;
                var {default: CommonHelper} = await import('@/helpers/common.helper');
                this.contactoNombres = "";
                this.contactoEmpresa = "";
                this.contactoPais = "";
                this.contactoTelefono = "";
                this.contactoCorreoElectronico = "";
                this.contactoServicio = "";
                this.contactoMensaje = "";
                await CommonHelper.delay(2000);
                const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
                const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );
                const matchMediaLarge = window.matchMedia( "(min-width: 64em)" );

                if (matchMediaSmall.matches) {
                  this.$store.dispatch('sidebarMenuStore/setSection',"page__inicio-mobile");
                }

                if (matchMediaMedium.matches) {
                  this.$store.dispatch('sidebarMenuStore/setSection',"page__inicio-mobile");
                }

                if (matchMediaLarge.matches) {
                  this.$store.dispatch('sidebarMenuStore/setSection',"page__inicio");
                }

                await CommonHelper.delay(1000);
                this.$store.dispatch('sidebarMenuStore/setSection',"");
                this.$emit('show-loading', false);
                break;

              default:
                this.modal_isClosed = false;
                this.modal_isVisible = false;
                this.modal_isLoading = false;
                var error = httpEnviarCorreoData.message;
                throw Error("[Index.vue] enviarInformacion()::ERROR = "+error);
            }
          }

        } else {
          this.mensajeError = "Por favor ingrese los datos faltantes";
        }

      } catch (error) {
        this.modal_isClosed = false;
        this.modal_isVisible = false;
        this.modal_isLoading = false;
        var tmpExcepcion = "[Index.vue] enviarInformacion()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    // LISTENERS
    handleMediaQueries(){
      this.mediaQueries();
    },

    handleDivDimensions(){
      this.divDimensions();
    },

    // MODAL
    modalIndexServicioCerrar(){
      this.modal_isIndexServicioVisible = false;
    },
    modalIndexNoticiaCerrar(){
      this.modal_isIndexNoticiaVisible = false;
    },

    modalAceptar(){
      this.modal_isClosed = false;
      this.modal_isVisible = false;
      this.modal_isLoading = false;
    },


    // MODAL ERROR
    modal__ErrorRegistrarExcepcion(excepcion){
      this.modalErrorTitle = "LO SENTIMOS";
      this.modalErrorMessage = "Se produjo un error al realizar la operación. Por favor intente más tarde.";
      this.modalErrorException = excepcion;
      this.isModalErrorMessage = true;
    },
    modal__ErrorAceptar(){
      this.isModalErrorMessage=false;
    },

    // =================
    // PRIVATE FUNCTIONS
    // =================

    mediaQueries() {
      var _inicioSectionHeight = 0;
      var _clienteSectionHeight = 0;
      var _contactoSectionHeight = 0;

      var _menuSpaceHeight = 0;
      var _contactoBackgroundImageWidth = 0;
      var _carrouselHeight = 0;
      var _carrouselNavigationWidth = 0;
      var _carrouselNavigationHeight = 0;
      var _noticiaListaImagenHeight = 0;
      var _noticiaDetalleImagenHeight = 0;
      var _clienteImageWidth = 0;
      var _clienteImageHeight = 0;

      const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
      const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );
      const matchMediaLarge = window.matchMedia( "(min-width: 64em)" );

      if (matchMediaSmall.matches) {
        _inicioSectionHeight = 1000;
        _contactoSectionHeight = 400;
        _clienteSectionHeight = 500;

        _menuSpaceHeight = 100;
        _contactoBackgroundImageWidth = 560;
        _carrouselHeight = 300;
        _carrouselNavigationWidth = 50;
        _carrouselNavigationHeight = 50;
        _noticiaListaImagenHeight= 140;
        _noticiaDetalleImagenHeight = 0;
        _clienteImageWidth = 100;
        _clienteImageHeight = 100;
      }

      if (matchMediaMedium.matches) {
        _inicioSectionHeight = 1000;
        _contactoSectionHeight = 400;
        _clienteSectionHeight = 500;

        _menuSpaceHeight = 100;
        _contactoBackgroundImageWidth = 560;
        _carrouselHeight = 300;
        _carrouselNavigationWidth = 50;
        _carrouselNavigationHeight = 50;
        _noticiaListaImagenHeight= 140;
        _noticiaDetalleImagenHeight = 140;
        _clienteImageWidth = 120;
        _clienteImageHeight = 120;
      }

      if (matchMediaLarge.matches) {
        _inicioSectionHeight = 1050;
        _contactoSectionHeight = 950;
        _clienteSectionHeight = 500;

        _menuSpaceHeight = 100;
        _contactoBackgroundImageWidth = 560;
        _carrouselHeight = 500;
        _carrouselNavigationWidth = 50;
        _carrouselNavigationHeight = 50;
        _noticiaListaImagenHeight= 140;
        _noticiaDetalleImagenHeight = 300;
        _clienteImageWidth = 150;
        _clienteImageHeight = 150;
      }

      this.inicioSectionHeight = _inicioSectionHeight+"px";
      this.clienteSectionHeight = _clienteSectionHeight+"px";
      this.contactoSectionHeight = _contactoSectionHeight+"px";

      this.menuSpaceHeight = _menuSpaceHeight+"px";
      this.carrouselHeight = _carrouselHeight+"px";
      this.contactoBackgroundImageWidth = _contactoBackgroundImageWidth+"px";
      this.carrouselNavigationWidth = _carrouselNavigationWidth+"px";
      this.carrouselNavigationHeight = _carrouselNavigationHeight+"px";
      this.noticiaListaImagenHeight= _noticiaListaImagenHeight+"px";
      this.noticiaDetalleImagenHeight= _noticiaDetalleImagenHeight+"px";
      this.clienteImageWidth = _clienteImageWidth+"px";
      this.clienteImageHeight = _clienteImageHeight+"px";
    },

    divDimensions() {
      this.pageHeight = window.innerHeight;
      this.windowHeight = this.getWindowHeight();

      // CARROUSEL
      var _carrouselWidth = document.getElementById('carrouselContainer').offsetWidth;
      this.carrouselWidth = _carrouselWidth+"px";
      console.log("carrouselWidth=",this.carrouselWidth);

      // SERVICIOS
      if(this.objInformacion.servicios) {
        if (this.objInformacion.servicios.length > 0) {
          var serviciosBoxHeight = parseFloat(window.getComputedStyle(document.querySelector('.index__servicios-lista-detalle-box')).height.replace("px",""));
          var serviciosMaxBoxRows = 4;
          var menuSpaceHeight = this.menuSpaceHeight; // aditional height space in the section
          var serviciosMainContainerPaddingTop = 50; // aditional height space in the section
          var serviciosSectionHeight = parseFloat(serviciosBoxHeight*serviciosMaxBoxRows)+parseFloat(menuSpaceHeight)+parseFloat(serviciosMainContainerPaddingTop);

          this.serviciosSectionHeight = serviciosSectionHeight+"px";
          this.serviciosListaContainerHeight = document.getElementById('servicioListaContainer').offsetHeight;
          this.serviciosListaHeight = document.getElementById('servicioListaBoxes').offsetHeight;
          this.serviciosScrollPixels = document.querySelector('.index__servicios-lista-detalle-box').offsetHeight;

          // MOBILE
          var mobile_serviciosListaDetalleBoxHeight = parseFloat(window.getComputedStyle(document.querySelector('.index__servicios-lista-detalle-box-mobile')).height.replace("px",""));
          var mobile_serviciosListaMaxBoxRows = 3;
          this.serviciosListaContainerHeightMobile = mobile_serviciosListaDetalleBoxHeight*mobile_serviciosListaMaxBoxRows;
          this.serviciosListaHeightMobile = document.getElementById('servicioListaBoxesMobile').offsetHeight;
          this.serviciosScrollPixelsMobile = mobile_serviciosListaDetalleBoxHeight;
        }
      }

      // NOTICIAS
      if(this.objInformacion.noticias) {
        if (this.objInformacion.noticias.length > 0) {
          var noticiasBoxHeight = parseFloat(window.getComputedStyle(document.querySelector('.index__noticia-lista-box')).height.replace("px",""));
          var noticiasMaxRows = 6;
          var menuSpaceHeight = this.menuSpaceHeight; // aditional height space in the section
          var noticiasMainContainerPaddingTop = 50; // aditional height space in the section
          var noticiaMarginBottom = 15; // aditional height space in the section
          var noticiasSectionHeight = parseFloat(noticiasBoxHeight*noticiasMaxRows)+parseFloat(menuSpaceHeight)+parseFloat(noticiasMainContainerPaddingTop)+parseFloat(noticiaMarginBottom*noticiasMaxRows);

          this.noticiasSectionHeight = noticiasSectionHeight+"px";
          this.noticiasListaContainerHeight = document.getElementById('noticiaListaContainer').offsetHeight;
          this.noticiasListaHeight = document.getElementById('noticiaListaBoxes').offsetHeight;
          this.noticiasScrollPixels = document.querySelector('.index__noticia-lista-box').offsetHeight;

          // MOBILE
          var mobile_noticiaListaBoxHeight = parseFloat(window.getComputedStyle(document.querySelector('.index__noticia-lista-box')).height.replace("px",""));
          var mobile_noticiaListaBoxMarginBottom = parseFloat(window.getComputedStyle(document.querySelector('.index__noticia-lista-box')).marginBottom.replace("px",""));
          var mobile_noticiaListaBoxHeight = parseFloat(mobile_noticiaListaBoxHeight) + parseFloat(mobile_noticiaListaBoxMarginBottom);
          var mobile_noticiaListaMaxBoxRows = 3;
          this.noticiasListaContainerHeightMobile = mobile_noticiaListaBoxHeight*mobile_noticiaListaMaxBoxRows;
          this.noticiasListaHeightMobile = document.getElementById('noticiaListaBoxesMobile').offsetHeight;
          this.noticiasScrollPixelsMobile = mobile_noticiaListaBoxHeight;
        }
      }

      // CONTACTO
      var _contactoBackgroundImageHeight = document.getElementById('contactoImagenContainer').offsetHeight;
      this.contactoBackgroundImageHeight = _contactoBackgroundImageHeight+"px";
      console.log("contactoBackgroundImageHeight=",this.contactoBackgroundImageHeight);
    },

    getWindowHeight(){
      var windowheight = 0;

      if(this.windowHeight<1000){
        windowheight = 1000;
      } else {
        windowheight = this.windowHeight;
      }

      return windowheight;
    },

    scrollToElement(idElement) {
      var toClassName = "."+idElement;
      var element = document.querySelector(toClassName);
      var top = element.offsetTop;
      var offset = 0;

      top = parseInt(top) + parseInt(offset);

      var scrollContainer = document.querySelector(".index__inicio__container");
      scrollContainer.scrollTo({
        top: parseInt(top),
        left: 0,
        behavior: 'smooth'
      });
    },

    openUrl(url){
      window.open(url, '_blank');
    },

    checkScrollUp(element){
      const scrollPos = element.scrollTop;
      if(scrollPos>0){
        return true;
      }

      return false;
    },

    checkScrollDown(element, scrollPixels){
      const scrollPos = element.scrollTop;

      if ( !( (scrollPos === 0 || scrollPixels > 0) && (element.clientHeight + scrollPos === element.scrollHeight || scrollPixels < 0))){
        return true;
      }
      return false;
    },

    obtenerPaisOpciones(){
      try {
        this.contactoPaisOptions = [];

        var optionObj = {};
        optionObj['id'] = "";
        optionObj['nombre'] = "Seleccione una opción";
        this.contactoPaisOptions.push(optionObj);

        var that;
        that = this;
        this.objInformacion.paises.forEach(function(objPais) {
          var optionObj = {};
          optionObj['id'] = objPais.id;
          optionObj['nombre'] = objPais.nombre;
          that.contactoPaisOptions.push(optionObj);
        });

      } catch (error) {
        throw Error("[Index.vue] obtenerPaisOpciones()::ERROR = "+error);
      }
    },

    obtenerServiciosOpciones(){
      try {
        this.contactoServicioOptions = [];

        var optionObj = {};
        optionObj['id'] = "";
        optionObj['nombre'] = "Seleccione una opción";
        this.contactoServicioOptions.push(optionObj);

        var that;
        that = this;
        this.objInformacion.servicios.forEach(function(objServicio) {
          var optionObj = {};
          optionObj['id'] = objServicio.id;
          optionObj['nombre'] = objServicio.nombre;
          that.contactoServicioOptions.push(optionObj);
        });

      } catch (error) {
        throw Error("[Index.vue] obtenerServiciosOpciones()::ERROR = "+error);
      }
    },

    async page__preloadImages() {
      var {default: ImageHelper} = await import('@/helpers/image.helper');

      this.isPageImagesLoad = true;
    },
  },
  beforeRouteLeave (to, from, next) {
    if(window.popStateDetected){
      // HACK: Se mostrará el LOADING siembre que se abandone la página. Esto nos
      // permitirá abordar los casos en que se haga BACK/FORWARD en el browser
      this.$emit('show-loading', true);
    }
    next();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('popstate', () => { window.popStateDetected = true });
  },
};
</script>

<style>
/* Small only */
@media screen and (max-width: 39.9375em) {
  #page__mobileView{
    display:block;
  }
  #page__largeView{
    display:none
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  #page__mobileView{
    display:block;
  }
  #page__largeView{
    display:none
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  #page__mobileView{
    display:none;
  }
  #page__largeView{
    display:block
  }
}
</style>



