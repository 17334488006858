<template>
  <div>
    <span class="label__font" :style="computedStyle">
      {{ labelText }}
    </span>
  </div>
</template>

<style scoped>
  /* Small only */
  @media screen and (max-width: 39.9375em) {
    .label__font{
      font-size:0.8em;
      line-height:0.8em;
    }
  }

  /* Medium only */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .label__font{
      font-size:0.8em;
      line-height:0.8em;
    }
  }

  /* Large and up */
  @media screen and (min-width: 64em) {
    .label__font{
      font-size:0.85em;
      line-height:0.85em;
    }
  }
</style>

<script>
  export default {
    name: "Label",
    props: {
      labelText: {
          type: String,
          default: ''
      },
      fontFamily: {
        type: String,
        default: 'roboto-regular'
      },
      fontColor: {
          type: String,
          default: '#000000'
      },
      fontColorError: {
        type: String,
        default: '#BD0000'
      },
      isError:{
          type: Boolean,
          default: false,
      }
    },
    async mounted() {
      try {
        await this.$nextTick();

      } catch (error) {
        console.log("[Label.vue] mounted()::ERROR = "+error);
      }
    },
    computed: {
      computedStyle() {
        var fontFamily = this.$props.fontFamily;
        var fontColor = this.$props.fontColor;
        var fontColorError = this.$props.fontColorError;
        var isError = this.$props.isError;

        var objStyle = {};
        objStyle['font-family'] = fontFamily;

        if(isError){
          objStyle['color'] = fontColorError;
        } else {
          objStyle['color'] = fontColor;
        }

        return objStyle;
      }
    }
  };
</script>

