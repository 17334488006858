import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

// Vue-Meta
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// AOS - Animate on scroll library
import AOS from 'aos'
import 'aos/dist/aos.css'

// VueSpritecore
import VueSpritecore from 'vue-spritecore'
Vue.use(VueSpritecore)

// Foundation for Sites 6
import FoundationCss from 'foundation-sites/dist/css/foundation.css';

Vue.config.productionTip = false

// Detectar BACK/FORWARD button click
window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})

Vue.mixin({
  created() {
    //console.log('[created] ' + this.$options.name)
  },
});


router.beforeEach(async (to, from, next) => {
  var toRouteAccessType = to.meta.routeAccessType;
  var toRequiresAuth = to.meta.requiresAuth;
  var loggedIn = false;
  loggedIn = store.getters['authSessionStore/getAuthenticated'];

  var public_initialRoute = "public_index";
  var session_initialRoute = "session_index";

  if(toRouteAccessType=="SESSION"){
    public_initialRoute = "session_index";
    session_initialRoute = "session_redirect";
  }

  if (!loggedIn) {
    // =============================================
    // NO estás LOGUEADO (loggedIn == false)
    // =============================================
    if(toRequiresAuth) {
      // Como la ruta "TO" necesita que estes logueado
      // te redireccionamos a la ruta pública de inicio
      next({ name: public_initialRoute, });
    } else {
      next();
    }
  } else {
    // =============================================
    // SI estás LOGUEADO (loggedIn == true)
    // =============================================
    if(toRouteAccessType=="SESSION"){
      if(to.name==public_initialRoute){
        // Estas logueado pero la ruta "TO" inicialmente
        // redirige al LOGIN: Nos aseguramos de redireccionar
        // a la ruta inicial de la Sesión.
        next({name: session_initialRoute,});
      } else {
        next();
      }
    } else {
      next();
    }
  }
})

new Vue({
  FoundationCss,
  router,
  store,
  created : function () {
    AOS.init()
  },
  render: h => h(App)
}).$mount('#app')
