/**
* @usage:
* 1. MULTIPLE:
* <CheckBox value="foo" v-model="MySelectedValues" />
* <CheckBox value="bar" v-model="MySelectedValues" />
* <CheckBox value="baz" v-model="MySelectedValues" />
*
* data(){
*    return {
*      MySelectedValues: [],
*    }
* }
*
* 2. TRUE-FALSE:
* <CheckBox v-model="MySelectedValues" />
*
* data(){
*    return {
*      selectedValue: false,
*    }
* }
*/

<template>
    <div style="width:30px;">
      <label class="container">
        <input type="checkbox"  :checked="isChecked"
                                :value="value"
                                @change="updateInput"/>
        <span style="color:transparent;">_</span>
        <span class="checkmark" :style="computedCheckmark" v-on:click="clicked()"></span>
      </label>
    </div>
</template>

<script>
    export default {
      name: "CheckBoxForm",
      model: {
        prop: 'modelValue',
        event: 'change'
      },
      props: {
        value: {
          type: String
        },
        backgroundColor: {
          type: String,
          default: "#000000",
        },
        modelValue: {
          default: ""
        },
        trueValue: {
          default: true
        },
        falseValue: {
          default: false
        },
        isError:{
          type: Boolean,
          default: false,
        }
      },
      async mounted() {
        try {
          //await this.$nextTick();

        } catch (error) {
          console.log("[CheckBoxForm.vue] mounted()::ERROR = "+error);
        }
      },
      computed: {
        computedCheckmark(){
          var isError = this.$props.isError;

          if(isError){
            return {
              'width': "20px",
              'height': "20px",
              'background-color': '#BD0000'
            };
          }

          return {
            'width': "20px",
            'height': "20px",
            'background-color': this.$props.backgroundColor
          };
        },
        isChecked() {
          if (this.modelValue instanceof Array) {
            return this.modelValue.includes(this.value)
          }
          // Note that `true-value` and `false-value` are camelCase in the JS
          return this.modelValue === this.trueValue
        }
      },
      methods: {
        clicked: function () {
          this.$emit('action-checkbox-clicked')
        },
        updateInput(event) {
          let isChecked = event.target.checked
          if (this.modelValue instanceof Array) {
            let newValue = [...this.modelValue]
            if (isChecked) {
              newValue.push(this.value)
            } else {
              newValue.splice(newValue.indexOf(this.value), 1)
            }
            this.$emit('change', newValue)
          } else {
            this.$emit('change', isChecked ? this.trueValue : this.falseValue)
          }
        }
      }
    };
</script>

<style scoped>
  .container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top:50%;
    left:0;
    transform: translate(0,-50%);
    /*top: 0;
    left: 0;*/

    border: 2px solid #000000;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 4px;
    top: 0;
    width: 8px;
    height: 13px;
    border: solid #848484;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
</style>
