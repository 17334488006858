import bgImage from "@/assets/images/bg.png";
import SessionLayout from "@/components/layout/session/SessionLayout";
import SlideListar from '@/views/pages/admin/slide/SlideListar';
import SlideCrear from '@/views/pages/admin/slide/SlideCrear';
import SlideEditar from '@/views/pages/admin/slide/SlideEditar';

const adminSlideRoutes = [
    {
        path: "/admin/slide/listar",
        name: "session_slide_listar",
        component: SlideListar,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
    {
        path: "/admin/slide/crear",
        name: "session_slide_crear",
        component: SlideCrear,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
    {
        path: "/admin/slide/editar/:idSlide",
        name: "session_slide_editar",
        component: SlideEditar,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
]

export default adminSlideRoutes