<template>
  <transition :name="transitionName"
              v-on:before-enter="beforeEnter"
              v-on:enter="enter"
              v-on:after-enter="afterEnter"
              v-on:before-leave="beforeLeave"
              v-on:after-leave="afterLeave"
              v-on:leave="leave"
  >
    <div class="modal__wrapper" v-if="data_isVisible">
      <div class="modal__close">
        <span class="cursor-pointer" v-on:click="clickClose()">X</span>
      </div>
      <div class="modal__container">
        <div v-if="serverlog" class="modal__content-box">
          <div class="modal__date color-999999">
            {{ serverlog.clientIp }}
          </div>
          <div class="modal__title color-6B6B6B">
            {{ serverlog.message }}
          </div>
          <div class="modal__title-sub color-999999">
            ORIGIN: {{ serverlog.origin }}
          </div>
          <div class="modal__title-sub color-999999">
            DEVICE: {{ serverlog.clientDevice }} | BROWSER: {{ serverlog.clientDevice }} ({{ serverlog.clientBrowserVersion }}) | PLATFORM: {{ serverlog.clientPlatform }} ({{ serverlog.clientPlatformVersion }})
          </div>
          <div class="modal__title-sub color-999999">
            Fecha: {{ serverlog.fechaRegistro }} | Hora: {{ serverlog.horaRegistro }}
          </div>
          <div v-if="serverlogContext!=''" class="modal__paragraph" style="word-break: break-all;">
            <span v-html="serverlogContext"></span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Label from "@/components/Label";
import TextBox from "@/components//TextBox";

export default {
  name: "ServerlogDetalleModal",
  props: {
    serverlog:{
      type: Object,
      default: {},
    },
    isVisible:{
      type: Boolean,
      default: false,
    },
    isLoading:{
      type: Boolean,
      default: false,
    },
    loadingMessage:{
      type: String,
      default: "",
    },
    transition:{
      type: String,
      default: "LEFT",
    },
  },
  components: {
    Label,
    TextBox,
  },
  data() {
    return {
      objServerlog: {},
      serverlogContext: '',
      data_isLoading: false,
      data_isVisible: false,
    };
  },
  async mounted() {
    try {
      this.objServerlog = this.$props.serverlog;
      await this.$nextTick();

    } catch (error) {
      console.log("[ServerlogDetalleModal.vue] mounted()::ERROR = "+error);
    }
  },
  computed: {
    transitionName() {
      var transition = this.$props.transition;

      switch(transition) {
        case "CLOSE":
          return "modal-top";
          break;
        case "RIGHT":
          return "modal-right";
          break;
        case "LEFT":
          return "modal-left";
          break;
        case "RIGHT-FULL":
          return "modal-right-full";
          break;
        case "LEFT-FULL":
          return "modal-left-full";
          break;
        case "BOTTOM":
          return "modal-bottom";
          break;
        case "TOP":
          return "modal-top";
          break;
        case "BOTTOM-FULL":
          return "modal-bottom-full";
          break;
        case "TOP-FULL":
          return "modal-top-full";
          break;
        default:
          return "modal-left";
      }
    },
  },
  watch: {
    serverlog(newValue) {
      this.serverlogContext = "";
      this.objServerlog = newValue;
      var arrContext = JSON.parse(this.objServerlog.context);
      if(arrContext.exception){
        if(arrContext.exception.xdebug_message){
          this.serverlogContext = arrContext.exception.xdebug_message;
        }
      }
    },
    isLoading(newValue) {
      this.data_isLoading = newValue;
    },
    isVisible(newValue) {
      this.data_isVisible = newValue;
    }
  },
  methods: {
    clickClose() {
      this.$emit('close');
    },

    // --

    beforeEnter() {
      this.$emit('before-enter');
    },
    enter() {
      this.$emit('enter');
    },
    afterEnter() {
      this.$emit('after-enter');
    },

    beforeLeave() {
      this.$emit('before-leave');
    },
    leave() {
      this.$emit('leave');
    },
    afterLeave() {
      this.$emit('after-leave');
    },

    // =================
    // PRIVATE FUNCTIONS
    // =================

    async page__preloadImages() {
    },
  }
};
</script>

<style scoped>
@keyframes pulseAnimation {
  from { transform: scale(1); }
  50% { transform: scale(0.85); }
  to { transform: scale(1); }
}

.modal__pulse-animation {
  animation-name: pulseAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.modal__wrapper {
  position: fixed;
  top:0;
  left:0;
  height:100vh;
  width:100vw;
  background:#DDDDDD;
  z-index:1000;
}

.modal__container{
  display:flex;
  flex-direction:column;
  width:100%;
  height:100%;
  justify-content:center;
  align-items:center;
  color: #FFFFFF;
}

.modal__close{
  position:fixed;
  width:100%;
  text-align:right;
  color:#7C7C7C;
  font-family:'kronika';
}

.modal__content-box{
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Remove Scrollbar >> IE and Edge */
  scrollbar-width: none;  /* Remove Scrollbar >> Firefox */
  background-color:#cfcfcf;
}
.modal__content-box::-webkit-scrollbar {
  display: none; /* Remove Scrollbar >> Chrome */
}

.modal__date{
  font-family:'Kronika';
  width:100%;
}
.modal__title{
  font-family:'futura-round-cond-medium';
  width:100%;
}

.modal__title-sub{
  font-family:'Kronika';
  width:100%;
}

.modal__paragraph{
  font-family:'futura-round-light';
  width:100%;
  color: #313843;
}

.cursor-pointer{
  cursor:pointer;
}

/* =============================== */

.close-enter-active{
  opacity: 0;
}

.close-leave-active {
  opacity: 0;
}

.close-enter,
.close-leave-to {
  opacity: 0;
}

/* =============================== */

.modal-right-enter-active{
  transition: transform 0.6s;
}

.modal-right-leave-active {
  transition: transform 0.8s;
}

.modal-right-enter,
.modal-right-leave-to {
  transform: translateX(-100vw);
}

/* =============================== */

.modal-left-enter-active{
  transition: transform 0.6s;
}

.modal-left-leave-active {
  transition: transform 0.8s;
}

.modal-left-enter,
.modal-left-leave-to {
  transform: translateX(100vw);
}

/* =============================== */

.modal-right-full-enter-active{
  transition: transform 0.6s;
}

.modal-right-full-leave-active {
  transition: transform 0.8s;
}

.modal-right-full-enter {
  transform: translateX(-100vw);
}

.modal-right-full-leave-to {
  transform: translateX(100vw);
}

/* =============================== */

.modal-left-full-enter-active{
  transition: transform 0.6s;
}

.modal-left-full-leave-active {
  transition: transform 0.8s;
}

.modal-left-full-enter {
  transform: translateX(100vw);
}

.modal-left-full-leave-to {
  transform: translateX(-100vw);
}

/* =============================== */

.modal-bottom-enter-active{
  transition: transform 0.5s;
}

.modal-bottom-leave-active {
  transition: transform 1s;
}

.modal-bottom-enter,
.modal-bottom-leave-to {
  transform: translateY(-100vw);
}

/* =============================== */

.modal-bottom-full-enter-active{
  transition: transform 0.5s;
}

.modal-bottom-full-leave-active {
  transition: transform 1s;
}

.modal-bottom-full-enter {
  transform: translateY(-100vw);
}

.modal-bottom-full-leave-to {
  transform: translateY(100vw);
}

/* =============================== */

.modal-top-enter-active{
  transition: transform 0.5s;
}

.modal-top-leave-active {
  transition: transform 1s;
}

.modal-top-enter,
.modal-top-leave-to {
  transform: translateY(100vw);
}

/* =============================== */

.modal-top-full-enter-active{
  transition: transform 0.5s;
}

.modal-top-full-leave-active {
  transition: transform 1s;
}

.modal-top-full-enter {
  transform: translateY(100vw);
}

.modal-top-full-leave-to {
  transform: translateY(-100vw);
}

/* =============================== */

/* Small only */
@media screen and (max-width: 39.9375em) {
  .modal__content-box{
    width:80%;
    max-height:80%;
    padding:20px;
  }

  .modal__date{
    font-size:1em;
    line-height:1em;
    padding-bottom:5px;
  }
  .modal__title{
    font-size:2em;
    line-height:1em;
    padding-bottom:5px;
  }

  .modal__title-sub{
    font-size:0.8em;
    line-height:1.5em;
    padding-top:5px;
  }

  .modal__paragraph{
    font-size:0.9em;
    line-height:1.5em;
    padding-top:30px;
  }

  .modal__close{
    font-size:1.9em;
    line-height:1em;
    padding:10px;
  }

}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .modal__content-box{
    width:80%;
    max-height:80%;
    padding:20px;
  }

  .modal__date{
    font-size:1em;
    line-height:1em;
    padding-bottom:5px;
  }
  .modal__title{
    font-size:2em;
    line-height:1em;
    padding-bottom:5px;
  }

  .modal__title-sub{
    font-size:0.8em;
    line-height:0.8em;
    padding-top:15px;
  }

  .modal__paragraph{
    font-size:0.9em;
    line-height:1.5em;
    padding-top:30px;
  }

  .modal__close{
    font-size:2.5em;
    line-height:1em;
    padding:10px;
  }

}

/* Large and up */
@media screen and (min-width: 64em) {
  .modal__content-box{
    width:80%;
    max-height:80%;
    padding:20px;
  }

  .modal__date{
    font-size:1em;
    line-height:1em;
    padding-bottom:5px;
  }
  .modal__title{
    font-size:2em;
    line-height:1em;
    padding-bottom:5px;
  }

  .modal__title-sub{
    font-size:0.8em;
    line-height:0.8em;
    padding-top:15px;
  }

  .modal__paragraph{
    font-size:0.9em;
    line-height:1.5em;
    padding-top:30px;
  }

  .modal__close{
    font-size:2.5em;
    line-height:1em;
    padding:10px;
  }

}
</style>