<template>
  <transition :name="transitionName"
              v-on:before-leave="beforeLeave"
              v-on:after-leave="afterLeave"
              v-on:leave="leave">
    <div class="loading__wrapper" v-if="isVisible">
      <div class="loading__container">
        <LoadingLogoPublic v-if="type=='PUBLIC'" class="loading__pulse-animation" />
        <LoadingLogoSession v-if="type=='SESSION'" class="loading__pulse-animation" />
        <div v-if="isTextVisible==true">
          <div v-if="message != '' ">
            <div v-if="type=='PUBLIC'" class="loading__message-public">
              {{ message }}
            </div>
            <div v-else class="loading__message-session">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import LoadingLogoPublic from "@/components/LoadingLogoPublic";
  import LoadingLogoSession from "@/components/LoadingLogoSession";

  export default {
    name: "Loading",
    props: {
      type:{
        type: String,
        default: "SESSION",
      },
      isVisible:{
          type: Boolean,
          default: false,
      },
      transition:{
        type: String,
        default: "LEFT",
      },
      message:{
        type: String,
        default: "",
      },
    },
    components: {
      LoadingLogoPublic,
      LoadingLogoSession,
    },
    data() {
      return {
        isTextVisible: true,
      };
    },
    async mounted() {
      try {
        await this.$nextTick();

      } catch (error) {
        console.log("[Loading.vue] mounted()::ERROR = "+error);
      }
    },
    computed: {
      transitionName() {
        var transition = this.$props.transition;

        switch(transition) {
          case "RIGHT":
            return "loading-right";
            break;
          case "LEFT":
            return "loading-left";
            break;
          case "RIGHT-FULL":
            return "loading-right-full";
            break;
          case "LEFT-FULL":
            return "loading-left-full";
            break;
          case "BOTTOM-FULL":
            return "loading-bottom-full";
            break;
          case "TOP-FULL":
            return "loading-top-full";
            break;
          default:
            return "loading-left";
        }
      },
    },
    methods: {
      beforeLeave: function () {
        this.$emit('before-leave');
      },
      leave: function () {
        this.$emit('leave');
      },
      afterLeave: function () {
        this.$emit('after-leave');
      },

      // =================
      // PRIVATE FUNCTIONS
      // =================

      async page__preloadImages() {
        this.isTextVisible = true;
      },
    }
  };
</script>

<style scoped>
  @keyframes pulseAnimation {
    from { transform: scale(1); }
    50% { transform: scale(0.85); }
    to { transform: scale(1); }
  }

  .loading__pulse-animation {
    animation-name: pulseAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }

  .loading__wrapper {
    position: fixed;
    top:0;
    left:0;
    height:100vh;
    width:100vw;
    background:#DDDDDD;
    z-index:1000;
  }

  .loading__container{
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
    justify-content:center;
    align-items:center;
    color: #FFFFFF;
  }
  .loading__message-public{
    font-family:'futura-round-medium';
    color:#7C7C7C;
    text-align:center;
  }


  .loading__message-session{
    font-family: 'kronika';
    color:#7C7C7C;
    text-align:center;
  }

  /* =============================== */

  .loading-right-leave-active,
  .loading-right-enter-active {
    transition: 0.6s;
  }
  .loading-right-enter {
    transform: translate(0, 0);
  }
  .loading-right-leave-to {
    transform: translate(100%, 0);
  }

  /* =============================== */

  .loading-left-leave-active,
  .loading-left-enter-active {
    transition: 0.6s;
  }
  .loading-left-enter {
    transform: translate(0, 0);
  }
  .loading-left-leave-to {
    transform: translate(-100%, 0);
  }

  /* =============================== */

  .loading-right-full-leave-active,
  .loading-right-full-enter-active {
    transition: 0.6s;
  }
  .loading-right-full-enter {
    transform: translate(100%, 0);
  }
  .loading-right-full-leave-to {
    transform: translate(-100%, 0);
  }

  /* =============================== */

  .loading-left-full-leave-active,
  .loading-left-full-enter-active {
    transition: 0.6s;
  }
  .loading-left-full-enter {
    transform: translate(-100%, 0);
  }
  .loading-left-full-leave-to {
    transform: translate(100%, 0);
  }

  /* =============================== */

  .loading-bottom-full-leave-active,
  .loading-bottom-full-enter-active {
    transition: 0.6s;
  }
  .loading-bottom-full-enter {
    transform: translate(0,100%);
  }
  .loading-bottom-full-leave-to {
    transform: translate(0,-100%);
  }

  /* =============================== */

  .loading-top-full-leave-active,
  .loading-top-full-enter-active {
    transition: 0.6s;
  }
  .loading-top-full-enter {
    transform: translate(0,-100%);
  }
  .loading-top-full-leave-to {
    transform: translate(0,100%);
  }

  /* =============================== */

  /* Small only */
  @media screen and (max-width: 39.9375em) {
    .loading__message-public{
      width:230px;
      padding-top:25px;
      font-size:1em;
      line-height:1.4em;
    }

    .loading__message-session{
      width:230px;
      padding-top:25px;
      font-size:0.8em;
      line-height:1.4em;
    }
  }

  /* Medium only */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .loading__message-public{
      width:320px;
      padding-top:25px;
      font-size:1em;
      line-height:1.4em;
    }

    .loading__message-session{
      width:320px;
      padding-top:25px;
      font-size:0.9em;
      line-height:1.4em;
    }
  }

  /* Large and up */
  @media screen and (min-width: 64em) {
    .loading__message-public{
      width:350px;
      padding-top:25px;
      font-size:1em;
      line-height:1.1em;
    }

    .loading__message-session{
      width:350px;
      padding-top:25px;
      font-size:0.9em;
      line-height:1.1em;
    }
  }
</style>

