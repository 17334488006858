import Index from '@/views/pages/public/Index';
import IndexInvitacion from '@/views/pages/public/IndexInvitacion';
import IndexOlvideClave from '@/views/pages/public/IndexOlvideClave';
import PublicLayout from "@/components/layout/PublicLayout";

const publicRoutes = [
    {   path: "/",
        name: "public_index",
        component: Index,
        meta: {
            layout: PublicLayout,
            apiAccessType: "PUBLIC",
            routeAccessType: "PUBLIC",
            requiresAuth: false,
        },
    },
    {   path: "/invitacion/:hash",
        name: "public_invitacion",
        component: IndexInvitacion,
        meta: {
            apiAccessType: "PUBLIC",
            routeAccessType: "PUBLIC",
            requiresAuth: false,
        },
    },
    {   path: "/olvideclave/:hash",
        name: "public_olvideclave",
        component: IndexOlvideClave,
        meta: {
            apiAccessType: "PUBLIC",
            routeAccessType: "PUBLIC",
            requiresAuth: false,
        },
    },
]

export default publicRoutes