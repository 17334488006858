import StorageHelper from '@/helpers/storage.helper';

const state = {
    authenticated: StorageHelper.getItem('authPublic_authenticated') || null,
    accessToken: StorageHelper.getItem('authPublic_accessToken') || null,
    refreshToken: StorageHelper.getItem('authPublic_refreshToken') || null,
}

const getters = {
    getAuthenticated (state) {
        if(state.authenticated==null){
            return false;
        }
        return state.authenticated
    },

    getAccessToken (state) {
        if(state.accessToken==null){
            return "";
        }
        return state.accessToken
    },

    getRefreshToken (state) {
        if(state.refreshToken==null){
            return "";
        }
        return state.refreshToken
    },
}

const mutations = {
    mut_setAuthenticated(state,data) {
        state.authenticated = data;
    },
    mut_deleteAuthenticated(state) {
        state.authenticated= null;
    },

    mut_setAccessToken(state,data) {
        state.accessToken = data;
    },
    mut_deleteAccessToken(state) {
        state.accessToken= null;
    },

    mut_setRefreshToken(state,data) {
        state.refreshToken = data;
    },
    mut_deleteRefreshToken(state) {
        state.refreshToken= null;
    },
}

const actions = {
    logIn(context) {
        StorageHelper.setItem('authPublic_authenticated', true);
        context.commit('mut_setAuthenticated',true);
    },
    logOut(context) {
        //if (context.getters.getAuthenticated){
            StorageHelper.removeItem('authPublic_authenticated');
            StorageHelper.setItem('authPublic_authenticated', null);
            context.commit('mut_deleteAuthenticated');

            StorageHelper.removeItem('authPublic_accessToken');
            StorageHelper.setItem('authPublic_accessToken', null);
            context.commit('mut_deleteAccessToken');

            StorageHelper.removeItem('authPublic_refreshToken');
            StorageHelper.setItem('authPublic_refreshToken', null);
            context.commit('mut_deleteRefreshToken');
        //}
    },

    setAccessToken(context, data) {
        StorageHelper.setItem('authPublic_accessToken', data);
        context.commit('mut_setAccessToken', data);
    },
    deleteAccessToken(context) {
        StorageHelper.removeItem('authPublic_accessToken');
        context.commit('mut_deleteAccessToken');
    },

    setRefreshToken(context, data) {
        StorageHelper.setItem('authPublic_refreshToken', data);
        context.commit('mut_setRefreshToken', data);
    },
    deleteRefreshToken(context) {
        StorageHelper.removeItem('authPublic_refreshToken');
        context.commit('mut_deleteRefreshToken');
    },
    reset(context) {
        StorageHelper.removeItem('authPublic_authenticated');
        StorageHelper.setItem('authPublic_authenticated', null);
        context.commit('mut_deleteAuthenticated');

        StorageHelper.removeItem('authPublic_accessToken');
        StorageHelper.setItem('authPublic_accessToken', null);
        context.commit('mut_deleteAccessToken');

        StorageHelper.removeItem('authPublic_refreshToken');
        StorageHelper.setItem('authPublic_refreshToken', null);
        context.commit('mut_deleteRefreshToken');
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}