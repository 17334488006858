import bgImage from "@/assets/images/bg.png";
import SessionLayout from "@/components/layout/session/SessionLayout";
import ServicioListar from '@/views/pages/admin/servicio/ServicioListar';
import ServicioCrear from '@/views/pages/admin/servicio/ServicioCrear';
import ServicioEditar from '@/views/pages/admin/servicio/ServicioEditar';

const adminServicioRoutes = [
    {
        path: "/admin/servicio/listar",
        name: "session_servicio_listar",
        component: ServicioListar,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
    {
        path: "/admin/servicio/crear",
        name: "session_servicio_crear",
        component: ServicioCrear,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
    {
        path: "/admin/servicio/editar/:idServicio",
        name: "session_servicio_editar",
        component: ServicioEditar,
        meta: {
            layout: SessionLayout,
            apiAccessType: "SESSION",
            routeAccessType: "SESSION",
            requiresAuth: true,
            bgImage: bgImage
        },
    },
]

export default adminServicioRoutes