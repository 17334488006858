<template>
  <div class="page__wrapper">
    <div class="page__container">
      <div class="page__header-container">
        <div class="page__header-container-left">
          <SpriteAnimator
              :id="'sprite'"
              :spritesheet="require('../../../../assets/sprites/appear/spritesheet.png')"
              :json="require('../../../../assets/sprites/appear/spritesheet.json')"
              :yoyo="false"
              :fps="15"
              :width=headerSpriteWidth
              :isLoop="false"
              ref="sprite"
          ></SpriteAnimator>
          <div class="page__header-title">
            CREAR SERVICIO
          </div>
          <Button v-on:action-button-clicked="regresar()"
                  labelText="REGRESAR"
                  bgColor="#C00606"
                  :width=buttonWidth />
        </div>
        <div class="page__header-container-right">
          <Button v-on:action-button-clicked="crearServicio()"
                  labelText="REGISTRAR DATOS"
                  :width=buttonWidth />
        </div>
      </div>
      <div class="page__header-container-mobile">
        <div class="page__header-container-mobile-top">
          <div class="page__header-sprite">
            <SpriteAnimator
                :id="'sprite'"
                :spritesheet="require('../../../../assets/sprites/appear/spritesheet.png')"
                :json="require('../../../../assets/sprites/appear/spritesheet.json')"
                :yoyo="false"
                :fps="15"
                :width=headerSpriteWidth
                :isLoop="false"
                ref="sprite"
            ></SpriteAnimator>
          </div>
          <div class="page__header-title">
            CREAR SERVICIO
          </div>
        </div>
        <div class="page__header-container-mobile-botom">
          <Button v-on:action-button-clicked="regresar()"
                  labelText="REGRESAR"
                  bgColor="#C00606"
                  :width=buttonWidth
                  class="button__margin-right"/>
          <Button v-on:action-button-clicked="crearServicio()"
                  labelText="REGISTRAR DATOS"
                  :width=buttonWidth />
        </div>
      </div>
      <div class="page__content-container">
        <div class="page__main-container">
          <div class="page__main-container-left">
            <div class="page__form-border-container">
              <div class="page__form-image-upload-cotainer">
                <div class="page__form-image-upload-cotainer-left">
                  <BackgroundImage isBordered="S" height="150px" width="150px" positionHeight="center" :backgroundURL="rutaImagen"/>
                </div>
                <div class="page__form-image-upload-cotainer-right">
                  <ButtonUpload @upload-object="getUploadObject" />
                </div>
              </div>
              <div class="page__form-image-upload-cotainer-mobile">
                <div class="page__form-image-upload-cotainer-mobile-top">
                  <BackgroundImage isBordered="S" height="150px" width="150px" positionHeight="center" :backgroundURL="rutaImagen"/>
                </div>
                <div class="page__form-image-upload-cotainer-mobile-bottom">
                  <ButtonUpload @upload-object="getUploadObject" />
                </div>
              </div>
              <div class="page__form-line">
                <div class="page__form-label">
                  <Label labelText="Nombre" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorNombre" />
                </div>
                <div class="full-width">
                  <TextBox placeholder="" maxlength="250" v-model="nombre" v-bind:isError="errorNombre" />
                </div>
              </div>
              <div class="page__form-line">
                <div class="page__form-label">
                  <Label labelText="Descripción Corta" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorDescripcionCorta" />
                </div>
                <div class="full-width">
                  <TextArea placeholder="" v-model="descripcionCorta" v-bind:isError="errorDescripcionCorta" />
                </div>
              </div>
              <div class="page__form-line">
                <div class="page__form-label">
                  <Label labelText="Descripción Larga" fontFamily="kronika" fontColor="#FF5229"  v-bind:isError="errorDescripcionLarga" />
                </div>
                <div class="full-width">
                  <TextEditor v-model="descripcionLarga"
                              v-bind:isError="errorDescripcionLarga" />
                </div>
              </div>
              <div class="page__form-error-message">
                {{ mensajeError }}
              </div>
            </div>
          </div>
          <div class="page__main-container-right">
          </div>
        </div>
      </div>
    </div>
    <ModalError :title="modalErrorTitle"
                :message="modalErrorMessage"
                :exception="modalErrorException"
                v-model="isModalErrorMessage"
                v-on:aceptar="modal__ErrorAceptar()" />
  </div>
</template>

<script>

import SpriteAnimator from "@/components/SpriteAnimator";
import Button from "@/components/Button";
import ButtonForm from "@/components/ButtonForm";
import ButtonUpload from "@/components/ButtonUpload";
import Label from "@/components/Label";
import TextBox from "@/components/TextBox";
import TextArea from "@/components/TextArea";
import TextEditor from "@/components/custom/texteditor/TextEditor";
import CheckBoxForm from "@/components/CheckBoxForm";
import BackgroundImage from "@/components/BackgroundImage";
import ModalError from "@/components/ModalError";

export default {
  name: "ServicioCrear",
  components: {
    SpriteAnimator,
    Button,
    ButtonForm,
    ButtonUpload,
    Label,
    TextBox,
    TextArea,
    TextEditor,
    CheckBoxForm,
    BackgroundImage,
    ModalError,
  },
  data() {
    return {
      // MEDIA QUERIES
      headerSpriteWidth:0,
      buttonWidth:"0",

      // FORM
      nombre: "",
      descripcionCorta: "",
      descripcionLarga: "",
      rutaImagen: "",
      fileImagen: null,
      mensajeError: "",

      // FORM ERROR
      errorNombre: false,
      errorDescripcionCorta: false,
      errorDescripcionLarga: false,

      // IMAGES
      isPageImagesLoad: true,
      isExternalImagesLoad: true,
      isMountFinished: false,

      // MODAL ERROR
      isModalErrorMessage:false,
      modalErrorTitle:'',
      modalErrorMessage:'',
      modalErrorException:'',
    };
  },
  created() {
    try {
      window.addEventListener('resize', this.handleWindowResize);
      this.handleWindowResize();

      this.mensajeError = "";
    } catch (error) {
      console.log("[ServicioCrear.vue] created()::ERROR = "+error);
    }
  },
  async mounted() {
    try {
      // BROWSER BACK/FORWARD BUTTON
      window.popStateDetected = false
      window.addEventListener('popstate', () => { window.popStateDetected = true });

      await this.page__preloadImages();
      await this.$nextTick();
      this.isMountFinished = true;

    } catch (error) {
      this.$emit('show-loading', false);
      var tmpExcepcion = "[ServicioCrear.vue] mounted()::ERROR >>> "+error;
      var strExcepcion = tmpExcepcion.replace('Error:', '');
      this.modal__ErrorRegistrarExcepcion(strExcepcion);
    }
  },
  computed: {
    page__isAllContentLoaded(){
      return this.isPageImagesLoad && this.isExternalImagesLoad && this.isMountFinished;
    },
  },
  watch: {
    page__isAllContentLoaded(newValue) {
      if(newValue){
        var that;
        that = this;
        setTimeout(function () {
          that.$emit('show-loading', false);
        }, 1500);
      }
    },
  },
  methods: {
    async crearServicio(){
      try {
        var contVacio = 0;
        var strNombre = this.nombre;
        var strDescripcionCorta = this.descripcionCorta;
        var strDescripcionLarga = this.descripcionLarga;

        if(strNombre===""){
          this.errorNombre=true;
          contVacio = contVacio + 1;
        } else {
          this.errorNombre=false;
        }

        if(strDescripcionCorta===""){
          this.errorDescripcionCorta=true;
          contVacio = contVacio + 1;
        } else {
          this.errorDescripcionCorta=false;
        }

        if(strDescripcionLarga===""){
          this.errorDescripcionLarga=true;
          contVacio = contVacio + 1;
        } else {
          this.errorDescripcionLarga=false;
        }

        if(contVacio===0){
          this.mensajeError = "";
          this.$emit('show-loading', true, "Registrando información. Un momento por favor","RIGHT");

          var objUser = this.$store.getters['authSessionStore/getUser'];

          var servicioObj = {};
          servicioObj['nombre'] = strNombre
          servicioObj['descripcionCorta'] = strDescripcionCorta;
          servicioObj['descripcionLarga'] = strDescripcionLarga;
          servicioObj['fileImagen'] = this.fileImagen;

          var {default: ServicioLogic} = await import('@/logic/servicio.logic');
          var httpServicio = await ServicioLogic.crear(servicioObj);
          var httpServicioData = httpServicio.data;
          var responseServicio = httpServicio.data.response;

          switch(httpServicioData.status) {
            case "OK":
              var nextRoute = 'session_servicio_listar';
              this.$router.push({ name: nextRoute }, () => {
              });
              break;

            default:
              var error = httpServicioData.message;
              throw Error("[ServicioCrear.vue] editarServicio()::ERROR = "+error);
          }

        } else {
          this.mensajeError = "/* Por favor ingrese los datos faltantes */";
        }

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[ServicioCrear.vue] editarServicio()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    regresar(){
      try {
        this.$emit('show-loading', true);
        var nextRoute = 'session_servicio_listar';
        this.$router.push({ name: nextRoute }, () => {
        });

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[ServicioCrear.vue] regresar()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    getUploadObject(objData){
      this.fileImagen = objData.data;
      this.rutaImagen = objData.url;
    },

    // LISTENERS
    handleWindowResize(){
      this.mediaQueries();
    },

    // MODAL ERROR
    modal__ErrorRegistrarExcepcion(excepcion){
      this.modalErrorTitle = "LO SENTIMOS";
      this.modalErrorMessage = "Se produjo un error al realizar la operación. Por favor intente más tarde.";
      this.modalErrorException = excepcion;
      this.isModalErrorMessage = true;
    },
    modal__ErrorAceptar(){
      this.isModalErrorMessage=false;
    },

    // =================
    // PRIVATE FUNCTIONS
    // =================

    mediaQueries() {
      var _headerSpriteWidth = 0;
      var _buttonWidth = 0;

      const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
      const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );
      const matchMediaLarge = window.matchMedia( "(min-width: 64em)" );

      if (matchMediaSmall.matches) {
        _headerSpriteWidth = 120;
        _buttonWidth = 120;
      }

      if (matchMediaMedium.matches) {
        _headerSpriteWidth = 120;
        _buttonWidth = 120;
      }

      if (matchMediaLarge.matches) {
        _headerSpriteWidth = 120;
        _buttonWidth = 120;
      }

      this.headerSpriteWidth = _headerSpriteWidth;
      this.buttonWidth = _buttonWidth+"px";
    },

    async page__preloadImages() {
      var {default: ImageHelper} = await import('@/helpers/image.helper');

      this.isPageImagesLoad = true;
    },
  },
  beforeRouteLeave (to, from, next) {
    if(window.popStateDetected){
      // HACK: Se mostrará el LOADING siembre que se abandone la página. Esto nos
      // permitirá abordar los casos en que se haga BACK/FORWARD en el browser
      this.$emit('show-loading', true);
    }
    next();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('popstate', () => { window.popStateDetected = true });
  }
};
</script>

<style scoped>

/* Small only */
@media screen and (max-width: 39.9375em) {

}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

}

/* Large and up */
@media screen and (min-width: 64em) {

}

</style>
