<template>
  <div class="page__wrapper">
    <div class="page__container">
      <div class="page__header-container">
        <div class="page__header-container-left">
          <SpriteAnimator
              :id="'sprite'"
              :spritesheet="require('../../../../assets/sprites/appear/spritesheet.png')"
              :json="require('../../../../assets/sprites/appear/spritesheet.json')"
              :yoyo="false"
              :fps="15"
              :width=headerSpriteWidth
              :isLoop="false"
              ref="sprite"
          ></SpriteAnimator>
          <div class="page__header-title">
            MIS CLIENTES
          </div>
        </div>
        <div class="page__header-container-right">
          <Button v-on:action-button-clicked="crearCliente()"
                  labelText="CREAR CLIENTE"
                  :width=buttonWidth />
        </div>
      </div>
      <div class="page__header-container-mobile">
        <div class="page__header-container-mobile-top">
          <div class="page__header-sprite">
            <SpriteAnimator
                :id="'sprite'"
                :spritesheet="require('../../../../assets/sprites/appear/spritesheet.png')"
                :json="require('../../../../assets/sprites/appear/spritesheet.json')"
                :yoyo="false"
                :fps="15"
                :width=headerSpriteWidth
                :isLoop="false"
                ref="sprite"
            ></SpriteAnimator>
          </div>
          <div class="page__header-title">
            MIS CLIENTES
          </div>
        </div>
        <div class="page__header-container-mobile-botom">
          <Button v-on:action-button-clicked="crearCliente()"
                  labelText="CREAR CLIENTE"
                  :width=buttonWidth
                  class="button__margin-right" />
          <img class="page__header-icon" src='@/assets/images/ico_camera_white.png' @click="buscarClienteModal()" />
        </div>
      </div>
      <div class="page__content-container">
        <div class="page__main-list-container">
          <div id="page__search-div" class="page__main-list-container-left">
            <div class="page__form-container page__border-dotted">
              <div class="page__form-search-line">
                <div class="page__form-label">
                  <Label labelText="Nombre" fontFamily="kronika" fontColor="#FFFFFF" />
                </div>
                <div class="full-width">
                  <TextBox placeholder="" maxlength="250" v-model="nombre" />
                </div>
              </div>
              <div class="full-width">
                <ButtonForm v-on:action-button-clicked="buscarClientes()"
                            labelText="BUSCAR"
                            :width=buttonFormWidth />
              </div>
            </div>
          </div>
          <div class="page__main-list-container-right">
            <div class="page__list-header-container">
              <div class="page__list-header-container-top">
                <div class="page__list-header-container-top-left">
                  <div class="page__list-flex-shrink">
                    <CheckBoxForm backgroundColor="#FFFFFF" v-model="chkSeleccionarTodo" v-on:action-checkbox-clicked="seleccionarTodo()" />
                  </div>
                  <div class="page__list-header-checkbox-label-container">
                    <span class="page__list-header-checkbox-label">Seleccionar todo</span>
                  </div>
                  <div class="page__list-flex-shrink">
                    <ButtonForm v-on:action-button-clicked="borrarClientes()"
                                labelText="BORRAR"
                                bdColor="#C00606"
                                bgColor="#FFFFFF"
                                fontColor="#C00606"
                                :width=buttonFormWidth />
                  </div>
                </div>
                <div class="page__list-header-container-top-right">
                  <div class="page__list-paginator-container">
                    <span v-if="isVisiblePagination" v-for="page in paginationLinks">
                      <span v-if="page==currentPage">
                        <span class="page__list-paginator-link-selected">
                          {{ page }}
                        </span>
                      </span>
                      <span v-else class="page__list-paginator-link">
                        <span v-if="page!='...'" style="margin-left:20px;" @click="buscarClientesPaginated(page)">
                          {{ page }}
                        </span>
                        <span v-else style="margin-left:10px;" >
                          {{ page }}
                        </span>
                      </span>
                    </span>
                    <span v-else class="page__list-paginator-link">
                      &nbsp
                    </span>
                  </div>
                </div>
              </div>
              <div class="page__list-header-container-bottom">
                <canvas ref="canvasHeaderListBorder" class="page__list-canvas-border"></canvas>
              </div>
            </div>
            <div class="page__list-container">
              <div v-if="lstClientes.length>0">
                <div v-for="(objCliente, indexCliente) in lstClientes" :key="indexCliente">
                  <div :class="listStyle(lstClientes.length,indexCliente)">
                    <div class="page__list-item-container-left">
                      <div class="page__list-item-column-checkbox">
                        <CheckBoxForm backgroundColor="#FFFFFF" v-model="objCliente.selected" />
                      </div>
                      <div class="page__list-item-column-image">
                        <BackgroundImage isBordered="S" :height=backgroundImageListWidth :width=backgroundImageListHeight positionHeight="center" :backgroundURL="objCliente.rutaImagen" style="border-radius:8px;" />
                      </div>
                      <div class="page__list-item-column-label">
                        <div class="page__list-item-label-main">
                          <div class="page__list-item-truncated-text">
                            {{ objCliente.nombre }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page__list-item-container-right">
                      <ButtonForm v-on:action-button-clicked="detalleCliente(objCliente.id)"
                                  labelText="EDITAR CLIENTE"
                                  bdColor="#FF5229"
                                  bgColor="#FFFFFF"
                                  fontColor="#FF5229"
                                  :width=buttonListWidth />
                    </div>
                  </div>
                  <div :class="listStyleMobile(lstClientes.length,indexCliente)">
                    <div class="page__list-item-container-mobile-left">
                      <CheckBoxForm backgroundColor="#FFFFFF" v-model="objCliente.selected" />
                    </div>
                    <div class="page__list-item-container-mobile-right">
                      <div class="page__list-item-column-image-mobile">
                        <BackgroundImage isBordered="S" :height=backgroundImageListWidth :width=backgroundImageListHeight positionHeight="center" :backgroundURL="objCliente.rutaImagen" style="border-radius:8px;" />
                      </div>
                      <div class="page__list-item-column-label-mobile">
                        <div class="page__list-item-label-main">
                          <div class="page__list-item-truncated-text">
                            {{ objCliente.nombre }}
                          </div>
                        </div>
                      </div>
                      <div class="page__list-item-column-options-mobile">
                        <ButtonForm v-on:action-button-clicked="detalleCliente(objCliente.id)"
                                    labelText="EDITAR CLIENTE"
                                    bdColor="#FF5229"
                                    bgColor="#FFFFFF"
                                    fontColor="#FF5229"
                                    :width=buttonListWidth />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="page__list-item-noregistros">
                /* NO PRESENTA REGISTROS DISPONIBLES */
              </div>
            </div>
            <div class="page__list-footer-container">
              <div class="page__list-footer-container-top">
                <canvas ref="canvasFooterListBorder" class="page__list-canvas-border"></canvas>
              </div>
              <div class="page__list-footer-container-bottom">
                <div class="page__list-paginator-container">
                    <span v-if="isVisiblePagination" v-for="page in paginationLinks">
                      <span v-if="page==currentPage">
                        <span class="page__list-paginator-link-selected">
                          {{ page }}
                        </span>
                      </span>
                      <span v-else class="page__list-paginator-link">
                        <span v-if="page!='...'" style="margin-left:20px;" @click="buscarClientesPaginated(page)">
                          {{ page }}
                        </span>
                        <span v-else style="margin-left:10px;" >
                          {{ page }}
                        </span>
                      </span>
                    </span>
                  <span v-else class="page__list-paginator-link">
                      &nbsp
                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal type="CONFIRM"
           :message="modal_message"
           :messageLoading="modal_messageLoading"
           :isVisible="modal_isVisible"
           :isLoading="modal_isLoading"
           :transition="modal_transition"
           :style="computedModalStyle"
           @aceptar="modalBorrarClientes"
           @cancelar="modalCancelar" />
    <ModalError :title="modalErrorTitle"
                :message="modalErrorMessage"
                :exception="modalErrorException"
                v-model="isModalErrorMessage"
                v-on:aceptar="modal__ErrorAceptar()" />
    <ClienteListarModal  :isVisible="modal_isClienteListarVisible"
                          :isLoading="modal_isClienteListarLoading"
                          :transition="modal_clienteListarTransition"
                          :loadingMessage="modal_clienteListarLoadingMessage"
                          :style="computedClienteListarModalStyle"
                          @close="modalCerrar"
                          @buscar="modalBuscarClientes" />
  </div>
</template>

<script>
import SpriteAnimator from "@/components/SpriteAnimator";
import Button from "@/components/Button";
import ButtonForm from "@/components/ButtonForm";
import TextBox from "@/components/TextBox";
import CheckBoxForm from "@/components/CheckBoxForm";
import BackgroundImage from "@/components/BackgroundImage";
import Label from "@/components/Label";
import Modal from "@/components/Modal";
import ModalError from "@/components/ModalError";
import ClienteListarModal from "@/views/pages/admin/cliente/ClienteListarModal";

export default {
  name: "ClienteListar",
  components: {
    SpriteAnimator,
    Button,
    ButtonForm,
    TextBox,
    CheckBoxForm,
    BackgroundImage,
    Label,
    Modal,
    ModalError,
    ClienteListarModal
  },
  data() {
    return {
      // MEDIA QUERIES
      headerSpriteWidth:0,
      buttonWidth:"0",
      buttonFormWidth:"0",
      buttonListWidth:"0",
      backgroundImageListWidth:"0",
      backgroundImageListHeight:"0",

      // VALIDATE
      mensajeError: '',
      contadorImagenes: 0,

      // FORM
      nombre: '',

      // PAGINATION
      itemsPerPage: 1,
      currentPage: 1,
      lstClientes: [],
      paginationLinks: [],
      chkSeleccionarTodo: false,
      lstSeleccionarTodo: false,
      loadPagination: true,
      isVisiblePagination: false,

      // IMAGES
      isPageImagesLoad: true, // No hay imagenes locales
      isExternalImagesLoad: true, // No hay imagenes externas
      isMountFinished: false,

      // MODAL
      modal_message:'',
      modal_messageLoading:'',
      modal_isVisible:false,
      modal_isLoading:false,
      modal_isClosed:false,
      modal_transition:"",

      modal_isClienteListarVisible:false,
      modal_isClienteListarLoading:false,
      modal_isClienteListarClosed:false, // Aqui indicamos si cerramos de inmediato el modal
      modal_clienteListarTransition:"",
      modal_clienteListarLoadingMessage:"",

      // MODAL ERROR
      isModalErrorMessage:false,
      modalErrorTitle:'',
      modalErrorMessage:'',
      modalErrorException:'',
    };
  },
  created() {
    try {
      this.modal_transition = "";
      window.addEventListener('resize', this.handleWindowResize);
      this.handleWindowResize();

    } catch (error) {
      console.log("[ClienteListar.vue] created()::ERROR = "+error);
    }
  },
  async mounted() {
    try {
      // BROWSER BACK/FORWARD BUTTON
      window.popStateDetected = false
      window.addEventListener('popstate', () => { window.popStateDetected = true });

      window.addEventListener('resize', this.handleCanvasResize);
      this.handleCanvasResize();
      await this.page__preloadImages();

      var objBusqueda = {};
      objBusqueda['currentPage'] = this.currentPage;
      objBusqueda['nombre'] = this.nombre;
      await this.listarClientes(objBusqueda,true);

      await this.$nextTick();
      this.isMountFinished = true;

    } catch (error) {
      this.$emit('show-loading', false);
      var tmpExcepcion = "[Index.vue] mounted()::ERROR >>> "+error;
      var strExcepcion = tmpExcepcion.replace('Error:', '');
      this.modal__ErrorRegistrarExcepcion(strExcepcion);
    }
  },
  computed: {
    computedModalStyle: function () {
      var objStyle = {};
      objStyle["visibility"] = "visible";

      if(this.modal_isClosed){
        objStyle["visibility"] = "hidden";
      }

      return objStyle;
    },
    computedClienteListarModalStyle: function () {
      var objStyle = {};
      objStyle["visibility"] = "visible";

      if(this.modal_isClienteListarClosed){
        objStyle["visibility"] = "hidden";
      }

      return objStyle;
    },

    canvasHeaderListBorder: function () {
      return this.$refs.canvasHeaderListBorder;
    },
    ctxHeaderListBorder: function () {
      return this.canvasHeaderListBorder.getContext('2d');
    },
    canvasFooterListBorder: function () {
      return this.$refs.canvasFooterListBorder;
    },
    ctxFooterListBorder: function () {
      return this.canvasFooterListBorder.getContext('2d');
    },
    page__isAllContentLoaded(){
      return this.isPageImagesLoad && this.isExternalImagesLoad && this.isMountFinished;
    },
  },
  watch: {
    page__isAllContentLoaded(newValue) {
      if(newValue){
        var that;
        that = this;
        setTimeout(function () {
          that.$emit('show-loading', false);
        }, 1500);
      }
    }
  },
  methods: {
    crearCliente(){
      try {
        this.$emit('show-loading', true);
        var nextRoute = "session_cliente_crear";
        this.$router.push({ name: nextRoute }, () => {
        });

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[ClienteListar.vue] crearCliente()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    detalleCliente(idCliente){
      try {
        this.$emit('show-loading', true);
        var nextRoute = "session_cliente_editar";
        this.$router.push({ name: nextRoute, params: { idCliente: idCliente } }, () => {
        });

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[ClienteListar.vue] detalleCliente()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    async buscarClientes(){
      try {
        var objBusqueda = {};
        objBusqueda['currentPage'] = this.currentPage;
        objBusqueda['nombre'] = this.nombre;

        var {default: CommonHelper} = await import('@/helpers/common.helper');

        this.$emit('show-loading',true,"","RIGHT-FULL");
        await CommonHelper.delay(700);
        await this.listarClientes(objBusqueda,true);
        await CommonHelper.delay(700);
        this.$emit('show-loading',false,"","RIGHT-FULL");

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[ClienteListar.vue] buscarClientes()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    async buscarClientesPaginated(page){
      try {
        var objBusqueda = {};
        objBusqueda['currentPage'] = page;
        objBusqueda['nombre'] = this.nombre;

        var {default: CommonHelper} = await import('@/helpers/common.helper');

        this.$emit('show-loading',true,"","RIGHT-FULL");
        await CommonHelper.delay(700);
        await this.listarClientes(objBusqueda,true);
        await CommonHelper.delay(700);
        this.$emit('show-loading',false,"","RIGHT-FULL");

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[ClienteListar.vue] buscarClientesPaginated()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    buscarClienteModal(){
      try {
        this.modal_isClienteListarVisible=true;

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[ClienteListar.vue] buscarClientesPaginated()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    async listarClientes(objBuscar,isInitialLoad=false){
      try {
        var objRequest = {}
        objRequest['currentPage']=objBuscar.currentPage;
        objRequest['nombre']=objBuscar.nombre;

        var {default: ClienteLogic} = await import('@/logic/cliente.logic');
        var httpCliente = await ClienteLogic.listar(objRequest);
        var httpClienteData = httpCliente.data;
        var responseCliente = httpCliente.data.response;

        switch(httpClienteData.status) {
          case "OK":
            if(responseCliente.currentPage=="" || responseCliente.currentPage==null){
              this.currentPage = 1;
            } else {
              this.currentPage = responseCliente.currentPage;
            }

            this.itemsPerPage = responseCliente.itemsPerPage;
            this.lstClientes = responseCliente.currentResults;
            this.paginationLinks = responseCliente.paginationLinks;

            if(responseCliente.paginationLinks.length>1){
              this.isVisiblePagination = true;
            } else {
              this.isVisiblePagination = false;
            }

            break;

          default:
            var error = httpClienteData.message;
            throw Error(error);
        }

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[ClienteListar.vue] listarClientes()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    borrarClientes(){
      try {
        var arrClientes = [];
        this.lstClientes.forEach((objCliente, indexCliente) => {
          if(objCliente.selected){
            arrClientes.push(objCliente.id);
          }
        });

        if(arrClientes.length>0){
          this.modal_isLoading = false;
          this.modal_message = "¿Está seguro que desea borrar estos clientes?";
          this.modal_transition = "RIGHT";
          this.modal_isClosed = false;
          this.modal_isVisible = true;
        }

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[ClienteListar.vue] borrarClientes()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    seleccionarTodo(){
      var isSelected = this.chkSeleccionarTodo;

      if(isSelected){
        this.lstSeleccionarTodo=false;
      } else {
        this.lstSeleccionarTodo=true;
      }

      var that;
      that = this;
      this.lstClientes.forEach((objCliente, indexCliente) => {
        if(that.lstSeleccionarTodo){
          objCliente.selected = true;
        } else {
          objCliente.selected = false;
        }
      });
    },


    // LISTENERS
    handleWindowResize(){
      this.mediaQueries();
    },

    handleCanvasResize(){
      this.canvasResize();
    },


    // MODAL
    async modalBorrarClientes(){
      this.modal_isLoading = true;
      this.modal_isVisible = true;
      this.modal_message = ""
      this.modal_messageLoading = "Borrando información. Un momento por favor."

      var arrClientes = [];
      this.lstClientes.forEach((objCliente, indexCliente) => {
        if(objCliente.selected){
          arrClientes.push(objCliente.id);
        }
      });

      var clienteObj = {};
      clienteObj['clientes'] = arrClientes;

      var {default: ClienteLogic} = await import('@/logic/cliente.logic');
      var httpCliente = await ClienteLogic.borrar(clienteObj);
      var httpClienteData = httpCliente.data;
      var responseCliente = httpCliente.data.response;

      switch(httpClienteData.status) {
        case "OK":
          var objBusqueda = {};
          objBusqueda['currentPage'] = this.currentPage;
          objBusqueda['nombre'] = this.nombre;

          await this.listarClientes(objBusqueda,true);
          this.chkSeleccionarTodo = false;
          this.modal_isLoading = false;
          this.modal_isClosed = false;
          this.modal_isVisible = false;
          break;

        default:
          var objBusqueda = {};
          objBusqueda['currentPage'] = this.currentPage;
          objBusqueda['nombre'] = this.nombre;

          await this.listarClientes(objBusqueda,true);
          this.chkSeleccionarTodo = false;
          this.modal_isLoading = false;
          this.modal_isClosed = false;
          this.modal_isVisible = false;

          var error = httpClienteData.message;
          throw Error(error);
      }
    },

    async modalBuscarClientes(objBusqueda){
      var _objBusqueda = {};
      _objBusqueda["currentPage"] = this.currentPage;
      _objBusqueda["nombre"] = objBusqueda["nombre"];

      this.nombre = objBusqueda["nombre"];
      this.modal_clienteListarLoadingMessage = "Buscando información. Un momento por favor",
      this.modal_isClienteListarLoading = true;
      await this.listarClientes(_objBusqueda,true);
      this.modal_isClienteListarVisible = false;
      this.modal_isClienteListarLoading = false;
    },

    modalCancelar(){
      this.modal_isLoading = false;
      this.modal_isClosed = false;
      this.modal_isVisible = false;
    },
    modalCerrar(){
      this.modal_isClienteListarVisible = false;
    },


    // MODAL ERROR
    modal__ErrorRegistrarExcepcion(excepcion){
      this.modalErrorTitle = "LO SENTIMOS";
      this.modalErrorMessage = "Se produjo un error al realizar la operación. Por favor intente más tarde.";
      this.modalErrorException = excepcion;
      this.isModalErrorMessage = true;
    },
    modal__ErrorAceptar(){
      this.isModalErrorMessage=false;
    },


    // =================
    // PRIVATE FUNCTIONS
    // =================

    listStyle(length,index){
      if(index == length - 1){
        return "page__list-item-container margin-bottom0";
      }

      return "page__list-item-container";
    },
    listStyleMobile(length,index){
      if(index == length - 1){
        return "page__list-item-container-mobile margin-bottom0";
      }

      return "page__list-item-container-mobile"
    },

    mediaQueries() {
      var _headerSpriteWidth = 0;
      var _buttonWidth = 0;
      var _buttonFormWidth = 0;
      var _buttonListWidth = 0;
      var _backgroundImageListWidth = 0;
      var _backgroundImageListHeight = 0;

      const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
      const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );
      const matchMediaLarge = window.matchMedia( "(min-width: 64em)" );

      if (matchMediaSmall.matches) {
        _headerSpriteWidth = 120;
        _buttonWidth = 120;
        _buttonFormWidth = 100;
        _buttonListWidth = 150;
        _backgroundImageListWidth = 120;
        _backgroundImageListHeight = 120;
      }

      if (matchMediaMedium.matches) {
        _headerSpriteWidth = 120;
        _buttonWidth = 120;
        _buttonFormWidth = 100;
        _buttonListWidth = 150;
        _backgroundImageListWidth = 150;
        _backgroundImageListHeight = 150;
      }

      if (matchMediaLarge.matches) {
        _headerSpriteWidth = 120;
        _buttonWidth = 120;
        _buttonFormWidth = 100;
        _buttonListWidth = 150;
        _backgroundImageListWidth = 80;
        _backgroundImageListHeight = 80;
      }

      this.headerSpriteWidth = _headerSpriteWidth;
      this.buttonWidth = _buttonWidth+"px";
      this.buttonFormWidth = _buttonFormWidth+"px";
      this.buttonListWidth = _buttonListWidth+"px";
      this.backgroundImageListWidth = _backgroundImageListWidth+"px";
      this.backgroundImageListHeight = _backgroundImageListHeight+"px";
    },

    canvasResize(){
      var canvasListBorderWidth = this.$refs.canvasHeaderListBorder.width;
      var canvasListBorderHeight = this.$refs.canvasHeaderListBorder.height;

      const border_extraRadius = canvasListBorderHeight*6;
      const border_lineWidth = 35;
      const border_extralineWidth = border_lineWidth*2;
      const border_X = canvasListBorderWidth / 2;
      const border_Y = canvasListBorderHeight+(border_extralineWidth+border_extraRadius);
      const border_radius = (canvasListBorderWidth / 2)+(border_extraRadius);

      this.ctxHeaderListBorder.beginPath();
      this.ctxHeaderListBorder.arc(border_X, border_Y, border_radius, 0, 2 * Math.PI, true);
      this.ctxHeaderListBorder.lineWidth = border_lineWidth;
      this.ctxHeaderListBorder.strokeStyle = "black";
      this.ctxHeaderListBorder.lineCap = 'round';
      this.ctxHeaderListBorder.lineJoin = 'round'
      this.ctxHeaderListBorder.stroke();
      this.ctxHeaderListBorder.closePath();

      this.ctxFooterListBorder.beginPath();
      this.ctxFooterListBorder.arc(border_X, border_Y, border_radius, 0, 2 * Math.PI, true);
      this.ctxFooterListBorder.lineWidth = border_lineWidth;
      this.ctxFooterListBorder.strokeStyle = "black";
      this.ctxFooterListBorder.lineCap = 'round';
      this.ctxFooterListBorder.lineJoin = 'round'
      this.ctxFooterListBorder.stroke();
      this.ctxFooterListBorder.closePath();
    },

    async page__preloadImages() {
      var {default: ImageHelper} = await import('@/helpers/image.helper');


      this.isPageImagesLoad = true;
    },
  },
  beforeRouteLeave (to, from, next) {
    if(window.popStateDetected){
      // HACK: Se mostrará el LOADING siembre que se abandone la página. Esto nos
      // permitirá abordar los casos en que se haga BACK/FORWARD en el browser
      this.$emit('show-loading', true);
    }
    next();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('resize', this.handleCanvasResize);
    window.removeEventListener('popstate', () => { window.popStateDetected = true });
  }
};
</script>

<style scoped>

/* Small only */
@media screen and (max-width: 39.9375em) {
  #page__search-div{
    display:none;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  #page__search-div{
    display:none;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  #page__search-div{
    display:block;
  }
}
</style>