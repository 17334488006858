<template>
  <div id="sidebarContainer" class="sidebar__container">
    <div class="sidebar__content-div">
      <div class="sidebar__menu-container-top">
        <div style="width:100%;text-align:center; padding-top:20px;">
          <img class="sessionLayout__logo" src='@/assets/images/logo_menu.png' />
        </div>
      </div>
      <div class="sidebar__menu-container-middle">
        <div class="sessionLayout__menu-container">
          <div class="sessionLayout__menu-content">
            <span class="sessionLayout__menu-label" @click="goTo('page__inicio-mobile')">INICIO</span>
          </div>
        </div>
        <div class="sessionLayout__menu-container">
          <div class="sessionLayout__menu-content">
            <span class="sessionLayout__menu-label" @click="goTo('page__servicios-mobile')">SERVICIOS</span>
          </div>
        </div>
        <div class="sessionLayout__menu-container">
          <div class="sessionLayout__menu-content">
            <span class="sessionLayout__menu-label" @click="goTo('page__noticias-mobile')">NOTICIAS</span>
          </div>
        </div>
        <div class="sessionLayout__menu-container">
          <div class="sessionLayout__menu-content">
            <span class="sessionLayout__menu-label" @click="goTo('page__clientes-mobile')">CLIENTES</span>
          </div>
        </div>
        <div class="sessionLayout__menu-container">
          <div class="sessionLayout__menu-content">
            <span class="sessionLayout__menu-label" @click="goTo('page__contacto-mobile')">CONTACTO</span>
          </div>
        </div>
      </div>
      <div class="sidebar__menu-container-bottom">
        <div class="sidebar__menu-container-bottom-top">
          <div class="sessionLayout__full-width">
            <div class="sessionLayout__menu-fullwidth-container">
              <span v-if="objInformacion.configuracion">
                <img v-if="objInformacion.configuracion.urlWhatsapp!=''" @click="openUrl(objInformacion.configuracion.urlWhatsapp)" class="sidebar__menu-redsocial" src='@/assets/images/ico_whatsapp.png' />
                <img v-if="objInformacion.configuracion.urlInstagram!=''" @click="openUrl(objInformacion.configuracion.urlInstagram)" class="sidebar__menu-redsocial" src='@/assets/images/ico_instagram.png' />
                <img v-if="objInformacion.configuracion.urlYoutube!=''" @click="openUrl(objInformacion.configuracion.urlYoutube)" class="sidebar__menu-redsocial" src='@/assets/images/ico_youtube.png' />
                <img v-if="objInformacion.configuracion.urlFacebook!=''" @click="openUrl(objInformacion.configuracion.urlFacebook)" class="sidebar__menu-redsocial" src='@/assets/images/ico_facebook.png' />
              </span>
            </div>
          </div>
        </div>
        <div class="sidebar__menu-container-bottom-bottom">
          <div class="sessionLayout__full-width sessionLayout__text-align-center">
            <img class="sidebar__poweredby-logo" src='@/assets/images/poweredby_grey.png' />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "PublicMobileMenu",
    components: {
    },
    props: {
      backgroundColor: {
        type: String,
        default: "#FFFFFF",
      },
      orientation: {
        type: String,
        default: "right",
      },
      width: {
        type: String,
        default: "300px",
      },
    },
    data() {
      return {
        objInformacion: {},
        // Images
        img_logo: '',
      };
    },
    async mounted() {
      try {
        this.$store.dispatch('sidebarMenuStore/setSection',"");
        await this.obtenerInformacionWeb();
        await this.$nextTick();

      } catch (error) {
        console.log("[PublicMobileMenu.vue] mounted()::ERROR = "+error);
      }
    },
    computed: {
    },
    methods: {
      async obtenerInformacionWeb(){
        try {
          var {default: PublicLogic} = await import('@/logic/public.logic');
          var httpPublic = await PublicLogic.informacion();
          var httpPublicData = httpPublic.data;
          var responsePublic = httpPublic.data.response;

          switch(httpPublicData.status) {
            case "OK":
              this.objInformacion = responsePublic;
              break;
            default:
              var error = httpPublicData.message;
              throw Error("[PublicMobileMenu.vue] obtenerInformacionWeb()::ERROR = "+error);
          }

        } catch (error) {
          throw Error("[PublicMobileMenu.vue] obtenerInformacionWeb()::ERROR = "+error);
        }
      },

      goTo(menuName) {
        var sectionBefore = this.$store.getters['sidebarMenuStore/getSection'];
        console.log(">>>> goTo() :: menuName: ",menuName," / sectionBefore: ",sectionBefore);
        this.$store.dispatch('sidebarMenuStore/setSection',menuName);

        var isOpen = this.$store.getters['sidebarMenuStore/getIsOpen'];
        this.$store.dispatch('sidebarMenuStore/setIsOpen',!isOpen);
      },

    }
  }
</script>

<style scoped>

.sessionLayout__full-width{
  width:100%;
}

.sessionLayout__text-align-center{
  text-align:center;
}

.sessionLayout__menu-container{
  display:flex;
  flex-direction:row;
  justify-content:center;
  width:100%;
}
.sessionLayout__menu-fullwidth-container{
  display:flex;
  flex-direction:row;
  justify-content:center;
  width:100%;
}
.sessionLayout__menu-icon{
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
}

.sessionLayout__menu-content{
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  flex:1;
}

.sessionLayout__menu-label{
  font-family:futura-round;
  cursor:pointer;
  color:#00B0A5;
}

.sidebar__content-div{
  position:absolute;
  z-index:3;
  height:100%;
  margin:0;
  padding:0;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  background-color:#FFFFFF;
}
.sidebar__container{
  position:relative;
  width:100%;
  height:100%;
}
.sidebar__menu-container-top{
  width:100%;
}
.sidebar__menu-container-middle{
  width:100%;
  flex:1;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;  /* Remove Scrollbar >> IE and Edge */
  scrollbar-width: none;  /* Remove Scrollbar >> Firefox */
}
.sidebar__menu-container-middle::-webkit-scrollbar {
  display: none; /* Remove Scrollbar >> Chrome */
}
.sidebar__menu-container-bottom{
  display:flex;
  flex-direction:column;
  width:100%;
}
.sidebar__menu-container-bottom-top{
  display:flex;
  flex-direction:column;
  width:100%;
  justify-content:flex-end;
}
.sidebar__menu-container-bottom-bottom{
  display:flex;
  flex-direction:column;
  width:100%;
  flex:1;
  justify-content:flex-end;
}


/* Small only */
@media screen and (max-width: 39.9375em) {
  .sessionLayout__logo{
    width:75%;
  }

  .sessionLayout__menu-container{
    padding-left:12px;
  }

  .sessionLayout__menu-fullwidth-container{
    padding-left:0;
  }

  .sessionLayout__menu-icon{
    width:28px;
  }

  .sessionLayout__menu-icon img{
    width:20px;
  }

  .sessionLayout__menu-content{
    padding-top:4px;
  }

  .sessionLayout__menu-label{
    font-size:1.1em;
    line-height:1em;
    padding-bottom:15px;
  }


  /* SIDEBAR */

  .sidebar__content-div{
    width:200px;
  }

  .sidebar__menu-container-top{
    height:90px;
  }

  .sidebar__menu-container-bottom{
    height:150px;
  }

  .sidebar__menu-container-bottom-top{
    height:40px;
  }

  .sidebar__menu-redsocial{
    width:35px;
    margin:0 8px;
  }

  .sidebar__poweredby-logo{
    width:125px;
    margin-bottom:15px;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .sessionLayout__logo{
    width:75%;
  }

  .sessionLayout__menu-container{
    padding-left:12px;
  }

  .sessionLayout__menu-fullwidth-container{
    padding-left:0;
  }

  .sessionLayout__menu-icon{
    width:28px;
  }

  .sessionLayout__menu-icon img{
    width:20px;
  }

  .sessionLayout__menu-content{
    padding-top:4px;
  }

  .sessionLayout__menu-label{
    font-size:1.2em;
    line-height:1em;
    padding-bottom:15px;
  }


  /* SIDEBAR */

  .sidebar__content-div{
    width:200px;
  }

  .sidebar__menu-container-top{
    height:100px;
  }

  .sidebar__menu-container-bottom{
    height:150px;
    /*background-color: lightgoldenrodyellow;*/
  }

  .sidebar__menu-container-bottom-top{
    height:40px;
  }

  .sidebar__menu-redsocial{
    width:35px;
    margin:0 8px;
  }

  .sidebar__poweredby-logo{
    width:125px;
    margin-bottom:15px;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  .sessionLayout__logo{
    width:110px;
  }

  .sessionLayout__menu-container{
    padding-left:12px;
  }

  .sessionLayout__menu-icon{
    width:28px;
  }

  .sessionLayout__menu-icon img{
    width:20px;
  }

  .sessionLayout__menu-content{
    padding-top:4px;
  }

  .sessionLayout__menu-label{
    font-size:1.2em;
    line-height:1em;
    padding-bottom:15px;
  }


  /* SIDEBAR */

  .sidebar__content-div{
    width:150px;
  }

  .sidebar__menu-container-top{
    height:100px;
  }

  .sidebar__menu-container-bottom{
    height:150px;
  }

  .sidebar__menu-container-bottom-top{
    height:40px;
  }

  .sidebar__menu-redsocial{
    width:40px;
  }

  .sidebar__poweredby-logo{
    width:125px;
    margin-bottom:15px;
  }
}
</style>
