import NotFound from '@/views/pages/error/NotFound';

const notFoundRoute = [
    {   path: "*",
        name: "notfound",
        component: NotFound,
        meta: {
            apiAccessType: "PUBLIC",
            routeAccessType: "PUBLIC",
            requiresAuth: false,
        },
    },
]

export default notFoundRoute