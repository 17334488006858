<template>
  <div :style="computedSectionStyle">
    <div :style="computedContainerStyle" :class="computedContainerClass">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "PageSection",
  props: {
    isFullWidth: {
      type: String,
      default: "N"
    },
    fixedHeight: {
      type: String|Number,
      default: ""
    },
    minHeight: {
      type: String|Number,
      default: "800px"
    },
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      sectionHeight: 0,
    };
  },
  created(){
  },
  async mounted() {
    try {
      await this.$nextTick();

    } catch (error) {
      throw Error("[PageSection.vue] mounted()::ERROR = "+error);
    }
  },
  computed: {
    computedSectionStyle(){
      var fixedHeight = this.$props.fixedHeight;
      var minHeight = this.$props.minHeight;
      var objStyle = {};

      if(fixedHeight!=""){
        objStyle["height"] = fixedHeight;
      } else {
        objStyle["min-height"] = minHeight;
      }

      return objStyle;
    },
    computedContainerStyle(){
      var fixedHeight = this.$props.fixedHeight;
      var minHeight = this.$props.minHeight;
      var objStyle = {};

      objStyle["display"] = "flex";
      objStyle["flex-direction"] = "column";
      objStyle["flex"] = "0 1 auto";
      objStyle["width"] = "100%";

      if(fixedHeight!=""){
        objStyle["height"] = fixedHeight;
      } else {
        objStyle["min-height"] = minHeight;
      }

      return objStyle;
    },
    computedContainerClass(){
      var isFullWidth = this.$props.isFullWidth;

      if(isFullWidth=="N"){
        return "pageSection__container";
      }

      return "";
    },
  },
  methods: {
  }
};
</script>

<style scoped>

.pageSection__container{
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}

/* Small only */
@media screen and (max-width: 39.9375em) {

}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

}

/* Large and up */
@media screen and (min-width: 64em) {

}
</style>