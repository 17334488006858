import StorageHelper from '@/helpers/storage.helper';

const state = {
    authenticated: StorageHelper.getItem('authSession_authenticated') || null,
    user: StorageHelper.getItem('authSession_user') || null,
    homeRoute: StorageHelper.getItem('authSession_homeRoute') || null,
    accessToken: StorageHelper.getItem('authSession_accessToken') || null,
    refreshToken: StorageHelper.getItem('authSession_refreshToken') || null,
}

const getters = {
    getAuthenticated (state) {
        if(state.authenticated==null){
            return false;
        }
        return state.authenticated
    },

    getUser (state) {
        return state.user
    },

    getHomeRoute (state) {
        if(state.homeRoute==null){
            return "";
        }
        return state.homeRoute
    },

    getAccessToken (state) {
        if(state.accessToken==null){
            return "";
        }
        return state.accessToken
    },

    getRefreshToken (state) {
        if(state.refreshToken==null){
            return "";
        }
        return state.refreshToken
    },
}

const mutations = {
    mut_setAuthenticated(state,data) {
        state.authenticated = data;
    },
    mut_deleteAuthenticated(state) {
        state.authenticated= null;
    },

    mut_setUser(state,data) {
        state.user = data;
    },
    mut_deleteUser(state) {
        state.user= null;
    },

    mut_setHomeRoute(state,data) {
        state.homeRoute = data;
    },
    mut_deleteHomeRoute(state) {
        state.homeRoute= null;
    },

    mut_setAccessToken(state,data) {
        state.accessToken = data;
    },
    mut_deleteAccessToken(state) {
        state.accessToken= null;
    },

    mut_setRefreshToken(state,data) {
        state.refreshToken = data;
    },
    mut_deleteRefreshToken(state) {
        state.refreshToken= null;
    },
}

const actions = {
    logIn(context) {
        StorageHelper.setItem('authSession_authenticated', true);
        context.commit('mut_setAuthenticated',true);
    },
    logOut(context) {
        StorageHelper.removeItem('authSession_authenticated');
        StorageHelper.setItem('authSession_authenticated', null);
        context.commit('mut_deleteAuthenticated');

        StorageHelper.removeItem('authSession_user');
        StorageHelper.setItem('authSession_user', null);
        context.commit('mut_deleteUser');

        StorageHelper.removeItem('authSession_accessToken');
        StorageHelper.setItem('authSession_accessToken', null);
        context.commit('mut_deleteAccessToken');

        StorageHelper.removeItem('authSession_refreshToken');
        StorageHelper.setItem('authSession_refreshToken', null);
        context.commit('mut_deleteRefreshToken');
    },

    setUser(context, data) {
        StorageHelper.setItem('authSession_user', data);
        context.commit('mut_setUser', data);
    },
    deleteUser(context) {
        StorageHelper.removeItem('authSession_user');
        context.commit('mut_deleteUser');
    },

    setHomeRoute(context, data) {
        StorageHelper.setItem('authSession_homeRoute', data);
        context.commit('mut_setHomeRoute', data);
    },
    deleteHomeRoute(context) {
        StorageHelper.removeItem('authSession_homeRoute');
        context.commit('mut_deleteHomeRoute');
    },

    setAccessToken(context, data) {
        StorageHelper.setItem('authSession_accessToken', data);
        context.commit('mut_setAccessToken', data);
    },
    deleteAccessToken(context) {
        StorageHelper.removeItem('authSession_accessToken');
        context.commit('mut_deleteAccessToken');
    },

    setRefreshToken(context, data) {
        StorageHelper.setItem('authSession_refreshToken', data);
        context.commit('mut_setRefreshToken', data);
    },
    deleteRefreshToken(context) {
        StorageHelper.removeItem('authSession_refreshToken');
        context.commit('mut_deleteRefreshToken');
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}