<template>
  <div class="page__wrapper">
    <div class="page__container">
      <div class="page__header-container">
        <div class="page__header-container-left">
          <SpriteAnimator
              :id="'sprite'"
              :spritesheet="require('../../../../assets/sprites/appear/spritesheet.png')"
              :json="require('../../../../assets/sprites/appear/spritesheet.json')"
              :yoyo="false"
              :fps="15"
              :width=headerSpriteWidth
              :isLoop="false"
              ref="sprite"
          ></SpriteAnimator>
          <div class="page__header-title">
            MIS USUARIOS
          </div>
        </div>
        <div class="page__header-container-right">
          <Button v-on:action-button-clicked="invitarUsuarioModal()"
                  labelText="INVITAR USUARIO"
                  :width=buttonWidth
                  class="button__margin-right"/>
          <Button v-on:action-button-clicked="crearUsuario()"
                  labelText="CREAR USUARIO"
                  :width=buttonWidth />
        </div>
      </div>
      <div class="page__header-container-mobile">
        <div class="page__header-container-mobile-top">
          <div class="page__header-sprite">
            <SpriteAnimator
                :id="'sprite'"
                :spritesheet="require('../../../../assets/sprites/appear/spritesheet.png')"
                :json="require('../../../../assets/sprites/appear/spritesheet.json')"
                :yoyo="false"
                :fps="15"
                :width=headerSpriteWidth
                :isLoop="false"
                ref="sprite"
            ></SpriteAnimator>
          </div>
          <div class="page__header-title">
            MIS USUARIOS
          </div>
        </div>
        <div class="page__header-container-mobile-botom">
          <Button v-on:action-button-clicked="invitarUsuarioModal()"
                  labelText="INVITAR USUARIO"
                  :width=buttonWidth
                  class="button__margin-right" />
          <Button v-on:action-button-clicked="crearUsuario()"
                  labelText="CREAR USUARIO"
                  :width=buttonWidth
                  class="button__margin-right" />
          <img class="page__header-icon" src='@/assets/images/ico_camera_white.png' @click="buscarUsuarioModal()" />
        </div>
      </div>
      <div class="page__content-container">
        <div class="page__main-list-container">
          <div id="page__search-div" class="page__main-list-container-left">
            <div class="page__form-container page__border-dotted">
              <div class="page__form-search-line">
                <div class="page__form-label">
                  <Label labelText="Nombre" fontFamily="kronika" fontColor="#FFFFFF" />
                </div>
                <div class="full-width">
                  <TextBox placeholder="" maxlength="250" v-model="nombre" />
                </div>
              </div>
              <div class="page__form-search-line">
                <div class="page__form-label">
                  <Label labelText="Primer Apellido" fontFamily="kronika" fontColor="#FFFFFF" />
                </div>
                <div class="full-width">
                  <TextBox placeholder="" maxlength="250" v-model="primerApellido" />
                </div>
              </div>
              <div class="page__form-search-line">
                <div class="page__form-label">
                  <Label labelText="Segundo Apellido" fontFamily="kronika" fontColor="#FFFFFF" />
                </div>
                <div class="full-width">
                  <TextBox placeholder="" maxlength="250" v-model="segundoApellido" />
                </div>
              </div>
              <div class="page__form-search-line">
                <div class="page__form-label">
                  <Label labelText="Correo Electrónico" fontFamily="kronika" fontColor="#FFFFFF" />
                </div>
                <div class="full-width">
                  <TextBox placeholder="" maxlength="250" v-model="email" />
                </div>
              </div>
              <div v-if="objUsuario.perfil.id=='PIX'" class="page__form-search-line">
                <div class="page__form-label">
                  <Label labelText="Perfil" fontFamily="kronika" fontColor="#FFFFFF" />
                </div>
                <div class="full-width">
                  <DropDownList v-model="idPerfil" v-bind:options="idPerfilOpciones" />
                </div>
              </div>
              <div class="full-width">
                <ButtonForm v-on:action-button-clicked="buscarUsuarios()"
                            labelText="BUSCAR"
                            :width=buttonFormWidth />
              </div>
            </div>
          </div>
          <div class="page__main-list-container-right">
            <div class="page__list-header-container">
              <div class="page__list-header-container-top">
                <div class="page__list-header-container-top-left">
                  <div class="page__list-flex-shrink">
                    <CheckBoxForm backgroundColor="#FFFFFF" v-model="chkSeleccionarTodo" v-on:action-checkbox-clicked="seleccionarTodo()" />
                  </div>
                  <div class="page__list-header-checkbox-label-container">
                    <span class="page__list-header-checkbox-label">Seleccionar todo</span>
                  </div>
                  <div class="page__list-flex-shrink">
                    <ButtonForm v-on:action-button-clicked="borrarUsuarios()"
                                labelText="BORRAR"
                                bdColor="#C00606"
                                bgColor="#FFFFFF"
                                fontColor="#C00606"
                                :width=buttonFormWidth />
                  </div>
                </div>
                <div class="page__list-header-container-top-right">
                  <div class="page__list-paginator-container">
                    <span v-if="isVisiblePagination" v-for="page in paginationLinks">
                      <span v-if="page==currentPage">
                        <span class="page__list-paginator-link-selected">
                          {{ page }}
                        </span>
                      </span>
                      <span v-else class="page__list-paginator-link">
                        <span v-if="page!='...'" style="margin-left:20px;" @click="buscarUsuariosPaginated(page)">
                          {{ page }}
                        </span>
                        <span v-else style="margin-left:10px;" >
                          {{ page }}
                        </span>
                      </span>
                    </span>
                    <span v-else class="page__list-paginator-link">
                      &nbsp
                    </span>
                  </div>
                </div>
              </div>
              <div class="page__list-header-container-bottom">
                <canvas ref="canvasHeaderListBorder" class="page__list-canvas-border"></canvas>
              </div>
            </div>
            <div class="page__list-container">
              <div v-if="lstUsuarios.length>0">
                <div v-for="(objLstUsuario, indexUsuario) in lstUsuarios" :key="indexUsuario">
                  <div :class="listStyle(lstUsuarios.length,indexUsuario)">
                    <div class="page__list-item-container-left">
                      <div class="page__list-item-column-checkbox">
                        <CheckBoxForm backgroundColor="#FFFFFF" v-model="objLstUsuario.selected" />
                      </div>
                      <div class="page__list-item-column-image">
                        <BackgroundImage isBordered="S" :height=backgroundImageListWidth :width=backgroundImageListHeight positionHeight="center" :backgroundURL="objLstUsuario.rutaImagen" style="border-radius:8px;" />
                      </div>
                      <div class="page__list-item-column-label">
                        <div v-if="objUsuario.perfil.id=='PIX'" class="page__list-item-label-sub color-6B6B6B">
                          {{ objLstUsuario.perfil.nombre }}
                        </div>
                        <div class="page__list-item-label-main">
                          {{ (objLstUsuario.primerApellido+" "+objLstUsuario.segundoApellido+", "+objLstUsuario.nombre).toUpperCase() }}
                        </div>
                        <div class="page__list-item-label-mainsub color-FF5229">
                          {{ objLstUsuario.email }}
                        </div>
                      </div>
                    </div>
                    <div class="page__list-item-container-right">
                      <ButtonForm v-on:action-button-clicked="detalleUsuario(objLstUsuario.id)"
                                  labelText="EDITAR USUARIO"
                                  bdColor="#FF5229"
                                  bgColor="#FFFFFF"
                                  fontColor="#FF5229"
                                  :width=buttonListWidth />
                    </div>
                  </div>
                  <div :class="listStyleMobile(lstUsuarios.length,indexUsuario)">
                    <div class="page__list-item-container-mobile-left">
                      <CheckBoxForm backgroundColor="#FFFFFF" v-model="objLstUsuario.selected" />
                    </div>
                    <div class="page__list-item-container-mobile-right">
                      <div class="page__list-item-column-image-mobile">
                        <BackgroundImage isBordered="S" :height=backgroundImageListWidth :width=backgroundImageListHeight positionHeight="center" :backgroundURL="objLstUsuario.rutaImagen" style="border-radius:8px;" />
                      </div>
                      <div class="page__list-item-column-label-mobile">
                        <div v-if="objUsuario.perfil.id=='PIX'" class="page__list-item-label-sub color-6B6B6B">
                          {{ objLstUsuario.perfil.nombre }}
                        </div>
                        <div class="page__list-item-label-main">
                          {{ (objLstUsuario.primerApellido+" "+objLstUsuario.segundoApellido+", "+objLstUsuario.nombre).toUpperCase() }}
                        </div>
                        <div class="page__list-item-label-mainsub color-FF5229">
                          {{ objLstUsuario.email }}
                        </div>
                      </div>
                      <div class="page__list-item-column-options-mobile">
                        <ButtonForm v-on:action-button-clicked="detalleUsuario(objLstUsuario.id)"
                                    labelText="EDITAR USUARIO"
                                    bdColor="#FF5229"
                                    bgColor="#FFFFFF"
                                    fontColor="#FF5229"
                                    :width=buttonListWidth />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="page__list-item-noregistros">
                /* NO PRESENTA REGISTROS DISPONIBLES */
              </div>
            </div>
            <div class="page__list-footer-container">
              <div class="page__list-footer-container-top">
                <canvas ref="canvasFooterListBorder" class="page__list-canvas-border"></canvas>
              </div>
              <div class="page__list-footer-container-bottom">
                <div class="page__list-paginator-container">
                    <span v-if="isVisiblePagination" v-for="page in paginationLinks">
                      <span v-if="page==currentPage">
                        <span class="page__list-paginator-link-selected">
                          {{ page }}
                        </span>
                      </span>
                      <span v-else class="page__list-paginator-link">
                        <span v-if="page!='...'" style="margin-left:20px;" @click="buscarUsuariosPaginated(page)">
                          {{ page }}
                        </span>
                        <span v-else style="margin-left:10px;" >
                          {{ page }}
                        </span>
                      </span>
                    </span>
                  <span v-else class="page__list-paginator-link">
                      &nbsp
                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal type="CONFIRM"
           :message="modal_message"
           :messageLoading="modal_messageLoading"
           :isVisible="modal_isVisible"
           :isLoading="modal_isLoading"
           :transition="modal_transition"
           @aceptar="modalBorrarUsuarios"
           @cancelar="modalBorrarUsuariosCancelar" />
    <ModalError :title="modalErrorTitle"
                :message="modalErrorMessage"
                :exception="modalErrorException"
                v-model="isModalErrorMessage"
                v-on:aceptar="modal__ErrorAceptar()" />
    <UsuarioListarModal   :isVisible="modal_isUsuarioListarVisible"
                          :isLoading="modal_isUsuarioListarLoading"
                          :transition="modal_usuarioListarTransition"
                          :loadingMessage="modal_usuarioListarLoadingMessage"
                          :style="computedUsuarioListarModalStyle"
                          @close="modalUsuarioBuscarCerrar"
                          @buscar="modalUsuarioBuscar" />
    <UsuarioInvitarModal  :isVisible="modal_isUsuarioInvitarVisible"
                          :isLoading="modal_isUsuarioInvitarLoading"
                          :transition="modal_usuarioInvitarTransition"
                          :loadingMessage="modal_usuarioInvitarLoadingMessage"
                          :style="computedUsuarioInvitarModalStyle"
                          @close="modalUsuarioInvitarCerrar"
                          @invitar="modalUsuarioInvitar" />
  </div>
</template>

<script>
import SpriteAnimator from "@/components/SpriteAnimator";
import Button from "@/components/Button";
import ButtonForm from "@/components/ButtonForm";
import TextBox from "@/components/TextBox";
import CheckBoxForm from "@/components/CheckBoxForm";
import BackgroundImage from "@/components/BackgroundImage";
import Label from "@/components/Label";
import DropDownList from "@/components/DropDownList";
import Modal from "@/components/Modal";
import ModalError from "@/components/ModalError";
import UsuarioListarModal from "@/views/pages/admin/usuario/UsuarioListarModal";
import UsuarioInvitarModal from "@/views/pages/admin/usuario/UsuarioInvitarModal";

export default {
  name: "UsuarioListar",
  components: {
    SpriteAnimator,
    Button,
    ButtonForm,
    TextBox,
    CheckBoxForm,
    BackgroundImage,
    Label,
    DropDownList,
    Modal,
    ModalError,
    UsuarioListarModal,
    UsuarioInvitarModal
  },
  data() {
    return {
      objUsuario: {},
      idPerfilOpciones: [],

      // MEDIA QUERIES
      headerSpriteWidth:0,
      buttonWidth:"0",
      buttonFormWidth:"0",
      buttonListWidth:"0",
      backgroundImageListWidth:"0",
      backgroundImageListHeight:"0",

      // VALIDATE
      mensajeError: '',
      contadorImagenes: 0,

      // FORM
      nombre: '',
      primerApellido: '',
      segundoApellido: '',
      email: '',
      idPerfil: '',

      // PAGINATION
      itemsPerPage: 1,
      currentPage: 1,
      lstUsuarios: [],
      paginationLinks: [],
      chkSeleccionarTodo: false,
      lstSeleccionarTodo: false,
      loadPagination: true,
      isVisiblePagination: false,

      // IMAGES
      isPageImagesLoad: true, // No hay imagenes locales
      isExternalImagesLoad: true, // No hay imagenes externas
      isMountFinished: false,

      // MODAL
      modal_message:'',
      modal_messageLoading:'',
      modal_isVisible:false,
      modal_isLoading:false,
      modal_transition:"",

      modal_isUsuarioListarVisible:false,
      modal_isUsuarioListarLoading:false,
      modal_isUsuarioListarClosed:false, // Aqui indicamos si cerramos de inmediato el modal
      modal_usuarioListarTransition:"",
      modal_usuarioListarLoadingMessage:"",

      modal_isUsuarioInvitarVisible:false,
      modal_isUsuarioInvitarLoading:false,
      modal_isUsuarioInvitarClosed:false, // Aqui indicamos si cerramos de inmediato el modal
      modal_usuarioInvitarTransition:"",
      modal_usuarioInvitarLoadingMessage:"",

      // MODAL ERROR
      isModalErrorMessage:false,
      modalErrorTitle:'',
      modalErrorMessage:'',
      modalErrorException:'',
    };
  },
  created() {
    try {
      this.modal_transition = "";
      this.objUsuario = this.$store.getters['authSessionStore/getUser'];
      window.addEventListener('resize', this.handleWindowResize);
      this.handleWindowResize();

    } catch (error) {
      console.log("[UsuarioListar.vue] created()::ERROR = "+error);
    }
  },
  async mounted() {
    try {
      // BROWSER BACK/FORWARD BUTTON
      window.popStateDetected = false
      window.addEventListener('popstate', () => { window.popStateDetected = true });

      this.idPerfil = "";
      window.addEventListener('resize', this.handleCanvasResize);
      this.handleCanvasResize();
      await this.obtenerIdPerfilOpciones();

      var objBusqueda = {};
      objBusqueda['currentPage'] = this.currentPage;
      objBusqueda['idPerfilRequest'] = this.objUsuario.perfil.id;
      objBusqueda['nombre'] = this.nombre;
      objBusqueda['primerApellido'] = this.primerApellido;
      objBusqueda['segundoApellido'] = this.segundoApellido;
      objBusqueda['email'] = this.email;
      objBusqueda['idPerfil'] = this.idPerfil;

      await this.listarUsuarios(objBusqueda,true);
      await this.page__preloadImages();
      await this.$nextTick();
      this.isMountFinished = true;

    } catch (error) {
      this.$emit('show-loading', false);
      var tmpExcepcion = "[Index.vue] mounted()::ERROR >>> "+error;
      var strExcepcion = tmpExcepcion.replace('Error:', '');
      this.modal__ErrorRegistrarExcepcion(strExcepcion);
    }
  },
  computed: {
    computedUsuarioListarModalStyle() {
      var objStyle = {};
      objStyle["visibility"] = "visible";

      if(this.modal_isUsuarioListarClosed){
        objStyle["visibility"] = "hidden";
      }

      return objStyle;
    },
    computedUsuarioInvitarModalStyle() {
      var objStyle = {};
      objStyle["visibility"] = "visible";

      if(this.modal_isUsuarioListarClosed){
        objStyle["visibility"] = "hidden";
      }

      return objStyle;
    },

    canvasHeaderListBorder: function () {
      return this.$refs.canvasHeaderListBorder;
    },
    ctxHeaderListBorder: function () {
      return this.canvasHeaderListBorder.getContext('2d');
    },
    canvasFooterListBorder: function () {
      return this.$refs.canvasFooterListBorder;
    },
    ctxFooterListBorder: function () {
      return this.canvasFooterListBorder.getContext('2d');
    },
    page__isAllContentLoaded(){
      return this.isPageImagesLoad && this.isExternalImagesLoad && this.isMountFinished;
    },
  },
  watch: {
    page__isAllContentLoaded(newValue) {
      if(newValue){
        var that;
        that = this;
        setTimeout(function () {
          that.$emit('show-loading', false);
        }, 1500);
      }
    }
  },
  methods: {
    crearUsuario(){
      try {
        this.$emit('show-loading', true);
        var nextRoute = "session_usuario_crear";
        this.$router.push({ name: nextRoute }, () => {
        });

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[UsuarioListar.vue] crearUsuario()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    detalleUsuario(idUsuario){
      try {
        this.$emit('show-loading', true);
        var nextRoute = "session_usuario_editar";
        this.$router.push({ name: nextRoute, params: { idUsuario: idUsuario } }, () => {
        });

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[UsuarioListar.vue] detalleUsuario()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    async buscarUsuarios(){
      try {
        var objBusqueda = {};
        objBusqueda['currentPage'] = this.currentPage;
        objBusqueda['idPerfilRequest'] = this.objUsuario.perfil.id;
        objBusqueda['nombre'] = this.nombre;
        objBusqueda['primerApellido'] = this.primerApellido;
        objBusqueda['segundoApellido'] = this.segundoApellido;
        objBusqueda['email'] = this.email;
        objBusqueda['idPerfil'] = this.idPerfil;

        var {default: CommonHelper} = await import('@/helpers/common.helper');

        this.$emit('show-loading',true,"","RIGHT-FULL");
        await CommonHelper.delay(700);
        await this.listarUsuarios(objBusqueda,true);
        await CommonHelper.delay(700);
        this.$emit('show-loading',false,"","RIGHT-FULL");

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[UsuarioListar.vue] buscarUsuarios()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    async buscarUsuariosPaginated(page){
      try {
        var objBusqueda = {};
        objBusqueda['currentPage'] = this.currentPage;
        objBusqueda['idPerfilRequest'] = this.objUsuario.perfil.id;
        objBusqueda['nombre'] = this.nombre;
        objBusqueda['primerApellido'] = this.primerApellido;
        objBusqueda['segundoApellido'] = this.segundoApellido;
        objBusqueda['email'] = this.email;
        objBusqueda['idPerfil'] = this.idPerfil;

        var {default: CommonHelper} = await import('@/helpers/common.helper');

        this.$emit('show-loading',true,"","RIGHT-FULL");
        await CommonHelper.delay(700);
        await this.listarUsuarios(objBusqueda,true);
        await CommonHelper.delay(700);
        this.$emit('show-loading',false,"","RIGHT-FULL");

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[UsuarioListar.vue] buscarUsuariosPaginated()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    async invitarUsuario(objInvitacion){
      try {
        var {default: InvitacionLogic} = await import('@/logic/invitacion.logic');
        var httpInvitacion = await InvitacionLogic.invitar(objInvitacion);
        var httpInvitacionData = httpInvitacion.data;
        var responseInvitacion = httpInvitacion.data.response;

        switch(httpInvitacionData.status) {
          case "OK":
            break;

          default:
            var error = httpInvitacionData.message;
            throw Error(error);
        }

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[UsuarioListar.vue] invitarUsuario()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    async listarUsuarios(objBuscar,isInitialLoad=false){
      try {
        this.objUsuario = this.$store.getters['authSessionStore/getUser'];

        var {default: UsuarioLogic} = await import('@/logic/usuario.logic');
        var httpUsuario = await UsuarioLogic.listar(objBuscar);
        var httpUsuarioData = httpUsuario.data;
        var responseUsuario = httpUsuario.data.response;

        switch(httpUsuarioData.status) {
          case "OK":
            if(responseUsuario.currentPage=="" || responseUsuario.currentPage==null){
              this.currentPage = 1;
            } else {
              this.currentPage = responseUsuario.currentPage;
            }

            this.itemsPerPage = responseUsuario.itemsPerPage;
            this.lstUsuarios = responseUsuario.currentResults;
            this.paginationLinks = responseUsuario.paginationLinks;

            if(responseUsuario.paginationLinks.length>1){
              this.isVisiblePagination = true;
            } else {
              this.isVisiblePagination = false;
            }

            break;

          default:
            var error = httpUsuarioData.message;
            throw Error(error);
        }

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[UsuarioListar.vue] listarUsuarios()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    borrarUsuarios(){
      try {
        var arrUsuarios = [];
        this.lstUsuarios.forEach((objUsuario, indexUsuario) => {
          if(objUsuario.selected){
            arrUsuarios.push(objUsuario.id);
          }
        });

        if(arrUsuarios.length>0){
          this.modal_message = "¿Está seguro que desea borrar estos usuarios?";
          this.modal_transition = "RIGHT";
          this.modal_isVisible = true;
        }

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[UsuarioListar.vue] borrarUsuarios()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    seleccionarTodo(){
      var isSelected = this.chkSeleccionarTodo;

      if(isSelected){
        this.lstSeleccionarTodo=false;
      } else {
        this.lstSeleccionarTodo=true;
      }

      var that;
      that = this;
      this.lstUsuarios.forEach((objUsuario, indexUsuario) => {
        if(that.lstSeleccionarTodo){
          objUsuario.selected = true;
        } else {
          objUsuario.selected = false;
        }
      });
    },


    // ===========
    //  LISTENERS
    // ===========

    handleWindowResize(){
      this.mediaQueries();
    },

    handleCanvasResize(){
      this.canvasResize();
    },

    // =======
    //  MODAL
    // =======

    // --------
    //  Borrar
    // --------

    async modalBorrarUsuarios(){
      this.modal_isLoading = true;
      this.modal_isVisible = true;
      this.modal_message = ""
      this.modal_messageLoading = "Borrando información. Un momento por favor."

      var arrUsuarios = [];
      this.lstUsuarios.forEach((objUsuario, indexUsuario) => {
        if(objUsuario.selected){
          arrUsuarios.push(objUsuario.id);
        }
      });

      var usuarioObj = {};
      usuarioObj['usuarios'] = arrUsuarios;

      var {default: UsuarioLogic} = await import('@/logic/usuario.logic');
      var httpUsuario = await UsuarioLogic.borrar(usuarioObj);
      var httpUsuarioData = httpUsuario.data;
      var responseUsuario = httpUsuario.data.response;

      switch(httpUsuarioData.status) {
        case "OK":
          var objBusqueda = {};
          objBusqueda['currentPage'] = this.currentPage;
          objBusqueda['idPerfilRequest'] = this.objUsuario.perfil.id;
          objBusqueda['nombre'] = this.nombre;
          objBusqueda['primerApellido'] = this.primerApellido;
          objBusqueda['segundoApellido'] = this.segundoApellido;
          objBusqueda['email'] = this.email;
          objBusqueda['idPerfil'] = this.idPerfil;

          await this.listarUsuarios(objBusqueda,true);
          this.chkSeleccionarTodo = false;
          this.modal_isLoading = false;
          this.modal_isVisible = false;
          break;

        default:
          var objBusqueda = {};
          objBusqueda['currentPage'] = this.currentPage;
          objBusqueda['nombre'] = this.nombre;

          await this.listarUsuarios(objBusqueda,true);
          this.chkSeleccionarTodo = false;
          this.modal_isLoading = false;
          this.modal_isVisible = false;

          var error = httpUsuarioData.message;
          throw Error(error);
      }
    },

    modalBorrarUsuariosCancelar(){
      this.modal_isLoading = false;
      this.modal_isVisible = false;
    },

    // --------
    //  Buscar
    // --------

    buscarUsuarioModal(){
      try {
        this.modal_isUsuarioListarVisible=true;

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[UsuarioListar.vue] buscarUsuarioModal()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    async modalUsuarioBuscar(objBusqueda){
      var _objBusqueda = {};
      _objBusqueda['currentPage'] = this.currentPage;
      _objBusqueda['idPerfilRequest'] = this.objUsuario.perfil.id;
      _objBusqueda['nombre'] = objBusqueda["nombre"];
      _objBusqueda['primerApellido'] = objBusqueda["primerApellido"];
      _objBusqueda['segundoApellido'] = objBusqueda["segundoApellido"];
      _objBusqueda['email'] = objBusqueda["email"];

      this.nombre = objBusqueda["nombre"];
      this.primerApellido = objBusqueda["primerApellido"];
      this.segundoApellido = objBusqueda["segundoApellido"];
      this.email = objBusqueda["email"];

      this.modal_usuarioListarLoadingMessage = "Buscando información. Un momento por favor";
      this.modal_isUsuarioListarLoading = true;
      await this.listarUsuarios(_objBusqueda,true);
      this.modal_isUsuarioListarVisible = false;
      this.modal_isUsuarioListarLoading = false;
    },

    modalUsuarioBuscarCerrar(){
      this.modal_isUsuarioListarVisible = false;
    },

    // ---------
    //  Invitar
    // ---------

    invitarUsuarioModal(){
      try {
        this.modal_isUsuarioInvitarVisible=true;

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[UsuarioListar.vue] invitarUsuarioModal()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },

    async modalUsuarioInvitar(objInvitacion){
      this.modal_usuarioInvitarLoadingMessage = "Enviando información. Un momento por favor";
      this.modal_isUsuarioInvitarLoading = true;
      //await this.invitarUsuario(objInvitacion);

      var {default: CommonHelper} = await import('@/helpers/common.helper');
      await CommonHelper.delay(700);

      this.modal_isUsuarioInvitarVisible = false;
      this.modal_isUsuarioInvitarLoading = false;
    },

    modalUsuarioInvitarCerrar(){
      this.modal_isUsuarioInvitarVisible = false;
    },

    // -------------
    //  Modal Error
    // -------------

    modal__ErrorRegistrarExcepcion(excepcion){
      this.modalErrorTitle = "LO SENTIMOS";
      this.modalErrorMessage = "Se produjo un error al realizar la operación. Por favor intente más tarde.";
      this.modalErrorException = excepcion;
      this.isModalErrorMessage = true;
    },
    modal__ErrorAceptar(){
      this.isModalErrorMessage=false;
    },


    // =================
    // PRIVATE FUNCTIONS
    // =================

    async obtenerIdPerfilOpciones(){
      try {
        this.idPerfilOpciones = [];

        var optionObj = {};
        optionObj['id'] = "";
        optionObj['nombre'] = "Seleccione opción";
        this.idPerfilOpciones.push(optionObj);

        var {default: PerfilLogic} = await import('@/logic/perfil.logic');
        var httpPerfil = await PerfilLogic.dropdownlist();
        var httpPerfilData = httpPerfil.data;
        var responsePerfil = httpPerfil.data.response;

        switch(httpPerfilData.status) {
          case "OK":
            responsePerfil.forEach((objPerfil, indexPerfil) => {
              if(objPerfil.id=="PIX"){
                if(this.objUsuario.perfil.id=="PIX"){
                  var optionObj = {};
                  optionObj['id'] = objPerfil.id;
                  optionObj['nombre'] = objPerfil.nombre;
                  this.idPerfilOpciones.push(optionObj);
                }
                if(this.objUsuario.perfil.id=="SYS"){
                  var optionObj = {};
                  optionObj['id'] = objPerfil.id;
                  optionObj['nombre'] = objPerfil.nombre;
                  this.idPerfilOpciones.push(optionObj);
                }
              } else {
                var optionObj = {};
                optionObj['id'] = objPerfil.id;
                optionObj['nombre'] = objPerfil.nombre;
                this.idPerfilOpciones.push(optionObj);
              }
            });

            break;

          default:
            var error = httpPerfilData.message;
            throw Error(error);
        }

      } catch (error) {
        throw Error("[UsuarioCrear.vue] obtenerIdPerfilOpciones()::ERROR = "+error);
      }
    },

    listStyle(length,index){
      if(index == length - 1){
        return "page__list-item-container margin-bottom0";
      }

      return "page__list-item-container";
    },
    listStyleMobile(length,index){
      if(index == length - 1){
        return "page__list-item-container-mobile margin-bottom0";
      }

      return "page__list-item-container-mobile"
    },

    mediaQueries() {
      var _headerSpriteWidth = 0;
      var _buttonWidth = 0;
      var _buttonFormWidth = 0;
      var _buttonListWidth = 0;
      var _backgroundImageListWidth = 0;
      var _backgroundImageListHeight = 0;

      const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
      const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );
      const matchMediaLarge = window.matchMedia( "(min-width: 64em)" );

      if (matchMediaSmall.matches) {
        _headerSpriteWidth = 120;
        _buttonWidth = 120;
        _buttonFormWidth = 100;
        _buttonListWidth = 150;
        _backgroundImageListWidth = 120;
        _backgroundImageListHeight = 120;
      }

      if (matchMediaMedium.matches) {
        _headerSpriteWidth = 120;
        _buttonWidth = 120;
        _buttonFormWidth = 100;
        _buttonListWidth = 150;
        _backgroundImageListWidth = 150;
        _backgroundImageListHeight = 150;
      }

      if (matchMediaLarge.matches) {
        _headerSpriteWidth = 120;
        _buttonWidth = 120;
        _buttonFormWidth = 100;
        _buttonListWidth = 150;
        _backgroundImageListWidth = 80;
        _backgroundImageListHeight = 80;
      }

      this.headerSpriteWidth = _headerSpriteWidth;
      this.buttonWidth = _buttonWidth+"px";
      this.buttonFormWidth = _buttonFormWidth+"px";
      this.buttonListWidth = _buttonListWidth+"px";
      this.backgroundImageListWidth = _backgroundImageListWidth+"px";
      this.backgroundImageListHeight = _backgroundImageListHeight+"px";
    },

    canvasResize(){
      var canvasListBorderWidth = this.$refs.canvasHeaderListBorder.width;
      var canvasListBorderHeight = this.$refs.canvasHeaderListBorder.height;

      const border_extraRadius = canvasListBorderHeight*6;
      const border_lineWidth = 35;
      const border_extralineWidth = border_lineWidth*2;
      const border_X = canvasListBorderWidth / 2;
      const border_Y = canvasListBorderHeight+(border_extralineWidth+border_extraRadius);
      const border_radius = (canvasListBorderWidth / 2)+(border_extraRadius);

      this.ctxHeaderListBorder.beginPath();
      this.ctxHeaderListBorder.arc(border_X, border_Y, border_radius, 0, 2 * Math.PI, true);
      this.ctxHeaderListBorder.lineWidth = border_lineWidth;
      this.ctxHeaderListBorder.strokeStyle = "black";
      this.ctxHeaderListBorder.lineCap = 'round';
      this.ctxHeaderListBorder.lineJoin = 'round'
      this.ctxHeaderListBorder.stroke();
      this.ctxHeaderListBorder.closePath();

      this.ctxFooterListBorder.beginPath();
      this.ctxFooterListBorder.arc(border_X, border_Y, border_radius, 0, 2 * Math.PI, true);
      this.ctxFooterListBorder.lineWidth = border_lineWidth;
      this.ctxFooterListBorder.strokeStyle = "black";
      this.ctxFooterListBorder.lineCap = 'round';
      this.ctxFooterListBorder.lineJoin = 'round'
      this.ctxFooterListBorder.stroke();
      this.ctxFooterListBorder.closePath();
    },

    async page__preloadImages() {
      var {default: ImageHelper} = await import('@/helpers/image.helper');

      this.isPageImagesLoad = true;
    },
  },
  beforeRouteLeave (to, from, next) {
    if(window.popStateDetected){
      // HACK: Se mostrará el LOADING siembre que se abandone la página. Esto nos
      // permitirá abordar los casos en que se haga BACK/FORWARD en el browser
      this.$emit('show-loading', true);
    }
    next();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleCanvasResize);
    window.removeEventListener('popstate', () => { window.popStateDetected = true });
  }
};
</script>

<style scoped>

/* Small only */
@media screen and (max-width: 39.9375em) {
  #page__search-div{
    display:none;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  #page__search-div{
    display:none;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  #page__search-div{
    display:block;
  }
}
</style>