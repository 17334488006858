<template>
    <div class="button__border" :style="[computedBackgroundColor,computedBorderRadius]" v-on:click="clicked()">
      <div class="button__container">
          <div class="button__container-text">
              <span class="button__label" :style="computedFontColor">{{ labelText }}</span>
          </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: "ButtonForm",
    props: {
        labelText: {
            type: String,
            default: "",
        },
        bdColor: {
          type: String,
          default: "#000000",
        },
        bgColor: {
            type: String,
            default: "#FFFFFF",
        },
        borderRadius: {
          type: [Number,String],
          default: 0,
        },
        fontColor: {
            type: String,
            default: "#000000",
        },
        width: {
          type: String,
          default: "",
        },
        isError:{
            type: Boolean,
            default: false,
        }
    },
    async mounted() {
      try {
        //await this.$nextTick();

      } catch (error) {
        console.log("[ButtonForm.vue] mounted()::ERROR = "+error);
      }
    },
    computed: {
        computedBackgroundColor() {
            var isError = this.$props.isError;

            if(isError){
                return {
                    border: '2px solid #BD0000',
                    backgroundColor: '#BD0000'
                };
            }

            return {
                border: '2px solid '+this.$props.bdColor,
                backgroundColor: this.$props.bgColor
            };
        },
        computedBorderRadius() {
          if(this.$props.width===""){
            if(this.$props.borderRadius===0){
              return {
                'border-radius': 0,
              };
            }

            return {
              'border-radius': this.$props.borderRadius,
            };
          } else {
            if(this.$props.borderRadius===0){
              return {
                'border-radius': 0,
                'width': this.$props.width,
              };
            }

            return {
              'border-radius': this.$props.borderRadius,
              'width': this.$props.width,
            };
          }
        },
        computedFontColor() {
            var isError = this.$props.isError;

            if(isError){
                return {
                    color: '#FFFFFF'
                };
            }

            return {
                color: this.$props.fontColor
            };
        }
    },
    methods: {
        clicked: function () {
            this.$emit('action-button-clicked')
        },
        updateValue: function (value) {
            this.$emit('input', value)
        }
    }
  };
</script>

<style scoped>
  .button__container{
    width:100%;
  }

  .button__container-text{
    display:flex;
    flex-direction:row;
    width:100%;
    justify-content:center;
    align-items:center;
  }

  .button__border{
    cursor:pointer;
  }

  .button__label{
    padding:0;
    margin:0;
    font-family: 'kronika';
  }

  /* Small only */
  @media screen and (max-width: 39.9375em) {
    .button__border{
      padding:7px 0 7px 0;
    }

    .button__label{
      font-size:0.7em;
      line-height:0.7em;
    }
  }

  /* Medium only */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .button__border{
      padding:7px 0 7px 0;
    }

    .button__label{
      font-size:0.7em;
      line-height:0.7em;
    }
  }

  /* Large and up */
  @media screen and (min-width: 64em) {
    .button__border{
      padding:8px 0 8px 0;
    }

    .button__label{
      font-size:0.7em;
      line-height:0.7em;
    }
  }

</style>
