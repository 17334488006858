<template>
  <div class="page__contacto index__fullpage-container">
    <div class="notfound__fullpage-centered">
      <div class="notfound__container">
        <div class="notfound__logo-image">
          <img class="menu__logo" src='@/assets/images/logo_menu.png' />
        </div>
        <div class="notfound__line-code">
          404
        </div>
        <div class="notfound__line">
          <div class="notfound__mensaje">
            Lo sentimos, la página que está buscando no se pudo encontrar.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  components: {
  },
  data() {
    return {
      // IMAGES
      isPageImagesLoad: true,
      isExternalImagesLoad: true,
      isMountFinished: false,
    };
  },
  created() {
    try {
    } catch (error) {
      console.log("[NotFound.vue] created()::ERROR = "+error);
    }
  },
  async mounted() {
    try {
      // BROWSER BACK/FORWARD BUTTON
      window.popStateDetected = false
      window.addEventListener('popstate', () => { window.popStateDetected = true });

      await this.page__preloadImages();
      await this.$nextTick();
      this.isMountFinished = true;

    } catch (error) {
      console.log("[NotFound.vue] mounted()::ERROR = "+error);
    }
  },
  computed: {
    page__isAllContentLoaded(){
      return this.isPageImagesLoad && this.isExternalImagesLoad && this.isMountFinished;
    },
  },
  watch: {
    page__isAllContentLoaded(newValue) {
      if(newValue){
        var that;
        that = this;
        setTimeout(function () {
          that.$emit('show-loading', false);
        }, 1500);
      }
    },
  },
  methods: {
    async page__preloadImages() {
      var {default: ImageHelper} = await import('@/helpers/image.helper');

      this.isPageImagesLoad = true;
    },
  },
  beforeRouteLeave (to, from, next) {
    if(window.popStateDetected){
      // HACK: Se mostrará el LOADING siembre que se abandone la página. Esto nos
      // permitirá abordar los casos en que se haga BACK/FORWARD en el browser
      this.$emit('show-loading', true);
    }
    next();
  },
  beforeDestroy() {
    window.removeEventListener('popstate', () => { window.popStateDetected = true });
  },
};
</script>

<style scoped>
.notfound__line-code{
  width:100%;
  text-align:center;
  font-family:'futura-round-cond-medium';
  color:#00B0A5;

  font-size:4.5em;
}

.notfound__fullpage-centered{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  width:100%;
  min-height:100vh;
}

.notfound__container{
  margin:0 auto;
}

.notfound__line{
  width:100%;
}

.notfound__mensaje{
  width:100%;
  text-align:center;
  color:#000000;
  font-family:"futura-round-cond-medium"
}

.notfound__logo-image{
  display:flex;
  flex-direction:row;
  justify-content:center;
  width:100%;
}


/* Small only */
@media screen and (max-width: 39.9375em) {
  .notfound__container{
    width:90%;
  }

  .notfound__line{
    padding-bottom:10px;
  }

  .notfound__mensaje{
    font-size:1.3em;
    line-height:1.1em;
    margin:20px 0;
  }

  .notfound__logo-image{
    padding-bottom:20px;
  }

  .menu__logo{
    width:200px;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .notfound__container{
    width:350px;
  }

  .notfound__line{
    padding-bottom:10px;
  }

  .notfound__mensaje{
    font-size:1.3em;
    line-height:1.1em;
    margin:20px 0;
  }

  .notfound__logo-image{
    padding-bottom:20px;
  }

  .menu__logo{
    width:220px;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  .notfound__container{
    width:350px;
  }

  .notfound__line{
    padding-bottom:10px;
  }

  .notfound__mensaje{
    font-size:1.3em;
    line-height:1.1em;
    margin:20px 0;
  }

  .notfound__logo-image{
    padding-bottom:30px;
  }

  .menu__logo{
    width:250px;
  }
}

</style>