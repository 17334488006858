<template>
  <div class="page__wrapper">
    <div class="page__container">
      <div class="page__header-container">
        <div class="page__header-container-left">
          <SpriteAnimator
              :id="'sprite'"
              :spritesheet="require('../../../../assets/sprites/appear/spritesheet.png')"
              :json="require('../../../../assets/sprites/appear/spritesheet.json')"
              :yoyo="false"
              :fps="15"
              :width=headerSpriteWidth
              :isLoop="false"
              ref="sprite"
          ></SpriteAnimator>
          <div class="page__header-title">
            EDITAR CLIENTE
          </div>
          <Button v-on:action-button-clicked="regresar()"
                  labelText="REGRESAR"
                  bgColor="#C00606"
                  :width=buttonWidth />
        </div>
        <div class="page__header-container-right">
          <Button v-on:action-button-clicked="editarCliente()"
                  labelText="EDITAR DATOS"
                  :width=buttonWidth />
        </div>
      </div>
      <div class="page__header-container-mobile">
        <div class="page__header-container-mobile-top">
          <div class="page__header-sprite">
            <SpriteAnimator
                :id="'sprite'"
                :spritesheet="require('../../../../assets/sprites/appear/spritesheet.png')"
                :json="require('../../../../assets/sprites/appear/spritesheet.json')"
                :yoyo="false"
                :fps="15"
                :width=headerSpriteWidth
                :isLoop="false"
                ref="sprite"
            ></SpriteAnimator>
          </div>
          <div class="page__header-title">
            EDITAR CLIENTE
          </div>
        </div>
        <div class="page__header-container-mobile-botom">
          <Button v-on:action-button-clicked="regresar()"
                  labelText="REGRESAR"
                  bgColor="#C00606"
                  :width=buttonWidth
                  class="button__margin-right"/>
          <Button v-on:action-button-clicked="editarCliente()"
                  labelText="EDITAR DATOS"
                  :width=buttonWidth />
        </div>
      </div>
      <div class="page__content-container">
        <div class="page__main-container">
          <div class="page__main-container-left">
            <div class="page__form-border-container">
              <div class="page__form-image-upload-cotainer">
                <div class="page__form-image-upload-cotainer-left">
                  <BackgroundImage isBordered="S" height="150px" width="150px" positionHeight="center" :backgroundURL="rutaImagen"/>
                </div>
                <div class="page__form-image-upload-cotainer-right">
                  <ButtonUpload @upload-object="getUploadObject" />
                </div>
              </div>
              <div class="page__form-image-upload-cotainer-mobile">
                <div class="page__form-image-upload-cotainer-mobile-top">
                  <BackgroundImage isBordered="S" height="150px" width="150px" positionHeight="center" :backgroundURL="rutaImagen"/>
                </div>
                <div class="page__form-image-upload-cotainer-mobile-bottom">
                  <ButtonUpload @upload-object="getUploadObject" />
                </div>
              </div>
              <div class="page__form-line">
                <div class="page__form-label">
                  <Label labelText="Nombre" fontFamily="kronika" fontColor="#FF5229" v-bind:isError="errorNombre" />
                </div>
                <div class="full-width">
                  <TextBox placeholder="" maxlength="250" v-model="nombre" v-bind:isError="errorNombre" />
                </div>
              </div>
              <div class="page__form-error-message">
                {{ mensajeError }}
              </div>
            </div>
          </div>
          <div class="page__main-container-right">
          </div>
        </div>
      </div>
    </div>
    <Modal type="CONFIRM"
           :message="modal_message"
           :messageLoading="modal_messageLoading"
           :isVisible="modal_isVisible"
           :isLoading="modal_isLoading"
           :transition="modal_transition"
           :style="computedModalStyle"
           @aceptar="modalAceptar"
           @cancelar="modalCancelar" />
    <ModalError :title="modalErrorTitle"
                :message="modalErrorMessage"
                :exception="modalErrorException"
                v-model="isModalErrorMessage"
                v-on:aceptar="modal__ErrorAceptar()" />
  </div>
</template>

<script>
  import SpriteAnimator from "@/components/SpriteAnimator";
  import Button from "@/components/Button";
  import ButtonForm from "@/components/ButtonForm";
  import ButtonUpload from "@/components/ButtonUpload";
  import Label from "@/components/Label";
  import TextBox from "@/components/TextBox";
  import TextArea from "@/components/TextArea";
  import TextEditor from "@/components/custom/texteditor/TextEditor";
  import CheckBoxForm from "@/components/CheckBoxForm";
  import BackgroundImage from "@/components/BackgroundImage";
  import Modal from "@/components/Modal";
  import ModalError from "@/components/ModalError";

  export default {
    name: "ClienteEditar",
    components: {
      SpriteAnimator,
      Button,
      ButtonForm,
      ButtonUpload,
      Label,
      TextBox,
      TextArea,
      TextEditor,
      CheckBoxForm,
      BackgroundImage,
      Modal,
      ModalError,
    },
    data() {
      return {
        // MEDIA QUERIES
        headerSpriteWidth:0,
        buttonWidth:"0",

        // FORM
        idCliente: 0,
        nombre: "",
        rutaImagen: "",
        fileImagen: null,
        mensajeError: "",

        // FORM ERROR
        errorNombre: false,

        // IMAGES
        isPageImagesLoad: true,
        isExternalImagesLoad: true,
        isMountFinished: false,

        // MODAL
        modal_message:'',
        modal_messageLoading:'',
        modal_isVisible:false,
        modal_isLoading:false,
        modal_isClosed:false,
        modal_transition:"",

        // MODAL ERROR
        isModalErrorMessage:false,
        modalErrorTitle:'',
        modalErrorMessage:'',
        modalErrorException:'',
      };
    },
    created() {
      try {
        this.modalTransition = "";
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();

        this.mensajeError = "";
      } catch (error) {
        console.log("[ClienteEditar.vue] created()::ERROR = "+error);
      }
    },
    async mounted() {
      try {
        // BROWSER BACK/FORWARD BUTTON
        window.popStateDetected = false
        window.addEventListener('popstate', () => { window.popStateDetected = true });

        var idCliente = this.$route.params.idCliente;
        this.idCliente = idCliente;

        await this.detalleCliente(idCliente);
        await this.page__preloadImages();
        await this.$nextTick();
        this.isMountFinished = true;

      } catch (error) {
        this.$emit('show-loading', false);
        var tmpExcepcion = "[ClienteEditar.vue] mounted()::ERROR >>> "+error;
        var strExcepcion = tmpExcepcion.replace('Error:', '');
        this.modal__ErrorRegistrarExcepcion(strExcepcion);
      }
    },
    computed: {
      computedModalStyle: function () {
        var objStyle = {};
        objStyle["visibility"] = "visible";

        if(this.modal_isClosed){
          objStyle["visibility"] = "hidden";
        }

        return objStyle;
      },
      page__isAllContentLoaded(){
        return this.isPageImagesLoad && this.isExternalImagesLoad && this.isMountFinished;
      },
    },
    watch: {
      page__isAllContentLoaded(newValue) {
        if(newValue){
          var that;
          that = this;
          setTimeout(function () {
            that.$emit('show-loading', false);
          }, 1500);
        }
      },
    },
    methods: {
      async detalleCliente(idCliente){
        try {
          var clienteObj = {};
          clienteObj['id'] = idCliente;

          var {default: ClienteLogic} = await import('@/logic/cliente.logic');
          var httpCliente = await ClienteLogic.detalle(clienteObj);
          var httpClienteData = httpCliente.data;
          var responseCliente = httpCliente.data.response;

          switch(httpClienteData.status) {
            case "OK":
              this.idCliente = responseCliente.id;
              this.nombre = responseCliente.nombre;
              this.rutaImagen = "";

              if(responseCliente.rutaImagen!=null){
                this.rutaImagen = responseCliente.rutaImagen;
              }

              break;

            default:
              var error = httpClienteData.message;
              throw Error("[ClienteEditar.vue] detalleCliente()::ERROR = "+error);
          }

        } catch (error) {
          throw Error("[ClienteEditar.vue] detalleCliente()::ERROR = "+error);
        }
      },

      async editarCliente(){
        try {
          var contVacio = 0;
          var strNombre = this.nombre;

          if(strNombre===""){
            this.errorNombre=true;
            contVacio = contVacio + 1;
          } else {
            this.errorNombre=false;
          }

          if(contVacio===0){
            this.mensajeError = "";
            this.modal_message = "¿Está seguro que desea editar esta información?";
            this.modal_transition = "RIGHT";
            this.modal_isClosed = false;
            this.modal_isVisible = true;

          } else {
            this.mensajeError = "/* Por favor ingrese los datos faltantes */";
          }

        } catch (error) {
          this.$emit('show-loading', false);
          var tmpExcepcion = "[ClienteEditar.vue] editarCliente()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },

      regresar(){
        try {
          this.$emit('show-loading', true);
          var nextRoute = 'session_cliente_listar';
          this.$router.push({ name: nextRoute }, () => {
          });

        } catch (error) {
          this.$emit('show-loading', false);
          var tmpExcepcion = "[ClienteEditar.vue] regresar()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },

      getUploadObject(objData){
        this.fileImagen = objData.data;
        this.rutaImagen = objData.url;
      },


      // LISTENERS
      handleWindowResize(){
        this.mediaQueries();
      },


      // MODAL
      async modalAceptar(){
        try {
          this.modal_isClosed = true;
          this.modal_isVisible = false;

          this.$emit('show-loading', true, "Actualizando información. Un momento por favor","RIGHT");

          var objUser = this.$store.getters['authSessionStore/getUser'];

          var clienteObj = {};
          clienteObj['id'] = this.idCliente;
          clienteObj['nombre'] = this.nombre;
          clienteObj['fileImagen'] = this.fileImagen;

          var {default: ClienteLogic} = await import('@/logic/cliente.logic');
          var httpCliente = await ClienteLogic.editar(clienteObj);
          var httpClienteData = httpCliente.data;
          var responseCliente = httpCliente.data.response;

          switch(httpClienteData.status) {
            case "OK":
              var nextRoute = 'session_cliente_listar';
              this.$router.push({ name: nextRoute }, () => {
              });
              break;

            default:
              var error = httpClienteData.message;
              throw Error("[ClienteEditar.vue] modalAceptar()::ERROR = "+error);
          }

        } catch (error) {
          this.$emit('show-loading', false);
          var tmpExcepcion = "[ClienteEditar.vue] modalAceptar()::ERROR >>> "+error;
          var strExcepcion = tmpExcepcion.replace('Error:', '');
          this.modal__ErrorRegistrarExcepcion(strExcepcion);
        }
      },
      modalCancelar(){
        this.modal_isClosed = false;
        this.modal_isVisible = false;
      },


      // MODAL ERROR
      modal__ErrorRegistrarExcepcion(excepcion){
        this.modalErrorTitle = "LO SENTIMOS";
        this.modalErrorMessage = "Se produjo un error al realizar la operación. Por favor intente más tarde.";
        this.modalErrorException = excepcion;
        this.isModalErrorMessage = true;
      },
      modal__ErrorAceptar(){
        this.isModalErrorMessage=false;
      },

      // =================
      // PRIVATE FUNCTIONS
      // =================

      mediaQueries() {
        var _headerSpriteWidth = 0;
        var _buttonWidth = 0;

        const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
        const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );
        const matchMediaLarge = window.matchMedia( "(min-width: 64em)" );

        if (matchMediaSmall.matches) {
          _headerSpriteWidth = 120;
          _buttonWidth = 120;
        }

        if (matchMediaMedium.matches) {
          _headerSpriteWidth = 120;
          _buttonWidth = 120;
        }

        if (matchMediaLarge.matches) {
          _headerSpriteWidth = 120;
          _buttonWidth = 120;
        }

        this.headerSpriteWidth = _headerSpriteWidth;
        this.buttonWidth = _buttonWidth+"px";
      },

      async page__preloadImages() {
        var {default: ImageHelper} = await import('@/helpers/image.helper');

        this.isPageImagesLoad = true;
      },
    },
    beforeRouteLeave (to, from, next) {
      if(window.popStateDetected){
        // HACK: Se mostrará el LOADING siembre que se abandone la página. Esto nos
        // permitirá abordar los casos en que se haga BACK/FORWARD en el browser
        this.$emit('show-loading', true);
      }
      next();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleWindowResize);
      window.removeEventListener('popstate', () => { window.popStateDetected = true });
    }
  };
</script>

<style scoped>

/* Small only */
@media screen and (max-width: 39.9375em) {

}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

}

/* Large and up */
@media screen and (min-width: 64em) {

}

</style>