import { render, staticRenderFns } from "./CheckBoxForm.vue?vue&type=template&id=05e30969&scoped=true&"
import script from "./CheckBoxForm.vue?vue&type=script&lang=js&"
export * from "./CheckBoxForm.vue?vue&type=script&lang=js&"
import style0 from "./CheckBoxForm.vue?vue&type=style&index=0&id=05e30969&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05e30969",
  null
  
)

/* custom blocks */
import block0 from "./CheckBoxForm.vue?vue&type=custom&index=0&blockType=CheckBox&value=foo&v-model=MySelectedValues"
if (typeof block0 === 'function') block0(component)
import block1 from "./CheckBoxForm.vue?vue&type=custom&index=1&blockType=CheckBox&value=bar&v-model=MySelectedValues"
if (typeof block1 === 'function') block1(component)
import block2 from "./CheckBoxForm.vue?vue&type=custom&index=2&blockType=CheckBox&value=baz&v-model=MySelectedValues"
if (typeof block2 === 'function') block2(component)
import block3 from "./CheckBoxForm.vue?vue&type=custom&index=3&blockType=CheckBox&v-model=MySelectedValues"
if (typeof block3 === 'function') block3(component)

export default component.exports